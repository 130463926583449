import React, {useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {mailUserListPopup} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";


function SendMailUserListPopup({match}){
    const dispatch = useDispatch();
    const idx = match.params.idx;
    const fsum = match.params.fsum; //발송실패 건수

    const [pageOfItems, setPageOfItems] = useState([]);
    const [userList, setUserList] = useState([]);

    //리스트 불러오기
    useLayoutEffect(() => {
       let body = {
           idx: idx,
       };

       dispatch(mailUserListPopup(body)).then((res) => {
           if(res.payload.userList) {
               const result = res.payload.userList;
               setUserList(result);
           }

       });
    }, [dispatch, idx]);

    //페이징 처리
    const onChangePage = (pageOfItems) => {
        setPageOfItems(pageOfItems);
    }

    const pageOfItem = pageOfItems.map((item) => {
        return (
            <tr key={item.histIdx}>
                <td style={{ width: "10%" }}>{item.rownum}</td>
                <td style={{ width: "10%" }}>{item.trandt}</td>
                <td>{item.memid}</td>
                <td style={{ width: "10%" }}>{item.memrealname}</td>
            </tr>
        );
    });


    return (
        <>
            <div className="address_detail">
                <div className="user_list_column">
                    <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                        발송실패 유저리스트
                    </h2>
                    <div className="select_column">
                        <div>| 발송실패 : 총 {fsum}건</div>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th style={{ width: "5%" }}>No.</th>
                            <th style={{ width: "30%" }}>발송일자</th>
                            <th>아이디(이메일)</th>
                            <th style={{ width: "15%" }}>이름</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pageOfItem.length > 0 ? (pageOfItem) : (
                            <tr>
                                <td colSpan={4}>등록된 글이 없습니다.</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <PaginationNum
                        items={userList}
                        onChangePage={onChangePage}
                    />
                </div>
            </div>
         </>
    );
}

export default SendMailUserListPopup