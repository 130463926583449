import React from "react";
import pageFirst from "../images/page_first.png";
import pagePrev from "../images/page_prev_gray.png";
import pageNext from "../images/page_next_gray.png";
import pageLast from "../images/page_last.png";

const defaultProps = {
  initialPage: 1,
  pageSize: 10,
};

class PaginationNum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pager: {},
      value: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  setPage(page) {
    var { items, pageSize } = this.props;
    var pager = this.state.pager;
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    pager = this.getPager(items.length, page, pageSize);

    var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
    this.setState({ pager: pager });
    if (pageOfItems === "false") {
      pageOfItems = [];
    }
    this.props.onChangePage(pageOfItems, page);
  }

  onKeyPress(e) {
    var value = this.state.value;
    if (e.key === "Enter") {
      this.setPage(value);
      document.activeElement.blur();
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || 10;

    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );
    // 페이지 변할 때 현재 페이지 반환해주게 해놓은 것(UserFree.js 참고)
    if (this.props.getPageNum) this.props.getPageNum(currentPage);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    var pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }

    return (
      <>
        <div className="admin_pagenation">
          <img
            src={pageFirst}
            alt="<<"
            className="page_first btn"
            onClick={() => this.setPage(1)}
          />
          <img
            src={pagePrev}
            alt="<"
            className="page_prev btn"
            onClick={() => this.setPage(pager.currentPage - 1)}
          />
          <div className="current_page">
            Page
            <input
              type="text"
              value={pager.currentPage}
              onKeyPress={this.onKeyPress}
              onChange={this.handleChange}
              style={{ marginLeft: 6, marginRight: 6 }}
              readOnly
            />
            of {pager.totalPages}
          </div>
          <img
            src={pageNext}
            alt=">"
            className="page_next btn"
            onClick={() => this.setPage(pager.currentPage + 1)}
          />
          <img
            src={pageLast}
            alt=">>"
            className="page_last btn"
            onClick={() => this.setPage(pager.totalPages)}
          />
        </div>
      </>
    );
  }
}

PaginationNum.defaultProps = defaultProps;
export default PaginationNum;
