import "./App.css";
import "./reset.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Main/Home";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Join/Login";
import JoinUser from "./pages/Join/JoinUser";
import JoinSNS from "./pages/Join/JoinSNS"; // ==> kakao & naver & apple 회원가입 병합본
import JoinAdd from "./pages/Join/JoinAdd";
import JoinFinish from "./pages/Join/JoinFinish";
import JoinAuth from "./pages/Join/JoinAuth";
import JoinEmailAuth from "./pages/Join/JoinEmailAuth";
import FindId from "./pages/Find/FindId";
import FindPw from "./pages/Find/FindPw";
import DormantUser from "./pages/Find/DormantUser";
import Counseling from "./pages/Board/Counseling";
import CounselingWrite from "./pages/Board/CounselingWrite";
import DetailCounseling from "./pages/Board/CounselingDetail";
import Information from "./pages/Board/Information";
import InformationDetail from "./pages/Board/InformationDetail";
import SearchResult from "./pages/Main/SearchResult";
import Mypage from "./pages/Mypage/Mypage";
import MypageAddress from "./pages/Mypage/MypageAddress";
import MyArticle from "./pages/Mypage/MyArticle";
import MyNotice from "./pages/Mypage/MyNotice";
import MyNoticeSetting from "./pages/Mypage/MyNoticeSetting";
import NoticeBoard from "./pages/Board/NoticeBoard";
import NoticeBoardDetail from "./pages/Board/NoticeBoardDetail";
import FrequentlyBoard from "./pages/Board/FrequentlyBoard";
import FrequentlyBoardDetail from "./pages/Board/FrequentlyBoardDetail";
import LawBoard from "./pages/Board/LawBoard";
import LawBoardDetail from "./pages/Board/LawBoardDetail";
import EventBoard from "./pages/Board/EventBoard";
import Agreement from "./pages/Mypage/Agreement";
import Perinformation from "./pages/Mypage/Perinformation";
import MypageModify from "./pages/Mypage/MypageModify";
import InquiryBoard from "./pages/Mypage/InquiryBoard";
import InquiryBoardDetail from "./pages/Mypage/InquiryBoardDetail";
import InquiryWrite from "./pages/Mypage/InquiryWrite";
import AutoAuth from "./pages/Mypage/AutoAuth";

import Agree01 from "./pages/Main/Agree01";
import Agree02 from "./pages/Main/Agree02";

import Description from "./pages/Main/Description";
import Maintenance from "./pages/Maintenance";

// 관리자 페이지
import AdminLogin from "./admin/AdminLogin";
import UserFree from "./admin/ManagerUser/UserFree";
import UserOut from "./admin/ManagerUser/OutUser/UserOut";
import UserOutDetail from "./admin/ManagerUser/OutUser/UserOutDetail";
import UserFreeDetail from "./admin/ManagerUser/UserFreeDetail";
import UserLawyer from "./admin/ManagerUser/UserLawyer";
import UserLawyerDetail from "./admin/ManagerUser/UserLawyerDetail";
import UserLawyerAdd from "./admin/ManagerUser/UserLawyerAdd";
import UserAdmin from "./admin/ManagerUser/UserAdmin";
import UserAdminDetail from "./admin/ManagerUser/UserAdminDetail";
import UserAdminAdd from "./admin/ManagerUser/UserAdminAdd";
import AddressDetail from "./admin/ManagerAddress/AddressDetail";
import Address from "./admin/ManagerAddress/Address";
import BoardConsult from "./admin/ManagerBoard/BoardConsult";
import BoardConsultDetail from "./admin/ManagerBoard/BoardConsultDetail";
import BoardDivision from "./admin/ManagerBoard/BoardDivision";
import BlogBoard from "./admin/ManagerBlog/BlogBoard";
import BlogBoardDetail from "./admin/ManagerBlog/BlogBoardDetail";
import BlogDivision from "./admin/ManagerBlog/BlogDivision";
import ServiceNotice from "./admin/ServiceCenter/ServiceNotice";
import ServiceNoticeDetail from "./admin/ServiceCenter/ServiceNoticeDetail";
import ServiceEvent from "./admin/ServiceCenter/ServiceEvent";
import ServiceEventDetail from "./admin/ServiceCenter/ServiceEventDetail";
import ServiceNoticeAdd from "./admin/ServiceCenter/ServiceNoticeAdd";
import ServiceEventAdd from "./admin/ServiceCenter/ServiceEventAdd";
import ServiceInquiry from "./admin/ServiceCenter/ServiceInquiry";
import ServiceInquiryDetail from "./admin/ServiceCenter/ServiceInquiryDetail";
import ServiceFaq from "./admin/ServiceCenter/ServiceFaq";
import ServiceFaqListAdd from "./admin/ServiceCenter/ServiceFaqListAdd";
import ServiceFaqListDetail from "./admin/ServiceCenter/ServiceFaqListDetail";
import ServiceLaw from "./admin/ServiceCenter/ServiceLaw";
import ServiceLawListAdd from "./admin/ServiceCenter/ServiceLawListAdd";
import ServiceLawListDetail from "./admin/ServiceCenter/ServiceLawListDetail";
import ServicePush from "./admin/ServiceCenter/ServicePush";
import LogLogin from "./admin/ManagerLog/LogLogin";
import LogPts from "./admin/ManagerLog/LogPts";
import LogMarketing from "./admin/ManagerLog/LogMarketing";
import SetMain from "./admin/ManageSet/SetMain";
import SetEvent from "./admin/ManageSet/SetEvent";
import SetPush from "./admin/ManageSet/SetPush";
import SetProvision from "./admin/ManageSet/SetProvision";
import SetVersion from "./admin/ManageSet/SetVersion";

import MypageMobile from "./pages/Mypage/MypageMobile";
import AgreementMarketing from "./pages/Mypage/AgreementMarketing";
import Auth from "./hoc/auth";
import MyArticleDetail from "./pages/Mypage/MyArticleDetail";
import EventBoardDetail from "./pages/Board/EventBoardDetail";
import BlogBoardAdd from "./admin/ManagerBlog/BlogBoardAdd";
import Success_nice from "./pages/NiceResult/Success_nice";
import Fail_nice from "./pages/NiceResult/Fail_nice";
import Success_nice_modify from "./pages/NiceResult/success_nice_modify";
import XauthSave from "./pages/XauthSave";
import OutLogLogin from "./admin/ManagerLog/OutLogLogin";
import OutLogMarketing from "./admin/ManagerLog/OutLogMarketing";
import Success_nice_dormant from "./pages/NiceResult/Success_nice_dormant";
import SendMailScheduleDetail from "./admin/ManageSet/sendMailScheduleDetail";
import SendMailResend from "./admin/ManageSet/sendMailResend";
import SendMailHistoryDetail from "./admin/ManageSet/sendMailHistoryDetail";
import SendMailUserListPopup from "./admin/ManageSet/sendMailUserListPopup";
import SendMailScheduleList from "./admin/ManageSet/sendMailScheduleList";
import SendMailHistoryList from "./admin/ManageSet/sendMailHistoryList";
import SendMailWrite from "./admin/ManageSet/sendMailWrite";
import SetConstruction from "./admin/ManageSet/setConstruction";
import SendSmsWrite from "./admin/ManageSet/sendSmsWrite";
import SendSmsHistoryList from "./admin/ManageSet/sendSmsHistoryList";

import { ADMIN_SUPER, ADMIN_GENERAL, ADMIN_LAWYER, ADMIN_PRIVACY } from "./Common/adminType"


if (window.location.hostname === "www.zibfine.com") {
  window.location.replace("https://zibfine.com" + window.location.pathname);
}

function App() {
  return (
    <>
      {/* 로그인해야 진입 가능 ===> true
      로그인하면 진입 불가 ===> false
      둘다 진입 가능 ===> null */}
      <Router>
        <Route exact path="/" component={Auth(Home, null, false)} />
        <Route exact path="/unauthorized" component={Auth(Unauthorized, null, false)} />
        <Route exact path="/maintenance" component={Maintenance} />

        <Route exact path="/agree01" component={Auth(Agree01, null, false)} />
        <Route exact path="/agree02" component={Auth(Agree02, null, false)} />
        <Route exact path="/pts/description" component={Auth(Description, null, false)} /> 

        <Route path="/login" component={Auth(Login, false, false)} />
        <Route exact path="/join" component={Auth(JoinUser, false)} />
        <Route path="/join/sns" component={Auth(JoinSNS, false)} />

        <Route path="/join/add" component={Auth(JoinAdd, true)} />
        <Route path="/join/finish" component={JoinFinish} />
        <Route path="/join/auth" component={Auth(JoinAuth, true)} />
        <Route path="/join/email/auth" component={Auth(JoinEmailAuth, false)} />
        <Route path="/find/id" component={Auth(FindId, false)} />
        <Route path="/find/pw" component={Auth(FindPw, false)} />
        <Route path="/dormant" component={Auth(DormantUser, false)} />
        <Route path="/success_nice" component={Auth(Success_nice, null)} />
        <Route path="/x_authsave" component={Auth(XauthSave, null)} />
        <Route
          path="/success_nice_modify"
          component={Auth(Success_nice_modify, null)}
        />
        <Route
          path="/success_nice_dormant"
          component={Auth(Success_nice_dormant, null)}
        />
        <Route path="/fail_nice" component={Auth(Fail_nice, null)} />
        <Route
          exact
          path="/counseling/detail/:idx/:pagen"
          component={DetailCounseling}
        />
        <Route
          exact
          path="/counseling/:pagen"
          component={Auth(Counseling, null)}
        />
        <Route
          exact
          path="/counselingWrite"
          component={Auth(CounselingWrite, true)}
        />
        {/* replyComplete 값으로 화면 조정 */}
        <Route
          exact
          path="/information/:idx"
          component={Auth(Information, null)}
        />
        <Route
          path="/information/detail/:idx"
          component={Auth(InformationDetail, null)}
        />
        <Route
          path="/search/result/:keyword1/:keyword2"
          component={Auth(SearchResult, null)}
        />
        <Route exact path="/mypage" component={Auth(Mypage, true)} />
        <Route
          exact
          path="/mypage/mobile"
          component={Auth(MypageMobile, true)}
        />
        <Route path="/mypage/address" component={Auth(MypageAddress, true)} />
        <Route
          exact
          path="/mypage/myarticle"
          component={Auth(MyArticle, true)}
        />
        <Route
          path="/mypage/myarticle/detail/:idx"
          component={Auth(MyArticleDetail, true)}
        />
        <Route exact path="/mypage/notice" component={Auth(MyNotice, true)} />
        <Route
          path="/mypage/notice/setting"
          component={Auth(MyNoticeSetting, true)}
        />
        <Route
          exact
          path="/notice/board/:cate/:idx"
          component={Auth(NoticeBoard, null)}
        />
        <Route
          path="/notice/board/detail/:cate/:idx"
          component={Auth(NoticeBoardDetail, null)}
        />

        <Route
          exact
          path="/frequently/:idx"
          component={Auth(FrequentlyBoard, null)}
        />
        <Route
          path="/frequently/detail/:idx/:pagen"
          component={Auth(FrequentlyBoardDetail, null)}
        />

        <Route
          exact
          path="/law/:cate/:idx"
          component={Auth(LawBoard, null)}
        />
        <Route
          path="/law/detail/:cate/:idx/:pagen"
          component={Auth(LawBoardDetail, null)}
        />

        <Route exact path="/event" component={Auth(EventBoard, null)} />
        <Route
          path="/event/detail/:idx"
          component={Auth(EventBoardDetail, null)}
        />
        <Route path="/mypage/autoAuth" component={Auth(AutoAuth, true)} />
        <Route path="/mypage/agreement" component={Auth(Agreement, true)} />
        <Route
          path="/mypage/perinformation"
          component={Auth(Perinformation, true)}
        />
        <Route
          path="/mypage/agreementMarketing"
          component={Auth(AgreementMarketing, true)}
        />
        <Route path="/mypage/modify" component={Auth(MypageModify, true)} />

        <Route
          exact
          path="/mypage/inquiry"
          component={Auth(InquiryBoard, true)}
        />
        <Route
          path="/mypage/inquiry/detail/:idx"
          component={Auth(InquiryBoardDetail, true)}
        />
        <Route
          path="/mypage/inquiry/write"
          component={Auth(InquiryWrite, true)}
        />

        {/* admin 페이지 */}
        <Route exact path="/rf_zib_admin" component={Auth(AdminLogin, true, true)} />
        <Switch>
          <Route
            path="/rf_zib_admin/user/free/detail/:idx/:pagen"
            component={Auth(UserFreeDetail, true, ADMIN_PRIVACY.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/free/:pagen"
            component={Auth(UserFree, true, ADMIN_PRIVACY.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/out/detail/:idx/:pagen"
            component={Auth(UserOutDetail, true, ADMIN_PRIVACY.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/out/:pagen"
            component={Auth(UserOut, true, ADMIN_PRIVACY.Memtype)}
          />
          <Route
            exact
            path="/rf_zib_admin/user/lawyer/add"
            component={Auth(UserLawyerAdd, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/lawyer/detail/:idx/:pagen"
            component={Auth(UserLawyerDetail, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/lawyer/:pagen"
            component={Auth(UserLawyer, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            exact
            path="/rf_zib_admin/user/admin/add"
            component={Auth(UserAdminAdd, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/admin/detail/:idx/:pagen"
            component={Auth(UserAdminDetail, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/user/admin/:pagen"
            component={Auth(UserAdmin, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/board/consult/detail/:idx/:pagen"
            component={Auth(BoardConsultDetail, true, ADMIN_LAWYER.Memtype)}
          />
          <Route
            exact
            path="/rf_zib_admin/board/consult/:pagen"
            component={Auth(BoardConsult, true, ADMIN_LAWYER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/service/inquiry/detail/:idx/:pagen"
            component={Auth(ServiceInquiryDetail, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            exact
            path="/rf_zib_admin/service/inquiry/:pagen"
            component={Auth(ServiceInquiry, true, ADMIN_SUPER.Memtype)}
          />
          <Route
            path="/rf_zib_admin/address/detail/:idx/:pagen"
            component={Auth(AddressDetail, true, ADMIN_GENERAL.Memtype)}
          />
          <Route
            exact
            path="/rf_zib_admin/address/:pagen"
            component={Auth(Address, true, ADMIN_GENERAL.Memtype)}
          />
        </Switch>

        <Route
          path="/rf_zib_admin/board/division"
          component={Auth(BoardDivision, true, ADMIN_LAWYER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/blog/board"
          component={Auth(BlogBoard, true, ADMIN_GENERAL.Memtype)}
        />
        <Route
          path="/rf_zib_admin/blog/board/add"
          component={Auth(BlogBoardAdd, true, ADMIN_GENERAL.Memtype)}
        />
        <Route
          path="/rf_zib_admin/blog/board/detail/:idx"
          component={Auth(BlogBoardDetail, true, ADMIN_GENERAL.Memtype)}
        />
        <Route
          path="/rf_zib_admin/blog/division"
          component={Auth(BlogDivision, true, ADMIN_GENERAL.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/notice"
          component={Auth(ServiceNotice, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/service/notice/add"
          component={Auth(ServiceNoticeAdd, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/service/notice/detail/:idx"
          component={Auth(ServiceNoticeDetail, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/event"
          component={Auth(ServiceEvent, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/service/event/detail/:idx"
          component={Auth(ServiceEventDetail, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/service/event/add"
          component={Auth(ServiceEventAdd, true, ADMIN_SUPER.Memtype)}
        />

        <Route
          exact
          path="/rf_zib_admin/service/faq"
          component={Auth(ServiceFaq, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/faq/add"
          component={Auth(ServiceFaqListAdd, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/faq/detail/:idx"
          component={Auth(ServiceFaqListDetail, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/law"
          component={Auth(ServiceLaw, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/law/add"
          component={Auth(ServiceLawListAdd, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/law/detail/:idx"
          component={Auth(ServiceLawListDetail, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/service/push"
          component={Auth(ServicePush, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/log/login"
          component={Auth(LogLogin, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/log/marketing"
          component={Auth(LogMarketing, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/out/log/login"
          component={Auth(OutLogLogin, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/out/log/marketing"
          component={Auth(OutLogMarketing, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/log/pts"
          component={Auth(LogPts, true, ADMIN_SUPER.Memtype)}
        />

        <Route
          path="/rf_zib_admin/set/main"
          component={Auth(SetMain, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/set/event"
          component={Auth(SetEvent, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/set/push"
          component={Auth(SetPush, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/set/provision"
          component={Auth(SetProvision, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          path="/rf_zib_admin/set/version"
          component={Auth(SetVersion, true, ADMIN_SUPER.Memtype)}
        />
        <Route
          exact
          path="/rf_zib_admin/set/sms"
          component={Auth(SendSmsWrite, true, "super")}
        />
        <Route
            exact
            path="/rf_zib_admin/set/sms/history"
            component={Auth(SendSmsHistoryList, true, "super")}
        />
        <Route
          exact
          path="/rf_zib_admin/set/mail"
          component={Auth(SendMailWrite, true, "super")}
        />
        <Route
            exact
            path="/rf_zib_admin/set/mail/schedule"
            component={Auth(SendMailScheduleList, true, "super")}
        />
        <Route
            exact
            path="/rf_zib_admin/set/mail/history"
            component={Auth(SendMailHistoryList, true, "super")}
        />
        <Route
          path="/rf_zib_admin/set/mail/schedule/detail/:idx"
          component={Auth(SendMailScheduleDetail, true, "super")}
        />
        <Route
          path="/rf_zib_admin/set/mail/resend/:idx"
          component={Auth(SendMailResend, true, "super")}
        />
        <Route
          path="/rf_zib_admin/set/mail/history/detail/:idx"
          component={Auth(SendMailHistoryDetail, true, "super")}
        />
        <Route
          path="/rf_zib_admin/set/mail/popup/:idx/:fsum"
          component={Auth(SendMailUserListPopup, true, "super")}
        />
        <Route
          exact
          path="/rf_zib_admin/set/construction"
          component={Auth(SetConstruction, true, "super")}
        />

      </Router>
    </>
  );
}

export default App;
