export const data = [
  {
    no: 1,
    title: "부동산정보",
    path: "/rf_zib_admin/blog/board",
  },
  {
    no: 2,
    title: "블로그 구분",
    path: "/rf_zib_admin/blog/division",
  },
];
