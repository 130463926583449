export const data = [
  {
    no: 1,
    title: "무료법률상담",
    path: "/rf_zib_admin/board/consult",
  },
  {
    no: 2,
    title: "게시판 구분",
    path: "/rf_zib_admin/board/division",
  },
];
