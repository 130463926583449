import React, { useLayoutEffect, useEffect, useState } from "react";
import "./Main.css";
import next from "../../images/next.png";
import moveTop from "../../images/move_top.png";
import { BrowserRouter, Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import SliderAddress from "../../components/SliderAddress";
import SliderContractDate from "../../components/SliderContractDate";
import SliderMainBanner from "../../components/SliderMainBanner";
import SubBanner from "../../components/SubBanner";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getAlarm } from "../../_actions/main_action";
import { changeDateFormat, deleteCookie, getCookie } from "../../Common/lib";
import MobileBottomNav from "../Footer/MobileBottomNav";
import axios from "axios";

import Service1 from "../../images/svg/service1.svg";
import Service2 from "../../images/svg/service2.svg";
import Service3 from "../../images/svg/service3.svg";

import MobileService1 from "../../images/svg/m-service1.svg";
import MobileService2 from "../../images/svg/m-service2.svg";
import MobileService3 from "../../images/svg/m-service3.svg";

import LinkEvent from "../../images/svg/link-event.svg";
import LinkFrequently from "../../images/svg/link-frequently.svg";
import LinkBlog from "../../images/svg/link-blog.svg";
import LinkInstargram from "../../images/svg/link-instargram.svg";
import LinkFacebook from "../../images/svg/link-facebook.svg";
import LinkYoutube from "../../images/svg/link-youtube.svg";

import MobileLinkEvent from "../../images/svg/m-link-event.svg";
import MobileLinkFrequently from "../../images/svg/m-link-frequently.svg";
import MobileLinkBlog from "../../images/svg/m-link-blog.svg";
import MobileLinkInstargram from "../../images/svg/m-link-instargram.svg";
import MobileLinkFacebook from "../../images/svg/m-link-facebook.svg";
import MobileLinkYoutube from "../../images/svg/m-link-youtube.svg";
import closebtn from "../../images/close.png";

function Home({ history }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [alarmList, setAlarmList] = useState([]);
  const [recentNotice, setRecentNotice] = useState({}); //최근 공지글

  const [faqWarning, setFaqWarning] = useState(false); // 법률faq

  const moveScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const hiddenUntil = localStorage.getItem('popupHiddenUntil');

  useEffect(() => {

    console.log(hiddenUntil);
    const today = new Date();
    const handleMainPop = () => {
      if (hiddenUntil && hiddenUntil > today) {
        console.log('리턴');
      // 현재 date가 localStorage의 시간보다 크면 return
        return;
      }
       if (!hiddenUntil || hiddenUntil < today) {
      console.log('콘솔');
      // 저장된 date가 없거나 today보다 작다면 popup 노출
      setFaqWarning(true);
      }
    };
    window.setTimeout(handleMainPop, 1000); // 1초 뒤 실행


  }, [hiddenUntil]);

  useLayoutEffect(() => {
    // const x_auth = localStorage.getItem("x_auth");
    const x_auth = getCookie("x_auth");

    // 유저정보
    getRecentNotice();
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    // 알림 가져오기
    dispatch(getAlarm()).then((res) => {
      if (res.payload.alarmList) {
        setAlarmList(res.payload.alarmList);
      }
    });
    if (isMobile && x_auth) {
      window.addEventListener("getToken", async (e) => {
        if (e.detail) {
          let url = "/api/deviceToken";
          let body = {
            x_auth: x_auth,
            deviceToken: e.detail,
          };
          axios.post(url, body).then((res) => {
            if (res.data.result === "undefined") {
              localStorage.clear();
              deleteCookie("x_auth");

              setIsLoginAuth(false);
              setMemname(null);
              setProfilePath("");
            }
            return res.data.result;
          });
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const getRecentNotice = () => {
    let url = "/api/service/notice/recent";
    axios
      .post(url)
      .then((res) => {
        if (res.data.result === "success" && res.data.notice) {
          setRecentNotice(res.data.notice);
        }
      })
      .catch((error) => console.log(error));
  };

  const gotoFaq = () => {
    history.push("/law/전체/1");
  }
  
  const closeFaq = () => {
    setFaqWarning(false);
  }

  const dayOff =  () => {
    setFaqWarning(false);
    const now = new Date();
    const tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours
    localStorage.setItem('popupHiddenUntil', tomorrow.getTime());
    console.log('dayOff');
  }

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobileHome" : null}>
        <Header
          isLoginAuth={isLoginAuth}
          memname={memname}
          profilePath={profilePath}
        />
        <div className="main center_main" id="home">
          {faqWarning ?
              <div style={{ position: "absolute", left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.6)", display: "flex", justifyContent: "space-around", alignItems: "center", zIndex: 10 }}>
                <div style={{ backgroundColor: "#fff", width: "350px", height: "350px", borderRadius: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={closebtn} alt="close" onClick={closeFaq} style={{ padding: "15px 20px", cursor: "pointer" }} />
                  </div>
                  <div style={{ fontSize: 17, color:"#4a4a4a", marginBottom: 30, textAlign: "center", lineHeight: "25px" }}>
                      <br />
                      <div style={{margin: "0 auto", width: "280px", paddingBottom: "10px", borderBottom: "3px solid"}}>
                        <b style={{ fontSize: 22, color:"#000" }}>경매 무료 법률상담 FAQ 안내</b>
                      </div>
                      <br />
                      집파인 서비스의 효율적 이용을 위해<br />경매 법률상담 FAQ 작업이 완료되어 <br/>알려드립니다. <br />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "90%" }} class="blue_btn btn"  onClick={gotoFaq}>세부내용 확인하기</div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "90%", cursor: "pointer", marginTop:"20px" }} onClick={dayOff}>오늘 하루 그만보기</div>
                  </div>
                </div>
              </div> : null
            }
          {isLoginAuth ? (
            <>
              <SliderAddress memname={memname} />
              <SliderContractDate memname={memname} />

              {alarmList && alarmList.length > 0 ? (
                <>
                  <div className="isMobil_recent_notice">
                    <div className="recent_notice">
                      <div className="header">
                        {isMobile ? (
                          <BrowserRouter forceRefresh={true}>
                            <Link to="/mypage/notice">
                              <h2>최근 알림 내역</h2>
                            </Link>
                          </BrowserRouter>
                        ) : (
                          <h2>최근 알림 내역</h2>
                        )}

                        <BrowserRouter forceRefresh={true}>
                          <Link to="/mypage/notice">
                            <div className="go_btn btn">
                              전체보기
                              <img src={next} alt="next" />
                            </div>
                          </Link>
                        </BrowserRouter>
                      </div>
                      <div className="recent_notice_lists">
                        {alarmList.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className={
                                  item.alarmread === "미확인"
                                    ? "list_column active"
                                    : "list_column"
                                }
                              >
                                <div className="list_dot"></div>
                                <div className="list_desc">
                                  {changeDateFormat(item.senddate, 3, ".", "")}
                                  &nbsp;{item.alarmgubun}
                                </div>
                              </div>
                              {alarmList.length === index + 1 ? null : (
                                <div
                                  className={
                                    item.alarmread === "미확인"
                                      ? "bar active"
                                      : "bar"
                                  }
                                ></div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <SliderMainBanner />
          )}
          <SubBanner />
          <div className="section_3">
            <h2 className="service_title">주요서비스</h2>
            <div className="service_container">
              {isMobile ? (
                <>
                  <div className="isMobile_service_column2 mb-30">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/pts/description">
                        <img src={ MobileService1 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="isMobile_service_column2 mb-30">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/counseling/1">
                        <img src={ MobileService2 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="isMobile_service_column2 mb-30">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/information/1">
                        <img src={ MobileService3 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                </>
              ) : (
                <>
                  <div className="service_column2 pe-10">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/pts/description">
                        <img src={ Service1 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="service_column2 px-5">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/counseling/1">
                        <img src={ Service2 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="service_column2 ps-10">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/information/1">
                        <img src={ Service3 } alt="service-banner"/>
                      </Link>
                    </BrowserRouter>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="section_3">
            <h2 className="service_title">영상</h2>
            <div className="service_container">
              {isMobile ? (
                <>
                  <div className="service_row mb-30">
                    <iframe 
                      width="1280" 
                      height="250" 
                      src="https://www.youtube.com/embed/o1Ep-wfxvu4?version=3&loop=1&playlist=o1Ep-wfxvu4" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                  <div className="service_row mb-30">
                    <iframe 
                      width="1280" 
                      height="250" 
                      src="https://www.youtube.com/embed/SIZYdES30Gw?version=3&loop=1&playlist=SIZYdES30Gw" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                </>
              ) : (
                <>
                  <div className="service_column3 pe-10">
                    <iframe 
                      width="1280" 
                      height="720" 
                      src="https://www.youtube.com/embed/o1Ep-wfxvu4?version=3&loop=1&playlist=o1Ep-wfxvu4" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                  <div className="service_column3 ps-10">
                    <iframe 
                      width="1280" 
                      height="720" 
                      src="https://www.youtube.com/embed/SIZYdES30Gw?version=3&loop=1&playlist=SIZYdES30Gw" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="section_3">
            <div className="service_container">
              {isMobile ? (
                <>
                  <div className="service_row mb-30">
                    <h2 className="service_title service_title_inline_block">공지사항</h2>
                    <span className="service_title_float_right">
                      <BrowserRouter forceRefresh={true}>
                        <Link to="/notice/board/all/1">
                          <div className="go_btn btn">더보기</div>
                        </Link>
                      </BrowserRouter>
                    </span>
                    <div className="notice_info">
                      {recentNotice && recentNotice.title ? (
                        <span 
                          className="btn-pointer"
                          onClick={() => {
                            history.push(recentNotice.url);
                          }}
                        >
                          {recentNotice.title}
                        </span>
                      ) : (
                        <span>아직 등록된 공지사항이 없습니다.</span>
                      )}
                    </div>
                  </div>
                  <div className="service_row dp-flex">
                    <div className="column-50 pe-10">
                      <BrowserRouter forceRefresh={true}>
                        <Link to="/event">
                          <img src={MobileLinkEvent} alt="link-banner" />
                        </Link>
                      </BrowserRouter>
                    </div>
                    <div className="column-50 ps-10">
                      <BrowserRouter forceRefresh={true}>
                        <Link to="/frequently/1">
                          <img src={MobileLinkFrequently} alt="link-banner" />
                        </Link>
                      </BrowserRouter>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="column-50 pe-10">
                    <h2 className="service_title service_title_inline_block">공지사항</h2>
                    <span className="service_title_float_right">
                      <BrowserRouter forceRefresh={true}>
                        <Link to="/notice/board/all/1">
                          <div className="go_btn btn">더보기</div>
                        </Link>
                      </BrowserRouter>
                    </span>
                    <div className="notice_info">
                      {recentNotice && recentNotice.title ? (
                        <span
                          className="btn-pointer"
                          onClick={() => {
                            history.push(recentNotice.url);
                          }}
                        >
                          {recentNotice.title}
                        </span>
                      ) : (
                        <span>아직 등록된 공지사항이 없습니다.</span>
                      )}
                    </div>
                  </div>
                  <div className="column-25 column-center px-5">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/event">
                        <img src={LinkEvent} alt="link-banner" />
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="column-25 column-center ps-10">
                    <BrowserRouter forceRefresh={true}>
                      <Link to="/frequently/1">
                        <img src={LinkFrequently} alt="link-banner" />
                      </Link>
                    </BrowserRouter>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="section_3 bottom-white-space">
            <div className="service_container">
              {isMobile ? (
                <>
                  <div class="row-link">
                    <Link target="_blank" to={{pathname: "https://blog.naver.com/zibfine"}}>
                      <img src={MobileLinkBlog} alt="link-banner" />
                    </Link>
                  </div>
                  <div class="row-link">
                    <Link target="_blank" to={{pathname: "https://www.instagram.com/zibfine/"}}>
                      <img src={MobileLinkInstargram} alt="link-banner" />
                    </Link>
                  </div>
                  <div class="row-link">
                    <Link target="_blank" to={{pathname: "https://www.facebook.com/zibfine/"}}>
                      <img src={MobileLinkFacebook} alt="link-banner" />
                    </Link>
                  </div>
                  <div class="row-link">
                    <Link target="_blank" to={{pathname: "https://www.youtube.com/channel/UChcCXm-xGnch3bwwfLTnu3Q"}}>
                      <img src={MobileLinkYoutube} alt="link-banner" />
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="column-25 column-center pe-10">
                    <Link target="_blank" to={{pathname: "https://blog.naver.com/zibfine"}}>
                      <img src={LinkBlog} alt="link-banner" />
                    </Link>
                  </div>
                  <div className="column-25 column-center px-5">
                    <Link target="_blank" to={{pathname: "https://www.instagram.com/zibfine/"}}>
                      <img src={LinkInstargram} alt="link-banner" />
                    </Link>
                  </div>
                  <div className="column-25 column-center px-5">
                    <Link target="_blank" to={{pathname: "https://www.facebook.com/zibfine/"}}>
                      <img src={LinkFacebook} alt="link-banner" />
                    </Link>
                  </div>
                  <div className="column-25 column-center ps-10">
                    <Link target="_blank" to={{pathname: "https://www.youtube.com/channel/UChcCXm-xGnch3bwwfLTnu3Q"}}>
                      <img src={LinkYoutube} alt="link-banner" />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {isMobile ? (
          <>
            <Footer>
              <div className="move_top btn" onClick={moveScrollTop}>
                <img src={moveTop} alt="top" />
              </div>
            </Footer>
            <MobileBottomNav />
          </>
        ) : (
          <Footer>
            <div className="move_top btn" onClick={moveScrollTop}>
              <img src={moveTop} alt="top" />
            </div>
          </Footer>
        )}
      </div>
    </>
  );
}

export default Home;
