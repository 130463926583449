import React, { useRef, useState } from "react";
import back from "../../images/back.png";
import warningWhite from "../../images/warning_white.png";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { changeDateFormat, getCookie } from "../../Common/lib";
import { useMediaQuery } from "react-responsive";
import { boardDivisionList, getBoardDetail } from "../../_actions/main_action";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";
import Modal from "../../components/Modal";
import { useRouteMatch, BrowserRouter, Link } from "react-router-dom";
import $ from "jquery";

function CounselingDetail({ location }) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  // 게시글 번호 (고유 index)
  const idx = match.params.idx;
  // 페이지 번호
  const pagen = match.params.pagen;
  const rownum = location.state && location.state.rownum; // 법률상담 리스트 번호와 동일
  const x_auth = getCookie("x_auth");
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  let history = useHistory();
  const inputRefs = useRef([]);

  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const [replyComplete, setReplyComplete] = useState(false);

  // 초기 제목
  const [originTitle, setOriginTitle] = useState("");
  // 상세페이지 문의 글
  const [detail, setDetail] = useState({});
  // 상세페이지 답변 글
  const [detailReply, setDetailReply] = useState({});
  // 해당 글의 작성자인지 확인
  const [writer, setWriter] = useState(false);

  // 카테고리 리스트
  const [categories, setCategories] = useState([]);
  // 인증
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [showWarning, setshowWarning] = useState(true);
  const [alertWarning, setAlertWarning] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  // const closeModal = () => {
  //   setModal(false);
  // };

  const [modal, setModal] = useState(false);
  useLayoutEffect(() => {
    // 글정보 목록 가져오기
    // let x_auth = localStorage.getItem("x_auth");
    let data = { idx: idx, x_auth: x_auth };

    dispatch(getBoardDetail(data)).then((res) => {
      if (res.payload.result !== "success") {
        openModal();
      } else {
        if (res.payload.consulting !== "false") {
          setDetail(res.payload.consulting);
          setOriginTitle(res.payload.consulting.title);

          if (res.payload.consultingreply) setReplyComplete(true);
          if (res.payload.writer) setWriter(true);
        }
        if (res.payload.consultingreply !== "false") {
          setDetailReply(res.payload.consultingreply);
        }
      }
    });

    let body = {
      kind: "무료법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
    // 인증
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    // eslint-disable-next-line
  }, [idx, dispatch]);

  // 상담내용, 제목, 내용 수정 관리
  let inputHandler = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
  };

  // 수정 버튼
  const agreeCaution = () => {
    if (detail["title"] === "") {
      alert("제목을 입력해주세요.");
      handleFocus(0);
      return;
    } else if (detail["text"] === "") {
      alert("내용을 입력해주세요.");
      handleFocus(1);
      return;
    }

    if(!$("#check_agree").prop("checked")){
      setAlertWarning(true);
      alert("경매관련 내용이 아닌경우, 상담이 불가합니다.");
      return;
    }else{
      setAlertWarning(false);
    }

    if (!$("#agree_caution").prop("checked")) {
      setshowWarning(false);
      return;
    } else {
      if(alertWarning === false){
        setshowWarning(true);
        let url = "/api/counseling/modify";
        let data = { ...detail };
        // var x_auth = localStorage.getItem("x_auth");
        data.x_auth = x_auth;

        axios.post(url, data).then((res) => {
          if (res.data.result === "success") {
            alert("수정 완료되었습니다.");
            window.location.reload();
          } else {
            alert("수정에 실패하였습니다.");
          }
        });
      }
    }
  };

  const goBack = () => {
    history.push("/counseling/" + pagen);
    //history.goBack();
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}

        <div id="noticeBoard" style={{ height: "54px !important" }}>
          {isMobile && (
            <div className="content board_main">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                무료법률상담
              </h2>
            </div>
          )}
        </div>

        <div className="main">
          <div className="write_column isMobile_detail">
            <h2 className="title">
              <span style={{ "margin-top": "0px" }}>{originTitle}</span>
            </h2>
            <div className="hr"></div>
            <form className="counseling_detail_form">
              <div className="form_column">
                <div className="form_column isMobile_writer">
                  <div className="column_name">답변상태</div>
                  {
                      detail.status === 1 ? (<div className="check_reply finish">완료</div>) 
                    : detail.status === 0 ? (<div className="check_reply">대기</div>) 
                    : <div className="check_reply ing">변호사 답변 대기중</div> 
                    }
                </div>
              </div>
              <div className="form_column">
                <div className="form_column isMobile_writer">
                  <div className="column_name">글번호</div>
                  <div className="writer">{rownum ?? idx}</div>
                </div>
              </div>
              <div className="form_column">
                <div className="form_column isMobile_writer">
                  <div className="column_name">작성일자</div>
                  <div className="writer">
                    {detail.registerdate &&
                      changeDateFormat(detail.registerdate, 5, "-", ":")}
                  </div>
                </div>
              </div>
              <div className="form_column" style={{ flexDirection: "row" }}>
                <div className="column_name">상담내용</div>
                {replyComplete || !writer ? (
                  <div className="writer">{detail.gubun}</div>
                ) : (
                  <select
                    name="gubun"
                    value={detail.gubun}
                    onChange={inputHandler}
                  >
                    {categories.map((item, index) => {
                      return <option key={index}>{item.name}</option>;
                    })}
                  </select>
                )}
              </div>
              <div className="form_column isMobile_writer">
                <div className="column_name">작성자</div>
                <div className="writer">{detail.memname}</div>
              </div>
              <div className="form_column">
                <div className="column_name">제목</div>
                <input
                  type="text"
                  className="write_title"
                  name="title"
                  readOnly={replyComplete || !writer}
                  onChange={inputHandler}
                  ref={(elem) => (inputRefs.current[0] = elem)}
                  value={detail.title ? detail.title : ""}
                />
              </div>
              <div className="form_column">
                <div className="column_name write_content">내용</div>
                <textarea
                  name="text"
                  readOnly={replyComplete || !writer}
                  value={detail.text ? detail.text : ""}
                  onChange={inputHandler}
                  ref={(elem) => (inputRefs.current[1] = elem)}
                ></textarea>
              </div>
            </form>
            {replyComplete ? (
              <>
                <div className="write_column reply_column">
                  <form>
                    <div className="form_column">
                      <div
                        className="form_column"
                        style={{ flexDirection: "row" }}
                      >
                        <div className="column_name">답변일자</div>
                        <div className="writer">
                          {changeDateFormat(
                            detailReply.registerdate,
                            5,
                            "-",
                            ":"
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="form_column"
                      style={{
                        flexDirection: "row",
                        margin: isMobile ? "10px 0" : null,
                      }}
                    >
                      <div className="column_name">작성자</div>
                      <div className="writer">{detailReply.replyMemname}</div>
                    </div>
                    <div className="form_column">
                      <div className="column_name">제목</div>
                      <input
                        type="text"
                        className="write_title"
                        value={detailReply.replytitle}
                        readOnly
                      />
                    </div>
                    <div className="form_column">
                      <div className="column_name write_content">내용</div>
                      <textarea
                        readOnly
                        defaultValue={detailReply.replycontent || ""}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="go_list btn" onClick={goBack}>
                  목록으로
                </div>
              </>
            ) : writer ? (
              <>
                <div className="caution_wrapper">
                  <img src={warningWhite} alt="warning" />
                  <div className="caution_column">
                    <div className="title">법률 무료 상담 시 주의사항</div>
                    <ul>
                      <li>
                        ㆍ집파인에 등록된 부동산 주소가 경매 진행 중인 경우,
                        무료 상담이 가능합니다.
                      </li>
                      <li>
                        ㆍ집파인에 등록된 부동산 주소가 없거나, 경매 진행이 없는
                        경우에는 상담이 불가합니다.
                      </li>
                      <li>
                        ㆍ제목과 상담내용 작성 시 개인정보가 포함되지 않도록
                        유의해 주십시오.
                      </li>
                      <li>
                        ㆍ작성하신 글에 개인정보가 포함되어 발생하는 피해에
                        대해서는 민형사상 책임을 지지 않습니다.
                      </li>
                      <li>
                        ㆍ또한 동일한 사안에 대해서는 재상담을 하지 않습니다.
                      </li>
                    </ul>
                    <div className="checkbox_column" style={{"marginBottom":5}}>
                      <div className="checkbox_wrap">
                        <input id="check_agree" type="checkbox" />
                        <label htmlFor="check_agree">경매관련 상담입니다.</label>
                      </div>
                      <div className="warning" style={{ color: "#e30d0d" }}>
                        {alertWarning ? "경매상담인 경우만 체크하세요." : ""}
                      </div>
                    </div>
                    <div className="checkbox_column">
                      <div className="checkbox_wrap">
                        <input id="agree_caution" type="checkbox" />
                        <label htmlFor="agree_caution">주의사항을 확인했습니다.</label>
                      </div>
                      <div className="warning" style={{ color: "#e30d0d" }}>
                        {showWarning ? "" : "주의사항을 확인하시고 체크하세요."}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="btn_column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 32,
                    marginBottom: 50,
                  }}
                >
                  <div
                    className="btn blue_btn close"
                    style={{
                      marginRight: 10,
                      width: isMobile ? 150 : null,
                    }}
                    onClick={goBack}
                  >
                    취소
                  </div>

                  <div
                    className="blue_btn btn"
                    onClick={agreeCaution}
                    style={{ width: isMobile ? 150 : null }}
                  >
                    수정
                  </div>
                </div>
              </>
            ) : (
              <div className="go_list btn" onClick={goBack}>
                목록으로
              </div>
            )}
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
      <Modal open={modal} width={isMobile ? "90%" : 400}>
        <div className="modal_inner_ct modal_400 is_login_modal">
          <h2 className="title" style={{ fontSize: isMobile && "5vw" }}>
            로그인 후 이용 가능합니다.
          </h2>
          <div className="btn_column">
            <BrowserRouter forceRefresh={true}>
              <Link to="/login">
                <div className="blue_btn btn">로그인</div>
              </Link>
            </BrowserRouter>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CounselingDetail;
