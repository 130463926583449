import React, { useLayoutEffect, useState } from "react";
import "./admin.css";
import logo from "../images/admin/logo.png";
import close from "../images/close.png";
import Modal from "../components/Modal";
import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../Common/lib";
import { ADMIN_GENERAL, ADMIN_LAWYER, ADMIN_PRIVACY } from "../Common/adminType";

function AdminLogin({ history }) {
  const memid = getCookie("memid");
  const [isRemember, setIsRemember] = useState(true);

  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
  });
  // const [user, setUser] = useState({ memid: "super", password: "super" });
  const [user, setUser] = useState({ memid: "", password: "" });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      loginSubmit();
    }
  };

 
  const adminAllowChk = (url) => {
    let data = {
      memid: memid,
    }

    axios.post("/api/admin/allow", data).then((res) => {
      if (!res.data.isAdminAllow) {
        history.push("/unauthorized");
      } else {
        //console.log(url)
        history.push(url);
      }
    });
  }

  const handleOnChange = () => {
    setIsRemember(!isRemember);
  };
  let loginSubmit = () => {
    let url = "/api/admin/login";
    let data = { ...user };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        localStorage.setItem("admin_auth", res.data.idx);
        localStorage.setItem("x_type", res.data.type);
        localStorage.setItem("admin_authname", res.data.memname);
        if (isRemember) {
          setCookie("memid", user["memid"], 30);
        } else {
          deleteCookie("memid");
        }

        if (res.data.memtype === ADMIN_LAWYER.Memtype) {
          history.push(ADMIN_LAWYER.RedirectUrl);
        } else if (res.data.memtype === ADMIN_GENERAL.Memtype) {
          adminAllowChk(ADMIN_GENERAL.RedirectUrl);
        } else if (res.data.memtype === ADMIN_PRIVACY.Memtype) {
          adminAllowChk(ADMIN_PRIVACY.RedirectUrl);
        } else {
          adminAllowChk("/rf_zib_admin/user/free/1");
        }
      } else if (res.data.result === "정지") {
        openModal("modal2");
      } else if (res.data.result === "권한없음") {
        openModal("modal3");
      } else {
        openModal("modal1");
      }
    });
  };

  const onChangeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  useLayoutEffect(() => {
    localStorage.clear();
    if (memid !== undefined) {
      setUser({ ...user, memid: memid });
      setIsRemember(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="admin_container">
        <div className="admin_login" id="adminLogin">
          <img src={logo} alt="logo" />
          <h1>관리시스템</h1>
          <input
            type="text"
            name="memid"
            onChange={onChangeHandler}
            value={user["memid"]}
            placeholder="아이디"
            className="user_id"
          />
          <input
            type="password"
            name="password"
            onChange={onChangeHandler}
            placeholder="비밀번호"
            onKeyPress={onKeyPress}
          />

          <div className="checkbox_wrap auto_login">
            <input
              id="auto_login"
              type="checkbox"
              checked={isRemember}
              onChange={handleOnChange}
            />
            <label htmlFor="auto_login">아이디저장</label>
          </div>

          <div
            className="login_btn btn"
            onClick={() => {
              loginSubmit();
            }}
          >
            로그인하기
          </div>
        </div>
      </div>

      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_info">
            아이디 또는 비밀번호가 <br />
            일치하지 않습니다.<br />
            비번 오류횟수가 5회 이상이면 <br />계정이 정지됩니다.
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal2"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <div className="modal_info">
            정지된 회원입니다.
            <br />
            관리자에게 문의하세요.
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal2")}
          >
            닫기
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal3"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal3")}
          />
          <div className="modal_info">
            비밀번호 오류횟수 초과로 접근제한 되었습니다.
            <br />
            관리자에게 문의하세요.
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal3")}
          >
            닫기
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdminLogin;
