import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import add from "../../images/add.png";
import back from "../../images/back.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import ManageAddress from "./ManageAddress_ver_1";
import ManageAddressModify from "./ManageAddressModify_ver_1";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { getManageAddress } from "../../_actions/main_action";
import Modal from "../../components/Modal";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function MypageAddress({ location }) {
  let searchParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };
  const qsTab = searchParam("tab");
  let history = useHistory();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [modal, setModal] = useState({
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 관리주소 가져오기
  const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const [tabPage, setTabPage] = useState(null);
  const [maxAddr, setMaxAddr] = useState(0);

  const [tabActive, setTabActive] = useState(0);

  // 추가 탭 클릭시
  const handleAddAddress = () => {
    setTabPage(
      <AddTab
        onClick={() => openModal("modal2")}
        count={lists.length}
        maxAddr={maxAddr}
      />
    );
    setTabActive(null);
  };

  useLayoutEffect(() => {
    dispatch(getManageAddress()).then((res) => {
      setMaxAddr(res.payload.max);
      if (res.payload.address) {
        setLists(res.payload.address);
        if (qsTab === "all") {
          handleAddAddress();
        } else if (qsTab) {
          setTabPage(
            <ManageAddressModify
              lists={res.payload.address}
              tabActive={qsTab}
            />
          );
          setTabActive(Number(qsTab));
        } else {
          setTabPage(
            <ManageAddressModify lists={res.payload.address} tabActive={0} />
          );
        }
      } else {
        setTabPage(<AddTab />);
      }
    });

    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    // eslint-disable-next-line
  }, [dispatch, qsTab]);

  // 관리주소 탭 클릭시
  const selectAddress = (page) => {
    setTabPage(<ManageAddressModify lists={lists} tabActive={page} />);
    setTabActive(page);
  };
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="mypageAddress">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                관리주소 설정
              </h2>

              <div className="add_address_column">
                <div className="add_tab_column">
                  {lists &&
                    lists.map((item, index) => {
                      return (
                        <div className="address_tab" key={item.idx}>
                          <div
                            className={
                              tabActive === index
                                ? "tab_name active"
                                : "tab_name"
                            }
                            onClick={() => selectAddress(index)}
                          >
                            관리주소 {index + 1}
                          </div>
                        </div>
                      );
                    })}

                  {lists.length > 0 ? (
                    ""
                  ) : (
                    <div
                      className={
                        tabActive === null
                          ? "add_btn tab_name active"
                          : "add_btn tab_name"
                      }
                      onClick={handleAddAddress}
                    >
                      추가 +
                    </div>
                  )}
                </div>
                {tabPage}
              </div>
            </div>
          </div>
          <Modal open={modal["modal2"]} width={400}>
            <div className="modal_inner_ct modal_400 exit_modal">
              <h2>관리 주소를 추가할 수 없습니다.</h2>
              <div className="error_info">관리자에게 문의바랍니다.</div>
              <div className="save_btn_column">
                <div
                  className="blue_btn btn"
                  onClick={() => closeModal("modal2")}
                >
                  확인
                </div>
              </div>
            </div>
          </Modal>
        </div>

        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default MypageAddress;

function AddTab({ onClick, count, maxAddr }) {
  const [activeAdd, setActiveAdd] = useState(false);

  useLayoutEffect(() => {}, []);
  const handelActive = () => {
    if (count >= maxAddr) {
      onClick();
    } else {
      setActiveAdd(true);
    }
  };
  return (
    <>
      {activeAdd ? (
        <ManageAddress list={false} />
      ) : (
        <div className="btn_column">
          <img
            src={add}
            alt="add"
            className="btn add_address_btn"
            onClick={handelActive}
          />
          <div className="info add_info">관리 주소를 추가하세요.</div>
        </div>
      )}
    </>
  );
}
