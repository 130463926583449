import React from "react";
import "./Mypage.css";

function Fail_nice() {
  return (
    <>
      <div className="mypage_body">
        <p>본인인증에 실패하였습니다.</p>
        <div
          className="modify_btn"
          onClick={() => {
            window.close();
          }}
        >
          팝업창 닫기
        </div>
      </div>
    </>
  );
}

export default Fail_nice;
