import React from "react";
import "./Mypage.css";
import { useMediaQuery } from "react-responsive";
import MypageMobile from "./MypageMobile";
import MypageWeb from "./MypageWeb";

function Mypage() {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  return <>{isMobile ? <MypageMobile /> : <MypageWeb />}</>;
}

export default Mypage;
