import React, { useState } from "react";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import ServiceNoticeList from "./ServiceNoticeList";
import ServiceNoticeDivision from "./ServiceNoticeDivision";
import { Link } from "react-router-dom";

function ServiceNotice() {
  const obj = {
    0: <ServiceNoticeList />,
    1: <ServiceNoticeDivision />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">공지사항</h2>
                <div className="user_control_column">
                  <Link to="/rf_zib_admin/service/notice/add">
                    <div className="admin_delete_btn btn">등록</div>
                  </Link>
                </div>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  목록
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  공지사항 구분
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceNotice;
