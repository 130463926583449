import React, { useState, useLayoutEffect } from "react";
import close from "../../images/close.png";
import calendar from "../../images/admin/calendar.png";
import Modal from "../../components/Modal";
import { useDispatch } from "react-redux";
import { userFreeDetailAddress } from "../../_actions/admin_board_action";
import { useRouteMatch } from "react-router-dom";
import { changeDateFormat } from "../../Common/lib";
function UserFreeAddress() {
  let match = useRouteMatch();
  const idx = match.params.idx;
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const [addrList, setAddrList] = useState();
  const [selectAddr, setselectAddr] = useState(0);
  const [ownerList, setOwnerList] = useState([]);
  const [addrucode, setAddrucode] = useState([]);
  useLayoutEffect(() => {
    let body = {
      memidx: idx,
    };
    // 관리주소 갯수 가져오기
    dispatch(userFreeDetailAddress(body)).then((res) => {
      if (res.payload.addrList) {
        const result = res.payload.addrList;
        setAddrList(result);
        setSubmitData(result[selectAddr]);
        setOwnerList(result[selectAddr].owner);
        setAddrucode(result[selectAddr].addrucode);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const [SubmitData, setSubmitData] = useState({});

  const changePage = (page) => {
    setselectAddr(page);
    setSubmitData(addrList[page]);
    setOwnerList(addrList[page].owner);
    setAddrucode(addrList[page].addrucode);
  };

  const chgEtcFormat = (comment, type, type2) =>{
    var add = "";
    var rtComment = comment;
    if(comment === null){rtComment = "";}
    if(type === "미승인" && type2 >= 1){add = "승인거절 - ";}
    if(type === "승인완료" && type2 > 1){add = "부분승인 - ";}

    return add + rtComment ;
  }
  
  return (
    <>
      {addrList ? (
        <div id="userFreeAddress">
          <div className="address_lists">
            <ul>
              {addrList.map((item, index) => {
                return (
                  <li
                    className={
                      index === selectAddr
                        ? "address_list active"
                        : "address_list"
                    }
                    key={index}
                    onClick={() => changePage(index)}
                  >
                    <div className="name">{item.addrnickname}</div>
                    <div className="date">
                      {changeDateFormat(item.registerdate, 5, ".", ":")}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="address_detail">
            <h3 className="detail_table_title" style={{ marginTop: 0 }}>
              관리주소
            </h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th colSpan="2">부동산 닉네임</th>
                  <td>
                    <input
                      type="text"
                      value={SubmitData && SubmitData.addrnickname}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th className="column_name" rowSpan="7">
                    회원
                    <br />
                    입력
                    <br />
                    정보
                  </th>
                  <th className="sub_th">기본주소</th>
                  <td>
                    <input
                      type="text"
                      value={SubmitData && SubmitData.addr}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">상세주소</th>
                  <td>
                    <input
                      type="text"
                      value={SubmitData && SubmitData.addrsub}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">소유형태</th>
                  <td>
                    <div className="free_user_radio">
                      <input
                        type="radio"
                        checked={
                          SubmitData && SubmitData.addrower === "자가" && true
                        }
                        id="radio1"
                        name="own_radio_column"
                        disabled
                      />
                      <label htmlFor="radio1">자가</label>
                      <input
                        type="radio"
                        id="radio2"
                        name="own_radio_column"
                        checked={
                          SubmitData && SubmitData.addrower === "임차" && true
                        }
                        disabled
                      />
                      <label htmlFor="radio2">임차(전/월세)</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">임대차 계약일</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? changeDateFormat(
                            SubmitData && SubmitData.addrrentdate,
                            3,
                            "-",
                            ""
                          )
                        : "해당 없음"}
                      <img src={calendar} alt="calendar" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">임대차 만기일</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? changeDateFormat(
                            SubmitData && SubmitData.addrrentedate,
                            3,
                            "-",
                            ""
                          )
                        : "해당 없음"}
                      <img src={calendar} alt="calendar" />
                    </div>
                  </td>
                </tr>

                <tr>
                  <th className="sub_th">알림 시작일</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? SubmitData.addralarmstart + "개월 전"
                        : "해당 없음"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">알림 주기</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? SubmitData.addralarmcycle
                        : "해당 없음"}
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
            <div className="detail_table_header">
              <h3 className="detail_table_title">지번주소 및 고유번호 정보</h3>
            </div>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th className="refine_address">지번주소</th>
                  <td>
                    <div className="refine_address_desc">
                      {SubmitData && SubmitData.addrrenew}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="detail_table">
              <tbody>
              {
                addrucode && addrucode.map((item, index) => {
                return(
                  <tr>
                  <th>고유번호 {index + 1}</th>
                  <td>
                    <div className="day_change_count">
                      <input
                        type="text"
                        name="addrucode"
                        readOnly
                        value={item}
                      />
                    </div>
                  </td>
                </tr>
                )
                })
              }
              </tbody>
            </table>

            <h3 className="detail_table_title">관계인(소유주) 정보</h3>
            <table className="detail_table">
              <tbody>
              {
                ownerList && ownerList.map((item, index) => {
                return(
                  <tr>
                  <th>관계인(소유주) {index + 1}</th>
                  <td>
                    <div className="day_change_count">
                      <input
                        type="text"
                        name="owner"
                        readOnly
                        value={item}
                      />
                    </div>
                  </td>
                </tr>
                )
                })
              }
              </tbody>
            </table>
            <h3 className="detail_table_title">경매정보</h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>경매배당기일</th>
                  <td style={{ width: "55%" }}>
                    <div className="calendar_data">
                      {SubmitData.verstdate
                        ? changeDateFormat(
                            SubmitData.verstdate,
                            3,
                            "-",
                            ""
                          ).trim()
                        : "-"}
                      <img src={calendar} alt="calendar" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{display:"flex", alignItems:"flex-end"}}>
              <h3 className="detail_table_title">등록정보 승인여부</h3>
              <div className="warning">
                {SubmitData.addrtypedate &&
                  "※ 최종처리일 : " +
                    changeDateFormat(
                      SubmitData.addrtypedate,
                      5,
                      ".",
                      ":"
                    )
                }
              </div>
            </div>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>승인결과</th>
                  <td style={{ width: "55%" }}>
                    <div className="day_change_count">
                    <input
                        type="text"
                        name="addrtype"
                        readOnly
                        value={SubmitData.addrtype === "승인완료" ? "승인완료" : SubmitData.addrtype === "보류" ? "보류" : "미승인"}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>결과사유</th>
                  <td style={{ width: "55%" }}>
                    <div className="day_change_count">
                    <input
                        type="text"
                        name="etcomment"
                        readOnly
                        value={SubmitData.etcomment !== "" ? chgEtcFormat(SubmitData.etcomment,  SubmitData.addrtype, SubmitData.addrtype2) : "-"}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="detail_table_title">기타정보</h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th className="refine_address">메모</th>
                  <td>
                    <div className="refine_address_desc">
                      {SubmitData && SubmitData.memo}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      ) : (
        <div>등록된 주소가 없습니다.</div>
      )}

      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_info title">관리주소 고유번호</div>
          <div className="modal_list_column">
            {addrucode &&
              addrucode.map((item) => {
                return <div>{item}</div>;
              })}
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>

      <Modal open={modal["modal2"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <div className="modal_info title">관리주소 소유주</div>
          <div className="modal_list_column">
            {ownerList &&
              ownerList.map((item) => {
                return <div>{item}</div>;
              })}
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal2")}
          >
            닫기
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserFreeAddress;
