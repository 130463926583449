export const data = [
  {
    no: 1,
    title: "메인 배너",
    path: "/rf_zib_admin/set/main",
  },
  {
    no: 2,
    title: "이벤트 배너",
    path: "/rf_zib_admin/set/event",
  },
  // {
  //   no: 3,
  //   title: "PUSH 설정",
  //   path: "/admin/set/push",
  // },
  {
    no: 2,
    title: "약관",
    path: "/rf_zib_admin/set/provision",
  },
  {
    no: 2,
    title: "앱버전",
    path: "/rf_zib_admin/set/version",
  },
  {
    no: 2,
    title: "메일발송",
    path: "/rf_zib_admin/set/mail",
  },
  {
    no: 2,
    title: "문자발송",
    path: "/rf_zib_admin/set/sms",
  },  
  {
    no: 2,
    title: "작업공지",
    path: "/rf_zib_admin/set/construction",
  },
];
