import React, { useLayoutEffect, useState } from "react";
import "./Main.css";
import search from "../../images/search.png";
import dropdownSearch from "../../images/dropdown_search.png";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TotalResultTab from "../Tab/TotalResultTab";
import CounselingTab from "../Tab/CounselingTab";
import InformationTab from "../Tab/InformationTab";
import NoticeTab from "../Tab/NoticeTab";
import EventTab from "../Tab/EventTab";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import Frequently from "../Tab/Frequently";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { combinedSearch } from "../../_actions/main_action";
import useHistoryState from "use-history-state";
import { BrowserRouter, Link } from "react-router-dom";

function SearchResult({ match, history }) {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const dispatch = useDispatch();

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  const [keyword, setKeyword] = useState(match.params.keyword1);
  const originKeyword = match.params.keyword1;
  const [condition, setCondition] = useState(match.params.keyword2);
  const [handelCondition, sethandelCondition] = useState("통합검색");

  const [totalListCnt, setTotalListCnt] = useState(0);
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      condition: condition,
      search: keyword,
    };
    dispatch(combinedSearch(body)).then((res) => {
      const result = res.payload.searchList;
      let a = result.consulting.length > 0 ? result.consulting : [];
      let b = result.realEstate.length > 0 ? result.realEstate : [];
      let c = result.notice.length > 0 ? result.notice : [];
      let d = result.event.length > 0 ? result.event : [];
      let e = result.question.length > 0 ? result.question : [];
      setTotalListCnt(a.length + b.length + c.length + d.length + e.length);
    });

    handleCondition(condition);
    // eslint-disable-next-line
  }, [dispatch]);

  const obj = {
    0: (
      <TotalResultTab
        keyword={keyword}
        condition={condition}
        totalListCnt={totalListCnt}
      />
    ),
    1: <CounselingTab keyword={keyword} condition={condition} />,
    2: <InformationTab keyword={keyword} condition={condition} />,
    3: <NoticeTab keyword={keyword} condition={condition} />,
    4: <EventTab keyword={keyword} condition={condition} />,
    5: <Frequently keyword={keyword} condition={condition} />,
  };

  const [tabActive, setTabActive] = useHistoryState(0, "tabActive");
  const [tabPage, setTabPage] = useState(obj[tabActive]);
  // const [tabActive, setTabActive] = useState(1);
  const [searchDropdown, setSearchDropdown] = useState(false);

  // const showSearchDropdown = () => {
  //   setSearchDropdown(true);
  // };

  const hideSearchDropdown = () => {
    setSearchDropdown(false);
  };

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  const menus = [
    "통합검색",
    "무료 법률상담",
    "부동산 정보",
    "공지사항",
    "이벤트",
    "자주하는 질문",
  ];

  const menuList = menus.map((menu, index) => (
    <li
      key={index}
      className={tabActive === index ? "active" : null}
      onClick={() => clickTab(index)}
    >
      {menu}
    </li>
  ));

  const menusMobile = [
    "통합검색",
    "무료 법률상담",
    "부동산 정보",
    "공지사항",
    "이벤트",
    "자주하는 질문",
  ];
  const menuListMobile = menusMobile.map((menu, index) => (
    <li
      key={index}
      className={tabActive === index ? "active" : null}
      onClick={() => clickTab(index)}
    >
      {menu}
    </li>
  ));

  const handleCondition = (name) => {
    setCondition(name);
    switch (name) {
      case "all":
        return sethandelCondition("통합검색");
      case "title+content":
        return sethandelCondition("제목 내용");
      case "title":
        return sethandelCondition("제목");
      case "content":
        return sethandelCondition("내용");
      case "writer":
        return sethandelCondition("작성자");
      default:
        return sethandelCondition("통합검색");
    }
  };

  const handleSearchKwyword = (e) => {
    setKeyword(e.target.value);
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      moveSearchResult();
    }
  };

  const moveSearchResult = () => {
    if (keyword === "") {
      alert("검색어를 입력하세요");
      return;
    }
    if (condition === "all") {
      history.push("/search/result/" + keyword + "/all");
    } else {
      history.push("/search/result/" + keyword + "/" + condition);
    }
    window.location.reload();
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header
          isLoginAuth={isLoginAuth}
          memname={memname}
          profilePath={profilePath}
        />
        <div
          className="result_bg"
          style={{ display: !searchDropdown && "none" }}
          onMouseEnter={hideSearchDropdown}
        ></div>
        <div className="main center_main " id="searchResult">
          <div className="search section_1">
            <div
              className={
                searchDropdown ? "condition_column show" : "condition_column"
              }
            >
              <div
                onClick={() => setSearchDropdown(!searchDropdown)}
                className="select"
              >
                {handelCondition}
                <img src={dropdownSearch} alt="down" className="search_down" />
              </div>
              <ul
                className={
                  searchDropdown ? "condition_lists show" : "condition_lists"
                }
                onClick={hideSearchDropdown}
              >
                <li
                  onClick={() => handleCondition("all")}
                  style={{ display: handelCondition === "통합검색" && "none" }}
                >
                  통합검색
                </li>
                <li
                  onClick={() => handleCondition("title+content")}
                  style={{ display: handelCondition === "제목 내용" && "none" }}
                >
                  제목 내용
                </li>
                <li
                  onClick={() => handleCondition("title")}
                  style={{ display: handelCondition === "제목" && "none" }}
                >
                  제목
                </li>
                <li
                  onClick={() => handleCondition("content")}
                  style={{ display: handelCondition === "내용" && "none" }}
                >
                  내용
                </li>
                <li
                  onClick={() => handleCondition("writer")}
                  style={{ display: handelCondition === "작성자" && "none" }}
                >
                  작성자
                </li>
              </ul>
            </div>
            <div className="search_column">
              <input
                type="text"
                onChange={handleSearchKwyword}
                onKeyPress={onKeyPress}
                value={keyword}
                placeholder="부동산, 경매 관련 법률 상담 내용을 검색하세요."
              />
              <img
                src={search}
                alt="search"
                className="search btn"
                onClick={moveSearchResult}
              />
            </div>
          </div>

          <div className="search_word_column">
            <div className="search_word_title" onClick={hideSearchDropdown}>
              인기 검색어
            </div>
            <div className="bar">│</div>
            <div className="search_word_desc">
              <BrowserRouter forceRefresh={true}>
                <Link to="/search/result/등기부정보변동/all">
                  등기부정보변동
                </Link>
              </BrowserRouter>
              ,
              <BrowserRouter forceRefresh={true}>
                <Link to="/search/result/경매배당기일/all">경매배당기일</Link>
              </BrowserRouter>
              ,
              <BrowserRouter forceRefresh={true}>
                <Link to="/search/result/수수료/all">수수료</Link>
              </BrowserRouter>
            </div>
          </div>

          <div className="tab_column">
            <ul>{isMobile ? menuListMobile : menuList}</ul>
          </div>

          <div className="search_result_column">
            <div className="total_search_coumn">
              <strong>‘{originKeyword}’</strong>에 대한 총
              <strong>{totalListCnt}</strong>건의 검색 결과가 있습니다.
            </div>

            {tabPage}
          </div>
        </div>

        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default SearchResult;
