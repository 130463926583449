import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import { changeDateFormat, searchDataSet, searchCashClear, hidePhoneNumber, hideName } from "../../Common/lib";
import SideMenu from "./SideMenu";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import PaginationNum from "../../components/PaginationNum";
import { searchUser, userList } from "../../_actions/admin_board_action";

function UserFree() {
  const dispatch = useDispatch();
  let history = useHistory();
  let match = useRouteMatch();
  let pagen = match.params.pagen;

  // 달력 시작일 셋팅
  const [searchDate, setSearchDate] = useState({
    start: "",
    end: "",
  });

  const handleSearchDate = (e) => {
    setSearchDate({ ...searchDate, [e.target.name]: e.target.value });
  };

  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardList, setBoardList] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();

  useLayoutEffect(() => {

    let body = {
      registerStart: searchDate["start"],
      registerEnd: searchDate["end"],
      memaddrcurjoin: searchMemaddrcurjoin,
      keyword: searchKeyword,
      memstatus: searchMemstatus,
    };

    let finalData = searchDataSet(body);
    // 무료회원 목록 가져오기
    dispatch(searchUser(finalData)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.users;
        setBoardList(result);
        setBoardListCnt(result.length);

        // State 수정
        setSearchDate({ ...searchDate, ["start"]: finalData.registerStart, ["end"]: finalData.registerEnd });
        setSearchMemaddrcurjoin(finalData.memaddrcurjoin);
        setSearchKeyword(finalData.keyword);
        setSearchMemstatus(finalData.memstatus);

      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });

  }, [dispatch]);

  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/user/free/${pageNum}`);
  };
  // 회원 목록
  const pageOfItem = pageOfItems.map((user) => {
    return (
      <tr key={user.idx}>
        <td>{user.rownum}</td>
        <td>{changeDateFormat(user.memregdtime, 5, ".", ":")}</td>
        <td title={user.memrealname ? user.memrealname : "-"}>{user.memrealname ? hideName(user.memrealname) : "-"}</td>
        <td>
          <Link to={`/rf_zib_admin/user/free/detail/${user.idx}/${pagen}`}>
            <span className="btn">{user.memname}</span>
          </Link>
        </td>
        <td title={user.cellno ? user.cellno : "-"}>{user.cellno ? hidePhoneNumber(user.cellno) : "-"}</td>
        <td>{user.memid}</td>
        <td>{user.memjointype}</td>
        <td>{user.memaddrmaxjoin}</td>
        <td>{user.COUNT > 0 ? user.COUNT : "-"}</td>
        <td className={user.memstatus === "정지" ? "stop" : null}>
          {user.memstatus}
        </td>
      </tr>
    );
  });
  // 검색하기
  const handleSearch = () => {
    let body = {
      registerStart: searchDate["start"],
      registerEnd: searchDate["end"],
      memaddrcurjoin: searchMemaddrcurjoin,
      keyword: searchKeyword,
      memstatus: searchMemstatus,
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(searchUser(body)).then((res) => {
      history.replace(`/rf_zib_admin/user/free/1`);
      if (res.payload.result) {
        const result = res.payload.users;
        setBoardList(result);
        if (
          result === "false"
            ? setBoardSearchListCnt(0)
            : setBoardSearchListCnt(result.length)
        );
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };

  // 검색어 셋팅
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 주소 등록 셋팅
  const [searchMemaddrcurjoin, setSearchMemaddrcurjoin] = useState("전체");
  const handleMemaddrcurjoin = (e) => {
    setSearchMemaddrcurjoin(e.target.value);
  };

  // 공개여부 셋팅
  const [searchMemstatus, setSearchMemstatus] = useState("전체");
  const handleMemstatus = (e) => {
    setSearchMemstatus(e.target.value);
  };

  // 검색 조건 초기화
  // const handleReset = () => {
  //   setSearchDate({ start: adminGetToday(), end: adminGetToday() });
  //   setSearchMemaddrcurjoin("전체");
  //   $("input:radio[id='radio1']").prop("checked", true);
  //   setSearchMemstatus("전체");
  //   $("input:radio[id='radio8']").prop("checked", true);
  //   setSearchKeyword("");
  // };

  // 가입일 검색하는 html부분
  const user_search_column = (
    <div className="user_search_column">
      <div className="first_column">
        <div className="search_regdate_column">
          <span className="input_title">가입일</span>
          <input
            type="date"
            name="start"
            value={searchDate["start"]}
            onChange={handleSearchDate}
          />
          <span className="range_text">~</span>
          <input
            type="date"
            name="end"
            value={searchDate["end"]}
            onChange={handleSearchDate}
          />
        </div>
        <div className="search_keyword_column">
          <span className="input_title">검색</span>
          <input
            type="text"
            value={searchKeyword}
            placeholder="이름, 닉네임, 이메일, 휴대폰"
            onChange={handleSearchKeyword}
            onKeyPress={onKeyPress}
          />
        </div>
      </div>
      <div className="second_column">
        <div className="register_address">
          <span className="input_title">주소등록</span>

          <input
            type="radio"
            id="radio1"
            name="memaddrcurjoin"
            value="전체"
            onClick={handleMemaddrcurjoin}
            checked={searchMemaddrcurjoin === "전체" ? true : false}
          />
          <label htmlFor="radio1">전체</label>
          <input
            type="radio"
            id="radio2"
            value="등록"
            name="memaddrcurjoin"
            onClick={handleMemaddrcurjoin}
            checked={searchMemaddrcurjoin === "등록" ? true : false}
          />
          <label htmlFor="radio2">등록</label>
          <input
            type="radio"
            id="radio3"
            value="미등록"
            name="memaddrcurjoin"
            onClick={handleMemaddrcurjoin}
            checked={searchMemaddrcurjoin === "미등록" ? true : false}
          />
          <label htmlFor="radio3">미등록</label>
        </div>
        <div className="bar"></div>
        <div>
          <span className="input_title">회원상태</span>

          <input
            type="radio"
            id="radio8"
            name="memstatus"
            value="전체"
            onClick={handleMemstatus}
            checked={searchMemstatus === "전체" ? true : false}
          />
          <label htmlFor="radio8">전체</label>
          <input
            type="radio"
            id="radio4"
            name="memstatus"
            value="정상"
            onClick={handleMemstatus}
            checked={searchMemstatus === "정상" ? true : false}
          />
          <label htmlFor="radio4">정상</label>
          <input
            type="radio"
            id="radio5"
            name="memstatus"
            value="휴면"
            onClick={handleMemstatus}
            checked={searchMemstatus === "휴면" ? true : false}
          />
          <label htmlFor="radio5">휴면</label>
          <input
            type="radio"
            id="radio6"
            name="memstatus"
            value="정지"
            onClick={handleMemstatus}
            checked={searchMemstatus === "정지" ? true : false}
          />
          <label htmlFor="radio6">정지</label>
          {/* <input
            type="radio"
            id="radio7"
            name="memstatus"
            value="탈퇴"
            onClick={handleMemstatus}
          />
          <label htmlFor="radio7">탈퇴</label> */}
        </div>
      </div>
      <div className="admin_btn_column">
        <div
          className="admin_white_btn btn left"
          onClick={() => {
            searchCashClear();
            window.location.href = `/rf_zib_admin/user/free/1`;
          }}
        >
          기본설정
        </div>
        <div className="admin_blue_btn btn" onClick={handleSearch}>
          검색
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <Header />
        <div className="admin_container">
          <SideMenu tabActive={0} />
          <div className="main_container">
            <div>
              <h2 className="main_container_name">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/user/free/1">무료회원</Link>
                </BrowserRouter>
                <span className="total_column">
                  전체 <span>{boardListCnt}</span>
                </span>
              </h2>

              {user_search_column}

              <div className="user_list_column">
                <div className="title_column">
                  <h3>목록</h3>
                  {boardSearchListCnt ? (
                    <div className="search_result">
                      검색결과 <span> {boardSearchListCnt}</span>
                    </div>
                  ) : (
                    <div className="search_result"></div>
                  )}
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>가입일시</th>
                      <th>이름</th>
                      <th>회원닉네임</th>
                      <th>휴대폰</th>
                      <th>아이디(이메일)</th>
                      <th>가입유형</th>
                      <th>최대 주소등록(개)</th>
                      <th>현재 주소등록(개)</th>
                      <th>회원상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItem.length > 0 ? (
                      pageOfItem
                    ) : (
                      <tr>
                        <td colSpan={10}>등록된 회원이 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <PaginationNum
                  items={boardList}
                  onChangePage={onChangePage}
                  getPageNum={getPageNum}
                  initialPage={pagen++}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserFree;
