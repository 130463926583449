import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { adminInquiryList } from "../../_actions/admin_board_action";
import { useDispatch } from "react-redux";
import PaginationNum from "../../components/PaginationNum";
import { changeDateFormat } from "../../Common/lib";

function ServiceInquiry() {
  const history = useHistory();
  const match = useRouteMatch();
  let pagen = match.params.pagen;

  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/service/inquiry/${pageNum}`);
  };
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(adminInquiryList()).then((res) => {
      if (res.payload.result) {
        const result = res.payload.inquiryList;
        setBoardList(result);
      }
    });
  }, [dispatch]);

  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
        <td>
          <Link to={`/rf_zib_admin/service/inquiry/detail/${item.idx}/${pagen}`}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{item.memname}</td>
        <td>{item.memid}</td>
        <td>
          {item.replydate ? changeDateFormat(item.replydate, 5, ".", ":") : "-"}
        </td>
        <td>{item.status === 1 ? "답변완료" : "-"}</td>
      </tr>
    );
  });

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container">
          <div className="detail_user_header">
            <h2 className="main_container_name">문의하기</h2>
          </div>
          <div className="user_list_column">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>No.</th>
                  <th style={{ width: "15%" }}>등록일시</th>
                  <th>제목</th>
                  <th style={{ width: "10%" }}>작성자</th>
                  <th style={{ width: "10%" }}>이메일(ID)</th>
                  <th style={{ width: "15%" }}>답변일시</th>
                  <th style={{ width: "10%" }}>답변처리</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItem.length > 0 ? (
                  pageOfItem
                ) : (
                  <tr>
                    <td colSpan={7}>등록된 문의글이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>

            <PaginationNum
              items={boardList}
              onChangePage={onChangePage}
              getPageNum={getPageNum}
              initialPage={pagen++}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceInquiry;
