import React, { useLayoutEffect, useState } from "react";
import "../Mypage/Mypage.css";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getEventDetail } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import MobileBottomNav from "../Footer/MobileBottomNav";

function EventBoardDetail({ match }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    let getPathName = localStorage.getItem("pathname");
    if (getPathName === "home") {
      history.push("/event");
    } else {
      history.goBack();
    }
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 게시글 번호
  const idx = match.params.idx;
  const [EventContent, setEventContent] = useState([]); // 공지글 내용
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      idx: idx, // 글번호
    };
    dispatch(getEventDetail(body)).then((res) => {
      if (res.payload.event) {
        setEventContent(res.payload.event);
      }
    });
  }, [dispatch, idx]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="noticeBoard">
          <div className="mypage_body">
            <div className="content board_main">
              <h2 className="isMobile_detail_h2">
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
              </h2>

              <div className="detail_column">
                <div
                  className="detail_title_column"
                  style={{
                    padding: isMobile ? "0 0 24px" : "24px 0",
                    borderTop: "none",
                  }}
                >
                  <h2 style={{ padding: isMobile && 0 }}>
                    {EventContent.title}
                  </h2>
                  <div className="regdate">
                    {EventContent.datestart &&
                      changeDateFormat(EventContent.datestart, 3, "-", "") +
                        "~"}
                    {EventContent.dateend &&
                      changeDateFormat(EventContent.dateend, 3, "-", "")}
                  </div>
                </div>
                {EventContent.file && (
                  <img
                    style={{
                      margin: "10px auto",
                      display: "block",
                      maxWidth: isMobile ? "90vw" : "40vw",
                    }}
                    src={
                      EventContent.file === "" || EventContent.file === null
                        ? null
                        : EventContent.file
                    }
                    alt="img"
                    className="event_img"
                  />
                )}

                <div
                  className="detail_content_column"
                  style={{ minHeight: "auto", whiteSpace: "pre-line" }}
                >
                  {EventContent.content}
                </div>

                <div className="btn_column">
                  <div
                    className="go_list btn"
                    onClick={() => {
                      history.push(`/event`);
                    }}
                    style={{
                      width: isMobile && "100%",
                      marginBottom: isMobile && "20px",
                    }}
                  >
                    목록으로
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default EventBoardDetail;
