
import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Link } from "react-router-dom";
import { auth } from "../../_actions/user_action";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MobileBottomNav from "../Footer/MobileBottomNav";

import "./Description.css";
import depthHome from "../../images/depth_home.png";

import MainBanner from "../../images/svg/desc-main-banner.svg";
import SubBanner1 from "../../images/svg/desc-sub-banner-1.svg";
import SubBanner2 from "../../images/svg/desc-sub-banner-2.svg";
import SubBanner3 from "../../images/svg/desc-sub-banner-3.svg";
import SubBanner4 from "../../images/svg/desc-sub-banner-4.svg";
import Btn from "../../images/svg/desc-btn.svg";

import LinkBlog from "../../images/svg/link-blog.svg";
import LinkInstargram from "../../images/svg/link-instargram.svg";
import LinkFacebook from "../../images/svg/link-facebook.svg";
import LinkYoutube from "../../images/svg/link-youtube.svg";

import MobileMainBanner from "../../images/svg/m-desc-main-banner.svg";
import MobileSubBanner1 from "../../images/svg/m-desc-sub-banner-1.svg";
import MobileSubBanner2 from "../../images/svg/m-desc-sub-banner-2.svg";
import MobileSubBanner3 from "../../images/svg/m-desc-sub-banner-3.svg";
import MobileSubBanner4 from "../../images/svg/m-desc-sub-banner-4.svg";
import MobileBtn from "../../images/svg/m-desc-btn.svg";

import MobileLinkBlog from "../../images/svg/m-link-blog.svg";
import MobileLinkInstargram from "../../images/svg/m-link-instargram.svg";
import MobileLinkFacebook from "../../images/svg/m-link-facebook.svg";
import MobileLinkYoutube from "../../images/svg/m-link-youtube.svg";

export default function Description() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [memId, setMemId] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
      setMemId(res.payload.memid);
    });
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}

        <div className="desc_main_container">
          {isMobile ? (
            <>
              <img src={MobileMainBanner} alt="main-banner" />
            </>
          ) : (
            <>
              <img src={MainBanner} alt="main-banner" />
            </>
          )}
        </div>

        <div className="main" id="Description">
          {isMobile ? null : (
            <div className="description_header" style={{ border: "none" }}>
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>등기 변동 알림
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="desc_sub_container">
          {isMobile ? (
            <>
              <img src={MobileSubBanner1} alt="sub-banner" />
              <img src={MobileSubBanner2} alt="sub-banner" />
              <img src={MobileSubBanner3} alt="sub-banner" />
              <img src={MobileSubBanner4} alt="sub-banner" />
            </>
          ) : (
            <>
              <img src={SubBanner1} alt="sub-banner" />
              <img src={SubBanner2} alt="sub-banner" />
              <img src={SubBanner3} alt="sub-banner" />
              <img src={SubBanner4} alt="sub-banner" />
            </>
          )}
        </div>

        <div className="desc_btn_container">
          {isMobile ? (
            <>
              <BrowserRouter forceRefresh={true}>
                <Link to={isLoginAuth ? "/mypage/address" : "/login"}>
                  <img src={MobileBtn} alt="btn" className="btn" />
                </Link>
              </BrowserRouter>
            </>
          ) : (
            <>
              <BrowserRouter forceRefresh={true}>
                <Link to={isLoginAuth ? "/mypage/address" : "/login"}>
                  <img src={Btn} alt="btn" className="btn" />
                </Link>
              </BrowserRouter>
            </>
          )}
        </div>

        <div className="link_container_area top-white-space bottom-white-space">
          {isMobile ? (
            <>
              <div class="row-link">
                <Link target="_blank" to={{pathname: "https://blog.naver.com/zibfine"}}>
                  <img src={MobileLinkBlog} alt="link-banner" />
                </Link>
              </div>
              <div class="row-link">
                <Link target="_blank" to={{pathname: "https://www.instagram.com/zibfine/"}}>
                  <img src={MobileLinkInstargram} alt="link-banner" />
                </Link>
              </div>
              <div class="row-link">
                <Link target="_blank" to={{pathname: "https://www.facebook.com/zibfine/"}}>
                  <img src={MobileLinkFacebook} alt="link-banner" />
                </Link>
              </div>
              <div class="row-link">
                <Link target="_blank" to={{pathname: "https://www.youtube.com/channel/UChcCXm-xGnch3bwwfLTnu3Q"}}>
                  <img src={MobileLinkYoutube} alt="link-banner" />
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="link_container">
                <div className="column-25 column-center pe-10">
                  <Link target="_blank" to={{pathname: "https://blog.naver.com/zibfine"}}>
                    <img src={LinkBlog} alt="link-banner" />
                  </Link>
                </div>
                <div className="column-25 column-center px-5">
                  <Link target="_blank" to={{pathname: "https://www.instagram.com/zibfine/"}}>
                    <img src={LinkInstargram} alt="link-banner" />
                  </Link>
                </div>
                <div className="column-25 column-center px-5">
                  <Link target="_blank" to={{pathname: "https://www.facebook.com/zibfine/"}}>
                    <img src={LinkFacebook} alt="link-banner" />
                  </Link>
                </div>
                <div className="column-25 column-center ps-10">
                  <Link target="_blank" to={{pathname: "https://www.youtube.com/channel/UChcCXm-xGnch3bwwfLTnu3Q"}}>
                    <img src={LinkYoutube} alt="link-banner" />
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>

        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}