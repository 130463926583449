import React, { useState, useLayoutEffect } from "react";
import close from "../../images/close.png";
import Modal from "../../components/Modal";
import PaginationNum from "../../components/PaginationNum";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alarmMember } from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";

function UserFreeNoticeView() {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const idx = match.params.idx;
  const [modal, setModal] = useState({
    modal1: false,
  });
  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };
  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };
  const [lists, setLists] = useState();
  useLayoutEffect(() => {
    let body = {
      memidx: idx,
    };
    dispatch(alarmMember(body)).then((res) => {
      const result = res.payload.alarmList;
      if (res.payload.alarmList) {
        setLists(result);
      }
    });
  }, [dispatch, idx]);

  const [pageNum, setPageNum] = useState();
  const [pageOfItems, setPageOfItems] = useState([]);

  // 페이징 처리
  const onChangePage = (pageOfItems, page) => {
    setPageNum(page);
    setPageOfItems(pageOfItems);
  };

  const [detailAlarm, setdetailAlarm] = useState();

  const viewDetail = (num) => {
    setdetailAlarm(lists.filter((list) => list.idx === num));
    openModal("modal1");
  };
  return (
    <>
      <div className="user_list_column" id="userFreeNoticeView">
        <table>
          <thead>
            <tr>
              <th style={{ width: 50 }}>No.</th>
              <th>구분</th>
              <th style={{ width: 100 }}>부동산 닉네임</th>
              <th style={{ width: 300 }}>알림제목</th>
              <th style={{ width: 70 }}>발송일</th>
              <th style={{ width: 70 }}>확인여부</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItems.length > 0 ? (
              pageOfItems.map((item, index) => {
                return (
                  <tr
                    className={item.alarmread !== "미확인" ? "confirmed" : ""}
                    key={index}
                  >
                    <td>{lists.length - ((pageNum - 1) * 10) - index}</td>
                    <td>{item.alarmgubun}</td>
                    <td>{item.addrnickname}</td>
                    <td className="notice_title">
                      <span onClick={() => viewDetail(item.idx)}>
                        {item.alarmtitle}
                      </span>
                    </td>
                    <td>{changeDateFormat(item.senddate, 3, "-", "")}</td>
                    <td>{item.alarmread}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">알림 내역이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationNum items={lists} onChangePage={onChangePage} />
      </div>
      <Modal open={modal["modal1"]} width={400}>
        <div className="modal_inner_ct admin_basic_modal modal_400 admin_notice_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          {/* <div className="modal_info title">
            [{detailAlarm && detailAlarm[0].addrnickname}]
            {detailAlarm && detailAlarm[0].alarmtitle}
          </div> */}
          <div className="modal_desc" style={{ width: 300 }}>
            {detailAlarm &&
              detailAlarm[0].alarmcontent.split(";").map((text, index) => {
                  if (text.length) {
                    return (
                      <div>
                        <div
                          style={{
                            background: "#ececec",
                            padding: 10,
                            marginBottom: 10,
                            whiteSpace: "pre-line",
                          }}
                        >
                          {text}
                        </div>
                        {detailAlarm[0].alarmcontent.split(";").length - 1 > index ?<div className="detail_column"></div>:null}
                      </div>
                    );
                  } else {
                    return ("");
                  }
              })
            }
          </div>

          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserFreeNoticeView;
