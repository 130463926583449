import React, { useLayoutEffect } from "react";
import "./Mypage.css";

function Success_nice_modify() {
  useLayoutEffect(() => {
    window.close();
  }, []);
  return (
    <>
      <div className="mypage_body"></div>
    </>
  );
}

export default Success_nice_modify;
