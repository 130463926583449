import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import back from "../../images/back.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { Agreement2 } from "../../components/AgreementData";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";

function Perinformation() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="agreement">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body" id="agreement">
            {isMobile ? null : <Sidebar />}
            <div className="content">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                개인정보처리방침
              </h2>

              <div className="mypage_tab_menu ">
                <Link to="/mypage/agreement">
                  <div className="tab">서비스 이용약관</div>
                </Link>

                <Link to="/mypage/perinformation">
                  <div className="active tab">개인정보처리방침</div>
                </Link>
                <Link to="/mypage/agreementMarketing">
                  <div className="tab">마케팅활용동의</div>
                </Link>
              </div>
              <Agreement2 />
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default Perinformation;
