import React, { useLayoutEffect } from "react";

function Success_nice_dormant() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const result = params.get("result");

  useLayoutEffect(() => {
    window.opener.niceDormantFunc(result);
    window.close();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mypage_body"></div>
    </>
  );
}

export default Success_nice_dormant;
