// 유저 관리
export const AUTH_USER = "auth_user";
export const GET_USER = "get_user";
export const SAVE_AUTH_NUM = "save_auth_num";
export const GET_AUTH_NUM = "get_auth_num";
export const FIND_EMAIL = "find_email";

// 사용자 페이지
export const MAIN_BANNER = "main_banner";
export const GET_MANAGE_ADDRESS = "get_manage_address";
export const GET_ALARM = "get_alarm";
export const GET_COUNSELING = "get_counseling";
export const GET_COUNSELING_DETAIL = "get_counseling_detail";
export const GET_BOARD_DETAIL = "get_board_detail";
export const GET_BOARD_DIVISION_LIST = "get_board_division_list"
export const GET_COUNSELING_MY = "get_counseling_my";
export const GET_INQUIRY = "get_inquiry";
export const GET_INQUIRY_DETAIL = "get_inquiry_detail";
export const GET_NOTICE_LIST = "get_notice_list";
export const GET_NOTICE = "get_notice";
export const GET_OTHER_NOTICE = "get_other_notice";
export const GET_OTHER_BLOG = "get_other_blog";
export const GET_EVENT_LIST = "get_event_list";
export const GET_EVENT_DETAIL = "get_event_detail";
export const WRITE_INQUIRY = "write_inquiry";
export const MODIFY_INQUIRY = "modify_inquiry";
export const GET_ALARM_COUNT = "get_alarm_count";
export const GET_COUNSELING_COUNT = "get_counseling_count";
export const GET_INQUIRY_COUNT = "get_inquiry_count";
export const MANAGE_ADDR_NICK_CHK = "manage_addr_nick_chk";
export const ADD_MANAGE_ADDRESS = "add_manage_address";
export const MODIFY_MANAGE_ADDRESS = "modify_manage_address";
export const SEARCH_MYPAGE_LIST = "search_mypage_list";
export const COMBINED_SEARCH = "get_manageAddress";
export const SERVICE_QUESTION = "service_question";
export const SERVICE_QUESTION_DETAIL = "service_question_detail";
export const ADDRESS_BOLG = "address_blog";
export const ADDRESS_BOLG_DETAIL = "address_blog_detail";
export const LEGAL_FAQ = "service_law";
export const LEGAL_FAQ_DETAIL = "service_law_detail";

//COMBINED_SEARCH

// 관리자
export const ADMIN_BOARD_CONSULTING = "admin_board_consulting";
export const ADMIN_BOARD_SEARCH_CONSULTING = "admin_board_search_consulting";
export const ADMIN_USER_LIST = "admin_user_list";
export const ADMIN_SEARCH_USER = "admin_search_user";
export const ADMIN_SEARCH_USER_OUT = "admin_search_user_out";
export const ADMIN_BOARD_DIVISION_LIST = "admin_division_list";
export const ADMIN_BOARD_DIVISION_DELETE = "admin_division_delete";
export const ADMIN_BOARD_DIVISION_ADD = "admin_division_add";
export const ADMIN_BOARD_CONSULT_DETAIL = "admin_board_consult_detail";
export const SERVICE_EVENT_ADD = "service_event_add";
export const BOARD_CONSULT_REPLY = "board_consult_reply";
export const BOARD_CONSULT_REPLY_DELETE = "board_consult_reply_delete";
export const BOARD_CONSULT_DELETE = "board_consult_delete";
export const ADMIN_BOARD_BLOG = "admin_board_blog";
export const ADMIN_BLOG_ADD = "admin_blog_add";
export const ADMIN_BLOG_DETAIL = "admin_blog_datail";
export const ADMIN_BLOG_MODIFY = "admin_blog_modify";
export const ADMIN_BLOG_IMG_DELETE = "admin_blog_img_delete";
export const ADMIN_BLOG_DELETE = "admin_blog_delete";
export const ALARM_MEMBER = "alarm_member";
export const ALARM_OUT_MEMBER = "alarm_out_member";
export const USER_FREE_DETAIL_ADDRESS = "user_free_detail_address";
export const USER_OUT_DETAIL_ADDRESS = "user_out_detail_address";
export const ADDRESS_ALL = "address_all";
export const USER_ADDRESS_DETAIL = "user_address_detail";
export const ADDRESS_MODIFY = "address_modify";
export const ADMIN_BOARD_SEARCH_BLOG = "board_search_blog";
export const ADDRESS_SEARCH = "address_search";
export const ADDRESS_CALL_API = "address_call_api";
export const ADDRESS_CALL_EVENT_API = "address_call_event_api";
export const ADDRESS_CALL_OWNER_API = "address_call_owner_api";
export const ADDRESS_CALL_PDF_API  = "address_call_pdf_api";
export const USER_ADMIN_ADD = "user_admin_add";
export const USER_ADMIN_LIST = "user_admin_list";
export const USER_ADMIN_SEARCH = "user_admin_search";
export const USER_ADMIN_DETAIL = "user_admin_detail";
export const USER_ADMIN_MODIFY = "user_admin_modify";
export const USER_ADMIN_MODIFY_PASSWORD = "user_admin_modify_password";
export const USER_ADMIN_MODIFY_PASSWORD_RESET =
  "user_admin_modify_password_reset";
export const USER_ADMIN_MODIFY_STATUS = "user_admin_modify_status";
export const USER_ADMIN_DELETE = "user_admin_delete";
export const USER_LAWYER_ADD = "user_lawyer_add";
export const USER_LAWYER_LIST = "user_lawyer_list";
export const USER_LAWYER_SEARCH = "user_lawyer_search";
export const USER_LAWYER_DETAIL = "user_lawyer_detail";
export const USER_LAWYER_MODIFY_PASSWORD = "user_lawyer_modify_password";
export const USER_LAWYER_MODIFY_PASSWORD_RESET =
  "user_lawyer_modify_password_reset";
export const USER_LAWYER_MODIFY_STATUS = "user_lawyer_modify_status";
export const USER_LAWYER_DELETE = "user_lawyer_delete";
export const USER_LAWYER_IMG_DELETE = "user_lawyer_img_delete";
export const USER_LAWYER_MODIFY = "user_lawyer_modify";
export const USER_LAWYER_REPLY = "user_lawyer_reply";
export const ADMIN_NOTICE_ADD = "admin_notice_add";
export const USER_ADDRESS_DELETE = "user_address_delete";
export const ADMIN_NOTICE_LIST = "user_address_list";
export const ADMIN_NOTICE_DETAIL = "user_address_detail";
export const ADMIN_NOTICE_MODIFY = "user_address_modify";
export const ADMIN_NOTICE_DELETEIMG = "admin_notice_deleteimg";
export const ADMIN_EVENT_DELETEIMG = "admin_event_deleteimg";
export const ADMIN_NOTICE_DELETE = "user_address_delete";
export const ADMIN_FAQ_LIST = "admin_faq_list";
export const ADMIN_FAQ_ADD = "admin_faq_add";
export const ADMIN_FAQ_DETAIL = "admin_faq_detail";
export const ADMIN_FAQ_MODIFY = "admin_faq_modify";
export const ADMIN_FAQ_DELETE = "admin_faq_delete";
export const ADMIN_EVENT_LIST = "admin_event_list";
export const ADMIN_EVENT_DETAIL = "admin_event_detail";
export const ADMIN_EVENT_MODIFY = "admin_event_modify";
export const ADMIN_EVENT_DELETE = "admin_event_delete";
export const ADMIN_INQUIRY_LIST = "admin_inquiry_list";
export const ADMIN_INQUIRY_DETAIL = "admin_inquiry_detail";
export const ADMIN_INQUIRY_ADD_REPLY = "admin_inquiry_add_reply";
export const ADMIN_INQUIRY_DELETE = "admin_inquiry_delete";
export const ADMIN_SEND_MAIL = "admin_send_mail";
export const ADMIN_SEND_SMS = "admin_send_sms";
export const ADMIN_SMS_HISTORYLIST = "admin_sms_historylist";
export const ADMIN_MAIL_SCHEDULELIST = "admin_mail_schedulelist";
export const ADMIN_MAIL_HISTORYLIST = "admin_mail_historylist";
export const ADMIN_MAIL_SCHEDULE_DETAIL = "admin_mail_schedule_detail";
export const ADMIN_MAIL_SCHEDULE_MODIFY = "admin_mail_schedule_modify";
export const ADMIN_MAIL_HISTORY_DETAIL = "admin_mail_history_detail";
export const ADMIN_MAIL_POPUP_USERS = "admin_mail_popup_users";
export const ADMIN_RESEND_MAIL_DETAIL = "admin_resend_mail_datail";
export const ADMIN_ALLOW = "admin_allow";
export const ADMIN_MAIL_SCHEDULE_DELETE = "admin_mail_schedule_delete";
export const ADMIN_CONSTRUCTION_LOG_ADD = "admin_construction_log_add";
export const ADMIN_CONSTRUCTION = "admin_construction";
