import React, { useState, useLayoutEffect } from "react";
import PaginationNum from "../../components/PaginationNum";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { userLawyerReply } from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";

function UserLawyerWrite() {
  let match = useRouteMatch();
  const idx = match.params.idx;
  const dispatch = useDispatch();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let data = {
      memidx: idx,
    };
    dispatch(userLawyerReply(data)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.replyList;
        setBoardList(result);
      }
    });
  }, [dispatch, idx]);

  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{item.gubun}</td>
        <td>{item.title}</td>
        <td>{item.memname}</td>
        <td>{changeDateFormat(item.boDate, 3, "-", "")}</td>
        <td>{changeDateFormat(item.reDate, 3, "-", "")}</td>
      </tr>
    );
  });
  return (
    <>
      <div className="user_list_column">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>구분</th>
              <th>상담 게시글 제목</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>답변일</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItem.length > 0 ? (
              pageOfItem
            ) : (
              <tr>
                <td colSpan={6}>등록된 글이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationNum items={boardList} onChangePage={onChangePage} />
      </div>
    </>
  );
}

export default UserLawyerWrite;
