import {useEffect} from "react";

function useScript() {
    /*
    * 네이버 전환 스크립트
    * 2022-01-25 공통스크립트가 실행되지 않아, 전환+공통 스트립트형태로 수정
    * */
    useEffect(() => {
        const script_wcslog = document.createElement('script');
        const script_nasa = document.createElement('script');

        script_wcslog.type = "text/javascript";
        script_wcslog.src = "//wcs.naver.net/wcslog.js";

        script_nasa.type = "text/javascript";

        script_nasa.innerHTML =
            ' if (!wcs_add) var wcs_add={};' +
            ' wcs_add["wa"] = "f0d2cceaf52508";' +
            'if (!_nasa) var _nasa={};' +
            '_nasa["cnv"] = wcs.cnv("2","1");' +
            ' wcs_do(_nasa);'

        //공통스크립트보다 상단에 위치해야한다.
        document.body.prepend(script_wcslog, script_nasa);
        // console.log("네이버 전환스크립트 실행");

    },[]);

}

export { useScript };


//네이버 전환 스크립트 (아래와 같이 출력)
/*<!-- NAVER SCRIPT -->
<script type="text/x-x-javascript" src="//wcs.naver.net/wcslog.js"></script>
<script type="text/x-x-javascript">
    if (!wcs_add) var wcs_add={};
    wcs_add["wa"] = "f0d2cceaf52508";
    if (!_nasa) var _nasa={};
    _nasa["cnv"] = wcs.cnv("2","1");
    wcs_do(_nasa);
</script>
<!-- NAVER SCRIPT END -->*/




