import React, { useState, useLayoutEffect } from "react";
import "./Board.css";
import slideGo from "../../images/slide_go.png";
import counselingImg from "../../images/counseling_img.png";
import depthHome from "../../images/depth_home.png";
import reply from "../../images/reply.png";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { getCounseling } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import { auth } from "../../_actions/user_action";
import { useRouteMatch } from "react-router-dom";

function Counseling() {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const pagen = match.params.pagen;
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const pathName = "/counseling/";
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [memId, setMemId] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // counseling 글
  const [consultingList, setConsultingList] = useState([]);

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
      setMemId(res.payload.memid);
    });
    dispatch(getCounseling()).then((res) => {
      if (res.payload.boardList !== false) {
        const result = res.payload.boardList;
        setConsultingList(result);
      }
    });
  }, [dispatch]);
  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 상담 신청하기 클릭 시
  const applyCounseling = () => {
    if (isLoginAuth) {
      history.push("/counselingWrite");
    } else {
      openModal("modal1");
    }
  };

  const gotofaq = () => {
    history.push("/law/전체/1");
  }

  // 법률상담 상세페이지 이동, state에는 rownum 번호 전달
  const moveDetail = (e, idx, rownum) => {
    history.push({
      pathname: `/counseling/detail/${idx}/${pagen}`,
      state: { rownum: rownum },
    });
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main board_main" id="counseling">
          <div className="section_1">
            <div className="container">
              <h1 className="title">무료 법률상담</h1>
              <div className="desc">
                부동산 경매에 관한 법률 상담이 필요하신가요? <br />
                전문 변호사가 직접 답변해드립니다.
              </div>
              <div className="go_btn_column" style={{ display: "flex" }}>
                <div className="go_btn btn" onClick={applyCounseling} style={{ marginRight : "10px"}}>
                  경매상담 신청
                  <img src={slideGo} alt="go" className="slide_go" />
                </div>
                <div className="go_btn btn" onClick={gotofaq}>
                  법률상담 FAQ
                  <img src={slideGo} alt="go" className="slide_go" />
                </div>
              </div>

              <img
                src={counselingImg}
                alt="counseling"
                className="board_main_img"
              />
            </div>
          </div>
          <div className="section_2">
            <div className="depth_column">
              <BrowserRouter forceRefresh={true}>
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
              </BrowserRouter>
              <div className="board_name">
                <span>〉</span>무료 법률상담
              </div>
            </div>
            <div
              className="table_column"
              style={{ minHeight: pageOfItems.length > 0 ? 660 : "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>번호</th>
                    <th style={{ width: "10%" }}>구분</th>
                    <th>제목</th>
                    <th style={{ width: "10%" }}>답변</th>
                    <th style={{ width: "15%" }}>작성자</th>
                    <th style={{ width: "10%" }}>작성일</th>
                  </tr>
                </thead>
                <tbody>
                  {isMobile ? (
                    pageOfItems.length > 0 ? (
                      pageOfItems.map((item, index) => {
                        return (
                          <>
                            <tr
                              className={
                                item.open === 0
                                  ? item.replyTitle
                                    ? "isMobil_tr no_visible have_reply"
                                    : "isMobil_tr no_visible"
                                  : item.replyTitle
                                  ? "isMobil_tr have_reply"
                                  : "isMobil_tr"
                              }
                              key={index}
                            >
                              <td className="num">{item.rownum}</td>
                              {item.open === 0 ? (
                                <td
                                  onClick={(e) =>
                                    item.memid !== memId
                                      ? openModal("modal2")
                                      : moveDetail(e, item.idx, item.rownum)
                                  }
                                >
                                  <div>{item.gubun}</div>
                                  <div className="wrapper">
                                    <div className="title">{item.title}</div>
                                    {item.status === 1 ? (
                                      <div className="check_reply finish">완료</div>
                                    ) : item.status === 0 ? (
                                      <div className="check_reply">대기</div>
                                    ) : <div className="check_reply ing">변호사 답변 대기</div> }
                                  </div>
                                  <div className="wrapper">
                                    <div className="nickname">
                                      {item.memname}
                                    </div>
                                    <div className="bar"></div>
                                    <div>
                                      {changeDateFormat(
                                        item.registerdate,
                                        3,
                                        "-",
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {item.replyTitle && (
                                    <div
                                      className="reply_column"
                                      style={{ marginTop: 16 }}
                                    >
                                      <div>
                                        <img src={reply} alt="reply" />
                                      </div>
                                      <div>
                                        <div className="wrapper">
                                          <div className="title">
                                            {item.replyTitle}
                                          </div>
                                        </div>
                                        <div className="wrapper">
                                          <div>{item.replyMemname}</div>
                                          <div className="bar"></div>
                                          <div>
                                            {changeDateFormat(
                                              item.replyRegisterdate,
                                              3,
                                              "-",
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              ) : (
                                <td>
                                  <BrowserRouter forceRefresh={true}>
                                    <Link
                                      to={{
                                        pathname: `/counseling/detail/${item.idx}/${pagen}`,
                                        state: { rownum: item.rownum },
                                      }}
                                    >
                                      <div>{item.gubun}</div>
                                      <div className="wrapper">
                                        <div className="title">
                                          {item.title}
                                        </div>
                                        {item.status === 1 ? (
                                          <div className="check_reply finish">완료</div>
                                        ) : item.status === 0 ? (
                                          <div className="check_reply">대기</div>
                                        ) : <div className="check_reply ing">변호사 답변 대기</div> }
                                      </div>
                                      <div className="wrapper">
                                        <div className="nickname">
                                          {item.memname}
                                        </div>
                                        <div className="bar"></div>
                                        <div>
                                          {changeDateFormat(
                                            item.registerdate,
                                            3,
                                            "-",
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {item.replyTitle && (
                                        <div
                                          className="reply_column"
                                          style={{ marginTop: 16 }}
                                        >
                                          <div>
                                            <img src={reply} alt="reply" />
                                          </div>
                                          <div>
                                            <div className="wrapper">
                                              <div className="title">
                                                {item.replyTitle}
                                              </div>
                                            </div>
                                            <div className="wrapper">
                                              <div>{item.replyMemname}</div>
                                              <div className="bar"></div>
                                              <div>
                                                {changeDateFormat(
                                                  item.replyRegisterdate,
                                                  3,
                                                  "-",
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Link>
                                  </BrowserRouter>
                                </td>
                              )}
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>등록된 글이 없습니다.</td>
                      </tr>
                    )
                  ) : pageOfItems.length > 0 ? (
                    pageOfItems.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className={
                              item.open === 0
                                ? item.replyTitle
                                  ? "no_visible have_reply"
                                  : "no_visible"
                                : item.replyTitle
                                ? "have_reply"
                                : ""
                            }
                          >
                            <td>{item.rownum}</td>
                            <td>{item.gubun}</td>

                            {item.open === 0 ? (
                              <>
                                <td
                                  onClick={(e) =>
                                    item.memid !== memId
                                      ? openModal("modal2")
                                      : moveDetail(e, item.idx, item.rownum)
                                  }
                                >
                                  {item.title}
                                  {item.replyTitle && (
                                    <div
                                      className="reply_column"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={reply}
                                        alt="reply"
                                        style={{ marginRight: 6 }}
                                      />
                                      <div className="title">
                                        {item.replyTitle}
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </>
                            ) : (
                              <td>
                                <BrowserRouter forceRefresh={true}>
                                  <Link
                                    to={{
                                      pathname: `/counseling/detail/${item.idx}/${pagen}`,
                                      state: { rownum: item.rownum },
                                    }}
                                  >
                                    {item.title}
                                    {item.replyTitle && (
                                      <div
                                        className="reply_column"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={reply}
                                          alt="reply"
                                          style={{ marginRight: 6 }}
                                        />
                                        <div className="title">
                                          {item.replyTitle}
                                        </div>
                                      </div>
                                    )}
                                  </Link>
                                </BrowserRouter>
                              </td>
                            )}
                            <td>
                              {item.status === 1 ? (
                                <div className="check_reply finish">완료</div>
                              ) : item.status === 0 ? (
                                <div className="check_reply">대기</div>
                              ) : <div className="check_reply ing">변호사 답변 대기</div>}
                            </td>
                            <td>
                              <div>{item.memname}</div>
                              {item.replyTitle && (
                                <div>{item.replyMemname}</div>
                              )}
                            </td>
                            <td className="regdate">
                              <div>
                                {changeDateFormat(
                                  item.registerdate,
                                  3,
                                  "-",
                                  ""
                                )}
                              </div>
                              {item.replyTitle && (
                                <div>
                                  {changeDateFormat(
                                    item.replyRegisterdate,
                                    3,
                                    "-",
                                    ""
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>등록된 글이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {isMobile ? null :
            <div className="go_btn_column apply_counseling">
              <div className="go_btn btn" onClick={applyCounseling}>
                경매상담 신청
                <img src={slideGo} alt="go" className="slide_go" />
              </div>
            </div>
            }
            <Pagination
              items={consultingList}
              onChangePage={onChangePage}
              initialPage={pagen}
              pathName={pathName}
            />
          </div>
          <Modal open={modal["modal1"]} width={400}>
            <div className="modal_inner_ct modal_400 is_login_modal">
              <h2 className="title">로그인 후 이용 가능합니다.</h2>
              <div className="btn_column">
                <div
                  className="btn blue_btn close "
                  onClick={() => closeModal("modal1")}
                >
                  닫기
                </div>

                <BrowserRouter forceRefresh={true}>
                  <Link to="/login">
                    <div className="blue_btn btn">로그인</div>
                  </Link>
                </BrowserRouter>
              </div>
            </div>
          </Modal>
          <Modal open={modal["modal2"]} width={400}>
            <div className="modal_inner_ct modal_400 is_login_modal">
              <h2 className="title">비공개 글입니다.</h2>
              <div className="btn_column">
                <div
                  className="btn blue_btn close "
                  onClick={() => closeModal("modal2")}
                >
                  닫기
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default Counseling;
