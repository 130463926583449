import React, { useLayoutEffect, useRef, useState } from "react";
import "./FindUser.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import MobileBottomNav from "../Footer/MobileBottomNav";

function FindId() {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const [btnMessage, setBtnMessage] = useState("인증번호 발송");
  const [inputData, setInputData] = useState(false);
  const [userId, setUserId] = useState(false);
  const [warningMessge, setWarningMessge] = useState("");
  const [infoMessage, setInfoMessage] = useState("(-) 없이 숫자만 입력하세요.");
  const [getAuthNumVal, setGetAuthNumVal] = useState("");
  const [authOk, setAuthOk] = useState(false);
  const [authnum, setAuthnum] = useState("");
  // 핸드폰 번호 입력창
  const [telNum, setTelNum] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [display, setDisplay] = useState("");

  // 휴대폰 번호 입력 후 enter 눌렀을 때
  const onKeyPressTel = (e) => {
    if (e.key === "Enter") {
      sendAuthNum();
    }
  };

  const sendAuthNum = () => {
    let body = {
      cellno: telNum,
    };
    // 인증번호 DB 저장
    axios.post("/api/call/aligo/sms", body).then((res) => {
      if (res.data.result === "success") {
        setGetAuthNumVal(res.data.authnum);
        setBtnMessage("재발송");
        setInputData(true);
        handleFocus(1);
        setWarningMessge("");
        if (isRunning) {
          replaceCurrentInterval();
          checkStatus(180);
        } else {
          checkStatus(180);
        }
      } else if (res.data.result === "undefined") {
        setWarningMessge("가입된 전화번호가 아닙니다.");
        handleFocus(0);
        setGetAuthNumVal("");
      } else {
        setWarningMessge("문자 전송에 실패하였습니다.");
        handleFocus(0);
        setGetAuthNumVal("");
      }
    });
  };

  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);

  // 인증번호 입력
  const handleAuthNum = (e) => {
    let value = e.target.value;
    if (value.length > 5) {
      value = e.target.value.slice(0, 5);
    }
    setAuthnum(value);
    if (getAuthNumVal === value) {
      setInfoMessage("인증이 완료 되었습니다.");
      setAuthOk(true);
      clearCurrentInterval();
    }
  };

  // 인증번호 타이머
  const _interval = useRef(null);
  const interval = () => _interval.current;

  const clearCurrentInterval = () => {
    clearInterval(interval());
  };

  const replaceCurrentInterval = (newInterval) => {
    clearCurrentInterval();
    _interval.current = newInterval;
  };

  const checkStatus = (count) => {
    let minutes, seconds;
    const newInterval = setInterval(() => {
      minutes = parseInt(count / 60, 10);
      seconds = parseInt(count % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setDisplay(minutes + ":" + seconds);

      if (--count < 0) {
        clearInterval(newInterval);
      }
    }, 1000);

    replaceCurrentInterval(newInterval);
    setIsRunning(true);
  };

  // 인증 확인
  const onSubmit = (e) => {
    e.preventDefault();
    if (authOk) {
      let url = "/api/call/aligo/key";
      let body = {
        cellno: telNum,
        authnum: authnum,
      };
      axios.post(url, body).then((res) => {
        if (res.data.result === "success") {
          setWarningMessge("");
          setUserId(res.data.memid);
        } else if (res.data.result === "wrong") {
          setWarningMessge("인증번호가 일치하지 않습니다.");
        } else {
          e.preventDefault();
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    }
  };
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header />
        <div className="main center_main find_user" id="findId">
          <h1 className="main_title">아이디 찾기</h1>

          <div className={userId ? "title_info confirm" : "title_info"}>
            {userId ? (
              <>가입하신 ID(이메일)입니다.</>
            ) : (
              <>
                아이디(이메일 주소) 확인을 위해 <br />
                가입한 전화번호의 문자 인증이 필요합니다.
              </>
            )}
          </div>

          <form onSubmit={onSubmit}>
            {userId ? (
              <>
                <div className="user_id">{userId}</div>
                <Link to="/login">
                  <div className="blue_btn btn login_btn">로그인</div>
                </Link>
              </>
            ) : (
              <>
                <div className="input_column">
                  <input
                    type="text"
                    placeholder="휴대폰 번호를 입력하세요."
                    onChange={handelTelNum}
                    onKeyPress={onKeyPressTel}
                    value={telNum}
                    ref={(elem) => (inputRefs.current[0] = elem)}
                  />
                  <div className="check_btn" onClick={sendAuthNum}>
                    {btnMessage}
                  </div>
                </div>
                {inputData ? (
                  <div className="input_column auth_num_column">
                    <input
                      type="text"
                      placeholder="인증번호를 입력하세요."
                      className="auth_num"
                      onChange={handleAuthNum}
                      ref={(elem) => (inputRefs.current[1] = elem)}
                    />
                    <span className="counter">
                      {display}
                      {/* 03:00 */}
                    </span>
                  </div>
                ) : null}

                <div className="info">
                  ※ {infoMessage}
                  {/* ※ (-) 없이 숫자만 입력하세요./인증이 완료 되었습니다. */}
                </div>
                <div className="warning">
                  {warningMessge}
                  {/* /인증번호가 일치하지 않습니다. */}
                </div>
                <div className="btn_column">
                  {authOk ? (
                    <div className="next_btn active btn" onClick={onSubmit}>
                      인증 확인
                    </div>
                  ) : (
                    <div className="next_btn btn">인증 확인</div>
                  )}
                </div>
                <div className="info btn_bottom">
                  ※ 전화번호가 변경되었을 경우에는 고객센터로 문의바랍니다.
                </div>
              </>
            )}
          </form>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default FindId;
