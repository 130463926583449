import React, { useState, useLayoutEffect } from "react";
import profileImg from "../../../images/profile_img.png";
import { changeDateFormat } from "../../../Common/lib";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";

function UserOutBasic() {
  let match = useRouteMatch();
  let history = useHistory();
  const idx = match.params.idx;
  const pagen = match.params.pagen;

  // 회원정보
  const [user, setUser] = useState({});

  const [advertising, setAdvertising] = useState([]);
  // 프로필 사진
  const [img, setImg] = useState({});

  // 관리주소 현황
  const [imdaecha, setImdaecha] = useState(0);

  // 회원 닉네임
  const [Nickname, setNickname] = useState("");

  useLayoutEffect(() => {
    let url = "/api/admin/user/out/detail/user";
    let data = { idx: idx };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        if (res.data.user !== "false") {
          setUser(res.data.user);
          setNickname(res.data.user.memname);
          if (res.data.user.advertising !== "false") {
            setAdvertising(res.data.user.advertising);
          }
        }
        if (res.data.profile !== "false") {
          setImg(res.data.profile);
        }

        if (res.data.imdaecha !== "false") {
          setImdaecha(res.data.imdaecha);
        }
      } else {
        alert("회원 정보를 불러오지 못했습니다.");
      }
    });
  }, [idx]);

  const handleImgError = (e) => {
    e.target.src = profileImg;
  };

  return (
    <>
      <table className="detail_table out_detail_table">
        <tbody>
          <tr>
            <th>회원상태</th>
            <td>
              <div className="free_user_radio">
                <input
                  type="radio"
                  id="radio7"
                  name="memstatus"
                  checked={user.memstatus === "탈퇴"}
                  value="탈퇴"
                  // onChange={userlHandler}
                  readOnly
                />
                <label htmlFor="radio7">탈퇴</label>
              </div>
            </td>
            <th rowSpan="5">프로필 사진</th>
            <td rowSpan="5">
              <div className="img_column">
                <img
                  style={{ maxWidth: 160 }}
                  src={img.profilePath ? img.profilePath : profileImg}
                  alt="profile"
                  onError={handleImgError}
                />
                {/* <div className="img_name">
                  {img.profileName
                    ? img.profileName
                    : "등록된 프로필이 없습니다."}
                </div> */}
                {/* <div
                  className="admin_black_btn btn"
                  onClick={() => {
                    deleteProfile();
                  }}
                >
                  삭제
                </div> */}
              </div>
            </td>
          </tr>
          <tr>
            <th>가입일</th>
            <td>{changeDateFormat(user.memregdtime, 5, "-", ":")}</td>
          </tr>

          <tr>
            <th>이름</th>
            <td>{user.memrealname ? user.memrealname : "-"}</td>
          </tr>
          <tr>
            <th>회원 닉네임</th>
            <td className="nickName">
              <div className="nickName_column">{Nickname ? Nickname : "-"}</div>
            </td>
          </tr>
          <tr>
            <th className="tel_num">핸드폰 번호</th>
            <td>
              {user && user.cellno ? (
                <>
                  {user.cellno ? user.cellno : "-"}
                  {/* <div className="admin_input_info">
                    ※ 인증일시 :
                    {user.authdate
                      ? changeDateFormat(user.authdate, 5, ".", ":")
                      : " - "}
                  </div> */}
                </>
              ) : (
                <>
                  {"-"}
                  <div className="admin_input_info" />
                </>
              )}
            </td>
          </tr>

          <tr>
            <th>이메일</th>
            <td>{user.memid ? user.memid : ""}</td>
            <th>가입유형</th>
            <td>{user.memjointype ? user.memjointype : ""}</td>
          </tr>
          <tr>
            <th>최종 로그인 일시</th>
            <td>{changeDateFormat(user.lastlogin, 6, "-", ":")}</td>
            <th>최종 로그인 IP</th>
            <td>{user.ip}</td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">관리주소 현황</h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th rowSpan="2">주소등록 한도</th>
            <td rowSpan="2">
              {user.memaddrmaxjoin ? user.memaddrmaxjoin : 1}건
            </td>
            <th>주소등록 건</th>
            <td>
              <div className="day_change_count">
                {user.COUNT ? user.COUNT : 0}건
              </div>
            </td>
          </tr>
          <tr>
            <th>임대차만기일</th>
            <td>{imdaecha}건</td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">마케팅 활용</h3>
      <table className="detail_table marketing_table">
        <tbody>
          <tr>
            <th rowSpan="2">마케팅 정보 수신</th>
            <td>
              <div className="free_user_radio">
                <input
                  type="radio"
                  checked={user.advertising !== "false"}
                  id="radio4"
                  name="agreeMarketing"
                  disabled
                />
                <label htmlFor="radio4">동의</label>
                <input
                  type="radio"
                  id="radio5"
                  name="agreeMarketing"
                  checked={user.advertising === "false"}
                  disabled
                />
                <label htmlFor="radio5">동의안함</label>
              </div>
            </td>
            <th rowSpan="2">
              마케팅 정보수신 <br />
              변경일시
            </th>
            <td rowSpan="2">
              {user.advdate
                ? changeDateFormat(user.advdate, 5, ".", ":")
                : "변경 이력이 없습니다."}
            </td>
          </tr>
          <tr>
            <td>
              <div className="free_user_chk">
                {["앱푸시", "이메일", "문자(SMS)", "알림톡"].map((val, i) => {
                  return (
                    <span key={"adv" + i}>
                      <input
                        type="checkbox"
                        id={"chk" + i}
                        checked={advertising.includes(i.toString())}
                        disabled
                      />
                      <label htmlFor={"chk" + i}>{val}</label>
                    </span>
                  );
                })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">정지/탈퇴 정보</h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>이용정지 일시</th>
            <td>
              {user.usestoptime
                ? changeDateFormat(user.usestoptime, 6, "-", ":")
                : "-"}
            </td>
            <th>이용정지 상태</th>
            <td>{user.usestopdesc ? user.usestopdesc : "-"}</td>
          </tr>
          <tr>
            <th>회원탈퇴 일시</th>
            <td>
              {user.unregtime
                ? changeDateFormat(user.unregtime, 6, "-", ":")
                : "-"}
            </td>
            <th>회원탈퇴 사유</th>
            <td>{user.unregdesc ? user.unregdesc : "-"}</td>
          </tr>
        </tbody>
      </table>
      <div className="detail_user admin_btn_column">
        <div
          className="admin_white_btn btn"
          onClick={() => {
            history.push(`/rf_zib_admin/user/out/${pagen}`);
          }}
        >
          목록
        </div>
      </div>
    </>
  );
}

export default UserOutBasic;
