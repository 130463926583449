import React, { useLayoutEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { combinedSearch } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import { BrowserRouter, Link } from "react-router-dom";

function EventTab({ keyword, condition }) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [listCnt, setListCnt] = useState(0);
  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let body = {
      condition: condition,
      search: keyword,
    };

    dispatch(combinedSearch(body)).then((res) => {
      const result = res.payload.searchList;
      setList(result.event);
      setListCnt(result.event.length);
    });
  }, [dispatch, keyword, condition]);
  return (
    <>
      <div className="sub_result_column">
        <h2>
          이벤트&nbsp;<div className="count">{listCnt}</div>
        </h2>
        <ul>
          {pageOfItems.length > 0 ? (
            pageOfItems.map((item, index) => {
              return (
                <li key={index}>
                  <div className="link">
                    <BrowserRouter forceRefresh={true}>
                      <Link to={item.url} className="link">
                        {item.title}
                      </Link>
                    </BrowserRouter>
                  </div>
                  <div className="link_info">
                    <div>{item.gubun}</div>
                    <div className="bar"></div>
                    <div>{changeDateFormat(item.registerdate, 3, "-", "")}</div>
                    <div className="bar"></div>
                    <div>{item.memname}</div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>검색된 결과가 없습니다.</li>
          )}
        </ul>
      </div>
      <Pagination items={list} onChangePage={onChangePage} pageSize={5} />
    </>
  );
}

export default EventTab;
