import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Link } from "react-router-dom";
import "./SubBanner.css";
import SubBannerNum1 from "../images/svg/sub-banner-num-1.svg";
import SubBannerNum2 from "../images/svg/sub-banner-num-2.svg";
import SubBannerNum3 from "../images/svg/sub-banner-num-3.svg";
import SubBannerNum4 from "../images/svg/sub-banner-num-4.svg";
import SubBannerNum5 from "../images/svg/sub-banner-num-5.svg";
import SubBannerNumHover1 from "../images/svg/sub-banner-num-1-hover.svg";
import SubBannerNumHover2 from "../images/svg/sub-banner-num-2-hover.svg";
import SubBannerNumHover3 from "../images/svg/sub-banner-num-3-hover.svg";
import SubBannerNumHover4 from "../images/svg/sub-banner-num-4-hover.svg";
import SubBannerNumHover5 from "../images/svg/sub-banner-num-5-hover.svg";

import MobileSubBannerNum1 from "../images/svg/m-sub-banner-num-1.svg";
import MobileSubBannerNum2 from "../images/svg/m-sub-banner-num-2.svg";
import MobileSubBannerNum3 from "../images/svg/m-sub-banner-num-3.svg";
import MobileSubBannerNum4 from "../images/svg/m-sub-banner-num-4.svg";
import MobileSubBannerNum5 from "../images/svg/m-sub-banner-num-5.svg";
import MobileSubBannerNumHover1 from "../images/svg/m-sub-banner-num-1-hover.svg";
import MobileSubBannerNumHover2 from "../images/svg/m-sub-banner-num-2-hover.svg";
import MobileSubBannerNumHover3 from "../images/svg/m-sub-banner-num-3-hover.svg";
import MobileSubBannerNumHover4 from "../images/svg/m-sub-banner-num-4-hover.svg";
import MobileSubBannerNumHover5 from "../images/svg/m-sub-banner-num-5-hover.svg";

import SubBannerRight1 from "../images/svg/sub-banner-right-1.svg";
import SubBannerRight2 from "../images/svg/sub-banner-right-2.svg";
import SubBannerRight3 from "../images/svg/sub-banner-right-3.svg";
import SubBannerRight4 from "../images/svg/sub-banner-right-4.svg";
import SubBannerRight5 from "../images/svg/sub-banner-right-5.svg";
import MobileSubBannerRight1 from "../images/svg/m-sub-banner-right-1.svg";
import MobileSubBannerRight2 from "../images/svg/m-sub-banner-right-2.svg";
import MobileSubBannerRight3 from "../images/svg/m-sub-banner-right-3.svg";
import MobileSubBannerRight4 from "../images/svg/m-sub-banner-right-4.svg";
import MobileSubBannerRight5 from "../images/svg/m-sub-banner-right-5.svg";

export default function SliderMainBanner() {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const [active, setActive] = useState(0)

  const images = {
    pc: {
      title: [
        { src : SubBannerNum1, hover: SubBannerNumHover1, alt: "sub-banner" },
        { src : SubBannerNum2, hover: SubBannerNumHover2, alt: "sub-banner" },
        { src : SubBannerNum3, hover: SubBannerNumHover3, alt: "sub-banner" },
        { src : SubBannerNum4, hover: SubBannerNumHover4, alt: "sub-banner" },
        { src : SubBannerNum5, hover: SubBannerNumHover5, alt: "sub-banner" },  
      ],
      contents: [
        { src : SubBannerRight1, alt: 'sub-banner-right', link: '/pts/description' },
        { src : SubBannerRight2, alt: 'sub-banner-right', link: '/pts/description' },
        { src : SubBannerRight3, alt: 'sub-banner-right', link: '/pts/description' },
        { src : SubBannerRight4, alt: 'sub-banner-right', link: '/counseling/1' },
        { src : SubBannerRight5, alt: 'sub-banner-right', link: '/information/1' },
      ]
    },
    mobile: {
      title: [
        { src : MobileSubBannerNum1, hover: MobileSubBannerNumHover1, alt: "sub-banner" },
        { src : MobileSubBannerNum2, hover: MobileSubBannerNumHover2, alt: "sub-banner" },
        { src : MobileSubBannerNum3, hover: MobileSubBannerNumHover3, alt: "sub-banner" },
        { src : MobileSubBannerNum4, hover: MobileSubBannerNumHover4, alt: "sub-banner" },
        { src : MobileSubBannerNum5, hover: MobileSubBannerNumHover5, alt: "sub-banner" },  
      ],
      contents: [
        { src : MobileSubBannerRight1, alt: 'sub-banner-right', link: '/pts/description' },
        { src : MobileSubBannerRight2, alt: 'sub-banner-right', link: '/pts/description' },
        { src : MobileSubBannerRight3, alt: 'sub-banner-right', link: '/pts/description' },
        { src : MobileSubBannerRight4, alt: 'sub-banner-right', link: '/counseling/1' },
        { src : MobileSubBannerRight5, alt: 'sub-banner-right', link: '/information/1' },
      ]
    }
  };

  const onMouseHandler = (index) => {
    setActive(index)
  };

  return (
    <div className="section_4">
      <h2 className="service_title">도와줘요, 집파인!</h2>
      <div className="service_container">
        {isMobile ? (
          <>
            <div className="sub_banner_accordion">
              {images.mobile.title.map((row, index) => {
                return (
                  <div className="sub_banner_accordion_item" key={`sub:banner:mobile:${index}`}>
                    <div className="sub_banner_accordion_item_header">
                      <img 
                        src={ active === index ? row.hover : row.src }
                        onClick={() => { onMouseHandler(index) }}
                        alt={row.alt} 
                      />
                    </div>
                    <div className={"sub_banner_accordion_item_body" + (active === index ? ' active' : '')}>
                      <BrowserRouter forceRefresh={true} key={index}>
                        <Link to={images.mobile.contents[index].link}>
                          <img src={ images.mobile.contents[index].src } alt={row.alt} />
                        </Link>
                      </BrowserRouter>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className="sub_banner_row">
            <div className="sub_banner_column_1 pe-15">
              {images.pc.title.map((row, index) => {
                return (
                  <img 
                    key={`sub:banner:pc:${index}`}
                    src={ active === index ? row.hover : row.src }
                    onMouseEnter={() => { onMouseHandler(index) }}
                    alt={ row.alt }
                  />
                )
              })}
            </div>
            <div className="sub_banner_column_2">
              <BrowserRouter forceRefresh={true}>
                <Link to={images.pc.contents[active].link}>
                  <img src={ images.pc.contents[active].src } alt={images.pc.contents[active].alt} />
                </Link>
              </BrowserRouter>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}