import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import profileImg from "../../images/profile_img.png";
import profileIconKakao from "../../images/profile_icon_kakao.png";
import profileIconNaver from "../../images/profile_icon_naver.png";
import profileIconApple from "../../images/profile_icon_apple.png";

import modify from "../../images/modify.png";
import next from "../../images/next.png";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getManageAddress } from "../../_actions/main_action";
import { useHistory } from "react-router-dom";

function Mypage() {
  const allAlarmCount = useSelector((store) => store.adminInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [userId, setUserId] = useState("");
  const [profilePath, setProfilePath] = useState("");
  const [addRentalCnt, setAddRentalCnt] = useState(true);
  const [profileIcon, setProfileIcon] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
      setUserId(res.payload.memid);
      if (res.payload.memjointype === "카카오톡") {
        setProfileIcon(profileIconKakao);
      } else if (res.payload.memjointype === "네이버") {
        setProfileIcon(profileIconNaver);
      } else if (res.payload.memjointype === "애플") {
        setProfileIcon(profileIconApple);
      }
    });
  }, [dispatch]);

  const addRental = () => {
    dispatch(getManageAddress()).then((res) => {
      if (!res.payload.address) {
        setAddRentalCnt(false);
      } else {
        history.push("/mypage/address");
      }
    });
  };
  return (
    <>
      <div className="root_container">
        <Header
          isLoginAuth={isLoginAuth}
          memname={memname}
          profilePath={profilePath}
        />
        <div className="main center_main mypage_main">
          <div className="mypage_header">
            <div className="depth_column">
              <Link to="/">
                <img src={depthHome} alt="home" />
              </Link>
              <div className="board_name">
                <span>〉</span>마이페이지
              </div>
            </div>
          </div>

          <div className="mypage_body">
            <Sidebar />
            <div className="content">
              <h2>내 정보</h2>

              <div className="profile_wrapper">
                <div className="profile_column">
                  <img
                    src={profilePath ? profilePath : profileImg}
                    alt="profile"
                    className="profile"
                  />
                  <div className="profile_info">
                    <div className="profile_name">
                      <strong>{memname}</strong>님
                    </div>
                    <div className="profile_email">
                      {profileIcon === "" ? (
                        ""
                      ) : (
                        <img src={profileIcon} alt="icon" />
                      )}
                      {userId}
                    </div>
                    <Link to="/mypage/modify">
                      <div className="modify_btn btn">
                        <img src={modify} alt="modify" />
                        수정
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="btns_column">
                  <Link to="/mypage/address">
                    <div className="btns btn">
                      <div className="btn_name">관리주소 등록</div>
                      <img src={next} alt="next" />
                    </div>
                  </Link>

                  <div className="btns btn" onClick={addRental}>
                    <div>
                      <div className="btn_name">임대차만기일 등록</div>
                      <div className="warning">
                        {!addRentalCnt && "*관리주소 등록 후 이용 가능합니다."}
                      </div>
                    </div>
                    <img src={next} alt="next" />
                  </div>

                  <Link to="/mypage/myarticle">
                    <div className="btns btn">
                      <div className="btn_name">내글 보기</div>
                      <div className="btn_count">
                        <strong>{allAlarmCount.counselingCount}</strong>건
                      </div>
                    </div>
                  </Link>
                  <Link to="/mypage/notice">
                    <div className="btns btn">
                      <div className="btn_name">알림 보기</div>
                      <div className="btn_count">
                        <strong>{allAlarmCount.alarmCount}</strong>건
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Mypage;
