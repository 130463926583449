import React, { useLayoutEffect, useRef, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminBlogAdd,
  boardDivisionList,
} from "../../_actions/admin_board_action";

function BlogBoardAdd({ history }) {
  const dispatch = useDispatch();

  // 뒤로가기
  const goBack = () => {
    history.goBack();
  };

  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]); // 카테고리 리스트
  const [categorie, setCategorie] = useState(); // 선택한 카테고리
  const [fileUrl, setFileUrl] = useState(""); // 화면에 이미지 표시
  const [FileInfo, setFileInfo] = useState();
  useLayoutEffect(() => {
    let body = {
      kind: "블로그",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
        setCategorie(result[0].name);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch]);

  // input값 제어
  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "title":
        setTitle(e.target.value);
        return;
      case "text":
        setText(e.target.value);
        return;
      case "status":
        setStatus(e.target.value);
        return;
      case "gubun":
        setCategorie(e.target.value);
        return;
      default:
        return;
    }
  };

  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  // 파일 정보 저장
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
  };

  // 파일 삭제
  const deleteFileUrl = () => {
    setFileInfo(null);
    setFileUrl("");
  };

  // 저장
  const submitHandler = () => {
    if (title === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (text === "") {
      alert("내용을 입력하세요.");
      return;
    }
    let admin_auth = localStorage.getItem("admin_auth");
    let bodyNameList = [
      "title",
      "text",
      "gubun",
      "blogImg",
      "status",
      "admin_auth",
    ];
    let bodyValueList = [title, text, categorie, FileInfo, status, admin_auth];
    let formData = new FormData();

    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      formData.append(val, bodyValueList[i]);
      return "";
    });

    dispatch(adminBlogAdd(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">부동산정보 등록</h2>
              </div>

              <table className="detail_table board_table">
                <tbody>
                  <tr>
                    <th>공개여부</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          accept="image/*"
                          defaultChecked
                          id="radio1"
                          onChange={inputHandler}
                          name="status"
                          value={1}
                        />
                        <label htmlFor="radio1">공개</label>
                        <input
                          type="radio"
                          id="radio2"
                          onChange={inputHandler}
                          name="status"
                          value={0}
                        />
                        <label htmlFor="radio2">비공개</label>
                      </div>
                    </td>
                    <th rowSpan="3">대표 이미지</th>
                    <td rowSpan="3">
                      <div className="img_column">
                        <div className="sample_img">
                          {fileUrl && (
                            <img
                              alt="sample"
                              src={fileUrl}
                              style={{ margin: "auto" }}
                            />
                          )}

                          <input
                            name="eventImg"
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            ref={(elem) => (fileInputs.current = elem)}
                            onChange={setFile}
                          />
                        </div>

                        <div
                          className="admin_black_btn_column"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="admin_black_btn left btn"
                            onClick={() => {
                              openFile();
                            }}
                          >
                            업로드
                          </div>
                          <div
                            className="admin_black_btn btn left"
                            onClick={() => deleteFileUrl()}
                          >
                            삭제
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>구분</th>
                    <td>
                      <select onChange={inputHandler} name="gubun">
                        {categories.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <th>제목</th>
                    <td>
                      <input
                        type="text"
                        placeholder="제목을 입력하세요."
                        onChange={inputHandler}
                        name="title"
                      />
                    </td>
                  </tr>
                  <tr className="board_content">
                    <th>내용</th>
                    <td colSpan="3">
                      <textarea
                        placeholder="내용을 입력하세요."
                        onChange={inputHandler}
                        name="text"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="detail_user admin_btn_column">
                <div className="admin_white_btn btn left" onClick={goBack}>
                  목록
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogBoardAdd;
