import { ADMIN_GENERAL, ADMIN_LAWYER, ADMIN_PRIVACY } from "../../Common/adminType";

/**
 * 관리자 페이지 상단 메뉴바
 *
 * no : index
 * title : 보여지는 제목
 * path : 이동 경로
 * basicPath :
 * auth : 권한, 지정 권한 및 상위 권한만 보여지고 접속 가능. (권한별 내용은 enumAdmin 참조)
 * */
export const data = [
  {
    no: 1,
    title: "사용자관리",
    path: "/rf_zib_admin/user/free/1",
    basicPath: "/rf_zib_admin/user",
    auth : ADMIN_PRIVACY.Memtype,
  },
  {
    no: 2,
    title: "주소관리",
    path: "/rf_zib_admin/address/1",
    basicPath: "/rf_zib_admin/address",
    auth: ADMIN_GENERAL.Memtype,
  },
  {
    no: 3,
    title: "게시판 관리",
    path: "/rf_zib_admin/board/consult/1",
    basicPath: "/rf_zib_admin/board",
    auth: ADMIN_LAWYER.Memtype,
  },
  {
    no: 4,
    title: "블로그 관리",
    path: "/rf_zib_admin/blog/board",
    basicPath: "/rf_zib_admin/blog",
    auth: ADMIN_GENERAL.Memtype,
  },
  {
    no: 5,
    title: "고객센터",
    path: "/rf_zib_admin/service/notice",
    basicPath: "/rf_zib_admin/service",
  },
  {
    no: 6,
    title: "LOG",
    path: "/rf_zib_admin/log/login",
    basicPath: "/rf_zib_admin/log",
  },
  {
    no: 7,
    title: "설정",
    path: "/rf_zib_admin/set/main",
    basicPath: "/rf_zib_admin/set",
  },
];
