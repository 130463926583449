import React, { useLayoutEffect } from "react";

function Success_nice() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var nicename = params.get("name");
  var nicephone = params.get("mobileno");
  var type = params.get("type");
  var memid = params.get("memid");
  var token = params.get("token");

  useLayoutEffect(() => {
    window.opener.niceauthFunc(nicename, nicephone, type, memid, token);
    window.close();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mypage_body"></div>
    </>
  );
}

export default Success_nice;
