import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { BrowserRouter, Link } from "react-router-dom";
import { changeDateFormat, searchDataSet, searchCashClear } from "../../Common/lib";
import { useDispatch } from "react-redux";
import {
  boardBlog,
  boardDivisionList,
  boardSearchBlog,
} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";
import defaultProfile from "../../images/event_img.png";

function BlogBoard() {
  const dispatch = useDispatch();

  // 달력 시작일 셋팅
  // eslint-disable-next-line
  const [searchDate, setSearchDate] = useState({
    start: "",
    end: "",
  });

  const [categoryList, setCategoryList] = useState([]);

  useLayoutEffect(() => {

    let body1 = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      gubun: searchData.gubun,
      status: searchData.status,
      keyword: searchData.keyword,
    };

    // 검색input 호출
    let finalData = searchDataSet(body1);
    dispatch(boardSearchBlog(finalData)).then((res) => {
      if (res.payload.blog) {
        const result = res.payload.blog;
        setBoardList(result);
        setBoardListCnt(result.length);
        setSearchData(finalData);
      }
    });

    let body2 = {
      kind: "블로그",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body2)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategoryList(result);
      } else {
        setCategoryList([]);
      }
    });
  }, [dispatch]);

  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  // input값 제어
  const [searchData, setSearchData] = useState({
    startDate: searchDate["start"],
    endDate: searchDate["end"],
    gubun: "전체",
    status: "전체",
    keyword: "",
  });
  const searchHandler = (e) => {
    let el = e.target;
    setSearchData({ ...searchData, [el.name]: el.value });
  };

  // 검색 조건 초기화
  // const handleReset = () => {
  //   setSearchData({
  //     ...searchData,
  //     startDate: searchDate["start"],
  //     endDate: searchDate["end"],
  //     gubun: "전체",
  //     status: "전체",
  //     keyword: "",
  //   });
  //   $("input:radio[id='radio10']").prop("checked", true);
  // };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleImgError = (e) => {
    e.target.src = defaultProfile;
  };
  // 회원 목록
  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
        <td>{item.memname}</td>
        <td>
          <div className="thumbnail">
            {item.file === "" || item.file === null ? null : (
              <img
                style={{ width: "100%", height: "100%" }}
                src={item.file}
                alt="img"
                onError={handleImgError}
              />
            )}
          </div>
        </td>
        <td>{item.gubun}</td>
        <td>
          <Link to={"/rf_zib_admin/blog/board/detail/" + item.idx}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{item.status === 0 ? "비공개" : "공개"}</td>
      </tr>
    );
  });

  // 검색하기
  const handleSearch = () => {
    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      gubun: searchData.gubun,
      status: searchData.status,
      keyword: searchData.keyword,
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(boardSearchBlog(body)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.blog;
        setBoardList(result);
        if (result !== "false") setBoardSearchListCnt(result.length);
        else setBoardSearchListCnt(0);
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container" id="boardConsult">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/blog/board">부동산정보 현황</Link>
                </BrowserRouter>

                <span className="total_column">
                  전체 <span>{boardListCnt}</span>
                </span>
              </h2>
              <BrowserRouter forceRefresh={true}>
                <Link to="/rf_zib_admin/blog/board/add">
                  <div className="user_control_column">
                    <div className="admin_delete_btn btn">글등록</div>
                  </div>
                </Link>
              </BrowserRouter>
            </div>
            <div className="user_search_column">
              <div className="first_column">
                <div className="search_regdate_column">
                  <span className="input_title">등록일</span>
                  <input
                    type="date"
                    value={searchData.startDate}
                    name="startDate"
                    onChange={searchHandler}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    value={searchData.endDate}
                    name="endDate"
                    onChange={searchHandler}
                  />
                </div>
                <div className="search_keyword_column">
                  <span className="input_title">검색</span>
                  <input
                    type="text"
                    placeholder="작성자명, 글제목"
                    name="keyword"
                    value={searchData.keyword}
                    onChange={searchHandler}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
              <div className="second_column top">
                <div className="register_address">
                  <span
                    className="input_title"
                    style={{ display: "inline-block", width: 44 }}
                  >
                    구분
                  </span>

                  <select
                    name="gubun"
                    onChange={searchHandler}
                    value={searchData.gubun}
                    id="searchGubun"
                    
                  >
                    <option value="전체">전체</option>
                    {categoryList.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="bar"></div>
                <div>
                  <span className="input_title">공개여부</span>

                  <input
                    type="radio"
                    id="radio10"
                    name="status"
                    value="전체"
                    onChange={searchHandler}
                    checked={searchData.status === "전체" ? true : false}
                  />
                  <label htmlFor="radio10">전체</label>
                  <input
                    type="radio"
                    id="radio11"
                    name="status"
                    value="공개"
                    onChange={searchHandler}
                    checked={searchData.status === "공개" ? true : false}
                  />
                  <label htmlFor="radio11">공개</label>
                  <input
                    type="radio"
                    id="radio12"
                    name="status"
                    value="비공개"
                    onChange={searchHandler}
                    checked={searchData.status === "비공개" ? true : false}
                  />
                  <label htmlFor="radio12">비공개</label>
                </div>
              </div>
              <div className="admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    searchCashClear();
                    window.location.reload();
                  }}
                >
                  기본설정
                </div>
                <div className="admin_blue_btn btn" onClick={handleSearch}>
                  검색
                </div>
              </div>
            </div>

            <div className="user_list_column">
              <div className="title_column">
                <h3>목록</h3>
                {boardSearchListCnt ? (
                  <div className="search_result">
                    검색결과 <span> {boardSearchListCnt}</span>
                  </div>
                ) : (
                  <div className="search_result"></div>
                )}
              </div>

              <table className="property_board_list">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>등록일시</th>
                    <th>작성자</th>
                    <th>대표이미지</th>
                    <th>구분</th>
                    <th>제목</th>
                    <th>공개여부</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={7}>등록된 글이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <PaginationNum items={boardList} onChangePage={onChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogBoard;
