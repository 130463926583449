import React from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";

function SetPush() {
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container" id="setPush">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">PUSH 설정</h2>
              </div>

              <table className="detail_table board_table">
                <tbody>
                  <tr>
                    <th className="th_taskname">Task 명</th>
                    <th>발송내용</th>
                    <th className="th_checkbox">
                      APP <br /> PUSH
                    </th>
                    <th className="th_checkbox">
                      알림톡 <br /> (카카오톡)
                    </th>
                    <th className="th_checkbox">E-mail</th>
                    <th className="th_checkbox">SMS</th>
                  </tr>
                  <tr>
                    <td>
                      회원가입 완료 <br />
                      <span className="info">
                        (회원이 등록한 관리주소에 고유번호가
                        <br /> 기입완료되었을때)
                      </span>
                    </td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      >
                        [회원가입완료] 등록하신 주소 확인이 완료되었습니다.
                      </textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>등기변동</td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      ></textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>전세만기</td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      ></textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>경매배당기일 배정</td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      ></textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>법률 질의 답변 등록</td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      ></textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>부동산 정보 등록</td>
                    <td>
                      <textarea
                        className="push_send_message"
                        placeholder="알림 내용을 입력하세요."
                      ></textarea>
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="detail_user admin_btn_column">
                <div className="admin_blue_btn btn">저장</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetPush;
