import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { changeDateFormat, searchDataSet, searchCashClear } from "../../Common/lib";
import { useDispatch } from "react-redux";
import {
  userAdminList,
  userAdminSearch,
} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";
import { ADMIN_SUPER, ADMIN_GENERAL, ADMIN_PRIVACY } from "../../Common/adminType";

function UserAdmin() {
  const dispatch = useDispatch();
  let history = useHistory();
  const match = useRouteMatch();
  let pagen = match.params.pagen;

  // 달력 시작일 셋팅
  const searchDate = {
    start: "",
    end: "",
  };

  useLayoutEffect(() => {

    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      memtype: searchData.memtype,
      memstatus: searchData.memstatus,
      keyword: searchData.keyword,
    };

    // 검색input 호출
    let finalData = searchDataSet(body);

    dispatch(userAdminSearch(finalData)).then((res) => {
      if (res.payload.user) {
        const result = res.payload.user;
        setBoardList(result);
        setBoardListCnt(result.length);
        setSearchData(finalData);
      }
    });
  }, [dispatch]);
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/user/admin/${pageNum}`);
  };
  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  // input값 제어
  const [searchData, setSearchData] = useState({
    startDate: searchDate["start"],
    endDate: searchDate["end"],
    memstatus: "전체",
    memtype: "전체",
    keyword: "",
  });

  const searchHandler = (e) => {
    let el = e.target;
    setSearchData({ ...searchData, [el.name]: el.value });
  };

  // 검색 조건 초기화
  // const handleReset = () => {
  //   setSearchData({
  //     ...searchData,
  //     startDate: searchDate["start"],
  //     endDate: searchDate["end"],
  //     memtype: "전체",
  //     keyword: "",
  //   });
  //   $("input:radio[id='radio4']").prop("checked", true);
  // };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 회원 목록
  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        {/* <td>{item.idx}</td> */}
        <td>{item.rownum}</td>
        <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
        <td>
          {
            (() => {

              if (item.memtype === ADMIN_SUPER.Memtype   || item.memtype === ADMIN_SUPER.Alias) return ADMIN_SUPER.Alias;
              if (item.memtype === ADMIN_GENERAL.Memtype || item.memtype === ADMIN_GENERAL.Alias) return ADMIN_GENERAL.Alias;
              if (item.memtype === ADMIN_PRIVACY.Memtype || item.memtype === ADMIN_PRIVACY.Alias) return ADMIN_PRIVACY.Alias;

            })()
          }
        </td>
        <td>
          <Link to={`/rf_zib_admin/user/admin/detail/${item.idx}/${pagen}`}>
            <span className="btn">{item.memname}</span>
          </Link>
        </td>
        <td>{item.cellno}</td>
        <td>{item.memid}</td>
        <td>{item.memstatus}</td>
      </tr>
    );
  });

  // 검색하기
  const handleSearch = () => {
    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      memtype: searchData.memtype,
      memstatus: searchData.memstatus,
      keyword: searchData.keyword,
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(userAdminSearch(body)).then((res) => {
      history.replace(`/rf_zib_admin/user/admin/1`);
      if (res.payload.result) {
        const result = res.payload.user;
        setBoardList(result);
        if (
          result === "false"
            ? setBoardSearchListCnt(0)
            : setBoardSearchListCnt(result.length)
        );
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/user/admin/1">관리자 관리</Link>
                </BrowserRouter>

                <span className="total_column">
                  전체 <span>{boardListCnt}</span>
                </span>
              </h2>
              <Link to="/rf_zib_admin/user/admin/add">
                <div className="admin_delete_btn btn left">신규등록</div>
              </Link>
            </div>
            <div className="user_search_column">
              <div className="first_column">
                <div className="search_regdate_column">
                  <span className="input_title">등록일</span>
                  <input
                    type="date"
                    value={searchData.startDate}
                    name="startDate"
                    onChange={searchHandler}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    value={searchData.endDate}
                    name="endDate"
                    onChange={searchHandler}
                  />
                </div>
                <div className="search_keyword_column">
                  <span className="input_title">검색</span>
                  <input
                    type="text"
                    placeholder="관리자명, 이메일, 휴대폰"
                    name="keyword"
                    value={searchData.keyword}
                    onChange={searchHandler}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
              <div className="second_column">
                <div>
                  <span className="input_title">관리자 등급</span>

                  <input
                    type="radio"
                    id="radio4"
                    name="memtype"
                    value="전체"
                    onChange={searchHandler}
                    checked={searchData.memtype === "전체" ? true : false}
                  />
                  <label htmlFor="radio4">전체</label>
                  <input
                    type="radio"
                    id="radio5"
                    name="memtype"
                    value={ADMIN_GENERAL.Alias}
                    onChange={searchHandler}
                    checked={searchData.memtype === ADMIN_GENERAL.Alias ? true : false}
                  />
                  <label htmlFor="radio5">{ADMIN_GENERAL.Alias}</label>
                  <input
                    type="radio"
                    id="radio6"
                    name="memtype"
                    value={ADMIN_SUPER.Alias}
                    onChange={searchHandler}
                    checked={searchData.memtype === ADMIN_SUPER.Alias ? true : false}
                  />
                  <label htmlFor="radio6">{ADMIN_SUPER.Alias}</label>
                  <input
                    type="radio"
                    id="radio7"
                    name="memtype"
                    value={ADMIN_PRIVACY.Alias}
                    onChange={searchHandler}
                    checked={searchData.memtype === ADMIN_PRIVACY.Alias ? true : false}
                  />
                  <label htmlFor="radio7">{ADMIN_PRIVACY.Alias}</label>
                </div>
                <div className="bar"></div>
                <div>
                  <span className="input_title">회원상태</span>
                  <input
                    type="radio"
                    id="radio8"
                    name="memstatus"
                    value="전체"
                    onClick={searchHandler}
                    checked={searchData.memstatus === "전체" ? true : false}
                  />
                  <label htmlFor="radio8">전체</label>
                  <input
                    type="radio"
                    id="radio9"
                    name="memstatus"
                    value="정상"
                    onClick={searchHandler}
                    checked={searchData.memstatus === "정상" ? true : false}
                  />
                  <label htmlFor="radio9">정상</label>

                  <input
                    type="radio"
                    id="radio10"
                    name="memstatus"
                    value="정지"
                    onClick={searchHandler}
                    checked={searchData.memstatus === "정지" ? true : false}
                  />
                  <label htmlFor="radio10">정지</label>
                </div>
              </div>
              <div className="admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    searchCashClear();
                    window.location.href = `/rf_zib_admin/user/admin/1`;
                  }}
                >
                  기본설정
                </div>
                <div className="admin_blue_btn btn" onClick={handleSearch}>
                  검색
                </div>
              </div>
            </div>
            <div className="user_list_column">
              <div className="title_column">
                <h3>목록</h3>
                {boardSearchListCnt ? (
                  <div className="search_result">
                    검색결과 <span> {boardSearchListCnt}</span>
                  </div>
                ) : (
                  <div className="search_result"></div>
                )}
              </div>

              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>등록일시</th>
                    <th>관리자 등급</th>
                    <th>관리자명</th>
                    <th>휴대폰</th>
                    <th>아이디(이메일)</th>
                    <th>회원상태</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={7}>등록된 관리자가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <PaginationNum
                items={boardList}
                onChangePage={onChangePage}
                getPageNum={getPageNum}
                initialPage={pagen++}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAdmin;
