import React, { useLayoutEffect, useRef, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminNoticeDelete,
  adminNoticeDeleteImg,
  adminNoticeDetail,
  adminNoticeModify,
  boardDivisionList,
} from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";
import defaultProfile from "../../images/event_img.png";

function ServiceNoticeDetail({ match, history }) {
  const dispatch = useDispatch();
  const idx = match.params.idx;
  const [categories, setCategories] = useState([]); // 카테고리 리스트
  // 뒤로가기
  const goBack = () => {
    history.goBack();
  };

  const handleImgError = (e) => {
    e.target.src = defaultProfile;
  };

  // input값 제어
  const [SubmitData, setSubmitData] = useState({});
  const [isVisible, setIsVisible] = useState();
  const [isFile, setIsFile] = useState();
  const [fileUrl, setFileUrl] = useState(""); // 화면에 이미지 표시
  const [FileInfo, setFileInfo] = useState(null);
  const [FileDelete, setFileDelete] = useState(0);

  const visibleHandler = (e) => {
    if (e.target.value === "노출") {
      setIsVisible(1);
    } else {
      setIsVisible(0);
    }
  };
  useLayoutEffect(() => {
    // dispatch 가져오기
    let body = {
      idx: idx,
    };

    dispatch(adminNoticeDetail(body)).then((res) => {
      if (res.payload.result === "success") {
        const result = res.payload.notice;
        setSubmitData(result);
        setIsVisible(result.visible);
        setFileUrl(result.file);
        setIsFile(result.filename);
      }
    });

    let data = {
      kind: "공지사항",
    };
    dispatch(boardDivisionList(data)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch, idx]);

  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  // 파일 정보 저장
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
    setFileDelete(0);
  };

  // 파일 삭제
  const deleteFileUrl = () => {
    URL.revokeObjectURL(fileUrl);
    setFileUrl("");
    setFileDelete(1);
  };

  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value });
  };

  // 전송시
  const submitHandler = () => {
    if (SubmitData.title === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (SubmitData.content === "") {
      alert("내용을 입력하세요.");
      return;
    }

    // 이미지 삭제 클릭시에 등록된 사진 삭제처리하기
    if (fileUrl === "" && FileDelete) {
      let body = { file: isFile, idx: idx };
      dispatch(adminNoticeDeleteImg(body)).then((res) => {
        if (res.payload.result) setFileInfo(null);
      });
    }
    
    let admin_auth = localStorage.getItem("admin_auth");
    // gubun, title, content,  visible, noticeImg (사진)
    let bodyNameList = [
      "idx",
      "gubun",
      "title",
      "content",
      "visible",
      "noticeImg",
      "admin_auth",
    ];
    let bodyValueList = [
      idx,
      SubmitData.gubun,
      SubmitData.title,
      SubmitData.content,
      isVisible,
      FileInfo,
      admin_auth,
    ];
    let formData = new FormData();
    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      return formData.append(val, bodyValueList[i]);
    });
    dispatch(adminNoticeModify(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/service/notice");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  const deleteBoard = () => {
    let body = {
      idx: idx,
      file: isFile,
    };

    dispatch(adminNoticeDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        goBack();
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div className="detail_user_header">
            <h2 className="main_container_name">공지사항 상세</h2>
            <div className="admin_delete_btn btn" onClick={deleteBoard}>
              삭제
            </div>
          </div>

          <table className="detail_table board_table single_column">
            <tbody>
              <tr>
                <th>등록일</th>
                <td>
                  {changeDateFormat(SubmitData.registerdate, 5, ".", ":")}
                </td>
              </tr>
              <tr>
                <th>구분</th>
                <td>
                  <select
                    onChange={inputHandler}
                    name="gubun"
                    value={SubmitData.gubun}
                  >
                    {categories.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    type="text"
                    placeholder="제목을 입력하세요."
                    onChange={inputHandler}
                    name="title"
                    className="title"
                    value={SubmitData.title}
                  />
                </td>
              </tr>
              <tr>
                <th>이미지</th>
                <td>
                  <div
                    className="img_column"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div className="sample_img">
                      {fileUrl && fileUrl !== "/api" ? (
                        <img
                          alt="sample"
                          src={fileUrl}
                          style={{ margin: "auto" }}
                          onError={handleImgError}
                        />
                      ) : null}

                      <input
                        name="eventImg"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        ref={(elem) => (fileInputs.current = elem)}
                        onChange={setFile}
                      />
                    </div>

                    <div
                      className="admin_black_btn_column"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="admin_black_btn left btn"
                        onClick={() => {
                          openFile();
                        }}
                      >
                        업로드
                      </div>
                      <div
                        className="admin_black_btn btn left"
                        onClick={() => deleteFileUrl()}
                      >
                        삭제
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="board_content">
                <th>내용</th>
                <td colSpan="3">
                  <textarea
                    placeholder="내용을 입력하세요."
                    onChange={inputHandler}
                    name="content"
                    value={SubmitData.content || ""}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>노출</th>
                <td>
                  <div className="free_user_radio">
                    <input
                      type="radio"
                      checked={isVisible === 1 && true}
                      id="radio1"
                      onChange={visibleHandler}
                      value="노출"
                      name="visible"
                    />
                    <label htmlFor="radio1">노출</label>

                    <input
                      type="radio"
                      id="radio2"
                      checked={isVisible === 0 && true}
                      onChange={visibleHandler}
                      value="비노출"
                      name="visible"
                    />
                    <label htmlFor="radio2">비노출</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="detail_user admin_btn_column" style={{ margin: 0 }}>
            <div className="admin_white_btn btn left" onClick={goBack}>
              목록
            </div>
            <div className="admin_blue_btn btn" onClick={submitHandler}>
              저장
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceNoticeDetail;
