import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminFaqDelete,
  adminFaqDetail,
  adminFaqModify,
  boardDivisionList,
} from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";

function ServiceFaqListDetail({ match, history }) {
  const dispatch = useDispatch();
  const idx = match.params.idx;
  const [categories, setCategories] = useState([]); // 카테고리 리스트
  // 뒤로가기
  const goBack = () => {
    history.goBack();
  };

  // input값 제어
  const [SubmitData, setSubmitData] = useState({});
  const [isVisible, setIsVisible] = useState();
  const visibleHandler = (e) => {
    if (e.target.value === "노출") {
      setIsVisible(1);
    } else {
      setIsVisible(0);
    }
  };
  useLayoutEffect(() => {
    // dispatch 가져오기
    let body = {
      idx: idx,
    };

    dispatch(adminFaqDetail(body)).then((res) => {
      if (res.payload.result === "success") {
        const result = res.payload.question;
        setSubmitData(result);
        setIsVisible(result.visible);
      }
    });

    let data = {
      kind: "자주하는질문",
    };
    dispatch(boardDivisionList(data)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch, idx]);

  const inputHandler = (e) => {
    let el = e.target;

    setSubmitData({ ...SubmitData, [el.name]: el.value });
  };

  // 전송시
  const submitHandler = () => {
    if (SubmitData.title === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (SubmitData.content === "") {
      alert("내용을 입력하세요.");
      return;
    }

    let admin_auth = localStorage.getItem("admin_auth");

    let body = {
      idx: idx,
      gubun: SubmitData.gubun,
      title: SubmitData.title,
      content: SubmitData.content,
      visible: isVisible,
      admin_auth: admin_auth,
    };

    // gubun, title, content, visible;
    dispatch(adminFaqModify(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/service/faq");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  const deleteBoard = () => {
    let body = {
      idx: idx,
    };

    dispatch(adminFaqDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        goBack();
      }
    });
  };

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={3} />
        <div className="main_container">
          <div className="detail_user_header">
            <h2 className="main_container_name">자주하는질문 상세</h2>
            <div className="admin_delete_btn btn" onClick={deleteBoard}>
              삭제
            </div>
          </div>

          <table className="detail_table board_table single_column">
            <tbody>
              <tr>
                <th>등록일</th>
                <td>
                  {changeDateFormat(SubmitData.registerdate, 5, ".", ":")}
                </td>
              </tr>
              <tr>
                <th>구분</th>
                <td>
                  <select
                    onChange={inputHandler}
                    name="gubun"
                    value={SubmitData.gubun}
                  >
                    {categories.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    type="text"
                    placeholder="제목을 입력하세요."
                    onChange={inputHandler}
                    name="title"
                    className="title"
                    value={SubmitData.title}
                  />
                </td>
              </tr>

              <tr className="board_content">
                <th>내용</th>
                <td colSpan="3">
                  <textarea
                    placeholder="내용을 입력하세요."
                    onChange={inputHandler}
                    name="content"
                    value={SubmitData.content || ""}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>노출</th>
                <td>
                  <div className="free_user_radio">
                    <input
                      type="radio"
                      checked={isVisible === 1 && true}
                      id="radio1"
                      onChange={visibleHandler}
                      value="노출"
                      name="visible"
                    />
                    <label htmlFor="radio1">노출</label>

                    <input
                      type="radio"
                      id="radio2"
                      checked={isVisible === 0 && true}
                      onChange={visibleHandler}
                      value="비노출"
                      name="visible"
                    />
                    <label htmlFor="radio2">비노출</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="detail_user admin_btn_column" style={{ margin: 0 }}>
            <div className="admin_white_btn btn left" onClick={goBack}>
              목록
            </div>
            <div className="admin_blue_btn btn" onClick={submitHandler}>
              저장
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceFaqListDetail;
