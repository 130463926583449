export const data = [
  {
    no: 1,
    title: "공지사항",
    path: "/rf_zib_admin/service/notice",
  },
  {
    no: 2,
    title: "이벤트",
    path: "/rf_zib_admin/service/event",
  },
  {
    no: 3,
    title: "문의하기",
    path: "/rf_zib_admin/service/inquiry/1",
  },
  {
    no: 4,
    title: "자주하는 질문",
    path: "/rf_zib_admin/service/faq",
  },
  {
    no: 5,
    title: "법률상담 FAQ",
    path: "/rf_zib_admin/service/law",
  },
];
