import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { changeDateFormat, searchDataSet, searchCashClear  } from "../../Common/lib";
import { useDispatch } from "react-redux";
import {
  userLawyerList,
  userLawyerSearch,
} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";

function UserLawyer() {
  const dispatch = useDispatch();
  const history = useHistory();
  let match = useRouteMatch();
  let pagen = match.params.pagen;
  // 달력 시작일 셋팅
  const searchDate = {
    start: "",
    end: "",
  };
  useLayoutEffect(() => {

    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      memstatus: searchData.memstatus,
      keyword: searchData.keyword,
    };

    // 검색input 호출
    let finalData = searchDataSet(body);

    dispatch(userLawyerSearch(finalData)).then((res) => {
      if (res.payload.user) {
        const result = res.payload.user;
        setBoardList(result);
        setBoardListCnt(result.length);

        setSearchData(finalData);
      }
    });
  }, [dispatch]);

  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/user/lawyer/${pageNum}`);
  };

  // input값 제어
  const [searchData, setSearchData] = useState({
    startDate: searchDate["start"],
    endDate: searchDate["end"],
    memstatus: "전체",
    keyword: "",
  });

  const searchHandler = (e) => {
    let el = e.target;
    setSearchData({ ...searchData, [el.name]: el.value });
  };

  // 검색 조건 초기화
  // const handleReset = () => {
  //   setSearchData({
  //     ...searchData,
  //     startDate: searchDate["start"],
  //     endDate: searchDate["end"],
  //     memstatus: "전체",
  //     keyword: "",
  //   });
  //   $("input:radio[id='radio4']").prop("checked", true);
  // };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 회원 목록
  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
        <td>
          <Link to={`/rf_zib_admin/user/lawyer/detail/${item.idx}/${pagen}`}>
            <span className="btn">{item.memname}</span>
          </Link>
        </td>
        <td>{item.cellno}</td>
        <td>{item.memid}</td>
        <td>{item.company}</td>
        <td>{item.COUNT}</td>
        <td className={item.memstatus === "정지" ? "stop" : ""}>
          {item.memstatus}
        </td>
      </tr>
    );
  });

  // 검색하기
  const handleSearch = () => {

    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      memstatus: searchData.memstatus,
      keyword: searchData.keyword,
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(userLawyerSearch(body)).then((res) => {
      if (res.payload.result) {
        history.replace(`/rf_zib_admin/user/lawyer/1`);
        const result = res.payload.user;
        setBoardList(result);
        if (
          result === "false"
            ? setBoardSearchListCnt(0)
            : setBoardSearchListCnt(result.length)
        );
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={1} />
        <div className="main_container">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/user/lawyer/1">변호사 관리</Link>
                </BrowserRouter>

                <span className="total_column">
                  전체 <span>{boardListCnt}</span>
                </span>
              </h2>
              <Link to="/rf_zib_admin/user/lawyer/add">
                <div className="admin_delete_btn btn left">신규등록</div>
              </Link>
            </div>
            <div className="user_search_column">
              <div className="first_column">
                <div className="search_regdate_column">
                  <span className="input_title">등록일</span>
                  <input
                    type="date"
                    value={searchData.startDate}
                    name="startDate"
                    onChange={searchHandler}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    value={searchData.endDate}
                    name="endDate"
                    onChange={searchHandler}
                  />
                </div>
                <div className="search_keyword_column">
                  <span className="input_title">검색</span>
                  <input
                    type="text"
                    placeholder="이름, 이메일, 휴대폰, 소속"
                    name="keyword"
                    value={searchData.keyword}
                    onChange={searchHandler}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
              <div className="second_column">
                <div>
                  <span className="input_title">회원상태</span>

                  <input
                    type="radio"
                    id="radio3"
                    name="memstatus"
                    value="전체"
                    onChange={searchHandler}
                    checked={searchData.memstatus === "전체" ? true : false}
                  />
                  <label htmlFor="radio3">전체</label>
                  <input
                    type="radio"
                    id="radio4"
                    name="memstatus"
                    value="정상"
                    onChange={searchHandler}
                    checked={searchData.memstatus === "정상" ? true : false}
                  />
                  <label htmlFor="radio4">정상</label>
                  <input
                    type="radio"
                    id="radio5"
                    name="memstatus"
                    value="정지"
                    onChange={searchHandler}
                    checked={searchData.memstatus === "정지" ? true : false}
                  />
                  <label htmlFor="radio5">정지</label>
                </div>
              </div>
              <div className="admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    searchCashClear();
                    window.location.href = `/rf_zib_admin/user/lawyer/1`;
                  }}
                >
                  기본설정
                </div>
                <div className="admin_blue_btn btn" onClick={handleSearch}>
                  검색
                </div>
              </div>
            </div>
            <div className="user_list_column">
              <div className="title_column">
                <h3>목록</h3>
                {boardSearchListCnt ? (
                  <div className="search_result">
                    검색결과 <span> {boardSearchListCnt}</span>
                  </div>
                ) : (
                  <div className="search_result"></div>
                )}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>등록일시</th>
                    <th>이름</th>
                    <th>휴대폰</th>
                    <th>아이디(이메일)</th>
                    <th>소속</th>
                    <th>등록 글(개)</th>
                    <th>회원상태</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={8}>등록된 관리자가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PaginationNum
                items={boardList}
                onChangePage={onChangePage}
                getPageNum={getPageNum}
                initialPage={pagen++}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserLawyer;
