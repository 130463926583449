import axios from "axios";
import React, { useLayoutEffect, useState } from "react";

let url = "/api/set/provision";
export function Agreement1() {
  const [agreementContent, setAgreementContent] = useState("");
  useLayoutEffect(() => {
    axios.get(url).then((res) => {
      if (res.data.result === "success") {
        setAgreementContent(res.data.terms[0].termsdescription);
      } else {
        alert("정보를 가져오는데 실패하였습니다.");
      }
    });
  }, []);

  return (
    <>
      <textarea
        className="agreement_desc"
        value={agreementContent || ""}
        readOnly
        style={{ cursor: "default" }}
      ></textarea>
    </>
  );
}

export function Agreement2() {
  const [agreementContent, setAgreementContent] = useState("");
  useLayoutEffect(() => {
    axios.get(url).then((res) => {
      if (res.data.result === "success") {
        setAgreementContent(res.data.terms[1].termsdescription);
      } else {
        alert("정보를 가져오는데 실패하였습니다.");
      }
    });
  }, []);

  return (
    <>
      <textarea
        className="agreement_desc"
        value={agreementContent || ""}
        readOnly
        style={{ cursor: "default" }}
      ></textarea>
    </>
  );
}

export function Agreement3() {
  const [agreementContent, setAgreementContent] = useState("");
  useLayoutEffect(() => {
    axios.get(url).then((res) => {
      if (res.data.result === "success") {
        setAgreementContent(res.data.terms[3].termsdescription);
      } else {
        alert("정보를 가져오는데 실패하였습니다.");
      }
    });
  }, []);

  return (
    <>
      <textarea
        className="agreement_desc"
        value={agreementContent || ""}
        readOnly
        style={{ cursor: "default" }}
      ></textarea>
    </>
  );
}
