import React, { useLayoutEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { setCookie, saveLimitTime } from "../../Common/lib";

function JoinAuth({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const user = location.user;

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [authnum, setAuthnum] = useState("");
  const [profilePath, setProfilePath] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
  }, [dispatch]);

  // 인증코드 체크
  const checkAuthnum = () => {
    let url = "/api/call/mail/key";
    let data = {
      authnum: authnum,
      memid: user.memid,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        join();
      } else if (res.data.result === "wrong") {
        alert("인증코드가 일치하지 않습니다.");
      } else {
        alert("에러가 발생하였습니다.");
      }
    }, []);
  };

  // 회원 가입
  const join = () => {
    let url = "/api/join";
    let data = user;

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        setCookie("x_auth", res.data.idx, saveLimitTime());
        // localStorage.setItem("x_auth", res.data.idx);
        history.push("/join/add");
      } else {
        alert("가입에 실패하였습니다.");
      }
    }, []);
  };

  let authnumHandler = (e) => {
    const regex = /^[0-9\b]{0,6}$/;
    if (regex.test(e.target.value)) {
      setAuthnum(e.target.value);
    }
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <div className="ie_container">
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
          <div className="main center_main" id="joinAuth">
            <h1 className="main_title">이메일 인증</h1>

            <div className="title_info">
              메일로 발송된 인증코드 6자리를 입력하세요.
            </div>

            <input
              type-="text"
              className="email_input"
              value={authnum}
              onChange={authnumHandler}
            />
            <span className="email_info">
              인증코드가 포함된 메일을 받지 못하셨나요? <br />
              스팸메일함에도 확인 바랍니다.
            </span>
            <button
              className="btn blue_btn"
              onClick={() => {
                checkAuthnum();
              }}
            >
              가입완료
            </button>
          </div>
          {isMobile ? <MobileBottomNav /> : <Footer />}
        </div>
      </div>
    </>
  );
}

export default JoinAuth;
