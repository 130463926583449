// IE11의 경우
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import { isAndroid, isIOS } from "react-device-detect";

// redux
import { applyMiddleware, createStore } from "redux";
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import Reducer from "./_reducers";
import { Provider } from "react-redux";
axios.defaults.withCredentials = true;

var baseURL;

if (
  window.location.hostname === "www.zibfine.com" ||
  window.location.hostname === "zibfine.com"
) {
  baseURL = "https://api.zibfine.com/";
} else if (window.location.hostname === "dev.zibfine.com") {
  baseURL = "https://api-dev.zibfine.com/";
} else if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:3001/";
} // 데모용도 - 운영환경에서는 적당한 것으로 바꾸세요

axios.defaults.baseURL = baseURL;

window.Kakao.init("ec9802588de40a6d70e2955d00195ad7");

try {
  if (isAndroid) {
    window.BRIDGE.requestToken();
  } else if (isIOS) {
    window.webkit.messageHandlers.jsHandler.postMessage("Hello from website!");
  }
} catch (error) {
  console.error(error);
}

const createStoreWithMiddleware = applyMiddleware(
  promiseMiddleware,
  ReduxThunk
)(createStore);

ReactDOM.render(
  <Provider
    store={createStoreWithMiddleware(
      Reducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )}
  >
    <App />
  </Provider>,
  document.getElementById("root")
);
