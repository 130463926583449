import React, { useLayoutEffect, useState } from "react";
import PaginationNum from "../../components/PaginationNum";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminNoticeList, boardDivisionList } from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";
import search from "../../images/search.png";

function ServiceNoticeList() {
  const dispatch = useDispatch();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectVisible, setSelectVisible] = useState("전체");
  const [Categorie, setCategorie] = useState("전체"); // 선택한 카테고리
  const [Condition, setCondition] = useState("title+content"); // 선택한 조건
  const [SearchTitle, setSearchTitle] = useState(""); // 검색어

  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "categorie":
        setCategorie(e.target.value);
        return;
      case "condition":
        setCondition(e.target.value);
        return;
      case "searchTitle":
        setSearchTitle(e.target.value);
        return;
      default:
        return;
    }
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let data = {
      kind: "공지사항",
      visible: selectVisible,
      gubun: Categorie,
      condition: Condition,
      search: SearchTitle,
    };

    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(data)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategoryList(result);
      } else {
        setCategoryList([]);
      }
    });

    dispatch(adminNoticeList(data)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.noticeList;
        setBoardList(result);
      }
    });
  }, [dispatch, selectVisible, Categorie]);

  const handleVisible = (e) => {
    setSelectVisible(e.target.value);
  };

  const handlegubun = (e) => {
    setCategorie(e.target.value);
  };


  // 검색처리
  const handleSearch = () => {
    let body = {
      visible: selectVisible,
      gubun: Categorie,
      condition: Condition,
      search: SearchTitle,
    };

    if (SearchTitle.length < 1) {
      body.SearchTitle = "";
    }

    dispatch(adminNoticeList(body)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.noticeList;
        setBoardList(result);
        setPageOfItems(result || []); //얘가 안먹혀서 여기서 설정해주기
      }
    });
  };


  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{item.gubun}</td>
        <td>
          <Link to={`/rf_zib_admin/service/notice/detail/${item.idx}`}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{item.visible === 1 ? "노출중" : "비노출"}</td>
        <td>{changeDateFormat(item.registerdate, 3, ".", "")}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="user_list_column">
        <div className="select_column">
          <div className="select_name">노출여부</div>
          <select onChange={handleVisible}>
            <option value="전체">전체</option>
            <option value="노출중">노출중</option>
            <option value="비노출">비노출</option>
          </select>
          <div className="select_name">구분</div>
          <select
              name="categorie"
              onChange={handlegubun}
              className="left"
            >
              <option value="전체">구분 전체</option>
              {categoryList.map((item, index) => {
                return (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "10%" }}>No.</th>
              <th style={{ width: "10%" }}>구분</th>
              <th>내용</th>
              <th style={{ width: "10%" }}>노출여부</th>
              <th style={{ width: "10%" }}>등록일</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItem.length > 0 ? (
              pageOfItem
            ) : (
              <tr>
                <td colSpan={4}>등록된 글이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationNum items={boardList} onChangePage={onChangePage} />
      </div>
    </>
  );
}

export default ServiceNoticeList;
