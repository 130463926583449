import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminInquiryAddReply,
  adminInquiryDelete,
  adminInquiryDetail,
} from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";

const textStyle = {
  minHeight: 150,
  height: "auto",
};

function ServiceInquiryDetail({ match, history }) {
  const dispatch = useDispatch();
  const idx = match.params.idx;
  const pagen = match.params.pagen;

  const [boardDetail, setboardDetail] = useState({});

  // input값 제어
  const [SubmitData, setSubmitData] = useState({
    replytitle: "",
    replytext: "",
  });

  // 저장하기
  const submitHandler = () => {
    if (SubmitData.replytitle === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (SubmitData.replytext === "") {
      alert("내용을 입력하세요.");
      return;
    }

    let admin_auth = localStorage.getItem("admin_auth");

    let body = {
      seq: idx,
      gubun: boardDetail.gubun,
      replytitle: SubmitData.replytitle,
      replytext: SubmitData.replytext,
      memid: boardDetail.memid, //앱푸시 발송 용
      admin_auth: admin_auth,
    };
    dispatch(adminInquiryAddReply(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/service/inquiry/1");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value });
  };

  const [replyDate, setReplyDate] = useState();
  useLayoutEffect(() => {
    let body = {
      idx: idx,
    };
    dispatch(adminInquiryDetail(body)).then((res) => {
      if (res.payload.result) {
        const boardResult = res.payload.inquiry;
        const replyResult = res.payload.inquiryReply;
        setboardDetail(boardResult);
        if (replyResult) {
          setSubmitData({
            replytitle: replyResult.replytitle,
            replytext: replyResult.replytext,
          });
          setReplyDate(changeDateFormat(replyResult.registerdate, 5, ".", ":"));
        }
      }
    });
  }, [dispatch, idx]);

  const deleteBoard = () => {
    if (!SubmitData.replytitle || !SubmitData.replytext) {
      alert("등록되어 있는 답변이 없습니다.");
      return;
    }
    let body = {
      seq: idx,
    };
    dispatch(adminInquiryDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        history.push("/rf_zib_admin/service/inquiry/1");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container">
          <div className="detail_user_header">
            <h2 className="main_container_name">문의하기 상세</h2>
            <div className="admin_delete_btn btn" onClick={deleteBoard}>
              답변 삭제
            </div>
          </div>

          <table className="detail_table board_table single_column">
            <tbody>
              <tr>
                <th>등록일</th>
                <td>
                  {changeDateFormat(boardDetail.registerdate, 5, ".", ":")}
                </td>
              </tr>
              <tr>
                <th>작성자</th>
                <td>{boardDetail.memname}</td>
              </tr>
              <tr>
                <th>구분</th>
                <td>
                  <select disabled>
                    <option value={boardDetail.gubun}>
                      {boardDetail.gubun}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    readOnly
                    type="text"
                    className="title"
                    value={boardDetail.title}
                  />
                </td>
              </tr>
              <tr className="board_content">
                <th style={textStyle}>내용</th>
                <td colSpan="3">
                  <textarea
                    style={textStyle}
                    readOnly
                    value={boardDetail.text || ""}
                  ></textarea>
                </td>
              </tr>
              {boardDetail.phinfo && (
                <tr className="board_content">
                  <th style={textStyle}>기기정보</th>
                  <td colSpan="3">
                    <textarea
                      style={textStyle}
                      readOnly
                      value={boardDetail.phinfo || ""}
                    ></textarea>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <table className="detail_table board_table single_column reply_column">
            <tbody>
              <tr>
                <th>답변일</th>
                <td>
                  {replyDate
                    ? replyDate
                    : changeDateFormat(new Date().toString(), 5, ".", ":")}
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    type="text"
                    name="replytitle"
                    className="title"
                    placeholder="제목을 입력하세요."
                    value={SubmitData.replytitle}
                    onChange={inputHandler}
                  />
                </td>
              </tr>

              <tr className="board_content">
                <th>답변내용</th>
                <td colSpan="3">
                  <textarea
                    placeholder="내용을 입력하세요."
                    style={{ marginBottom: 15, marginLeft: 0 }}
                    name="replytext"
                    className="title"
                    value={SubmitData.replytext || ""}
                    onChange={inputHandler}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="detail_user admin_btn_column" style={{ margin: 0 }}>
            <div
              className="admin_white_btn btn left"
              onClick={() => history.push(`/rf_zib_admin/service/inquiry/${pagen}`)}
            >
              목록
            </div>
            <div className="admin_blue_btn btn" onClick={submitHandler}>
              저장
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceInquiryDetail;
