import React, { useLayoutEffect, useState } from "react";
import PaginationNum from "../../components/PaginationNum";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLawList } from "../../_actions/admin_board_action";

function ServiceLawList() {
  const dispatch = useDispatch();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [selectVisible, setSelectVisible] = useState(1);

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let data = {
      visible: selectVisible,
    };
    dispatch(adminLawList(data)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.questionList;
        setBoardList(result);
      }
    });
  }, [dispatch, selectVisible]);

  const handleVisible = (e) => {
    setSelectVisible(e.target.value);
  };

  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        {/* <td>{item.idx}</td> */}
        <td>{item.rownum}</td>
        <td>{item.gubun}</td>
        <td>
          <Link to={`/rf_zib_admin/service/law/detail/${item.idx}`}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{item.visible === 1 ? "노출중" : "비노출"}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="user_list_column">
        <div className="select_column">
          <div className="select_name">노출여부</div>
          <select onChange={handleVisible}>
            <option value="전체">전체</option>
            <option value="노출중">노출중</option>
            <option value="비노출">비노출</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "10%" }}>No.</th>
              <th style={{ width: "10%" }}>구분</th>
              <th>내용</th>
              <th style={{ width: "10%" }}>노출여부</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItem.length > 0 ? (
              pageOfItem
            ) : (
              <tr>
                <td colSpan={4}>등록된 글이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationNum items={boardList} onChangePage={onChangePage} />
      </div>
    </>
  );
}

export default ServiceLawList;
