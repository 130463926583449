import React, {useLayoutEffect, useState} from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import {BrowserRouter, Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {mailHistoryDetail} from "../../_actions/admin_board_action";

import { Viewer } from '@toast-ui/react-editor';

function SendMailHistoryDetail({match, history}) {
    const dispatch = useDispatch();
    const idx = match.params.idx;


    //뒤로가기
    const goBack = () => {
        history.goBack();
    };


    //발송실패 유저리스트 팝업
    // const getFailUserList = () => {
    //     let url = `/admin/set/mail/popup?idx=`+idx;
    //     let popupName = "userListPopup";
    //     let options =
    //         "width=930, height=900, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=n";
    //     window.open(url,popupName,options);
    // };

    // const [group, setGroup] = useState("");
    // const [noticeName, setNoticeName] = useState("");
    // const [retransmission, setRetransmission] = useState("");
    // const [title, setTitle] = useState("");
    // const [trandt, setTrandt] = useState(""); // 발송일자
    // const [content, setContent] = useState("");


    const [submitData, setSubmitData] = useState({}); //기존 값 담아온것
    const [showChild, setShowChild] = useState(false); //toast ui 렌더링

    //기존 값 불러오기
    useLayoutEffect(() => {
       let body = {
           idx: idx,
       };

       dispatch(mailHistoryDetail(body)).then((res) => {
          if(res.payload.history) {
              const result = res.payload.history[0];
              setSubmitData(result);

              //렌더링 조건걸기 (true 되어야기 viewer 렌더링됨)
              setShowChild(true);

          }
       });

    },[dispatch, idx]);




    return (
        <>
            <Header />
            <div className="admin_container">
                <SideMenu tabActive={4} />
                <div className="main_container">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <div className="detail_user_container">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                발송내역 상세보기
                            </h2>
                            <table className="detail_table board_table">
                                <tbody>
                                <tr>
                                    <th rowSpan={3}>수신그룹</th>
                                    <td rowSpan={3}>
                                        <input
                                            type="text"
                                            readOnly
                                            defaultValue={submitData.grp}
                                        />
                                        {submitData.retran === "Y" ? (<div>[재전송 건]</div>) : (<div></div>)}
                                    </td>
                                    <th>총</th>
                                    <td>
                                        <input
                                            type="text"
                                            readOnly
                                            defaultValue={submitData.totalcnt}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>성공</th>
                                    <td>
                                        <input
                                            type="text"
                                            readOnly
                                            defaultValue={submitData.successcnt}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>실패</th>
                                    <td>
                                        <div className="day_change_count">
                                            <input
                                                type="text"
                                                readOnly
                                                defaultValue={submitData.failcnt}/>

                                            {submitData.failcnt > 0 ? (
                                                <div
                                                    className="admin_white_btn btn"
                                                >
                                                    <Link
                                                        to ={`/rf_zib_admin/set/mail/popup/${idx}/${submitData.failcnt}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span>유저리스트 보기</span>
                                                    </Link>
                                                </div>
                                            ) : (<div></div>)}

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>발송 일자</th>
                                    <td>
                                        <input
                                            type="text"
                                            readOnly
                                            defaultValue={submitData.trandt}
                                        />
                                    </td>
                                    {submitData.failcnt > 0  && submitData.resendyn === "N" ? (
                                        <td colSpan={2}>
                                            <BrowserRouter forceRefresh={true}>
                                                <Link to={`/rf_zib_admin/set/mail/resend/${idx}`}>
                                                    <div className="admin_white_btn btn">재전송</div>
                                                </Link>
                                            </BrowserRouter>
                                        </td>
                                    ) : (
                                        <td colSpan={2}></td>
                                    )}
                                </tr>
                                <tr>
                                    <th>메일종류</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            name="title"
                                            defaultValue={submitData.nm}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            name="title"
                                            defaultValue={submitData.title}
                                        />
                                    </td>
                                </tr>
                                <tr className="board_content">
                                    <th>내용</th>
                                    <td colSpan={3}>
                                        {showChild && (
                                            <Viewer
                                                height="600px"
                                                initialValue={String(submitData.cont)}
                                            />
                                        )}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div className="admin_blue_btn btn" onClick={() => goBack()}>
                                    뒤로가기
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMailHistoryDetail;
