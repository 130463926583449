import React, { useLayoutEffect, useState, useRef } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import close from "../../images/close.png";
import warningWhite from "../../images/warning_white.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { changeDateFormat, getCookie } from "../../Common/lib";
import { boardDivisionList, getCounselingDetail } from "../../_actions/main_action";
import axios from "axios";
import MobileBottomNav from "../Footer/MobileBottomNav";
import $ from "jquery";

function MyArticleDetail({ match }) {
  const idx = match.params.idx;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  const [showWarning, setshowWarning] = useState(true);
  const [alertWarning, setAlertWarning] = useState(false);
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [categories, setCategories] = useState([]); // 카테고리 리스트

  // 상세페이지 문의 글
  const [detail, setDetail] = useState({});
  // 상세페이지 답변 글
  const [detailReply, setDetailReply] = useState({});
  const [isDetailReply, setIsDetailReply] = useState(false);
  // 초기 제목
  const [originTitle, setOriginTitle] = useState("");
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      kind: "무료법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });

    let data = {
      idx: idx,
    };

    dispatch(getCounselingDetail(data)).then((res) => {
      if (res.payload.result === "success") {
        if (res.payload.consulting !== "false") {
          setDetail(res.payload.consulting);
          setOriginTitle(res.payload.consulting.title);
          if (res.payload.consultingreply) setIsDetailReply(true);
        }
        if (res.payload.consultingreply !== "false") {
          setDetailReply(res.payload.consultingreply);
        }
      } else {
        alert("정보를 가져오지 못했습니다.");
      }
    });
  }, [dispatch, idx]);

  const inputHandler = (e) => {
    let el = e.target.name;
    setDetail({ ...detail, [el]: e.target.value });
  };
  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };
  const handelSubmit = () => {
    if (detail.title === "") {
      alert("제목을 입력해주세요");
      handleFocus(0);
      return;
    } else if (detail.text === "") {
      alert("내용을 입력해주세요");
      handleFocus(1);
      return;
    }

    if(!$("#check_agree").prop("checked")){
      setAlertWarning(true);
      alert("경매관련 내용이 아닌경우, 상담이 불가합니다.");
      return;
    }else{
      setAlertWarning(false);
    }

    if (!$("#agree_caution").prop("checked")) {
      setshowWarning(false);
      return;
    } else {
      if(alertWarning === false){
        setshowWarning(true);
        const x_auth = getCookie("x_auth");
        // let x_auth = window.localStorage.getItem("x_auth");
        let url = "/api/counseling/modify";
        let data = { ...detail, x_auth: x_auth };

        axios.post(url, data).then((res) => {
          if (res.data.result === "success") {
            alert("수정 완료되었습니다.");
            window.location.reload();
          } else {
            alert("수정에 실패하였습니다.");
          }
        });
      }
    }
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div
          className="main center_main mypage_main inquiry_write"
          id="inquiryBoard"
        >
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content board_main">
              <h2 className="isMobile_inquiryBoard_h2">
                {originTitle}
                {isMobile ? (
                  <img
                    src={close}
                    alt="close"
                    className="close btn"
                    onClick={goBack}
                  />
                ) : null}
              </h2>

              <div className="write_column">
                <form>
                  <div className="form_column ">
                    <div className="form_column isMobile_form_column">
                      <div className="column_name">작성일</div>
                      <div className="writer">
                        {changeDateFormat(detail.registerdate, 5, "-", ":")}
                      </div>
                    </div>
                  </div>
                  <div className="form_column">
                    <div className="column_name">
                      구분 <span className="warning">*</span>
                    </div>
                    <select
                      disabled={isDetailReply}
                      name="gubun"
                      value={detail.gubun}
                      onChange={inputHandler}
                    >
                      {categories.map((item, index) => {
                        return <option key={index}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form_column">
                    <div className="column_name">
                      제목 <span className="warning">*</span>
                    </div>

                    <input
                      readOnly={isDetailReply}
                      type="text"
                      name="title"
                      value={detail.title}
                      onChange={inputHandler}
                      ref={(elem) => (inputRefs.current[0] = elem)}
                      className="write_title"
                    />
                  </div>
                  <div className="form_column">
                    <div className="column_name write_content">
                      내용 <span className="warning">*</span>
                    </div>
                    <textarea
                      readOnly={isDetailReply}
                      name="text"
                      value={detail.text}
                      onChange={inputHandler}
                      ref={(elem) => (inputRefs.current[1] = elem)}
                    ></textarea>
                  </div>
                </form>

                {isDetailReply ? (
                  <form className="reply_form">
                    <div className="form_column">
                      <div className="form_column isMobile_form_column">
                        <div className="column_name">작성일</div>
                        <div className="writer">
                          {changeDateFormat(
                            detailReply.registerdate,
                            5,
                            "-",
                            ":"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form_column">
                      <div className="form_column isMobile_form_column">
                        <div className="column_name">작성자</div>
                        <div className="writer">{detailReply.memname}</div>
                      </div>
                    </div>
                    <div className="form_column">
                      <div className="column_name">제목</div>
                      <input
                        type="text"
                        className="write_title"
                        defaultValue={detailReply.replytitle}
                        readOnly
                      />
                    </div>
                    <div className="form_column">
                      <div className="column_name write_content">
                        내용 <span className="warning">*</span>
                      </div>
                      <textarea
                        readOnly
                        defaultValue={detailReply.replycontent}
                      ></textarea>
                    </div>
                  </form>
                ) : (
                  <>
                    {" "}
                    <div className="caution_wrapper">
                      <img src={warningWhite} alt="warning" />
                      <div className="caution_column">
                        <div className="title">법률 무료 상담 시 주의사항</div>
                        <ul>
                          <li>
                            ㆍ집파인에 등록된 부동산 주소가 경매 진행 중인 경우,
                            무료 상담이 가능합니다.
                          </li>
                          <li>
                            ㆍ집파인에 등록된 부동산 주소가 없거나, 경매 진행이
                            없는 경우에는 상담이 불가합니다.
                          </li>
                          <li>
                            ㆍ제목과 상담내용 작성 시 개인정보가 포함되지 않도록
                            유의해 주십시오.
                          </li>
                          <li>
                            ㆍ작성하신 글에 개인정보가 포함되어 발생하는 피해에
                            대해서는 민형사상 책임을 지지 않습니다.
                          </li>
                          <li>
                            ㆍ또한 동일한 사안에 대해서는 재상담을 하지
                            않습니다.
                          </li>
                        </ul>
                        <div className="checkbox_column" style={{"marginBottom":5}}>
                          <div className="checkbox_wrap">
                            <input id="check_agree" type="checkbox" />
                            <label htmlFor="check_agree">경매관련 상담입니다.</label>
                          </div>
                          <div className="warning" style={{ color: "#e30d0d" }}>
                            {alertWarning ? "경매상담인 경우만 체크하세요." : ""}
                          </div>
                        </div>
                        <div className="checkbox_column">
                          <div className="checkbox_wrap">
                            <input id="agree_caution" type="checkbox" />
                            <label htmlFor="agree_caution">주의사항을 확인했습니다.</label>
                          </div>
                          <div className="warning" style={{ color: "#e30d0d" }}>
                            {showWarning ? "" : "주의사항을 확인하시고 체크하세요."}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="btn_column modify">
                  {isDetailReply ? (
                    <div
                      className="blue_btn btn"
                      onClick={goBack}
                      style={{ marginTop: 10 }}
                    >
                      목록으로
                    </div>
                  ) : (
                    <>
                      <div className="blue_btn btn close" onClick={goBack}>
                        취소
                      </div>
                      <div className="blue_btn btn" onClick={handelSubmit}>
                        수정하기
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default MyArticleDetail;
