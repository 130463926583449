import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import check from "../../images/check.png";
import back from "../../images/back.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { changeDateFormat, getCookie } from "../../Common/lib";
import { useDispatch, useSelector } from "react-redux";
import { getAlarmCount } from "../../_actions/user_action";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function MyNotice() {
  const alarmCount = useSelector((store) => store.adminInfo.alarmCount);
  const x_auth = getCookie("x_auth");

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  let history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const [modal, setModal] = useState({
    modal1: false,
  });

  const [alarmContent, setAlarmContent] = useState({
    content: "",
    contentSub: "",
  });

  const dispatch = useDispatch();
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  const [alarmList, setAlarmList] = useState([]);
  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
      if(res.payload.isLogin){
        getAlarm();
        getAlarmNonRead();
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  // 알람 가져오기
  let getAlarm = () => {
    let url = "/api/alarm/member";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };

    axios.post(url, body).then((res) => {
      if (res.data.alarmList) {
        setAlarmList(res.data.alarmList);
      }
    });
  };

  // 읽지 않은 알림 개수 가져오기
  let getAlarmNonRead = () => {
    let url = "/api/alarm/count";
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("alarm", res.data.alarmCount));
    });
  };
  // 모두 확인
  let readAll = () => {
    let url = "/api/alarm/read";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };

    axios.post(url, body).then((res) => {
      if (res.data.result) {
        getAlarm();
        dispatch(getAlarmCount("alarm", 0));
      }
    });
  };

  // 알림 내용 상세보기
  let AlarmDetail = (idx) => {
    let url = "/api/alarm/member/detail";
    // var x_auth = localStorage.getItem("x_auth");
    let data = { idx: idx, x_auth: x_auth };
    axios.post(url, data).then((res) => {
      if (res.data.result && res.data.alarm) {
        let copyAlarmContent = { ...alarmContent };
        copyAlarmContent.content = res.data.alarm.alarmcontent.map((text) => {
          return (
            <div
              style={{
                background: "#ececec",
                padding: 10,
                marginBottom: 10,
                whiteSpace: "pre-line",
              }}
            >
              {text}
            </div>
          );
        });
        copyAlarmContent.contentSub = res.data.addrInfo;

        setAlarmContent(copyAlarmContent);
        if (
          alarmCount.alarmCount !== 0 &&
          res.data.alarm.alarmread === "미확인"
        ) {
          dispatch(getAlarmCount("alarm", alarmCount - 1));
        }
        openModal("modal1");
        getAlarm();
      } else {
        alert("알림 내용을 가져오지 못했습니다. 다시 시도해주세요.");
      }
    });
  };

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const alarmBoard =
    pageOfItems.length > 0 &&
    pageOfItems.map((val, i) => {
      return (
        <tr
          key={"alarm_" + i}
          className={val.alarmread === "확인" ? "confrim_notice" : ""}
        >
          <td>{val.alarmgubun}</td>
          <td>{val.addrnickname}</td>
          <td
            onClick={() => {
              AlarmDetail(val.idx);
            }}
          >
            {val.alarmread === "확인" ? (
              <span style={{ color: "#999" }}>{val.alarmtitle}</span>
            ) : (
              <>{val.alarmtitle}</>
            )}
          </td>
          <td className="regdate">
            {changeDateFormat(val.senddate, 3, "-", "")}
          </td>
          <td className={val.alarmread === "확인" ? "confirm" : "not_confirm"}>
            {val.alarmread}
          </td>
        </tr>
      );
    });
  const alarmMobileBoard =
    alarmList.length > 0 &&
    alarmList.map((val, i) => {
      return (
        <tr
          className="isMobil_tr"
          key={"alarm_mobile_" + i}
          onClick={() => {
            AlarmDetail(val.idx);
          }}
        >
          <td>
            <div>{val.alarmgubun}</div>
            <div className="wrapper">
              {val.alarmread === "확인" ? (
                <div className="title" style={{ color: "#999" }}>
                  {val.alarmtitle}
                </div>
              ) : (
                <div className="title">{val.alarmtitle}</div>
              )}
            </div>
            <div className="wrapper">
              <div className="nickname">{val.addrnickname}</div>
              <div className="bar"></div>
              <div>{changeDateFormat(val.senddate, 3, "-", "")}</div>
              <div className="bar"></div>
              <div
                className={val.alarmread === "확인" ? "confirm" : "not_confirm"}
              >
                {val.alarmread}
              </div>
            </div>
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="myNotice">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content board_main">
              <div className="title_column">
                <h2>
                  <div style={{ paddingRight: 14 }} onClick={goBack}>
                    <img src={back} alt="back" className="isMobile_back" />
                  </div>
                  알림보기 <span className="count">{alarmCount}</span>
                </h2>
                <div
                  className="all_confirm"
                  onClick={() => {
                    readAll();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  모두확인 <img src={check} alt="check" />
                </div>
              </div>

              <div className="table_column">
                <table>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>부동산닉네임</th>
                      <th>알림 제목</th>
                      <th className="th_day">발송일</th>
                      <th>확인여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isMobile ? (
                      <>
                        {alarmMobileBoard ? (
                          alarmMobileBoard
                        ) : (
                          <tr>
                            <td colSpan={5}>알림이 존재하지 않습니다.</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        {alarmBoard ? (
                          alarmBoard
                        ) : (
                          <tr>
                            <td colSpan={5}>알림이 존재하지 않습니다.</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination items={alarmList} onChangePage={onChangePage} />
            </div>
          </div>
          <Modal open={modal["modal1"]} width={400}>
            <div className="modal_inner_ct modal_400 notice_modal">
              <h2>알림</h2>
              <div className="notice_desc">{alarmContent.content}</div>
              <div className="sub_content">
                {/* <p>{alarmContent.contentSub.address}</p>
                <p>{alarmContent.contentSub.addrucode}</p> */}
              </div>
              <div className="save_btn_column">
                <div
                  className="blue_btn btn"
                  onClick={() => closeModal("modal1")}
                >
                  닫기
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default MyNotice;
