import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { changeDateFormat, CheckEmail, CheckPw } from "../../Common/lib";
import {
  adminLawyerImgDelete,
  userLawyerDetail,
  userLawyerModify,
  userLawyerModifyPassword,
  userLawyerModifyPasswordReset,
} from "../../_actions/admin_board_action";
import defaultProfile from "../../images/event_img.png";

function UserLawyerBasic() {
  let match = useRouteMatch();
  let history = useHistory();
  const dispatch = useDispatch();
  const idx = match.params.idx;
  const pagen = match.params.pagen;

  const [fileUrl, setFileUrl] = useState(""); // 화면에 이미지 표시
  const [FileInfo, setFileInfo] = useState(null);
  const [isFile, setIsFile] = useState();
  // input값 제어
  const [SubmitData, setSubmitData] = useState({});
  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value.replace(" ", "") });
  };
  const handleImgError = (e) => {
    e.target.src = defaultProfile;
  };

  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  // 파일 정보 저장
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
  };

  // 파일 삭제
  const deleteFileUrl = () => {
    if (isFile) {
      if (!window.confirm("정말 삭제하시겠습니까?")) return;
      let body = {
        idx: idx,
        file: isFile,
      };

      dispatch(adminLawyerImgDelete(body)).then((res) => {
        if (res.payload.result === "success") {
          setIsFile("");
        }
      });
    }

    setFileInfo(null);
    setFileUrl("");
  };

  // 변호사 정보 불러오기
  useLayoutEffect(() => {
    let body = {
      idx: idx,
    };

    dispatch(userLawyerDetail(body)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.user;
        if (result[0].access === "N") {
          setAccessStatus(false);
        }
        setSubmitData(result[0]);
        setTelNum(result[0].cellno);
        setFileUrl(result[0].profilefile);
        setIsFile(result[0].profilename);
      }
    });
  }, [dispatch, idx]);

  // 전송시
  const submitHandler = () => {
    if (SubmitData.memname === "") {
      alert("이름을 입력해주세요.");
      return;
    } else if (SubmitData.company === "") {
      alert("소속을 입력해주세요.");
      return;
    } else if (telNum === "") {
      alert("핸드폰번호를 입력해주세요.");
      return;
    } else if (!CheckEmail(SubmitData.memid)) {
      // 아이디(이메일) 유효성 검사
      alert("이메일 형식을 확인해주세요.");
      return;
    }

    let bodyNameList = [
      "idx",
      "memstatus",
      "memname",
      "company",
      "cellno",
      "memid",
      "access",
      "profileImg",
    ];

    let bodyValueList = [
      idx,
      SubmitData.memstatus,
      SubmitData.memname,
      SubmitData.company,
      telNum,
      SubmitData.memid,
      accessStatus ? "Y" : "N",
      FileInfo,
    ];
    let formData = new FormData();

    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      return formData.append(val, bodyValueList[i]);
    });

    dispatch(userLawyerModify(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  const [telNum, setTelNum] = useState("");
  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum && telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum && telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);

  const [SubmitDataPw, setSubmitDataPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const pwHandler = (e) => {
    let el = e.target;
    setSubmitDataPw({ ...SubmitDataPw, [el.name]: el.value.replace(" ", "") });
  };

  const changePw = () => {
    if (SubmitDataPw.originPw === "") {
      alert("비밀번호를 입력해주세요");
      return;
    } else if (!CheckPw(SubmitDataPw.originPw)) {
      // 비밀번호 유효성 검사
      alert("비밀번호 생성 규칙에 어긋납니다. 다시 설정 바랍니다.");
      return;
    } else if (SubmitDataPw.originPw !== SubmitDataPw.confirmPw) {
      alert("동일한 비밀번호를 입력하세요.");
      return;
    }
    let body = {
      idx: idx,
      password: SubmitDataPw.originPw,
    };

    dispatch(userLawyerModifyPassword(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("비밀번호가 변경되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const resetPw = () => {
    if (!window.confirm("정말 초기화하시겠습니까?")) return;
    let body = {
      idx: idx,
    };
    dispatch(userLawyerModifyPasswordReset(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("초기화 되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const [accessStatus, setAccessStatus] = useState(true);
  const accessToggle = () => {
    setAccessStatus(!accessStatus);
  };
  return (
    <>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>회원상태</th>
            <td>
              <div className="free_user_radio">
                <input
                  type="radio"
                  checked={SubmitData.memstatus === "정상" && true}
                  id="radio1"
                  name="memstatus"
                  value="정상"
                  onChange={inputHandler}
                />
                <label htmlFor="radio1">정상</label>
                <input
                  type="radio"
                  checked={SubmitData.memstatus === "정지" && true}
                  id="radio2"
                  name="memstatus"
                  value="정지"
                  onChange={inputHandler}
                />
                <label htmlFor="radio2">정지</label>
              </div>
            </td>
            <th rowSpan="6">프로필 사진</th>
            <td rowSpan="6">
              <div className="img_column">
                <div className="sample_img" style={{ maxWidth: 160 }}>
                  {fileUrl ? (
                    <img
                      alt="sample"
                      src={fileUrl}
                      style={{ margin: "auto" }}
                      onError={handleImgError}
                    />
                  ) : null}

                  <input
                    name="eventImg"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    ref={(elem) => (fileInputs.current = elem)}
                    onChange={setFile}
                  />
                </div>

                <div
                  className="admin_black_btn_column"
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    className="admin_black_btn left btn"
                    onClick={() => {
                      openFile();
                    }}
                  >
                    업로드
                  </div>
                  <div
                    className="admin_black_btn btn left"
                    onClick={() => deleteFileUrl()}
                  >
                    삭제
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>등록일</th>
            <td>{changeDateFormat(SubmitData.registerdate, 5, ".", ":")}</td>
          </tr>

          <tr>
            <th>이름</th>
            <td>
              <input
                type="text"
                name="memname"
                value={SubmitData.memname || ""}
                onChange={inputHandler}
              />
            </td>
          </tr>
          <tr>
            <th>소속</th>
            <td className="nickName">
              <div className="nickName_column">
                <input
                  type="text"
                  name="company"
                  value={SubmitData.company || ""}
                  onChange={inputHandler}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>핸드폰 번호</th>
            <td>
              <input
                type="text"
                placeholder="휴대 전화번호를 입력하세요."
                name="cellno"
                value={telNum || ""}
                onChange={handelTelNum}
              />
            </td>
          </tr>

          <tr>
            <th>이메일(ID)</th>
            <td>
              <input
                type="text"
                placeholder="이메일(ID)를 입력하세요."
                name="memid"
                value={SubmitData.memid || ""}
                onChange={inputHandler}
              />
            </td>
          </tr>
          <tr>
            <th>최종 로그인 일시</th>
            <td>
              {SubmitData.lastlogin ? (
                <>
                  {changeDateFormat(SubmitData.lastlogin, 5, ".", ":")}
                  <span className="ip_column">
                    {SubmitData.ip && "(IP:" + SubmitData.ip + ")"}
                  </span>
                </>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">비밀번호 관리</h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>비밀번호</th>
            <td>
              <input
                type="password"
                name="originPw"
                onChange={pwHandler}
                value={SubmitDataPw.originPw || ""}
                autoComplete="new-password"
              />
            </td>
            <th>비밀번호 확인</th>
            <td>
              <div className="password_column">
                <input
                  type="password"
                  name="confirmPw"
                  value={SubmitDataPw.confirmPw || ""}
                  onChange={pwHandler}
                />
                <div className="admin_white_btn" onClick={changePw}>
                  비밀번호 변경
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>비밀번호 오류 횟수</th>
            <td>
              <div className="day_change_count">
                {SubmitData.passworderrorcount}회
                <div
                  className="admin_white_btn admin_white_btn80"
                  onClick={resetPw}
                >
                  초기화
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">
        변호사 회원 권한 관리
        <span className="info">
          ※ 변호사 회원은 관리자 메뉴의 [게시판관리]에 대한 접근 및 권한만
          부여됩니다.
        </span>
      </h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>게시판 관리 접근</th>
            <td className="board_access">
              <input
                type="checkbox"
                id="chk5"
                checked={accessStatus}
                onChange={accessToggle}
              />
              <label htmlFor="chk5">권한 허용</label>
            </td>
            <th style={{ background: "none" }}></th>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className="detail_user admin_btn_column">
        <div
          className="admin_white_btn left btn"
          onClick={() => history.push(`/rf_zib_admin/user/lawyer/${pagen}`)}
        >
          목록
        </div>
        <div className="admin_blue_btn btn" onClick={submitHandler}>
          저장
        </div>
      </div>
    </>
  );
}

export default UserLawyerBasic;
