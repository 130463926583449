import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import PaginationNum from "../../components/PaginationNum";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import { changeDateFormat } from "../../Common/lib";
import axios from "axios";

function OutLogLogin() {
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();

  // 달력 시작일 셋팅
  const [searchDate, setSearchDate] = useState({
    start: "",
    end: "",
  });

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let body = {
      page: "login",
      out: 1,
    };
    let url = "/api/admin/log";
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        const result = res.data.logList;
        setBoardList(result);
        setBoardListCnt(result.length);
      }
    });
  }, []);

  const handleSearchDate = (e) => {
    setSearchDate({ ...searchDate, [e.target.name]: e.target.value });
  };

  const [loginStatus, setLoginStatus] = useState("전체");
  const handleloginStatus = (e) => {
    setLoginStatus(e.target.value);
  };
  // 검색하기
  const handleSearch = () => {
    let body = {
      page: "login",
      startDate: searchDate["start"],
      endDate: searchDate["end"],
      status: loginStatus,
      out: 1,
    };

    let url = "/api/admin/log/search";
    axios.post(url, body).then((res) => {
      if (res.data.result) {
        const result = res.data.logList;
        setBoardList(result);
        if (result !== "false") setBoardSearchListCnt(result.length);
        else setBoardSearchListCnt(0);
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };

  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        {/* <td>{item.idx}</td> */}
        <td>{item.rownum}</td>
        <td>{changeDateFormat(item.date, 5, ".", ":")}</td>
        <td>{item.memrealname}</td>
        <td>{item.memname}</td>
        <td>{item.cellno}</td>
        <td>{item.memid}</td>
        <td>{item.memjointype}</td>
        <td>{item.ip}</td>
        <td className={item.result === "실패" ? "stop" : ""}>{item.result}</td>
      </tr>
    );
  });
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container">
          <div>
            <h2 className="main_container_name">
              탈퇴 회원 로그인 현황
              <span className="total_column">
                전체 <span>{boardListCnt}</span>
              </span>
            </h2>
            <div className="user_search_column">
              <div className="second_column">
                <div className="search_regdate_column">
                  <span className="input_title">로그인 기간</span>
                  <input
                    type="date"
                    name="start"
                    value={searchDate["start"]}
                    onChange={handleSearchDate}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    name="end"
                    value={searchDate["end"]}
                    onChange={handleSearchDate}
                  />
                </div>
                <div className="bar"></div>
                <div>
                  <span className="input_title">상태</span>

                  <input
                    type="radio"
                    defaultChecked
                    id="radio4"
                    name="loginStatus"
                    value="전체"
                    onClick={handleloginStatus}
                  />
                  <label htmlFor="radio4">전체</label>
                  <input
                    type="radio"
                    id="radio5"
                    name="loginStatus"
                    value="성공"
                    onClick={handleloginStatus}
                  />
                  <label htmlFor="radio5">성공</label>
                  <input
                    type="radio"
                    id="radio6"
                    name="loginStatus"
                    value="실패"
                    onClick={handleloginStatus}
                  />
                  <label htmlFor="radio6">실패</label>
                </div>
              </div>
              <div className="admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  기본설정
                </div>
                <div className="admin_blue_btn btn" onClick={handleSearch}>
                  검색
                </div>
              </div>
            </div>
            <div className="user_list_column">
              <div className="title_column">
                <h3>목록</h3>
                {boardSearchListCnt ? (
                  <div className="search_result">
                    검색결과 <span> {boardSearchListCnt}</span>
                  </div>
                ) : (
                  <div className="search_result"></div>
                )}
              </div>

              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>로그인 일시</th>
                    <th>이름</th>
                    <th>회원닉네임</th>
                    <th>핸드폰</th>
                    <th>아이디(이메일)</th>
                    <th>가입유형</th>
                    <th>접속IP</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={9}>등록된 회원이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <PaginationNum items={boardList} onChangePage={onChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutLogLogin;
