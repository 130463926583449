import React from "react";
import tab from "../../images/admin/tab.png";
import { data } from "./SideMenuData";
import { Link } from "react-router-dom";

function SideMenu({ tabActive }) {
  const currentPath = window.location.pathname;

  return (
    <ul className="side_container">
      {data.map((item, index) => {
        return (
          <Link to={item.path} key={index}>
            <li
              className={
                currentPath.includes(item.path) ? "side_tab active" : "side_tab"
              }
              onClick={() => {
                window.location.pathname =
                  item.title === "무료법률상담" ? item.path + "/1" : item.path;
              }}
            >
              {item.title}
              {tabActive === index ? <img src={tab} alt="tab" /> : null}
            </li>
          </Link>
        );
      })}
    </ul>
  );
}

export default SideMenu;
