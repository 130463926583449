/**
 * admin memtype
 *
 * 등급 순 1. super
 *       2. 나머지 상호 베타적 general, lawyer, privacy
 * */
export const ADMIN_SUPER = {
  Memtype: "super",
  RedirectUrl: "rf_zib_admin/user/free/1",
  Alias: "최고",
}

export const ADMIN_GENERAL = {
  Memtype: "general",
  RedirectUrl: "/rf_zib_admin/address/1",
  Alias: "일반",
}

export const ADMIN_LAWYER = {
  Memtype: "lawyer",
  RedirectUrl: "/rf_zib_admin/board/consult/1",
  Alias: "변호사",
}

export const ADMIN_PRIVACY = {
  Memtype: "privacy",
  RedirectUrl: "/rf_zib_admin/user/free/1",
  Alias: "개인정보보호",
}