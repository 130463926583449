import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

function UserFreeNoticeSet() {
  let match = useRouteMatch();
  const idx = match.params.idx;
  // 모든 알림
  const [allAlarmControl, setAllAlarmControl] = useState(false);
  // 부동산 전체 알림조정
  const [allBaControl, setAllBaControl] = useState(false);
  // 임대차 전체 알림조정
  const [allIaControl, setAllIaControl] = useState(false);
  // 알림설정 총 개수 (앱푸시, 이메일, 문자, 알림톡 총 4개)
  const typeCount = 4;

  // budongsan Alarm -> ba
  const [baList, setBaList] = useState([]);
  // imdaecha Alarm -> ia
  const [iaList, setIaList] = useState([]);

  useLayoutEffect(() => {
    let url = "/api/mypage/notice/setting";
    let data = {
      idx: idx,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        if (res.data.alarmbudongsan !== "false") {
          let bal = res.data.alarmbudongsan;
          setBaList(bal);
          if (bal.length > 0 ? setAllBaControl(true) : setAllBaControl(false));
        }
        if (res.data.alarmimdaecha !== "false") {
          let ial = res.data.alarmimdaecha;
          setIaList(ial);
          if (ial.length > 0 ? setAllIaControl(true) : setAllIaControl(false));
        }
      } else {
        alert("존재하지 않는 정보입니다.");
      }
    });
    // baControl, iaControl
  }, [idx]);

  // 모든알림 활성화, 비활성화 여부 판단
  useLayoutEffect(() => {
    if (
      baList.length === typeCount && iaList.length === typeCount
        ? setAllAlarmControl(true)
        : setAllAlarmControl(false)
    );
  }, [baList, iaList]);

  // 알림들 onChange 관리
  const alarmHandler = (e) => {
    if (!window.confirm("해당 회원의 알림 설정을 변경하시겠습니까?")) return;
    let idx = e.target.id.substr(2, 2);
    // ba = 부동산 알림 , ia = 임대차 알림
    let changeList = [];
    let bal = [...baList];
    let ial = [...iaList];

    // 개별 알림들 한 번에 추가, 삭제
    let together = (what, how) => {
      changeList = [];
      switch (what) {
        case "ba":
          if (how === "add") {
            bal = [];
            for (let i = 0; i < typeCount; i++) {
              bal.push(i.toString());
            }
            setBaList(bal);
          } else if (how === "delete") {
            bal = [];
            setBaList(bal);
          }
          break;

        case "ia":
          if (how === "add") {
            ial = [];
            for (let i = 0; i < typeCount; i++) {
              ial.push(i.toString());
            }
            setIaList(ial);
          } else if (how === "delete") {
            ial = [];
            setIaList(ial);
          }
          break;

        default:
          break;
      }
    }; //end of together()

    switch (e.target.name) {
      case "everything": //모든 알림
        if (e.target.checked) {
          setAllAlarmControl(true);
          setAllBaControl(true);
          setAllIaControl(true);
          together("ba", "add");
          together("ia", "add");
          alarmAxios(bal, ial);
        } else {
          setAllAlarmControl(false);
          setAllBaControl(false);
          setAllIaControl(false);
          together("ba", "delete");
          together("ia", "delete");
          alarmAxios(bal, ial);
        }
        break;

      case "allBa":
        if (e.target.checked) {
          setAllBaControl(true);
          together("ba", "add");
        } else {
          setAllBaControl(false);
          together("ba", "delete");
        }
        alarmAxios(bal, ial);
        break;

      case "ba":
        if (e.target.checked) {
          bal.push(idx);
          setBaList(bal);
          if (
            bal.length === 0 ? setAllBaControl(false) : setAllBaControl(true)
          );
          alarmAxios(bal, ial);
        } else {
          changeList = bal.filter((val) => val !== idx);

          if (
            changeList.length === 0
              ? setAllBaControl(false)
              : setAllBaControl(true)
          );
          setBaList(changeList);
          alarmAxios(changeList, ial);
        }
        break;

      case "allIa":
        if (e.target.checked) {
          setAllIaControl(true);
          together("ia", "add");
        } else {
          setAllIaControl(false);
          together("ia", "delete");
        }
        alarmAxios(bal, ial);
        break;

      case "ia":
        if (e.target.checked) {
          ial.push(idx);
          if (
            ial.length === 0 ? setAllIaControl(false) : setAllIaControl(true)
          );
          setIaList(ial);
          alarmAxios(bal, ial);
        } else {
          changeList = ial.filter((val) => val !== idx);
          if (
            changeList.length === 0
              ? setAllIaControl(false)
              : setAllIaControl(true)
          );
          setIaList(changeList);
          alarmAxios(bal, changeList);
        }
        break;

      default:
        break;
    }
  };

  // 알림 변경 용 백엔드 통신
  let alarmAxios = async (bal, ial) => {
    let url = "/api/mypage/notice/setting/change";

    let data = {
      idx: idx,
      alarmbudongsan: bal,
      alarmimdaecha: ial,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        return;
      } else {
        alert("설정 변경에 실패하였습니다.");
      }
    });
  };

  // 부동산 정보변경알림 앱푸시~알림톡 개별 on off 버튼
  const makeBaEachCheck = () => {
    let htmlCode = [];
    for (let i = 0; i < typeCount; i++) {
      htmlCode.push(
        <td key={"ba" + i}>
          <div className="column__on-off">
            <input
              type="checkbox"
              id={"ba" + i}
              name="ba"
              className="input__on-off"
              onChange={alarmHandler}
              checked={baList.includes(i.toString())}
            />
            <label htmlFor={"ba" + i} className="label__on-off">
              <span className="marble"></span>
              <span className="on">ON</span>
              <span className="off">OFF</span>
            </label>
          </div>
        </td>
      );
    }
    return htmlCode;
  };

  // 임대차 만기일 앱푸시~알림톡 개별 on off 버튼
  const makeIaEachCheck = () => {
    let htmlCode = [];
    for (let i = 0; i < typeCount; i++) {
      htmlCode.push(
        <td key={"ia" + i}>
          <div className="column__on-off">
            <input
              type="checkbox"
              id={"ia" + i}
              name="ia"
              className="input__on-off"
              onChange={alarmHandler}
              checked={iaList.includes(i.toString())}
            />
            <label htmlFor={"ia" + i} className="label__on-off">
              <span className="marble"></span>
              <span className="on">ON</span>
              <span className="off">OFF</span>
            </label>
          </div>
        </td>
      );
    } // end of for()
    return htmlCode;
  };
  return (
    <>
      <div id="userFreeNoticeSet">
        <div className="all_check">
          <div className="input_name">모든 알림 </div>
          <div className="input_column">
            <div className="column__on-off">
              <input
                type="checkbox"
                id="everything"
                name="everything"
                className="input__on-off"
                onChange={alarmHandler}
                checked={allAlarmControl}
              />
              <label htmlFor="everything" className="label__on-off">
                <span className="marble"></span>
                <span className="on">ON</span>
                <span className="off">OFF</span>
              </label>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                알림 <br />
                On/Off
              </th>
              <th>앱 푸시</th>
              <th>이메일</th>
              <th>문자(SMS)</th>
              <th>알림톡(카카오톡)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="name">부동산 정보변경 알림 </td>
              <td>
                <div className="column__on-off">
                  <input
                    type="checkbox"
                    id="allBa"
                    name="allBa"
                    className="input__on-off"
                    onChange={alarmHandler}
                    checked={allBaControl}
                  />
                  <label htmlFor="allBa" className="label__on-off">
                    <span className="marble"></span>
                    <span className="on">ON</span>
                    <span className="off">OFF</span>
                  </label>
                </div>
              </td>
              {makeBaEachCheck()}
            </tr>
            <tr>
              <td className="name">임대차 만기일 알림 </td>
              <td>
                <div className="column__on-off">
                  <input
                    type="checkbox"
                    id="allIa"
                    name="allIa"
                    className="input__on-off"
                    onChange={alarmHandler}
                    checked={allIaControl}
                  />
                  <label htmlFor="allIa" className="label__on-off">
                    <span className="marble"></span>
                    <span className="on">ON</span>
                    <span className="off">OFF</span>
                  </label>
                </div>
              </td>
              {makeIaEachCheck()}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UserFreeNoticeSet;
