import axios from "axios";
import { getCookie } from "../Common/lib";
import {
  MAIN_BANNER,
  GET_MANAGE_ADDRESS,
  GET_COUNSELING,
  GET_COUNSELING_DETAIL,
  GET_COUNSELING_MY,
  GET_ALARM,
  GET_INQUIRY,
  GET_INQUIRY_DETAIL,
  WRITE_INQUIRY,
  MODIFY_INQUIRY,
  GET_NOTICE_LIST,
  GET_NOTICE,
  GET_OTHER_NOTICE,
  GET_OTHER_BLOG,
  GET_EVENT_LIST,
  GET_EVENT_DETAIL,
  SEARCH_MYPAGE_LIST,
  COMBINED_SEARCH,
  SERVICE_QUESTION,
  SERVICE_QUESTION_DETAIL,
  LEGAL_FAQ,
  LEGAL_FAQ_DETAIL,
  ADDRESS_BOLG,
  ADDRESS_BOLG_DETAIL,
  GET_BOARD_DETAIL, GET_BOARD_DIVISION_LIST,
} from "./types";

const x_auth = getCookie("x_auth");

// 메인 배너 가져오기
export function mainBanner(dataToSubmit) {
  const request = axios
    .post("/api/admin/set/banner", dataToSubmit)
    .then((res) => res.data);

  return {
    type: MAIN_BANNER,
    payload: request,
  };
}

// 관리주소 가져오기
export function getManageAddress() {
  // var x_auth = localStorage.getItem("x_auth");
  let body = {
    x_auth: x_auth,
  };

  const request = axios
    .post("/api/mypage/address", body)
    .then((res) => res.data);
  return {
    type: GET_MANAGE_ADDRESS,
    payload: request,
  };
}

// 알림 가져오기
export function getAlarm() {
  let body = {
    x_auth: x_auth,
  };

  const request = axios
    .post("/api/alarm/member", body)
    .then((res) => res.data);

  return {
    type: GET_ALARM,
    payload: request,
  };
}

// 무료 법률 상담 글 가져오기
export function getCounseling() {

  const request = axios
    .get("/api/board/consulting")
    .then((res) => res.data);

  return {
    type: GET_COUNSELING,
    payload: request,
  };
}

// 무료 법률 상담 디테일 가져오기(아래꺼는 mypage용인데... 이미 쓰고 있는 곳이 있어서)
export function getBoardDetail(dataToSubmit) {
  const request = axios
    .post("/api/counseling/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_BOARD_DETAIL,
    payload: request,
  };
}

// 카테고리 가져오기
export function boardDivisionList(dataToSubmit) {
  const request = axios
      .post("/api/category/get", dataToSubmit)
      .then((res) => res.data);

  return {
    type: GET_BOARD_DIVISION_LIST,
    payload: request,
  };
}

// 무료 법률 상담 글 정보 가져오기(Detail)
export function getCounselingDetail(dataToSubmit) {
  // var x_auth = localStorage.getItem("x_auth");
  dataToSubmit.x_auth = x_auth;
  const request = axios
    .post("/api/mypage/counseling/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_COUNSELING_DETAIL,
    payload: request,
  };
}

// 무료 법률 상담 내글 가져오기
export function getCounselingMy() {
  // var x_auth = localStorage.getItem("x_auth");
  const request = axios
    .get("/api/counseling/member/" + x_auth)
    .then((res) => res.data);

  return {
    type: GET_COUNSELING_MY,
    payload: request,
  };
}

// 문의 글 가져오기
export function getInquiry() {
  // var x_auth = localStorage.getItem("x_auth");
  const request = axios
    .get("/api/mypage/inquiry/member/" + x_auth)
    .then((res) => res.data);

  return {
    type: GET_INQUIRY,
    payload: request,
  };
}

// 문의 글 개별(자세히) 가져오기
export function getInquiryDetail(dataToSubmit) {
  // var x_auth = localStorage.getItem("x_auth");
  dataToSubmit.x_auth = x_auth;
  const request = axios
    .post("/api/mypage/inquiry/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_INQUIRY_DETAIL,
    payload: request,
  };
}

// 문의 글 쓰기
export function writeInquiry(dataToSubmit) {
  // var x_auth = localStorage.getItem("x_auth");
  dataToSubmit.x_auth = x_auth;
  const request = axios
    .post("/api/mypage/inquiry/add", dataToSubmit)
    .then((res) => res.data);

  return {
    type: WRITE_INQUIRY,
    payload: request,
  };
}

// 문의 글 수정하기
export function modifyInquiry(dataToSubmit) {
  // var x_auth = localStorage.getItem("x_auth");
  dataToSubmit.x_auth = x_auth;
  const request = axios
    .post("/api/mypage/inquiry/modify", dataToSubmit)
    .then((res) => res.data);

  return {
    type: MODIFY_INQUIRY,
    payload: request,
  };
}

// 공지 글 가져오기
export function getNoticeList(dataToSubmit) {
  const request = axios
    .post("/api/service/notice", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_NOTICE_LIST,
    payload: request,
  };
}

// 마이페이지 게시글 검색하기
export function searchMypageList(dataToSubmit) {
  const request = axios
    .post("/api/search/mypage", dataToSubmit)
    .then((res) => res.data);

  return {
    type: SEARCH_MYPAGE_LIST,
    payload: request,
  };
}

// 공지 글 내용보기
export function getNotice(dataToSubmit) {
  const request = axios
    .post("/api/service/notice/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_NOTICE,
    payload: request,
  };
}

// 공지 다음/이전 글 가져오기
export function getOtherNotice(dataToSubmit) {
  const request = axios
    .post("/api/admin/service/notice/detail/scope", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_OTHER_NOTICE,
    payload: request,
  };
}

// 부동산정보 다음/이전 글 가져오기
export function getOtherBlog(dataToSubmit) {
  const request = axios
    .post("/api/address/blog/detail/scope", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_OTHER_BLOG,
    payload: request,
  };
}

// 이벤트 가져오기
export function getEventList(dataToSubmit) {
  const request = axios
    .post("/api/service/event", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_EVENT_LIST,
    payload: request,
  };
}

// 이벤트 가져오기
export function getEventDetail(dataToSubmit) {
  const request = axios
    .post("/api/service/event/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_EVENT_DETAIL,
    payload: request,
  };
}

// 통합검색
export function combinedSearch(dataToSubmit) {
  const request = axios
    .post("/api/search", dataToSubmit)
    .then((res) => res.data);

  return {
    type: COMBINED_SEARCH,
    payload: request,
  };
}

// 자주하는 질문
export function serviceQuestion(dataToSubmit) {
  const request = axios
    .post("/api/service/question", dataToSubmit)
    .then((res) => res.data);

  return {
    type: SERVICE_QUESTION,
    payload: request,
  };
}

// 자주하는 질문
export function serviceQuestionDetail(dataToSubmit) {
  const request = axios
    .post("/api/service/question/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: SERVICE_QUESTION_DETAIL,
    payload: request,
  };
}

// 법관련 Faq
export function legalFaq(dataToSubmit) {
  const request = axios
    .post("/api/service/law", dataToSubmit)
    .then((res) => res.data);

  return {
    type: LEGAL_FAQ,
    payload: request,
  };
}

// 법관련 Faq 상세
export function legalFaqDetail(dataToSubmit) {
  const request = axios
    .post("api/service/law/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: LEGAL_FAQ_DETAIL,
    payload: request,
  };
}



// 부동산 정보
export function addressBlog(dataToSubmit) {
  const request = axios
    .post("/api/address/blog", dataToSubmit)
    .then((res) => res.data);

  return {
    type: ADDRESS_BOLG,
    payload: request,
  };
}

// 부동산 정보 상세
export function addressBlogDetail(dataToSubmit) {
  const request = axios
    .post("/api/admin/address/blog/detail", dataToSubmit)
    .then((res) => res.data);

  return {
    type: ADDRESS_BOLG_DETAIL,
    payload: request,
  };
}
