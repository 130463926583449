import {
  AUTH_USER,
  GET_ALARM_COUNT,
  GET_COUNSELING_COUNT,
  GET_INQUIRY_COUNT,
} from "../_actions/types";

// eslint-disable-next-line
export default function (state = initState, action) {
  switch (action.type) {
    case AUTH_USER:
      state = { ...state, adminInfo: action.payload };
      break;

    case GET_ALARM_COUNT:
      state = { ...state, alarmCount: action.payload };
      break;

    case GET_INQUIRY_COUNT:
      state = { ...state, inquiryCount: action.payload };
      break;

    case GET_COUNSELING_COUNT:
      state = { ...state, counselingCount: action.payload };
      break;

    default:
      break;
  }
  return state;
}

const initState = {
  alarmCount: 0,
  counselingCount: 0,
  inquiryCount: 0,
};
