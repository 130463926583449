import React, { useState, useLayoutEffect } from "react";
import close from "../../../images/close.png";
import Modal from "../../../components/Modal";
import { useDispatch } from "react-redux";
import { userOutDetailAddress } from "../../../_actions/admin_board_action";
import { useRouteMatch } from "react-router-dom";
import { changeDateFormat } from "../../../Common/lib";
function UserOutAddress() {
  let match = useRouteMatch();
  const idx = match.params.idx;
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const [addrList, setAddrList] = useState();
  const [selectAddr, setselectAddr] = useState(0);
  const [ownerList, setOwnerList] = useState([]);
  const [addrucode, setAddrucode] = useState([]);
  useLayoutEffect(() => {
    let body = {
      memidx: idx,
    };
    // 관리주소 갯수 가져오기
    dispatch(userOutDetailAddress(body)).then((res) => {
      if (res.payload.addrList) {
        const result = res.payload.addrList;
        setAddrList(result);
        setSubmitData(result[selectAddr]);
        setOwnerList(result[selectAddr].owner);
        setAddrucode(result[selectAddr].addrucode);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const [SubmitData, setSubmitData] = useState({});

  const changePage = (page) => {
    setselectAddr(page);
    setSubmitData(addrList[page]);
    setOwnerList(addrList[page].owner);
    setAddrucode(addrList[page].addrucode);
  };

  return (
    <>
      {addrList ? (
        <div id="userFreeAddress">
          <div className="address_lists">
            <ul>
              {addrList.map((item, index) => {
                return (
                  <li
                    className={
                      index === selectAddr
                        ? "address_list active"
                        : "address_list"
                    }
                    key={index}
                    onClick={() => changePage(index)}
                  >
                    <div className="name">{item.addrnickname}</div>
                    <div className="date">
                      {changeDateFormat(item.registerdate, 5, ".", ":")}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="address_detail">
            <h3 className="detail_table_title" style={{ marginTop: 0 }}>
              관리주소
            </h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th colSpan="2">부동산 닉네임</th>
                  <td>{SubmitData && SubmitData.addrnickname}</td>
                </tr>
                <tr>
                  <th colSpan="2">등록일</th>
                  <td>
                    {" "}
                    {changeDateFormat(
                      SubmitData && SubmitData.registerdate,
                      5,
                      ".",
                      ":"
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="column_name" rowSpan="5">
                    회원
                    <br />
                    입력
                    <br />
                    정보
                  </th>
                  <th className="sub_th">기본주소</th>
                  <td>
                    <input
                      type="text"
                      value={SubmitData && SubmitData.addr}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">상세주소</th>
                  <td>
                    <input
                      type="text"
                      value={SubmitData && SubmitData.addrsub}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">소유형태</th>
                  <td>
                    <div className="free_user_radio">
                      <input
                        type="radio"
                        checked={
                          SubmitData && SubmitData.addrower === "자가" && true
                        }
                        id="radio1"
                        name="own_radio_column"
                        disabled
                      />
                      <label htmlFor="radio1">자가</label>
                      <input
                        type="radio"
                        id="radio2"
                        name="own_radio_column"
                        checked={
                          SubmitData && SubmitData.addrower === "임차" && true
                        }
                        disabled
                      />
                      <label htmlFor="radio2">임차(전/월세)</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">임대차 계약일</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? changeDateFormat(
                            SubmitData && SubmitData.addrrentdate,
                            3,
                            "-",
                            ""
                          )
                        : "-"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="sub_th">임대차 만기일</th>
                  <td>
                    <div className="calendar_data">
                      {SubmitData && SubmitData.addrower === "임차"
                        ? changeDateFormat(
                            SubmitData && SubmitData.addrrentedate,
                            3,
                            "-",
                            ""
                          )
                        : "-"}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="detail_table_header">
              <h3 className="detail_table_title">정제주소 정보</h3>
              <div className="free_user_chk">
                <input
                  type="radio"
                  id="chk1"
                  name="addrtype"
                  value="승인완료"
                  disabled
                  checked={
                    SubmitData && SubmitData.addrtype === "승인완료"
                      ? true
                      : false
                  }
                />
                <label htmlFor="chk1">승인완료</label>
                <input
                  type="radio"
                  id="chk2"
                  name="addrtype"
                  value="미승인"
                  disabled
                  checked={
                    SubmitData && SubmitData.addrtype === "미승인"
                      ? true
                      : false
                  }
                />
                <label htmlFor="chk2">미승인</label>
                <input
                  type="radio"
                  id="chk3"
                  name="addrtype"
                  value="보류"
                  disabled
                  checked={
                    SubmitData && SubmitData.addrtype === "보류" ? true : false
                  }
                />
                <label htmlFor="chk3">보류</label>
              </div>
            </div>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th className="refine_address">정제주소</th>
                  <td>
                    <div className="refine_address_desc">
                      {SubmitData && SubmitData.addrrenew}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="detail_table_title">관계인(소유주) 정보</h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>관계인(소유주)</th>
                  <td>
                    <div className="day_change_count">
                      <input
                        type="text"
                        name="owner"
                        readOnly
                        value={ownerList && ownerList[0]}
                      />
                      <div
                        className="admin_white_btn admin_white_btn100 btn"
                        onClick={() => {
                          ownerList && openModal("modal2");
                        }}
                      >
                        더보기
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="detail_table_title">고유번호 정보</h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>고유번호</th>
                  <td>
                    <div className="day_change_count">
                      <input
                        type="text"
                        name="addrucode"
                        readOnly
                        value={(addrucode && addrucode[0]) || ""}
                      />
                      <div
                        className="admin_white_btn admin_white_btn100 btn"
                        onClick={() => {
                          addrucode && openModal("modal1");
                        }}
                      >
                        더보기
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="detail_table_title">경매정보</h3>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>경매배당기일</th>
                  <td style={{ width: "55%" }}>
                    <div className="calendar_data">
                      {SubmitData.verstdate
                        ? changeDateFormat(
                            SubmitData.verstdate,
                            3,
                            "-",
                            ""
                          ).trim()
                        : ""}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>등록된 주소가 없습니다.</div>
      )}

      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_info title">관리주소 고유번호</div>
          <div className="modal_list_column">
            {addrucode &&
              addrucode.map((item) => {
                return <div>{item}</div>;
              })}
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>

      <Modal open={modal["modal2"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <div className="modal_info title">관리주소 소유주</div>
          <div className="modal_list_column">
            {ownerList &&
              ownerList.map((item) => {
                return <div>{item}</div>;
              })}
          </div>
          <div
            className="btn close confirm_btn"
            onClick={() => closeModal("modal2")}
          >
            닫기
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserOutAddress;
