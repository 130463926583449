import React, { useLayoutEffect, useRef, useState } from "react";
import "./Login.css";
import kakao from "../../images/kakao.png";
import naver from "../../images/naver.png";
import Ios from "../../images/ios.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import {
  CheckEmail,
  CheckPw,
  deleteCookie,
  getCookie,
  setCookie,
  saveLimitTime,
} from "../../Common/lib";
import axios from "axios";
import $ from "jquery";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";

function Login({ location }) {
  let searchParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };
  const checkMemberStatus = searchParam("status");

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  let history = useHistory();
  const rememberEmail = getCookie("rememberEmail");

  const [isRemember, setIsRemember] = useState(true);
  // 사용자 로그인 정보
  const [userInfo, setUserInfo] = useState({
    userEmail: "",
    userPw: "",
    loginWarning: "",
    snsLoginWarnig: "",
  });
  // 유효성 검사 false일 때 경고 문구
  const [userInfoErr, setUserInfoErr] = useState({
    userEmailErr: "",
    userPwErr: "",
  });
  // 앱푸시용 devicetoken
  const [userToken, setUserToken] = useState("");

  // 앱에서 ios 확인
  const [appIOS, setAppIOS] = useState(false);

  // 아이디 저장 checkbox
  useLayoutEffect(() => {

    window.addEventListener("getToken", async (e) => {
      setUserToken(e.detail);
    });

    //window.addEventListener("getIos", async (e) => {
    //  console.log(e);
    //  setAppIOS(e.detail);
    //});

    // 애플기기 체크
    var checkUserAgent = navigator.userAgent.toLowerCase();
    if(checkUserAgent.indexOf("iphone") != -1 || checkUserAgent.indexOf("ipad") != -1 || checkUserAgent.indexOf("mac") != -1) { 
      setAppIOS("true");
    }

    if (checkMemberStatus) {
      let message;
      if (checkMemberStatus === "stop") {
        message = "정지";
      } else if (checkMemberStatus === "out") {
        message = "탈퇴";
      } else {
        return;
      }
      message += "된 회원입니다. 관리자에게 문의해주세요";
      setUserInfoErr({
        userEmailErr: "",
        userPwErr: "",
        loginWarning: "",
        snsLoginWarnig: message,
      });
    }
    localStorage.clear();
    deleteCookie("x_auth");

    if (rememberEmail !== undefined) {
      setUserInfo({ ...userInfo, userEmail: rememberEmail });
      setIsRemember(true);
    }

    // eslint-disable-next-line
  }, []);

  const onChangeUserInfo = (e) => {
    // input값 입력시 값 셋팅
    const value = e.target.value;
    setUserInfo({
      ...userInfo,
      [e.target.name]: value,
    });
  };

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const LoadingWithMask = () => {
    //화면의 높이와 너비를 구합니다.    
    let maskHeight = $(document).height();
    let maskWidth = window.document.body.clientWidth;

    //마스크의 높이와 너비를 화면 것으로 만들어 전체 화면을 채웁니다.
    $('#mask').css({
      'width' : maskWidth,
      'height': maskHeight,
      'opacity' : '0.3',
    }); 

    //마스크 표시   
    $('#mask').show();

  };

  const LoadingMaskClose = () => {
    //마스크 표시   
    $('#mask').hide();
  }

  const handleLogin = (e) => {
    // 로그인 버튼 클릭 시 유효성 검사
    e.preventDefault();

    if (!CheckEmail(userInfo["userEmail"])) {
      // 아이디(이메일) 유효성 검사
      setUserInfoErr({
        userEmailErr: "이메일 형식이 올바르지 않습니다.",
        userPwErr: "",
        loginWarning: "",
      });
      handleFocus(0);
    } else {
      setUserInfoErr({
        userEmailErr: "",
        userPwErr: "",
      });

      if((userInfo["userPw"]) === ""){
        setUserInfoErr({
          userEmailErr: "",
          userPwErr: "비밀번호를 입력해주세요.",
          loginWarning: "",
        });
        handleFocus(1);
        return;
      }

      let url = "/api/login";
      let body = {
        memid: userInfo["userEmail"],
        password: userInfo["userPw"],
        token: userToken,
      };

      //중복클릭 방지를 위해 마스크 생성
      LoadingWithMask();

      // 로그인 요청
      axios.post(url, body).then((response) => {
        if (response.data.result === "success") {
          setCookie("x_auth", response.data.idx, saveLimitTime());
          // localStorage.setItem("x_auth", response.data.idx);
          if (isRemember) {
            setCookie("rememberEmail", userInfo["userEmail"], 30);
          } else {
            deleteCookie("rememberEmail");
          }
          // history.goBack();
          // history.push("/");
          window.location.href = "/";
        } else if (response.data.result === "alreadyLogin") {
          alert("이미 로그인 중입니다.");
        } else if (response.data.result === "휴면") {
          history.push(`/dormant?memid=${userInfo["userEmail"]}`);
        } else if (response.data.result === "정지") {
          setUserInfoErr({
            userEmailErr: "",
            userPwErr: "",
            loginWarning: "정지된 회원입니다. 관리자에게 문의해주세요",
          });
        } else if (response.data.result === "탈퇴") {
          setUserInfoErr({
            userEmailErr: "",
            userPwErr: "",
            loginWarning: "탈퇴된 회원입니다. 관리자에게 문의해주세요",
          });
        } else {
            let warningMsg = ""
            //계정타입에 따라 다르게 문구 출력
            switch(response.data.memjointype){
              case "카카오톡":
                warningMsg = "카카오 계정으로 로그인해주세요.";
                break;
              case "네이버":
                warningMsg = "네이버 계정으로 로그인해주세요.";
                break;
              case "애플":
                  warningMsg = "애플 계정으로 로그인해주세요.";
                break;        
              default:
                warningMsg = "아이디 또는 비밀번호가 일치하지 않습니다.";
            }
          alert(warningMsg);
          setUserInfoErr({
            userEmailErr: "",
            userPwErr: "",
            loginWarning: warningMsg,
          });
        } // end of if()
        
        //마스크 해제
        LoadingMaskClose();
      });
    }
  };

  var objPopup;
  // SNS 로그인
  let snsLogin = (e, snsName) => {
    let pageUrl = `${axios.defaults.baseURL}api/join/${snsName}`;
    if (snsName === "apple") {
      var openUrl =
        "https://appleid.apple.com/auth/authorize?client_id=kr.co.refine.applelogin&redirect_uri=" +
        encodeURIComponent("https://zibfine.com/login") +
        "&response_type=code%20id_token&state=kedric&scope=email%20name&response_mode=web_message&frame_id=dccd8f89-719f-460e-a741-0c0d884d4606&m=41&v=1.5.3";
      var option =
        "width = 500, height = 700, top = 100, left = 200, location = no";
      objPopup = window.open(openUrl, "zibfine apple login", option);
    } else {
      document.location.href = pageUrl;
    }
  };

  // 애플로그인 콜백
  document.addEventListener("AppleIDSignInOnSuccess", (res) => {
    if (objPopup != null) objPopup.close();
    var resdata = res.detail;
    if (resdata && resdata.authorization) {
      let tokenArr = resdata.authorization.id_token.split(".");
      let url = "/api/join/apple/oauth";

      let data = {
        code: tokenArr[1],
      };
      axios
        .post(url, data)
        .then((res) => {
          let rs = res.data;
          if (rs.result === "login") {
            setCookie("x_auth", rs.idx, saveLimitTime());
            // localStorage.setItem("x_auth", rs.idx);
            history.push("/");
          } else if (rs.result === "join") {
            history.push(
              `/join/sns?type=apple&email=${rs.memid}&token=${rs.token}`
            );
          } else {
            alert("애플 로그인에 실패하였습니다.");
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("애플 정보를 가져오는데 실패하였습니다.");
    }
  });

  const handleOnChange = () => {
    setIsRemember(!isRemember);
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header />
        <div id='mask' style={{ zIndex: 9000, position: "absolute", left:0, top:0, backgroundColor:"#000000" }}></div>
        <div className="main center_main" id="login">
          <h1 className="join_title">로그인</h1>
          <h2>서비스 이용을 위해 로그인 해주세요.</h2>
          <form className="login_form">
            <div className="column">
              <div className="input_name">아이디(이메일 주소)</div>
              <input
                type="text"
                name="userEmail"
                value={userInfo["userEmail"]}
                onChange={onChangeUserInfo}
                ref={(elem) => (inputRefs.current[0] = elem)}
              />
            </div>
            <div className="warning">{userInfoErr["userEmailErr"]}</div>
            <div className="column">
              <div className="input_name">
                비밀번호 (8자 이상, 영문, 숫자, 특수문자 포함)
              </div>
              <input
                type="password"
                name="userPw"
                value={userInfo["userPw"]}
                onChange={onChangeUserInfo}
                ref={(elem) => (inputRefs.current[1] = elem)}
              />
            </div>
            <div className="warning">{userInfoErr["userPwErr"]}</div>
            <div className="checkbox_wrap auto_login">
              <input
                id="auto_login"
                type="checkbox"
                checked={isRemember}
                onChange={handleOnChange}
              />
              <label htmlFor="auto_login">아이디저장</label>
            </div>
            <div className="warning">{userInfoErr["loginWarning"]}</div>
            <button className="login_btn btn" onClick={handleLogin}>
              로그인하기
            </button>
          </form>
          <div className="horizontal_line">
            <div className="hr"></div>
            <span>OR</span>
            <div className="hr"></div>
          </div>
          <div className="sns_login_title">
            <h2 style={{ marginTop: "10px", letterSpacing: "5px" }}>
              SNS로 로그인 하기
            </h2>
          </div>
          <div className="sns_login">
            <BrowserRouter forceRefresh={true}>
              <div
                className="column"
                onClick={(e) => {
                  snsLogin(e, "kakao");
                }}
              >
                <img src={kakao} alt="kakao" />
              </div>
            </BrowserRouter>
            <BrowserRouter forceRefresh={true}>
              <div
                className="column"
                onClick={(e) => {
                  snsLogin(e, "naver");
                }}
              >
                <img src={naver} alt="naver" />
              </div>
            </BrowserRouter>
            {appIOS === "true" ? (
              <BrowserRouter forceRefresh={true}>
                <div
                  className="column"
                  onClick={(e) => {
                    snsLogin(e, "apple");
                  }}
                >
                  <img src={Ios} alt="ios" style={{ maxWidth: 60 }} />
                </div>
              </BrowserRouter>
            ) : ""}
          </div>
          {userInfoErr["snsLoginWarnig"] ? (
            <div className="sns_login_message">
              <div className="warning">{userInfoErr["snsLoginWarnig"]}</div>
            </div>
          ) : null}

          {/* end of <div className="sns_login"> */}

          <div className="sub_links">
            <BrowserRouter forceRefresh={true}>
              <Link to="/find/id">
                <div className="find">아이디 찾기</div>
              </Link>
            </BrowserRouter>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <BrowserRouter forceRefresh={true}>
              <Link to="/find/pw">
                <div className="find">비밀번호 찾기</div>
              </Link>
            </BrowserRouter>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <BrowserRouter forceRefresh={true}>
              <Link to="/join">
                <div>회원가입</div>
              </Link>
            </BrowserRouter>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default Login;
