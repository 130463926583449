import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import { setCookie, saveLimitTime } from "../Common/lib";

// 네이버, 카카오톡 로그인
function XauthSave({ location }) {
  let searchParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  const x_auth = searchParam("x_auth");
  // localStorage.setItem("x_auth", x_auth);
  setCookie("x_auth", x_auth, saveLimitTime());

  // 앱푸시용 devicetoken 저장
  const [userToken, setUserToken] = useState("");
  useLayoutEffect(() => {
    window.addEventListener("getToken", async (e) => {
      setUserToken(e.detail);
    });
  }, []);

  setTimeout(() => {
    movePage(userToken);
  }, 100);

  let movePage = (token) => {
    if (token) {
      let url = "/api/deviceToken";
      let data = {
        x_auth: x_auth,
        deviceToken: token,
      };

      axios
        .post(url, data)
        .then((res) => {
          window.location.href = "/";
        })
        .catch((err) => console.log(err));
    } else {
      window.location.href = "/";
    }
  };

  return <></>;
}

export default XauthSave;
