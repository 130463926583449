import React, { useRef, useState, useLayoutEffect } from "react";
import warningWhite from "../../images/warning_white.png";
import closebtn from "../../images/close.png";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { boardDivisionList } from "../../_actions/main_action";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { getCookie } from "../../Common/lib";

function CounselingWrite() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  let history = useHistory();
  const inputRefs = useRef([]);

  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 카테고리 리스트
  const [categories, setCategories] = useState([]);
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    let body = {
      kind: "무료법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch]);

  const [showWarning, setshowWarning] = useState(true);
  const [alertWarning, setAlertWarning] = useState(false);
  const [faqWarning, setFaqWarning] = useState(true);
  // 추후에 관리자 페이지에서 상담내용 카테고리 설정하게 되면 해당 목록 불러와서 넣어줄 칭구
  // const [gubunList, setGubunList] = useState([]);
  const gubunList = [];

  const [consulting, setConsulting] = useState({
    gubun: "기타",
    title: "",
    text: "",
  });
  // 상담내용, 제목, 내용 수정 관리
  let inputHandler = (e) => {
    setConsulting({ ...consulting, [e.target.name]: e.target.value });
  };

  const agreeCaution = () => {
    if (consulting["title"] === "") {
      alert("제목을 입력해주세요.");
      handleFocus(0);
      return;
    } else if (consulting["text"] === "") {
      alert("내용을 입력해주세요.");
      handleFocus(1);
      return;
    }

    if(!$("#check_agree").prop("checked")){
      setAlertWarning(true);
      alert("경매관련 내용이 아닌경우, 상담이 불가합니다.");
      return;
    }else{
      setAlertWarning(false);
    }


    if (!$("#agree_caution").prop("checked")) {
      setshowWarning(false);
      return;
    } else {
      if(alertWarning === false){
        setshowWarning(true);
        let url = "/api/counseling/write";
        let data = { ...consulting };
        const x_auth = getCookie("x_auth");
        // var x_auth = localStorage.getItem("x_auth");
        data.x_auth = x_auth;

        axios.post(url, data).then((res) => {
          if (res.data.result === "success") {
            alert("등록이 완료되었습니다");
            history.push("/counseling/1");
          } else {
            alert("등록 실패. 다시 시도해주세요.");
          }
        });
      }
    }
  };

  const gotoFaq = () => {
    history.push("/law/전체/1");
  }
  
  const passFaq = () => {
    setFaqWarning(false);
  }

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main">
          {faqWarning ?
            <div style={{ position: "absolute", left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.6)", display: "flex", justifyContent: "space-around", alignItems: "center", zIndex: 1 }}>
              <div style={{ backgroundColor: "#fff", width: "350px", height: "350px", borderRadius: "20px" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <img src={closebtn} alt="close" onClick={passFaq} style={{ padding: "15px 20px", cursor: "pointer" }} />
                </div>
                <div style={{ fontSize: 17, color:"#4a4a4a", marginBottom: 30, textAlign: "center", marginTop: 30 }}>
                    <br />
                    문의 접수 전 <b style={{ fontSize: 22, color:"#000" }}>[법률상담 FAQ]</b>에서 <br /><br /> 문의사항에 대한 답변을 빠르고 편리하게 <br /><br />
                    확인 하실 수 있습니다.
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "90%" }} class="blue_btn btn"  onClick={gotoFaq}>[법률상담 FAQ] 바로가기</div>
                </div>
              </div>
            </div> : null
          }
          <div className="write_column">
            <h2 className="title">
              <div style={{ paddingRight: 14 }} onClick={goBack}>
                <img src={back} alt="back" className="isMobile_back" />
              </div>
              <span>상담 신청하기</span>
            </h2>
            <div className="hr"></div>
            <form className="counseling_write_form">
              <div className="form_column">
                <div className="column_name">상담내용</div>
                <select
                  name="gubun"
                  value={gubunList ? gubunList[0] : consulting.gubun}
                  onChange={inputHandler}
                >
                  {categories.map((item, index) => {
                    return <option key={index}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="form_column isMobile_writer">
                <div className="column_name">작성자</div>
                <div className="writer">{memname}</div>
              </div>
              <div className="form_column">
                <div className="column_name">제목</div>
                <input
                  type="text"
                  name="title"
                  onChange={inputHandler}
                  ref={(elem) => (inputRefs.current[0] = elem)}
                  placeholder="제목을 입력하세요. (최대 20자)"
                  className="write_title"
                />
              </div>
              <div className="form_column">
                <div className="column_name write_content">내용</div>
                <textarea
                  name="text"
                  onChange={inputHandler}
                  ref={(elem) => (inputRefs.current[1] = elem)}
                  placeholder="내용을 입력하세요. (최대 1,000자 이내)&#13;&#10;※ 개인정보가 포함되지 않도록 유의해 주시기 바랍니다."
                ></textarea>
              </div>

              <div className="caution_wrapper">
                <img src={warningWhite} alt="warning" />
                <div className="caution_column">
                  <div className="title">법률 무료 상담 시 주의사항</div>
                  <ul>
                    <li>
                      ㆍ집파인에 등록된 부동산 주소가 경매 진행 중인 경우, 무료
                      상담이 가능합니다.
                    </li>
                    <li>
                      ㆍ집파인에 등록된 부동산 주소가 없거나, 경매 진행이 없는
                      경우에는 상담이 불가합니다.
                    </li>
                    <li>
                      ㆍ제목과 상담내용 작성 시 개인정보가 포함되지 않도록
                      유의해 주십시오.
                    </li>
                    <li>
                      ㆍ작성하신 글에 개인정보가 포함되어 발생하는 피해에
                      대해서는 민형사상 책임을 지지 않습니다.
                    </li>
                    <li>
                      ㆍ또한 동일한 사안에 대해서는 재상담을 하지 않습니다.
                    </li>
                  </ul>
                  <div className="checkbox_column" style={{"marginBottom":5}}>
                    <div className="checkbox_wrap">
                      <input id="check_agree" type="checkbox" />
                      <label htmlFor="check_agree">경매관련 상담입니다.</label>
                    </div>
                    <div className="warning" style={{ color: "#e30d0d" }}>
                      {alertWarning ? "경매상담인 경우만 체크하세요." : ""}
                    </div>
                  </div>
                  <div className="checkbox_column">
                    <div className="checkbox_wrap">
                      <input id="agree_caution" type="checkbox" />
                      <label htmlFor="agree_caution">주의사항을 확인했습니다.</label>
                    </div>
                    <div className="warning" style={{ color: "#e30d0d" }}>
                      {showWarning ? "" : "주의사항을 확인하시고 체크하세요."}
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_column">
                <div className="btn blue_btn close" onClick={goBack}>
                  취소
                </div>
                <div className="blue_btn btn" onClick={agreeCaution}>
                  등록
                </div>
              </div>
            </form>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default CounselingWrite;
