import React, { useState, useLayoutEffect } from "react";
import "./Board.css";
import "../Mypage/Mypage.css";
import depthHome from "../../images/depth_home.png";
import search from "../../images/search.png";
import back from "../../images/back.png";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { getNoticeList, searchMypageList, boardDivisionList } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import { auth } from "../../_actions/user_action";
import { useRouteMatch } from "react-router-dom";

function NoticeBoard() {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const idx = match.params.idx;
  let cate = match.params.cate;
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();
  const pathName = "/notice/board/"+ cate +"/";
  const goBack = () => {
    history.push("/");
    //history.goBack();
  };
  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  const [NoticeList, setNoticeList] = useState([]); // 공지글 리스트
  const [ListCount, setListCount] = useState(0); // 검색결과

  const [categoryList, setCategoryList] = useState([]);

  const [Categorie, setCategorie] = useState("전체"); // 선택한 카테고리
  const [Condition, setCondition] = useState("title+content"); // 선택한 조건
  const [SearchTitle, setSearchTitle] = useState(""); // 검색어
  const [title, setTitle] = useState(""); // 타이틀
  const [Warning, setWarning] = useState(""); // 검색어 경고

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let data = {
      kind: "공지사항",
    };

    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(data)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategoryList(result);
      } else {
        setCategoryList([]);
      }
    });

    if (cate === 'all'){
      setTitle("공지사항");
      let body = {
        visible: "노출중", // 공개된글
      };
      dispatch(getNoticeList(body)).then((res) => {
        if (res.payload.noticeList !== "false") {
          const result = res.payload.noticeList;
          setNoticeList(result);
        }
      });

    } else {
      let setting;
      if (cate === "terms") {
        setTitle("약관변경");
        setting = {
          page: "notice",
          gubun: "약관변경",
          condition: "title",
          search: "개인정보",
        };
      }

      dispatch(searchMypageList(setting)).then((res) => {
        history.push("/notice/board/"+cate+"/1");
        const result = res.payload.searchList;
        setNoticeList(result || []);
        setListCount(result.length || 0);
        setPageOfItems(result || []); //얘가 안먹혀서 여기서 설정해주기
      });   
    }

  }, [dispatch, idx]);

  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "categorie":
        setCategorie(e.target.value);
        return;
      case "condition":
        setCondition(e.target.value);
        return;
      case "searchTitle":
        setSearchTitle(e.target.value);
        return;
      default:
        return;
    }
  };
  // 검색처리
  const handleSearch = () => {
    let body = {
      page: "notice",
      gubun: Categorie,
      condition: Condition,
      search: SearchTitle,
    };

    if(Categorie === "약관변경") {
      cate = "terms";
    } else {
      cate = "all";
    }

    if (SearchTitle.length < 1) {
      body.SearchTitle = "";
    }

    setWarning("");
    dispatch(searchMypageList(body)).then((res) => {
      history.push("/notice/board/"+cate+"/1");
      const result = res.payload.searchList;

      setNoticeList(result || []);
      setListCount(result.length || 0);
      setPageOfItems(result || []); //얘가 안먹혀서 여기서 설정해주기
    });
    
  };

  const pageOfItem = pageOfItems.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.gubun}</td>
        <td>
          <BrowserRouter forceRefresh={true}>
            <Link to={`/notice/board/detail/${cate}/${item.idx}`}>
              <div>{item.title}</div>
            </Link>
          </BrowserRouter>
        </td>
        <td className="regdate">
          {changeDateFormat(item.registerdate, 3, "-", "")}
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main board_main" id="noticeBoard">
          <div className="section_2">
            <div className="depth_column">
              <BrowserRouter forceRefresh={true}>
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
              </BrowserRouter>
              <div className="board_name">
                <span>〉</span>{title}
              </div>
            </div>
            {isMobile && (
              <div className="content board_main">
                <h2>
                  <div style={{ paddingRight: 14 }} onClick={goBack}>
                    <img src={back} alt="back" className="isMobile_back" />
                  </div>
                  {title}
                </h2>
              </div>
            )}

            <div
              className="table_column"
              style={{
                minHeight: pageOfItems.length > 0 ? 660 : "auto",
                marginBottom: isMobile && 10,
              }}
            >
              <div className="mypage_body">
                <div className="content board_main">
                  <table className="noticeBoard_table">
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>구분</th>
                        <th>제목</th>
                        <th style={{ width: "15%" }}>게시일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageOfItem.length > 0 ? (
                        pageOfItem
                      ) : (
                        <tr>
                          <td colSpan="3">등록된 게시글이 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div
                    className="search_reslt"
                    style={{ visibility: ListCount > 0 ? "visible" : "hidden" }}
                  >
                    검색 결과 : 총 <strong>{ListCount}</strong>건
                  </div>

                  <div className="search_condition_column">
                    <div className="isMobile_select_wrapper">
                      <select
                        name="categorie"
                        onChange={inputHandler}
                        className="left"
                      >
                        <option value="전체">구분 전체</option>
                        {categoryList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <select name="condition" onChange={inputHandler}>
                        <option value="title+content">제목+내용 </option>
                        <option value="title">제목</option>
                        <option value="content">내용</option>
                      </select>
                    </div>
                    <div className="search_input">
                      <input
                        type="text"
                        name="searchTitle"
                        onChange={inputHandler}
                        onKeyPress={onKeyPress}
                        placeholder="검색할 단어를 입력하세요."
                      />
                      <img
                        src={search}
                        alt="search"
                        style={{ cursor: "pointer" }}
                        onClick={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="search_warning">{Warning}</div>
                </div>
              </div>
            </div>

            <Pagination
              items={NoticeList}
              onChangePage={onChangePage}
              initialPage={idx}
              pathName={pathName}
            />
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default NoticeBoard;
