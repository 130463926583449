import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import reply from "../../images/reply.png";
import Pagination from "../../components/Pagination";
import back from "../../images/back.png";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getCounselingMy } from "../../_actions/main_action";
import { changeDateFormat, getCookie } from "../../Common/lib";
import MobileBottomNav from "../Footer/MobileBottomNav";
import axios from "axios";
import { getAlarmCount } from "../../_actions/user_action";

function MyArticle() {
  const allAlarmCount = useSelector((store) => store.adminInfo);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  // counseling 글
  const [consultingList, setConsultingList] = useState([]);
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    isMobile && getCounselingNonRead();
    // 글 목록 가져오기
    dispatch(getCounselingMy()).then((res) => {
      if (res.payload.result === "success") {
        const result = res.payload.consultingList;
        setConsultingList(result);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  // 법률상담 읽지 않은 알림 개수 가져오기
  let getCounselingNonRead = () => {
    let url = "/api/mypage/counseling/count";
    // var x_auth = localStorage.getItem("x_auth");
    const x_auth = getCookie("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("counseling", res.data.counselingCount));
    });
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}

        <div className="main center_main mypage_main" id="myArticle">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content board_main">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                내글보기
                <span className="count">{allAlarmCount.counselingCount}</span>
              </h2>

              <div className="table_column">
                <table>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>구분</th>
                      <th>제목</th>
                      <th>답변</th>
                      <th>작성자</th>
                      <th>작성일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isMobile ? (
                      <>
                        {pageOfItems.length > 0 ? (
                          pageOfItems.map((item, index) => {
                            return (
                              <tr className="isMobil_tr" key={index}>
                                <td className="num">{item.rownum}</td>
                                <td>
                                  <div>{item.gubun}</div>
                                  <div className="wrapper">
                                    <BrowserRouter forceRefresh={true}>
                                      <Link
                                        to={`/mypage/myarticle/detail/${item.idx}`}
                                      >
                                        <div className="title">
                                          {item.checkread === "미확인" &&
                                          !item.status ? (
                                            <span
                                              className="new_icon"
                                              style={{ marginRight: "10px" }}
                                            >
                                              NEW
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {item.title}
                                        </div>
                                      </Link>
                                    </BrowserRouter>
                                    {item.status === 1 ? (
                                          <div className="check_reply finish">완료</div>
                                        ) : item.status === 0 ? (
                                          <div className="check_reply">대기</div>
                                        ) : <div className="check_reply ing">변호사 답변 대기</div> }
                                  </div>
                                  <div className="wrapper">
                                    <div className="nickname">
                                      {item.memname}
                                    </div>
                                    <div className="bar"></div>
                                    <div>
                                      {changeDateFormat(
                                        item.registerdate,
                                        3,
                                        "-",
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {item.replytitle && (
                                    <div
                                      className="reply_column"
                                      style={{ marginTop: 10 }}
                                    >
                                      <div>
                                        <img src={reply} alt="reply" />
                                      </div>
                                      <div>
                                        <div className="wrapper">
                                          <BrowserRouter forceRefresh={true}>
                                            <Link
                                              to={`/mypage/myarticle/detail/${item.idx}`}
                                            >
                                              <div className="title">
                                                {item.checkread === "미확인" &&
                                                item.status ? (
                                                  <span
                                                    className="new_icon"
                                                    style={{
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    NEW
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {item.replytitle}
                                              </div>
                                            </Link>
                                          </BrowserRouter>
                                        </div>
                                        <div className="wrapper">
                                          <div>{item.replyMemname}</div>
                                          <div className="bar"></div>
                                          <div>
                                            {changeDateFormat(
                                              item.replydate,
                                              3,
                                              "-",
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="2">등록된 글이 없습니다.</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        {pageOfItems.length > 0 ? (
                          pageOfItems.map((item, index) => {
                            return (
                              <tr
                                className={item.replytitle ? "have_re" : ""}
                                key={index}
                              >
                                <td>{item.rownum}</td>
                                <td>{item.gubun}</td>

                                <td>
                                  <BrowserRouter forceRefresh={true}>
                                    <Link
                                      to={`/mypage/myarticle/detail/${item.idx}`}
                                    >
                                      {item.checkread === "미확인" &&
                                      !item.status ? (
                                        <span
                                          className="new_icon"
                                          style={{ marginRight: "10px" }}
                                        >
                                          NEW
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {item.title}
                                      {item.replytitle && (
                                        <div>
                                          <img src={reply} alt="reply" />
                                          &nbsp;
                                          {item.checkread === "미확인" &&
                                          item.status ? (
                                            <span
                                              className="new_icon"
                                              style={{ marginRight: "10px" }}
                                            >
                                              NEW
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {item.replytitle}
                                        </div>
                                      )}
                                    </Link>
                                  </BrowserRouter>
                                </td>
                                <td>
                                  {
                                    item.status === 1 ? (
                                      <div className="check_reply finish">완료</div>
                                    ) : item.status === 0 ? (
                                      <div className="check_reply">대기</div>
                                    ) : <div className="check_reply ing">변호사 답변 대기</div> 
                                  }
                                </td>
                                <td>
                                  {item.memname}
                                  {item.replytitle && (
                                    <div className="writer_admin">
                                      {item.replyMemname}
                                    </div>
                                  )}
                                </td>
                                <td className="regdate">
                                  {changeDateFormat(
                                    item.registerdate,
                                    3,
                                    "-",
                                    ""
                                  )}
                                  {item.replytitle && (
                                    <div className="regdate">
                                      {changeDateFormat(
                                        item.replydate,
                                        3,
                                        "-",
                                        ""
                                      )}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="6">등록된 글이 없습니다.</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination items={consultingList} onChangePage={onChangePage} />
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default MyArticle;
