import axios from "axios";
import { setCookie, getCookie, saveLimitTime, strDecoding } from "../Common/lib";
import {
  AUTH_USER,
  SAVE_AUTH_NUM,
  GET_AUTH_NUM,
  FIND_EMAIL,
  ADD_MANAGE_ADDRESS,
  MODIFY_MANAGE_ADDRESS,
  MANAGE_ADDR_NICK_CHK,
  GET_ALARM_COUNT,
  GET_COUNSELING_COUNT,
  GET_INQUIRY_COUNT,
} from "./types";

// 유저 정보
export function auth(option) {
  
  // 관리자 인증 처리
  if (option === "admin") {
    let admin_auth = localStorage.getItem("admin_auth");
    let x_type = localStorage.getItem("x_type");
    let admin_authname = localStorage.getItem("admin_authname");
    let body = {
      admin_auth: admin_auth,
      x_type: x_type,
      admin_authname: admin_authname,
    };
    const request = axios
      .post("/api/auth/admin", body)
      .then((response) => response.data);

    return {
      type: AUTH_USER,
      payload: request,
    };
  } else {
    // 사용자 인증 처리
    const x_auth = getCookie("x_auth");
    // let x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    let request = axios
      .post("/api/auth", body)
      .then((response) => response.data);

    request.then((data) => {
      data.memid = strDecoding(data.memid);
      data.memname = strDecoding(data.memname);
      data.memrealname = strDecoding(data.memrealname);
      data.memjointype = strDecoding(data.memjointype);
      return data;
    })
    
    request.then((item) => {
      if(item.isLogin){
        setCookie("x_auth", x_auth, saveLimitTime());   // 1시간만 인증처리
      }
    });
    return {
      type: AUTH_USER,
      payload: request,
    };
  }
}

// 아이디 찾기 -> 인증번호 DB 저장
export function saveAuthNum(dataToSubmit) {
  const request = axios
    .post("/api/call/aligo/sms", dataToSubmit)
    .then((res) => res.data);

  return {
    type: SAVE_AUTH_NUM,
    payload: request,
  };
}

// 아이디 찾기 -> 인증번호 입력시 확인
export function getAuthNum(dataToSubmit) {
  const request = axios
    .post("/api/mypage/find/getauthnum", dataToSubmit)
    .then((res) => res.data);

  return {
    type: GET_AUTH_NUM,
    payload: request,
  };
}

// 아이디 찾기
export function findEmail(dataToSubmit) {
  const request = axios
    .post("/api/mypage/find/id", dataToSubmit)
    .then((res) => res.data);

  return {
    type: FIND_EMAIL,
    payload: request,
  };
}

// 관리주소 등록
export function addManageAddress(dataToSubmit) {
  const request = axios
    .post("/api/mypage/address/add", dataToSubmit)
    .then((res) => res.data);

  return {
    type: ADD_MANAGE_ADDRESS,
    payload: request,
  };
}

// 관리주소 수정
export function modifyManageAddress(dataToSubmit) {
  const request = axios
    .post("/api/mypage/address/modify", dataToSubmit)
    .then((res) => res.data);

  return {
    type: MODIFY_MANAGE_ADDRESS,
    payload: request,
  };
}

// 관리주소 닉네임 중복 확인
export function manageAddrNickChk(dataToSubmit) {
  const x_auth = getCookie("x_auth");
  // var x_auth = localStorage.getItem("x_auth");

  dataToSubmit.x_auth = x_auth;
  const request = axios
    .post("/api/mypage/address/checkMemname", dataToSubmit)
    .then((res) => res.data);

  return {
    type: MANAGE_ADDR_NICK_CHK,
    payload: request,
  };
}

// 읽지 않은 알림 개수 가져오기
export function getAlarmCount(type, dataToSubmit) {
  // dataToSubmit에는 db에서 조회 한 미확인 알림 개수 들고 옴
  let typeName = "";
  switch (type) {
    case "alarm":
      typeName = GET_ALARM_COUNT;
      break;
    case "inquiry":
      typeName = GET_INQUIRY_COUNT;
      break;
    case "counseling":
      typeName = GET_COUNSELING_COUNT;
      break;

    default:
      break;
  }
  return {
    type: typeName,
    payload: dataToSubmit,
  };
}
