import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { changeDateFormat, searchCashClear, searchDataSet } from "../../Common/lib";
import { useDispatch } from "react-redux";
import {
  boardConsulting,
  boardSearchConsulting,
  boardDivisionList,
} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";

function BoardConsult() {
  const dispatch = useDispatch();
  let history = useHistory();
  let match = useRouteMatch();
  let pagen = match.params.pagen;

  // 달력 시작일 셋팅
  const [searchDate, setSearchDate] = useState({
    start: "",
    end: "",
  });

  const handleSearchDate = (e) => {
    setSearchDate({ ...searchDate, [e.target.name]: e.target.value });
  };

  const [categoryList, setCategoryList] = useState([]);

  var x_type = localStorage.getItem("x_type");
  var admin_auth = localStorage.getItem("admin_auth");

  useLayoutEffect(() => {

    let body1 = {
      registerStart: searchDate.start,
      registerEnd: searchDate.end,
      gubun: searchGubun,
      open: searchOpenNclosed,
      status: searchReplyState,
      keyword: searchKeyword,
      x_type : x_type,
      admin_auth : admin_auth
    };

    let finalData = searchDataSet(body1);

    // 전체 글 가져오기
    dispatch(boardSearchConsulting(finalData)).then((res) => {
      if (res.payload.boardList) {
        const result = res.payload.boardList;
        setBoardList(result);
        setBoardListCnt(result.length);

        // state 저장
        setSearchDate({...searchDate,
          start : finalData.registerStart,
          end : finalData.registerEnd
        });
        setSearchGubun(finalData.gubun);
        setSearchOpenNclosed(finalData.open);
        setSearchKeyword(finalData.keyword);
        setSearchReplyState(finalData.status)
      }
    });

    let body2 = {
      kind: "무료법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body2)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategoryList(result);
      } else {
        setCategoryList([]);
      }
    });
  }, [dispatch]);

  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState(0);
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/board/consult/${pageNum}`);
  };

  // 구분 셋팅
  const [searchGubun, setSearchGubun] = useState("전체");
  const handleGubun = (e) => {
    setSearchGubun(e.target.value);
  };

  // 공개여부 셋팅
  const [searchOpenNclosed, setSearchOpenNclosed] = useState("전체");
  const handleOpenNclosed = (e) => {
    setSearchOpenNclosed(e.target.value);
  };

  // 답변상태 셋팅
  const [searchReplyState, setSearchReplyState] = useState("전체");
  const handleReplyState = (e) => {
    setSearchReplyState(e.target.value);
  };

  // 검색어 셋팅
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  // 검색하기
  const handleSearch = () => {
    let body = {
      registerStart: searchDate.start,
      registerEnd: searchDate.end,
      gubun: searchGubun,
      open: searchOpenNclosed,
      status: searchReplyState,
      keyword: searchKeyword,
      x_type : x_type,
      admin_auth : admin_auth
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(boardSearchConsulting(body)).then((res) => {
      if (res.payload.result) {
        history.replace(`/rf_zib_admin/board/consult/1`);
        const result = res.payload.boardList;
        setBoardList(result);

        if (result !== "false") {
          setBoardSearchListCnt(result.length);
        } else {
          setBoardSearchListCnt(0);
        }
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const pageOfItem = pageOfItems.map((item) => {
    return (
      <tr key={item.idx}>
        {/* <td>{item.idx}</td> */}
        <td>{item.rownum}</td>
        <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
        <td>{item.open ? "공개" : "비공개"}</td>
        <td>{item.memname}</td>
        <td>{item.gubun}</td>
        <td>
          <Link to={`/rf_zib_admin/board/consult/detail/${item.idx}/${pagen}`}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{item.status ? item.replyMemname : ""}</td>
        <td>
          {item.status
            ? changeDateFormat(item.replyRegisterdate, 5, ".", ":")
            : ""}
        </td>
        <td className={item.status ? "" : "stop"}>
          {item.status === 1 ? "완료" : item.status === 0 ? "대기" : "변호사 답변 대기"}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container" id="boardConsult">
          <div>
            <h2 className="main_container_name">
              <BrowserRouter forceRefresh={true}>
                <Link to="/rf_zib_admin/board/consult/1">무료법률상담 현황</Link>
              </BrowserRouter>

              <span className="total_column">
                전체 <span>{boardListCnt}</span>
              </span>
            </h2>
            <div className="user_search_column">
              <div className="first_column">
                <div className="search_regdate_column">
                  <span className="input_title">등록일</span>
                  <input
                    type="date"
                    name="start"
                    value={searchDate["start"]}
                    onChange={handleSearchDate}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    name="end"
                    value={searchDate["end"]}
                    onChange={handleSearchDate}
                  />
                </div>
                <div className="search_keyword_column">
                  <span className="input_title">검색</span>
                  <input
                    type="text"
                    value={searchKeyword}
                    placeholder="회원닉네임, 제목, 답변자명"
                    onChange={handleSearchKeyword}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
              <div className="second_column top">
                <div className="register_address">
                  <span
                    className="input_title"
                    style={{ display: "inline-block", width: 44 }}
                  >
                    구분
                  </span>

                  <select
                    onChange={handleGubun}
                    value={searchGubun}
                    id="searchGubun"
                  >
                    <option value="전체" >전체</option>
                    {categoryList.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="bar"></div>
                <div>
                  <span className="input_title">답변상태</span>

                  <input
                    type="radio"
                    id="radio1"
                    name="replyState"
                    value="전체"
                    onClick={handleReplyState}
                    checked={searchReplyState === "전체" ? true : false}
                  />
                  <label htmlFor="radio1">전체</label>
                  <input
                    type="radio"
                    id="radio2"
                    name="replyState"
                    value="1"
                    onClick={handleReplyState}
                    checked={searchReplyState === "1" ? true : false}
                  />
                  <label htmlFor="radio2">완료</label>
                  <input
                    type="radio"
                    id="radio3"
                    name="replyState"
                    value="0"
                    onClick={handleReplyState}
                    checked={searchReplyState === "0" ? true : false}
                  />
                  <label htmlFor="radio3">대기</label>
                  <input
                    type="radio"
                    id="radio4"
                    name="replyState"
                    value="2"
                    onClick={handleReplyState}
                    checked={searchReplyState === "2" ? true : false}
                  />
                  <label htmlFor="radio3">변호사 답변 대기</label>
                </div>
              </div>
              <div className="second_column">
                <div className="register_address"></div>
                <div className="bar"></div>
                <div>
                  <span className="input_title">공개여부</span>

                  <input
                    type="radio"
                    id="radio4"
                    name="openNclosed"
                    value="전체"
                    onClick={handleOpenNclosed}
                    checked={searchOpenNclosed === "전체" ? true : false}
                  />
                  <label htmlFor="radio4">전체</label>
                  <input
                    type="radio"
                    id="radio5"
                    name="openNclosed"
                    value="1"
                    onClick={handleOpenNclosed}
                    checked={searchOpenNclosed === "1" ? true : false}
                  />
                  <label htmlFor="radio5">공개</label>
                  <input
                    type="radio"
                    id="radio6"
                    name="openNclosed"
                    value="0"
                    onClick={handleOpenNclosed}
                    checked={searchOpenNclosed === "0" ? true : false}
                  />
                  <label htmlFor="radio6">비공개</label>
                </div>
              </div>
              <div className="admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    searchCashClear();
                    window.location.reload();
                  }}
                >
                  기본설정
                </div>
                <div className="admin_blue_btn btn" onClick={handleSearch}>
                  검색
                </div>
              </div>
            </div>

            <div className="user_list_column">
              <div className="title_column">
                <h3>목록</h3>
                {boardSearchListCnt ? (
                  <div className="search_result">
                    검색결과{" "}
                    <span> {boardSearchListCnt ? boardSearchListCnt : 0}</span>
                  </div>
                ) : (
                  <div className="search_result"></div>
                )}
              </div>

              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>등록일시</th>
                    <th>공개여부</th>
                    <th>회원닉네임</th>
                    <th>구분</th>
                    <th>제목</th>
                    <th>답변자명</th>
                    <th>답변 완료일</th>
                    <th>답변상태</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={9}>등록된 상담글이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PaginationNum
                items={boardList}
                onChangePage={onChangePage}
                getPageNum={getPageNum}
                initialPage={pagen++}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoardConsult;
