import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import MobileBottomNav from "./Footer/MobileBottomNav";
import {useMediaQuery} from "react-responsive";

function Maintenance() {

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header/>
        <div className="main center_main" id="unauthorized">
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <h1 className="join_title">현재 14:00 ~ 15:30(1시간 30분) 서비스 점검 중 입니다.</h1>
          <h1 className="join_title">작업시간 동안 서비스가 일시정지 되오니 양해 부탁드립니다.</h1>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          <div className="horizontal_line">
          </div>
          {isMobile ? <MobileBottomNav/> : <Footer/>}
        </div>
      </div>
    </>
  );
}

export default Maintenance;