import React, { useLayoutEffect, useRef, useState } from "react";
import "./JoinUser.css";
import warning from "../../images/warning.png";
import dropdown from "../../images/dropdown.png";
import close from "../../images/close.png";
import $ from "jquery";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import { formatDay, getCookie, checkSpecial, checkOnlyNumber } from "../../Common/lib";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { addManageAddress, auth } from "../../_actions/user_action";
import DaumPostcode from "react-daum-postcode";
import MobileBottomNav from "../Footer/MobileBottomNav";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../components/Calendar.css";
import dayjs from "dayjs";
import axios from "axios";
import {useScript} from "./naverWcslog";

function JoinAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [preventDoubleClick, setPreventDoubleClick] = useState(true);

  // juso.go.kr API
  const [keyword, setKeyword] = useState("");
  const [addrs, setAddrs] = useState([]);
  const [addrView, setAddrView] = useState(false);              // 주소리스트 영역
  const [addrDetailView, setAddrDetailView] = useState(false);  // 상세주소 영역
  const [addDetailDong, setAddDetailDong] = useState();         // 동 리스트
  const [addDetailFloor, setAddDetailFloor] = useState();       // 층 리스트
  const [addDetailHo, setAddDetailHo] = useState();             // 호 리스트

  // 주소 기본정보
  const [isAddress, setIsAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  // 주소 추가정보 [2021-12-28 박윤수]
  const [etcAddress, setEtcAddress] = useState();
  const [jusoDong, setJusoDong] = useState("");           // 선택한 동
  const [jusoFloor, setjusoFloor] = useState("");         // 선택한 층
  const [jusoHo, setjusoHo] = useState("");               // 선택한 호
  const [floorHo, setFloorHo] = useState();               // 층, 호수 state
  const [directView, setDirectView] = useState(true);     // 직접입력 체크박스 노출여부
  const [directCheck, setDirectCheck] = useState(false);  // 직접입력 체크박스
  const [directInput, setDirectInput] = useState("");     // 직접입력 input
  const [manageAdressWarning, setManageAdressWarning] = useState("");

  const onStartDateChange = (val) => {
    setStartDate(val);
    closeModal("modal5");
  };
  const onEndDateChange = (val) => {
    setEndDate(val);
    closeModal("modal5-1");
  };

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      // 현재 등록 개수가 1개 이상이면 마이페이지로 이동시킴
      if (0 < res.payload.memaddrcurjoin) {
        history.push("/mypage/address");
        return;
      } else {
        var loginurl = "/api/login/redissave";
        var loginbody = {
          memid: res.payload.memid,
        };
        // 로그인 요청
        axios.post(loginurl, loginbody).then((reslogin) => {});

        setIsLoginAuth(res.payload.isLogin);
        setMemname(res.payload.memname);
        setProfilePath(res.payload.profilesImg);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  // 부동산 닉네임
  const [realEstateName, setRealEstateName] = useState("");
  const handleRealEstateName = (e) => {
    setRealEstateName(e.target.value);
  };

  // 주소 찾기가 힘드시나요? 클릭시
  const helpAddress = () => {
    window.open("https://www.juso.go.kr/", "_blank");
  };

  // 모달창 셋팅
  const [modal, setModal] = useState({
    modal1: true,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    modal8: false,
  });

  // 만기일 알림
  const noticeStartLists = [
    "1개월 전부터",
    "2개월 전부터",
    "3개월 전부터",
    "6개월 전부터",
    "12개월 전부터",
  ];
  const noticeCycle = ["매주", "매일", "매월", "매년"];
  const [noticeStart, setNoticeStart] = useState("6개월 전부터");
  const [cycle, setCycle] = useState("매월");

  const [expire, setExpire] = useState(false); // 자가/전세 라디오 버튼 셋팅
  const [message, setMessage] = useState({
    title: "부동산 주소를 등록하시고 정보 변동 알림을 받으세요.",
    info: "모든 알림 서비스 이용료는 무료입니다.",
    warning: "",
  });
  // 달력 시작일/만기일 셋팅
  const [dateWarning, setdateWarning] = useState({
    start: "",
    end: "",
  });
  const [padding, setPadding] = useState(64); // 모달창 padding값

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 등록 완료 클릭 시
  const signUp = () => {
    // // 부동산 닉네임 입력
    if (isAddress === "") {
      setManageAdressWarning("주소를 검색해주세요.");
      return;
    } else if (realEstateName === "") {
      $(".real_estate_name").text("부동산 닉네임을 입력하세요.");
      handleFocus(2);
      return;
    } else if (checkSpecial(realEstateName)){
      $(".real_estate_name").text("특수문자 또는 공백이 포함되어있습니다.");
      handleFocus(2);
      return;
    } else if (checkOnlyNumber(realEstateName)){
      $(".real_estate_name").text("문자+숫자로 입력해주세요.");
      handleFocus(2);
      return;
    } else if (!$("#rd1").prop("checked") && !$("#rd2").prop("checked")) {
      $(".form_of_possession").text("소유 형태를 선택하세요.");
      $(".real_estate_name").text("");
      return;
    } else if ($("#rd2").prop("checked")) {
      //  임차 선택 시 계약일, 만기일 확인
      const formatStartDate = formatDay(startDate).split("/");
      const formatEndDate = formatDay(endDate).split("/");
      var newStartDate = new Date(
        formatStartDate[0],
        formatStartDate[1] - 1,
        formatStartDate[2]
      );
      var newEndDate = new Date(
        formatEndDate[0],
        formatEndDate[1] - 1,
        formatEndDate[2]
      );
      if (!(newStartDate.getTime() < newEndDate.getTime())) {
        setdateWarning({
          ...dateWarning,
          end: "계약일보다 빠른 날짜입니다. 다시 확인 바랍니다.",
        });
        return;
      }
    }
    // 더블 클릭 방지
    if (preventDoubleClick) {
      const x_auth = getCookie("x_auth");
      // let x_auth = localStorage.getItem("x_auth");
      let body = {
        x_auth: x_auth,
        addrnickname: realEstateName,
        addr: isAddress,
        addrsub: detailAddress,
        addrower: expire ? "임차" : "자가",
        addralarm: "on", // 기본 on
        addrmaturity: expire ? addrAlarm : "on", // 만기일 알림
        addrrentdate: expire ? formatDay(startDate) : "",
        addrrentedate: expire ? formatDay(endDate) : "",
        addralarmstart: expire
          ? addrAlarm === "on"
            ? noticeStart.replace("개월 전부터", "")
            : ""
          : "",
        addralarmcycle: expire ? (addrAlarm === "on" ? cycle : "") : "",
        bcode: etcAddress.bcode,      // 법정동 [2021-12-28 박윤수]
        bdcode: etcAddress.bdcode,    // 건물관리번호 [2021-12-28 박윤수]
        apt: etcAddress.apt,          // 공동주택 여부 [2021-12-28 박윤수]
        jusodong: jusoDong,           // 상세주소 동 [2022-03-11 박윤수]
        jusofloor: jusoFloor,         // 상세주소 층 [2022-03-11 박윤수]
        jusoho: jusoHo,               // 상세주소 호 [2022-03-11 박윤수]
      };

      console.log(body);
      dispatch(addManageAddress(body)).then((res) => {
        if (res.payload.result) {
          setPreventDoubleClick(false);
          // 모달창 변경
          setMessage({
            title: "관리 주소가 등록되었습니다.",
            info: "관리자가 주소확인 후 알림 설정이 완료됩니다.",
            warning: "(※ 등록일로부터 3영업일내 처리 예정)",
          });
          setPadding(35);
          openModal("modal2");
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    }
  };

  const ownOrHire = () => {
    $(".form_of_possession").text("");
    // 라디오 버튼 체크
    if ($("#rd2").prop("checked")) {
      setExpire(true);
    } else {
      setExpire(false);
    }
  };

  const [addrAlarm, setAddrAlarm] = useState("on");
  const switchOnOff = () => {
    // 알림 On/Off
    if ($("#switch1").prop("checked")) {
      setAddrAlarm("on");
      $(".notice_cycle").css("color", "#000000");
    } else {
      setAddrAlarm("off");
      $(".notice_cycle").css("color", "#999999");
    }
  };

  const openModal6 = () => {
    if ($("#switch1").prop("checked")) {
      openModal("modal6");
    }
  };

  const openModal7 = () => {
    if ($("#switch1").prop("checked")) {
      openModal("modal7");
    }
  };


  // 주소 검색
  const jusoSearch = () => { 
    const jusoUrl = "/api/join/jusoApi";
    var jusoBody = {
      keyword: keyword,
    };
    //상세박스가 열려있을수도 있으니 hidden / 초기화 [2022-02-15 박윤수]
    resetJuso();

    if(keyword.length > 5 ){
      axios.post(jusoUrl,jusoBody).then((res) => {
          //리스트 State에 등록 [2022-02-15 박윤수]
          setAddrs(res.data.juso); 
          setAddrView(true);
      }); //end of inner axios.post
    }else{
      alert("주소를 정확히 입력해주세요.");
    }
  };

  // 주소 검색 초기화
  const resetJuso = () => {
    setAddrDetailView(false);
    setDirectCheck(false);
    setDirectView(true);
    setAddDetailDong("");
    setAddDetailFloor("");
    setAddDetailHo("");
    setDirectInput("");
    setJusoDong("");
    setjusoFloor("");
    setjusoHo("");
    setFloorHo("");
  }

  // 주소 검색 키워드 Input
  const handleSetKeyword = (e) => {
    setKeyword(e.target.value);
  };

  // 상세주소 동 셀렉트박스
  const handleSetJusoDong = (e) => {
    setJusoDong(e.target.value);

    var val = e.target.value;
    var detailUrl = "/api/join/jusoDongApi";
    var detailBody = {
      admCd: etcAddress.bcode,
      rnMgtSn: etcAddress.rnMgtSn,
      udrtYn: etcAddress.udrtYn,
      buldMnnm: etcAddress.bdBonBun,
      buldSlno: etcAddress.buldSlno,
      searchType: "floor",
      dongNm:e.target.value,
    }
    // detail 데이터 호출
    axios.post(detailUrl,detailBody).then((res) => {
      //리스트 State에 등록 [2022-02-15 박윤수]
      setFloorHo(res.data.juso);
      let detailAddrArry = res.data.juso;
      let floor_arry = [];

      for(var i=0; i<detailAddrArry.length; i++){
        if(detailAddrArry[i].floorNm !== '' && floor_arry.indexOf(detailAddrArry[i].floorNm) === -1){
          if((val === '' || val === 'none') || (val !== '' && detailAddrArry[i].dongNm === val)){
            floor_arry.push(detailAddrArry[i].floorNm);
          }
        }
      }
      
      setAddDetailFloor(floor_arry);
    }); //end of inner axios.post

  };

  // 상세주소 층 셀렉트박스
  const handleSetJusoFloor = (e) => {
    setjusoFloor(e.target.value);
    let ho_arry = [];
    for(var i=0; i<floorHo.length; i++){
      if(floorHo[i].floorNm === e.target.value && floorHo[i].dongNm === jusoDong){
        ho_arry.push(floorHo[i].hoNm);
      }
    }
    setAddDetailHo(ho_arry);
  }

  // 상세주소 호 셀렉트박스
  const handleSetJusoHo = (e) => {
    setjusoHo(e.target.value);
  }

  // 주소리스트 선택시
  const selectAddrList = (e) => {
    let data = addrs[e];
    let apt = data.bdKdcd === 1 ? 'Y' : 'N';
    // 법정동, 건물관리번호, 공동주택 여부 추가 [2021-12-28 박윤수]
    let etcCode = { bcode:data.admCd, bdcode:data.bdMgtSn, apt:apt, rdNm:data.rn, bdBonBun:data.buldMnnm, rnMgtSn:data.rnMgtSn, udrtYn:data.udrtYn, buldSlno:data.buldSlno }  
    setEtcAddress(etcCode);
    setIsAddress(data.roadAddr);
    setDetailAddress("");
    if(data.detBdNmList === ""){
      setManageAdressWarning("");
      setDirectView(false);
      setDirectCheck(true);
      setAddrDetailView(true);
    }else{
      // 상세주소가 있으면
      var detailUrl = "/api/join/jusoDongApi";
      var detailBody = {
        admCd: data.admCd,
        rnMgtSn: data.rnMgtSn,
        udrtYn: data.udrtYn,
        buldMnnm: data.buldMnnm,
        buldSlno: data.buldSlno,
        searchType: "dong",
      }
      // detail 데이터 호출
      axios.post(detailUrl,detailBody).then((res) => {
        //리스트 State에 등록 [2022-02-15 박윤수]
        setAddDetailDong(res.data.juso); 
        setAddrDetailView(true);
      }); //end of inner axios.post
    }
  }

  //주소팝업 완료버튼 클릭시
  const selectAddrDetailList = () => {
    const url = "/api/join/jusoBdcode";
    let detailAddr = "";
    var body = {
      rdNm:etcAddress.rdNm,
      bdBonBun: etcAddress.bdBonBun,
      dtdBdNm:jusoDong
    };
    if(directCheck === false){
      if(jusoDong !== "" && jusoFloor !== "" && jusoHo !== ""){
        detailAddr = jusoDong + " ";
        axios.post(url,body).then((res) => {
          if(res.data[0] !== undefined){
            let apt = res.data[0].mluWh === 1 ? 'Y' : 'N';
            setEtcAddress({...etcAddress, bdcode: res.data[0].bdMngNo, bcode: res.data[0].bjdCd, apt: apt });
          }
          detailAddr += jusoFloor + " " + jusoHo;
          setDetailAddress(detailAddr);
          closeModal("modal8");
        }); //end of inner axios.post
      }else{
        alert("주소를 선택해주세요.");
      }
    }else{
        setDetailAddress(directInput);
        closeModal("modal8");
    }
  }

  // 상세 주소 Input
  const handleDetailAddress = (e) => {
    setDetailAddress(e.target.value);
  };

  // 직접입력 체크
  const handleDirect = () =>{
    setDirectCheck(directCheck => !directCheck); // on,off 개념
  }

  // 직접입력 상세주소
  const handleDirectInput = (e) => {
    setDirectInput(e.target.value);
  }

  // 다음 검색시작 ※현재 사용안함 [2022-02-17 박윤수]
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    
    // 법정동, 건물관리번호, 공동주택 여부 추가 [2021-12-28 박윤수]
    let etcCode = { bcode:data.bcode, bdcode:data.buildingCode, apt:data.apartment}  
    setEtcAddress(etcCode);
    
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setIsAddress(fullAddress);
    setManageAdressWarning("");
    closeModal("modal4");
  };

  //네이버 전환 스크립트 적용
  useScript();

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header
          isLoginAuth={isLoginAuth}
          memname={memname}
          profilePath={profilePath}
        />
        <div className="main center_main" id="joinAdd">
          <div className="isMobile_joinAdd">
            <h1 className="main_title">부동산 관리주소 등록</h1>

            <h2 className="sub_title">
              부동산 주소를 등록하시고 <br />
              정보 변동에 대한 알림 서비스를 받으세요!
            </h2>

            <div className="main_title_info">
              모든 알림 서비스는 무료로 제공됩니다.
            </div>
          </div>
          <form>
            <div className="isMobile_joinAdd">
              <div className="isMobile_search_btn column">
                <div className="input_name">
                  알림 서비스 대상 관리 주소 등록
                </div>
                <div
                  className="check_btn adree"
                  onClick={() => {openModal("modal8");resetJuso();}}
                >
                  주소 검색하기
                </div>
              </div>
              <div className="column manage_adress">
                <div className="title">
                  <div className="input_name">관리주소</div>
                  <div className="help" onClick={helpAddress}>
                    주소 찾기가 힘드시나요?
                  </div>
                </div>
                <input
                  onClick={() => {openModal("modal8");resetJuso();}}
                  type="text"
                  placeholder="주소를 입력하세요."
                  className="wide"
                  value={isAddress}
                  readOnly
                />
              </div>
              <div className="column">
                <div className="isMobile input_name">
                  상세주소 <span className="warning">*</span>
                </div>
                <div className="input_column" style={{ marginTop: 6 }}>
                  <input
                    type="text"
                    placeholder="상세주소를 입력해주세요."
                    onChange={handleDetailAddress}
                    value={detailAddress}

                  />
                </div>
              </div>
              <div className="manage_adress warning">{manageAdressWarning}</div>
              <div className="column">
                <div className="isMobile input_name">
                  부동산 닉네임 <span className="warning">*</span>
                </div>
                <div className="input_column">
                  <input
                    type="text"
                    placeholder="문자+숫자로 닉네임을 입력하세요."
                    onChange={handleRealEstateName}
                    ref={(elem) => (inputRefs.current[2] = elem)}
                  />
                </div>
              </div>
              <div className="real_estate_name warning"></div>
              <div className="column own_radio_container">
                <div className="isMobile input_name">
                  소유형태 <span className="warning">*</span>
                </div>
                <div className="own_radio_column">
                  <div className="radio_wrap left">
                    <input
                      id="rd1"
                      type="radio"
                      name="radio1"
                      onChange={ownOrHire}
                    />
                    <label htmlFor="rd1">
                      <span>자가</span>
                    </label>
                  </div>
                  <div className="radio_wrap">
                    <input
                      id="rd2"
                      type="radio"
                      name="radio1"
                      onChange={ownOrHire}
                    />
                    <label htmlFor="rd2">
                      <span>임차(전/월세)</span>
                    </label>
                  </div>
                </div>
                <div className="form_of_possession warning"></div>
              </div>
            </div>
            {expire ? (
              <div className="add_rental_contract">
                <h3>임대차 계약 만기일을 등록하세요.</h3>
                <div className="title_info">
                  원하시는 기간을 설정하고 알림 서비스를 받으세요.
                </div>
                <div className="sub_column">
                  <div className="sub_name">임대차 계약일</div>
                  <input
                    type="text"
                    value={formatDay(startDate)}
                    className="calendar"
                    readOnly
                    onClick={() => openModal("modal5")}
                  />
                </div>
                <div className="sub_column">
                  <div className="sub_name">임대차 만기일</div>
                  <input
                    type="text"
                    value={formatDay(endDate)}
                    className="calendar"
                    readOnly
                    onClick={() => openModal("modal5-1")}
                  />
                </div>
                <div className="column">
                  <div className="input_name"></div>
                  <span className="warning">{dateWarning["end"]}</span>
                </div>
                <div className="hr"></div>

                <div className="isMobile sub_column">
                  <div className=" sub_name">알림 On/Off</div>
                  <div className="column__on-off">
                    <input
                      type="checkbox"
                      id="switch1"
                      name="switch1"
                      className="input__on-off"
                      defaultChecked
                      onChange={switchOnOff}
                    />
                    <label htmlFor="switch1" className="label__on-off">
                      <span className="marble"></span>
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </label>
                  </div>
                </div>

                <div className="sub_column">
                  <div className="sub_name">알림 시작일</div>
                  <div className="notice_cycle btn" onClick={openModal6}>
                    {noticeStart}
                    <img src={dropdown} alt="dropdown" />
                  </div>
                </div>

                <div className="sub_column">
                  <div className="sub_name">알림 주기</div>
                  <div className="notice_cycle btn" onClick={openModal7}>
                    {cycle}
                    <img src={dropdown} alt="dropdown" />
                  </div>
                </div>

                <div className="warning_column">
                  <img src={warning} alt="warning" className="warning_icon" />
                  <div className="warning_message">
                    묵시적 갱신기간은 주택 임대차 종료일 6개월 전부터<br /> 2개월 전까지입니다. (상가 임대차 종료일 6개월 전부터 1개월 전까지)
                  </div>
                </div>
              </div>
            ) : null}

            <div className="btn_column">
              <div
                className="cancel btn left"
                onClick={() => openModal("modal3")}
              >
                취소
              </div>
              <div className="register btn" onClick={signUp}>
                등록 완료
              </div>
            </div>
          </form>
          <Modal open={modal["modal1"]} width={400}>
            <div
              className="modal_inner_ct modal_400"
              style={{ paddingLeft: padding, paddingRight: padding }}
            >
              <h2>{message["title"]}</h2>
              <div className="modal_info">{message["info"]}</div>
              <div className="modal_warning">{message["warning"]}</div>
              <div
                className="blue_btn btn close"
                onClick={() => closeModal("modal1")}
              >
                확인
              </div>
            </div>
          </Modal>
          <Modal open={modal["modal2"]} width={400}>
            <div
              className="modal_inner_ct modal_400"
              style={{ paddingLeft: padding, paddingRight: padding }}
            >
              <h2>{message["title"]}</h2>
              <div className="modal_info">{message["info"]}</div>
              <div className="modal_warning">{message["warning"]}</div>
              <div
                className="blue_btn btn close"
                onClick={() => {
                  // history.push("/");
                  window.location.href = "/";
                }}
              >
                확인
              </div>
            </div>
          </Modal>
          <Modal open={modal["modal3"]} width={400}>
            <div
              className="modal_inner_ct modal_400 exit_modal"
              style={{ paddingLeft: padding, paddingRight: padding }}
            >
              <h2>
                관리 주소 등록을 <br />
                취소 하시겠습니까?
              </h2>
              <div className="btn_column">
                <Link to="/join/finish">
                  <div className="cancel btn left">취소</div>
                </Link>
                <div
                  className="blue_btn btn close"
                  onClick={() => closeModal("modal3")}
                >
                  계속 입력
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={modal["modal4"]}
            width={500}
            center={true}
            close={() => closeModal("modal4")}
          >
            <DaumPostcode
              onComplete={handleComplete}
              style={{ height: 480, padding: 7 }}
            />
          </Modal>
          <Modal open={modal["modal5"]} width={600}>
            <div className="modal_inner_ct wide_modal calendar_modal">
              <img
                src={close}
                alt="close"
                className="close btn"
                onClick={() => closeModal("modal5")}
              />
              <Calendar
                onChange={onStartDateChange}
                value={startDate}
                calendarType="US"
                formatDay={(locale, date) => dayjs(date).format("D")}
              />
            </div>
          </Modal>
          <Modal open={modal["modal5-1"]} width={600}>
            <div className="modal_inner_ct wide_modal calendar_modal">
              <img
                src={close}
                alt="close"
                className="close btn"
                onClick={() => closeModal("modal5-1")}
              />
              <Calendar
                onChange={onEndDateChange}
                value={endDate}
                calendarType="US"
                formatDay={(locale, date) => dayjs(date).format("D")}
              />
            </div>
          </Modal>
          <Modal
            open={modal["modal6"]}
            width={400}
            close={() => closeModal("modal6")}
          >
            <div className="modal_inner_ct modal_400 notice_alram">
              {noticeStartLists.map((list, index) => (
                <div
                  key={index}
                  className={
                    noticeStart === list ? "notice_list active" : "notice_list"
                  }
                  onClick={() => {
                    setNoticeStart(list);
                    closeModal("modal6");
                  }}
                >
                  {list}
                </div>
              ))}
            </div>
          </Modal>
          <Modal
            open={modal["modal7"]}
            width={400}
            close={() => closeModal("modal7")}
          >
            <div className="modal_inner_ct modal_400 notice_alram">
              {noticeCycle.map((list, index) => (
                <div
                  key={index}
                  className={
                    cycle === list ? "notice_list active" : "notice_list"
                  }
                  onClick={() => {
                    setCycle(list);
                    closeModal("modal7");
                  }}
                >
                  {list}
                </div>
              ))}
            </div>
          </Modal>
          <Modal
            open={modal["modal8"]}
            width={500}
            close={() => closeModal("modal8")}
          >
            <div className="modal_inner_ct modal_500 pop-address-search">
              <div className="pop-address-search-inner">
                <div className="search-wrap" style={{ marginTop: 6 }}>
                <span class="wrap">
                  <input type="text" 
                    className="popSearchInput" 
                    placeholder="주소를 입력해주세요." 
                    onChange={handleSetKeyword} 
                    onKeyPress={(e)=>{
                      if(e.key === 'Enter'){jusoSearch()}
                    }}
                  />  
							    <input type="button" title="검색" onClick={jusoSearch} style={{cursor:'pointer'}}/>
						    </span>
                </div>
                <div className="result">
                  {
                    addrView ? <table className="data-col"> 
                    <thead> 
                      <tr> 
                        <th>번호</th> 
                        <th style={{width:"100vw"}}>도로명 주소</th> 
                        <th>우편번호</th> 
                      </tr>
                    </thead>
                    <tbody> 
                      {addrs.map((row,index) => ( 
                      <tr key={row.roadAddr}> 
                        <td style={{verticalAlign:"middle"}}>{index+1}</td> 
                        <td><div onClick={()=>{selectAddrList(index)}} style={{cursor:"pointer"}}><span className="list-roadAddr">{row.roadAddr}</span><br/><span className="list-jibunAddr">[지번] {row.jibunAddr}</span></div></td> 
                        <td style={{verticalAlign:"middle"}}>{row.zipNo}</td> 
                      </tr> 
                      ))} 
                      </tbody> 
                    </table>
                    : null
                  }
                </div>
                {addrDetailView ? 
                <div className="detail"> 
                    <table>
                      <tbody>
                          <tr>
                            <td className="titleTd">선택한 주소</td>
                            <td style={{verticalAlign:"middle"}}><span style={{fontSize:13,paddingLeft:10}}>{isAddress}</span></td>
                          </tr>
                          <tr>
                            <td className="titleTd">상세주소{!directCheck ? "(동선택)":null}</td>
                            <td style={{textAlign:"left"}}>
                              {!directCheck ?
                              <div>
                                <select onChange={handleSetJusoDong} value={jusoDong}>
                                  <option value="">동선택</option>
                                {addDetailDong.map((row,index) => (
                                  <option key={index} value={row.dongNm}>{row.dongNm}</option>
                                ))}
                                </select>
                                {Array.isArray(addDetailFloor)?
                                <select onChange={handleSetJusoFloor} value={jusoFloor}>
                                  <option value="">층선택</option>
                                {addDetailFloor.map((row,index) => (
                                  <option key={index} value={row}>{row}</option>
                                ))}
                                </select>
                                :null}
                                {Array.isArray(addDetailHo)?
                                <select onChange={handleSetJusoHo} value={jusoHo}>
                                  <option value="">호선택</option>
                                {addDetailHo.map((row,index) => (
                                  <option key={index} value={row}>{row}</option>
                                ))}
                                </select>
                                :null}
                              </div>:
                              <div>
                                <input type="text"
                                  onChange={handleDirectInput}
                                />
                              </div>
                              }
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style={{textAlign:"left"}}>
                            {directView ? 
                            <div className="checkbox_wrap main" style={{marginTop:5}}>
                            <input
                              id="direct"
                              type="checkbox"
                              name="direct"
                              checked={directCheck}
                              onChange={handleDirect}
                            />
                            <label htmlFor="direct">직접입력</label>
                            </div>
                            :null}
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    <div className="btn-area">
                      <div className="blue_btn btn close" style={{width:"100%",cursor:"pointer"}} onClick={()=>{selectAddrDetailList()}}>완료</div>
                    </div>
                </div>:null}
              </div>
            </div>
          </Modal>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default JoinAdd;