import React, {useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {adminConstruction, adminConstructionLogAdd} from "../../_actions/admin_board_action";

function SetConstruction(){
    const dispatch = useDispatch();
    const [isworking, setIsworking] = useState(false); //작업중 이면 true, 평소는 false (2022-07-11)

    //기존 값 불러오기
    useLayoutEffect(() => {
        dispatch(adminConstruction()).then((res) => {
            if(res.payload.isworking){
                const result = res.payload.isworking[0];
                if(result.isworking === 'false'){
                    setIsworking(false);
                }else {
                    setIsworking(true);
                }
            }
        });
    },[dispatch]);

    //input 값 제어
    const inputHandle = (e) => {
        setIsworking(!isworking);
    }

    //전송하기
    const submitHandler = () => {
        console.log("save click!");
        let admin_auth = localStorage.getItem("admin_auth");

        let body = {
            admin_auth : admin_auth,
            isworking : isworking,
        };

        dispatch(adminConstructionLogAdd(body)).then((res) => {
            console.log(res.payload.result);
            if(res.payload.result === "success"){
                alert("저장되었습니다.");
            } else {
                alert("잠시후 다시 시도해주세요.");
            }
        });
    };

    return (
        <>
            <Header />
            <div className="admin_container" id="addressDetail">
                <SideMenu tabActive={4}/>
                <div className="main_container" id="setVersion">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16}}>
                                작업공지
                            </h2>
                        </div>
                        <div className="address_detail">
                            <table className="detail_table version_table">
                                <tbody>
                                    <tr>
                                        <th>작업공지 ON/OFF</th>
                                        <td>
                                            <div className="input_column">
                                                <div className="column__on-off">
                                                    <input
                                                        type="checkbox"
                                                        id="construction"
                                                        name="construction"
                                                        className="input__on-off"
                                                        onChange={inputHandle}
                                                        checked={isworking}
                                                    />
                                                    <label htmlFor="construction" className="label__on-off">
                                                        <span className="marble"></span>
                                                        <span className="on">ON</span>
                                                        <span className="off">OFF</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div className="admin_blue_btn btn" onClick={submitHandler}>저장</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetConstruction
