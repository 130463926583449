import React, { useState } from "react";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import UserLawyerBasic from "./UserLawyerBasic";
import UserLawyerWrite from "./UserLawyerWrite";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  userLawyerDelete,
  userLawyerModifyStatus,
} from "../../_actions/admin_board_action";

function UserLawyerDetail() {
  let match = useRouteMatch();
  let history = useHistory();
  const idx = match.params.idx;
  const dispatch = useDispatch();

  const obj = {
    0: <UserLawyerBasic />,
    1: <UserLawyerWrite />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  const stopAdmin = () => {
    let body = {
      idx: idx,
    };
    dispatch(userLawyerModifyStatus(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("이용정지 되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const deleteAdmin = () => {
    let body = {
      idx: idx,
    };
    dispatch(userLawyerDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제 되었습니다.");
        history.goBack();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={1} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">변호사 상세 정보</h2>
                <div className="user_control_column">
                  <div
                    className="admin_delete_btn btn left"
                    onClick={stopAdmin}
                  >
                    이용정지
                  </div>
                  <div className="admin_delete_btn btn" onClick={deleteAdmin}>
                    삭제
                  </div>
                </div>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  기본정보
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  등록 글
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserLawyerDetail;
