import axios from "axios";
import {
  ADMIN_ALLOW,
  ADMIN_BOARD_CONSULTING,
  ADMIN_BOARD_SEARCH_CONSULTING,
  ADMIN_USER_LIST,
  ADMIN_SEARCH_USER,
  ADMIN_BOARD_DIVISION_LIST,
  ADMIN_BOARD_DIVISION_DELETE,
  ADMIN_BOARD_DIVISION_ADD,
  ADMIN_BOARD_CONSULT_DETAIL,
  SERVICE_EVENT_ADD,
  BOARD_CONSULT_REPLY,
  BOARD_CONSULT_REPLY_DELETE,
  BOARD_CONSULT_DELETE,
  ADMIN_BOARD_BLOG,
  ADMIN_BLOG_ADD,
  ADMIN_BLOG_DETAIL,
  ADMIN_BLOG_MODIFY,
  ADMIN_BLOG_IMG_DELETE,
  ADMIN_BLOG_DELETE,
  ALARM_MEMBER,
  USER_FREE_DETAIL_ADDRESS,
  ADDRESS_ALL,
  USER_ADDRESS_DETAIL,
  ADDRESS_MODIFY,
  ADMIN_BOARD_SEARCH_BLOG,
  ADDRESS_SEARCH,
  ADDRESS_CALL_API,
  ADDRESS_CALL_EVENT_API,
  ADDRESS_CALL_OWNER_API,
  ADDRESS_CALL_PDF_API,
  USER_ADMIN_ADD,
  USER_ADMIN_LIST,
  USER_ADMIN_SEARCH,
  USER_ADMIN_DETAIL,
  USER_ADMIN_MODIFY,
  USER_ADMIN_MODIFY_PASSWORD,
  USER_ADMIN_MODIFY_PASSWORD_RESET,
  USER_ADMIN_MODIFY_STATUS,
  USER_ADMIN_DELETE,
  USER_LAWYER_ADD,
  USER_LAWYER_LIST,
  USER_LAWYER_SEARCH,
  USER_LAWYER_DETAIL,
  USER_LAWYER_MODIFY_PASSWORD,
  USER_LAWYER_MODIFY_PASSWORD_RESET,
  USER_LAWYER_MODIFY_STATUS,
  USER_LAWYER_DELETE,
  USER_LAWYER_IMG_DELETE,
  USER_LAWYER_MODIFY,
  USER_LAWYER_REPLY,
  ADMIN_NOTICE_ADD,
  USER_ADDRESS_DELETE,
  ADMIN_NOTICE_LIST,
  ADMIN_NOTICE_DETAIL,
  ADMIN_NOTICE_MODIFY,
  ADMIN_NOTICE_DELETE,
  ADMIN_FAQ_LIST,
  ADMIN_FAQ_ADD,
  ADMIN_FAQ_DETAIL,
  ADMIN_FAQ_MODIFY,
  ADMIN_FAQ_DELETE,
  ADMIN_EVENT_LIST,
  ADMIN_EVENT_DETAIL,
  ADMIN_EVENT_MODIFY,
  ADMIN_EVENT_DELETE,
  ADMIN_INQUIRY_LIST,
  ADMIN_INQUIRY_DETAIL,
  ADMIN_INQUIRY_ADD_REPLY,
  ADMIN_INQUIRY_DELETE,
  USER_OUT_DETAIL_ADDRESS,
  ALARM_OUT_MEMBER,
  ADMIN_SEARCH_USER_OUT,
  ADMIN_NOTICE_DELETEIMG,
  ADMIN_EVENT_DELETEIMG,
  ADMIN_SEND_MAIL,
  ADMIN_SEND_SMS,
  ADMIN_SMS_HISTORYLIST,
  ADMIN_MAIL_SCHEDULELIST,
  ADMIN_MAIL_HISTORYLIST,
  ADMIN_MAIL_SCHEDULE_DETAIL,
  ADMIN_MAIL_SCHEDULE_MODIFY,
  ADMIN_MAIL_HISTORY_DETAIL,
  ADMIN_MAIL_POPUP_USERS,
  ADMIN_RESEND_MAIL_DETAIL,
  ADMIN_MAIL_SCHEDULE_DELETE,
  ADMIN_CONSTRUCTION_LOG_ADD, ADMIN_CONSTRUCTION,
  

} from "./types";

/**
 * 어드민 허용 ip여부 확인
 *  payload: {
 *    result,
 *    isAdminId,
 *    isAllowIp,
 *  }
 * */
export function isAdminAllow(dataToSubmit) {
  const request = axios
    .post("/api/admin/allow", dataToSubmit)
    .then((res) => res.data);

  return {
    type: ADMIN_ALLOW,
    payload: request,
  }
}

// 무료 회원 리스트
export function userList() {
  let dataToSubmit = {};
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/user/free", body)
    .then((res) => res.data);

  return {
    type: ADMIN_USER_LIST,
    payload: request
  };
}

// 무료회원 검색
export function searchUser(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/user/free/searchUser", body)
    .then((res) => res.data);

  return {
    type: ADMIN_SEARCH_USER,
    payload: request,
  };
}

// 탈퇴 회원 리스트
export function useroutList() {
  let dataToSubmit = {};
  let body = admin_auth_add(dataToSubmit);
  const request = axios.get("/api/admin/user/out", body).then((res) => res.data);

  return {
    type: ADMIN_USER_LIST,
    payload: request,
  };
}

// 탈퇴회원 검색
export function searchUserout(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/user/out/searchUser", body)
    .then((res) => res.data);

  return {
    type: ADMIN_SEARCH_USER_OUT,
    payload: request,
  };
}

// 무료법률상담 리스트
export function boardConsulting() {
  let dataToSubmit = {};
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .get("/api/admin/board/consulting", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_CONSULTING,
    payload: request,
  };
}

// 부동산정보 리스트
export function boardBlog() {
  let dataToSubmit = {};
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/address/blog", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_BLOG,
    payload: request,
  };
}

// 부동산정보 검색
export function boardSearchBlog(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/address/blog/searchBlog", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_SEARCH_BLOG,
    payload: request,
  };
}

// 무료법률상담 검색
export function boardSearchConsulting(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/board/searchConsulting", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_SEARCH_CONSULTING,
    payload: request,
  };
}

// 무료법률상담 글내용
export function boardConsultDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/board/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_CONSULT_DETAIL,
    payload: request,
  };
}

// 무료법률상담 댓글 작성
export function boardConsultReply(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/board/addReply", body)
    .then((res) => res.data);

  return {
    type: BOARD_CONSULT_REPLY,
    payload: request,
  };
}

// 무료법률상담 댓글 삭제
export function boardConsultReplyDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/board/delReply", body)
    .then((res) => res.data);

  return {
    type: BOARD_CONSULT_REPLY_DELETE,
    payload: request,
  };
}

// 무료법률상담 게시글 삭제
export function boardConsultDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/board/delConsulting", body)
    .then((res) => res.data);

  return {
    type: BOARD_CONSULT_DELETE,
    payload: request,
  };
}

// 카테고리 가져오기
export function boardDivisionList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/category/get", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_DIVISION_LIST,
    payload: request,
  };
}

// 카테고리 추가
export function boardDivisionAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/category/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_DIVISION_ADD,
    payload: request,
  };
}

// 카테고리 삭제
export function boardDivisionDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/category/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BOARD_DIVISION_DELETE,
    payload: request,
  };
}

// 이벤트 등록
export function serviceEventAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/service/event/add", body)
    .then((res) => res.data);

  return {
    type: SERVICE_EVENT_ADD,
    payload: request,
  };
}

// 블로그 등록
export function adminBlogAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/address/blog/add", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BLOG_ADD,
    payload: request,
  };
}

// 블로그 조회
export function adminBlogDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/address/blog/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BLOG_DETAIL,
    payload: request,
  };
}

// 블로그 수정
export function adminBlogModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/address/blog/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BLOG_MODIFY,
    payload: request,
  };
}

// 블로그 사진삭제
export function adminBlogImgDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/address/blog/deleteImg", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BLOG_IMG_DELETE,
    payload: request,
  };
}

// 블로그 삭제
export function adminBlogDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/address/blog/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_BLOG_DELETE,
    payload: request,
  };
}

// 알림 리스트
export function alarmMember(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/alarm/member", body)
    .then((res) => res.data);
  return {
    type: ALARM_MEMBER,
    payload: request,
  };
}

// 관리주소
export function userFreeDetailAddress(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit); 
  const request = axios
    .post("/api/admin/user/free/detail/address", body)
    .then((res) => res.data);

  return {
    type: USER_FREE_DETAIL_ADDRESS,
    payload: request,
  };
}

export function userAddressDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/user/address/detail", body)
    .then((res) => res.data);

  return {
    type: USER_ADDRESS_DETAIL,
    payload: request,
  };
}

// 해당 관리주소 삭제
export function userAddressDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .post("/api/admin/user/address/delete", body)
    .then((res) => res.data);

  return {
    type: USER_ADDRESS_DELETE,
    payload: request,
  };
}

// 관리주소 전체 리스트
export function addressAll(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
    .get("/api/admin/user/address/all", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_ALL,
    payload: request,
  };
}

// 관리주소 수정
export function addressModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/address/modify", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_MODIFY,
    payload: request,
  };
}

// 관리주소 경매배당기일 수정
export function addressVerstdate(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/address/verstdate", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_MODIFY,
    payload: request,
  };
}

// 관리주소 검색
export function addressSearch(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/address/searchAddr", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_SEARCH,
    payload: request,
  };
}

// 관리주소 고유번호 API 호출
export function addressCallAPI(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/call/refine/address", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_CALL_API,
    payload: request,
  };
}

// 관리주소 관계인 정보 일치여부 확인용 API 호출
export function addressCallEventAPI(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/call/address/detail/refine/registration", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_CALL_EVENT_API,
    payload: request,
  };
}
// 관리주소 소유자 추출 API
export function addressCallOwnerAPI(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/call/address/detail/refine/ownerlist", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_CALL_OWNER_API,
    payload: request,
  };
}

// 등기부등복 PDF 호출 API
export function pdfCallAPI(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/address/callPdf", body)
    .then((res) => res.data);

  return {
    type: ADDRESS_CALL_PDF_API,
    payload: request,
  };
}




// 관리자 등록
export function userAdminAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/add", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_ADD,
    payload: request,
  };
}

// 관리자 리스트
export function userAdminList() {
  const request = axios.get("/api/admin/user/admin").then((res) => res.data);

  return {
    type: USER_ADMIN_LIST,
    payload: request,
  };
}

// 관리자 검색
export function userAdminSearch(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/searchUser", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_SEARCH,
    payload: request,
  };
}

// 관리자 상세
export function userAdminDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/detail", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_DETAIL,
    payload: request,
  };
}

// 관리자 수정
export function userAdminModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/modify", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_MODIFY,
    payload: request,
  };
}

// 관리자 비밀번호 수정
export function userAdminModifyPassword(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/modify/password", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_MODIFY_PASSWORD,
    payload: request,
  };
}

// 관리자 비밀번호 오류 초기화
export function userAdminModifyPasswordReset(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/modify/password/reset", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_MODIFY_PASSWORD_RESET,
    payload: request,
  };
}

// 관리자 이용정지
export function userAdminModifyStatus(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/modify/status", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_MODIFY_STATUS,
    payload: request,
  };
}

// 관리자 유저 삭제
export function userAdminDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/admin/delete", body)
    .then((res) => res.data);

  return {
    type: USER_ADMIN_DELETE,
    payload: request,
  };
}

// 변호사 등록
export function userLawyerAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/add", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_ADD,
    payload: request,
  };
}

// 변호사 리스트
export function userLawyerList() {
  const request = axios.get("/api/admin/user/lawyer").then((res) => res.data);

  return {
    type: USER_LAWYER_LIST,
    payload: request,
  };
}

// 변호사 검색
export function userLawyerSearch(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/searchUser", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_SEARCH,
    payload: request,
  };
}

// 변호사 상세
export function userLawyerDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/detail", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_DETAIL,
    payload: request,
  };
}

// 변호사 비밀번호 수정
export function userLawyerModifyPassword(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/modify/password", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_MODIFY_PASSWORD,
    payload: request,
  };
}

// 변호사 비밀번호 오류 초기화
export function userLawyerModifyPasswordReset(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/modify/password/reset", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_MODIFY_PASSWORD_RESET,
    payload: request,
  };
}

// 변호사 이용정지
export function userLawyerModifyStatus(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/modify/status", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_MODIFY_STATUS,
    payload: request,
  };
}

// 변호사 유저 삭제
export function userLawyerDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/delete", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_DELETE,
    payload: request,
  };
}

// 변호사 사진삭제
export function adminLawyerImgDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/modify/deleteImg", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_IMG_DELETE,
    payload: request,
  };
}

// 변호사 수정
export function userLawyerModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/modify", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_MODIFY,
    payload: request,
  };
}

// 변호사 작성글 목록
export function userLawyerReply(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/lawyer/detail/reply", body)
    .then((res) => res.data);

  return {
    type: USER_LAWYER_REPLY,
    payload: request,
  };
}

// 공지사항 등록
export function adminNoticeAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/notice/add", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_ADD,
    payload: request,
  };
}

// 공지사항 리스트
export function adminNoticeList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios

    .post("/api/admin/service/notice", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_LIST,
    payload: request,
  };
}

// 공지사항 개별조회
export function adminNoticeDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/notice/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_DETAIL,
    payload: request,
  };
}

// 공지사항 사진 삭제
export function adminNoticeDeleteImg(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/notice/deleteImg", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_DELETEIMG,
    payload: request,
  };
}

// 공지사항 수정
export function adminNoticeModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/notice/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_MODIFY,
    payload: request,
  };
}

// 공지사항 삭제
export function adminNoticeDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/notice/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_NOTICE_DELETE,
    payload: request,
  };
}

// 자주하는질문 리스트
export function adminFaqList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/question", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_LIST,
    payload: request,
  };
}

// 자주하는질문 등록
export function adminFaqAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/question/add", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_ADD,
    payload: request,
  };
}

// 자주하는질문 개별조회
export function adminFaqDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/question/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_DETAIL,
    payload: request,
  };
}

// 자주하는질문 수정
export function adminFaqModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/question/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_MODIFY,
    payload: request,
  };
}

// 자주하는질문 삭제
export function adminFaqDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/question/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_DELETE,
    payload: request,
  };
}


// 법률상담faq 리스트
export function adminLawList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/law", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_LIST,
    payload: request,
  };
}

// 법률상담faq 등록
export function adminLawAdd(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/law/add", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_ADD,
    payload: request,
  };
}

// 법률상담faq 개별조회
export function adminLawDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/law/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_DETAIL,
    payload: request,
  };
}

// 법률상담faq 수정
export function adminLawModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/law/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_MODIFY,
    payload: request,
  };
}

// 법률상담faq 삭제
export function adminLawDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/law/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_FAQ_DELETE,
    payload: request,
  };
}

// 이벤트 리스트
export function adminEventList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/event", body)
    .then((res) => res.data);
  return {
    type: ADMIN_EVENT_LIST,
    payload: request,
  };
}

// 이벤트 개별조회
export function adminEventDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/event/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_EVENT_DETAIL,
    payload: request,
  };
}

// 이벤트 수정
export function adminEventModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/event/modify", body)
    .then((res) => res.data);

  return {
    type: ADMIN_EVENT_MODIFY,
    payload: request,
  };
}

// 이벤트 삭제
export function adminEventDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/event/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_EVENT_DELETE,
    payload: request,
  };
}

// 이벤트 사진 삭제
export function adminEventDeleteImg(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/event/deleteImg", body)
    .then((res) => res.data);

  return {
    type: ADMIN_EVENT_DELETEIMG,
    payload: request,
  };
}

// 문의하기
export function adminInquiryList() {
  let dataToSubmit = {};
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .get("/api/admin/service/inquiry", body)
    .then((res) => res.data);

  return {
    type: ADMIN_INQUIRY_LIST,
    payload: request,
  };
}

// 문의하기 개별조회
export function adminInquiryDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/inquiry/detail", body)
    .then((res) => res.data);

  return {
    type: ADMIN_INQUIRY_DETAIL,
    payload: request,
  };
}

// 문의하기 답글등록
export function adminInquiryAddReply(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/inquiry/add", body)
    .then((res) => res.data);

  return {
    type: ADMIN_INQUIRY_ADD_REPLY,
    payload: request,
  };
}

// 문의하기 삭제
export function adminInquiryDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/service/inquiry/delete", body)
    .then((res) => res.data);

  return {
    type: ADMIN_INQUIRY_DELETE,
    payload: request,
  };
}

// 탈퇴 회원

// 관리주소
export function userOutDetailAddress(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/out/detail/address", body)
    .then((res) => res.data);

  return {
    type: USER_OUT_DETAIL_ADDRESS,
    payload: request,
  };
}
// 알림 리스트
export function alarmOutMember(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
    .post("/api/admin/user/out/detail/alarm/member", body)
    .then((res) => res.data);

  return {
    type: ALARM_OUT_MEMBER,
    payload: request,
  };
}

//메일전송
export function adminSendMail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/send", body)
      .then((res) => res.data);

  return {
    type: ADMIN_SEND_MAIL,
    payload: request,
  };
}

//메시지전송
export function adminSendSms(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);
  const request = axios
      .post("/api/admin/set/sms/send", body)
      .then((res) => res.data);

  return {
    type: ADMIN_SEND_SMS,
    payload: request,
  };
}

//문자 발송내역 리스트
export function smsHistoryList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/sms/history", body)
      .then((res) => res.data);

  return {
    type: ADMIN_SMS_HISTORYLIST,
    payload: request,
  };
}

//메일 예약목록 리스트
export function mailScheduleList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/schedulelist", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_SCHEDULELIST,
    payload: request,
  };
}

//메일 발송내역 리스트
export function mailHistoryList(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/historylist", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_HISTORYLIST,
    payload: request,
  };
}

//메일 예약목록 디테일 조회
export function mailScheduleDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/schedule/detail", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_SCHEDULE_DETAIL,
    payload: request,
  };
}

//메일 예약목록 디테일 수정
export function mailScheduleModify(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/schedule/modify", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_SCHEDULE_MODIFY,
    payload: request,
  }
}

//메일 발송내역 디테일 조회
export function mailHistoryDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/history/detail", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_HISTORY_DETAIL,
    payload: request,
  }
}


//메일 발송실패 유저리스트
export function mailUserListPopup(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/popup/users", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_POPUP_USERS,
    payload: request,
  }
}

//메일 재전송 디테일 조회
export function adminResendMailDetail(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/resend/detail", body)
      .then((res) => res.data);

  return {
    type: ADMIN_RESEND_MAIL_DETAIL,
    payload: request,
  }
}

//메일 예약내역 삭제
export function mailScheduleDelete(dataToSubmit) {
  let body = admin_auth_add(dataToSubmit);  
  const request = axios
      .post("/api/admin/set/mail/schedule/delete", body)
      .then((res) => res.data);

  return {
    type: ADMIN_MAIL_SCHEDULE_DELETE,
    payload: request,
  }
}

//작업공지 on,off 조회
export function adminConstruction(){
  const request = axios
      .post("/api/admin/set/construction")
      .then((res) => res.data);

  return {
    type: ADMIN_CONSTRUCTION,
    payload: request,
  }
}

//작업공지 on,off 로그추가
export function adminConstructionLogAdd(dataToSubmit){
  let body = admin_auth_add(dataToSubmit);
  const request = axios
      .post("/api/admin/set/construction/add", body)
      .then((res) => res.data);

  return {
    type: ADMIN_CONSTRUCTION_LOG_ADD,
    payload: request,
  }
}

// 관리자 고유번호 추가(admin_log에 활용)
function admin_auth_add(data){
  if(jsType(data) === "[object Object]"){ // [object Object] : json
    let admin_auth = localStorage.getItem("admin_auth");
    let temp = {...data, admin_auth:admin_auth};
    return temp;
  }else{
    let temp = data;
    return temp;
  }
}

function jsType(data){
  return Object.prototype.toString.call(data)
}