import React, { useLayoutEffect, useState } from "react";
import "./Board.css";
import prev from "../../images/prev.png";
import next from "../../images/next.png";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getNotice, getOtherNotice } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import MobileBottomNav from "../Footer/MobileBottomNav";

function NoticeBoardDetail({ match }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.push("/notice/board/"+cate+"/1");
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 게시글 번호
  const idx = match.params.idx;
  const cate = match.params.cate;
  const [changeIdx, setChangeIdx] = useState(Number(idx));
  const [NoticeContent, setNoticeContent] = useState([]); // 공지글 내용

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      idx: idx, // 글번호
    };
    notice(body);

    // eslint-disable-next-line
  }, [dispatch]);

  useLayoutEffect(() => {
    let body = {
      idx: changeIdx, // 글번호
    };
    notice(body);
    // eslint-disable-next-line
  }, [changeIdx]);

  let notice = (body) => {
    dispatch(getNotice(body)).then((res) => {
      if (res.payload.notice) {
        setNoticeContent(res.payload.notice);
      } else {
        alert("잠시 후 다시 시도해주세요.");
        goBack();
      }
    });
  };

  // 이전/다음 글 가져오기
  let otherNotice = (criterion) => {
    let newIdx = changeIdx;
    let comment = "";
    if (criterion === "prev") {
      --newIdx;
      comment = "이전";
    } else if (criterion === "next") {
      ++newIdx;
      comment = "다음";
    }
    let body = {
      idx: newIdx, // 글번호
      scope: criterion,
    };
    dispatch(getOtherNotice(body)).then((res) => {
      if (res.payload.notice) {
        setChangeIdx(res.payload.notice.idx);
        window.location.href = `/notice/board/detail/${cate}/${res.payload.notice.idx}`;
      } else if (res.payload.result === "undefined") {
        alert(`${comment}글이 존재하지 않습니다.`);
      } else {
        alert("잠시 후 다시 시도해주세요.");
        goBack();
      }
    });
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div id="noticeBoard" style={{ height: "54px !important" }}>
          {isMobile && (
            <div className="content board_main">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                공지사항
              </h2>
            </div>
          )}
        </div>

        <div className="main center_main" id="informationDetail">
          <div className="header_column isMobile_detail">
            <h2 className="detail_title">
              <span style={{ "margin-top": "0px" }}>{NoticeContent.title}</span>
            </h2>
            <div className="right">
              {changeDateFormat(NoticeContent.registerdate, 3, "-", "")}
            </div>
          </div>
          <div className="body_column">
            {NoticeContent.file && (
              <img
                style={{
                  margin: "10px auto",
                  display: "block",
                  maxWidth: isMobile ? "90vw" : "30vw",
                }}
                src={
                  NoticeContent.file === "" || NoticeContent.file === null
                    ? null
                    : NoticeContent.file
                }
                alt="img"
                className="event_img"
              />
            )}

            <div
              className="detail_content_column"
              style={{ minHeight: "auto", whiteSpace: "pre-line" }}
            >
              {NoticeContent.content}
            </div>
          </div>
          <div className="btn_column">
            <div className="btn" onClick={() => otherNotice("prev")}>
              <img src={prev} alt="prev" className="prev" />
              이전글 보기
            </div>
            <div className="go_list btn" onClick={goBack}>
              목록으로
            </div>
            <div className="btn" onClick={() => otherNotice("next")}>
              다음글 보기
              <img src={next} alt="next" className="next" />
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default NoticeBoardDetail;
