import React, { useLayoutEffect, useState } from "react";
import PaginationNum from "../../components/PaginationNum";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminEventList } from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";

function ServiceEventList() {
  const dispatch = useDispatch();
  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [selectVisible, setSelectVisible] = useState("전체");

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    let data = {
      visible: selectVisible,
    };
    dispatch(adminEventList(data)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.eventList;
        setBoardList(result);
      }
    });
  }, [dispatch, selectVisible]);

  const handleVisible = (e) => {
    setSelectVisible(e.target.value);
  };

  const pageOfItem = pageOfItems.map((item) => {
    console.log(item);
    return (
      <tr key={item.idx}>
        <td>{item.rownum}</td>
        {/* <td>{item.idx}</td> */}
        <td>{item.gubun}</td>
        <td>
          <Link to={`/rf_zib_admin/service/event/detail/${item.idx}`}>
            <span className="btn">{item.title}</span>
          </Link>
        </td>
        <td>{changeDateFormat(item.datestart, 3, ".", "")}</td>
        <td>{changeDateFormat(item.dateend, 3, ".", "")}</td>
        <td>{item.visible ? "노출중" : "비노출"}</td>
        <td>{item.status ? "진행중" : "종료"}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="user_list_column">
        <div className="select_column">
          <div className="select_name">노출여부</div>
          <select onChange={handleVisible}>
            <option value="전체">전체</option>
            <option value="노출중">노출중</option>
            <option value="비노출">비노출</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "10%" }}>No.</th>
              <th style={{ width: "10%" }}>구분</th>
              <th>이벤트 제목</th>
              <th style={{ width: "10%" }}>시작일</th>
              <th style={{ width: "10%" }}>종료일</th>
              <th style={{ width: "10%" }}>노출여부</th>
              <th style={{ width: "10%" }}>상태</th>
            </tr>
          </thead>
          <tbody>
            {pageOfItem.length > 0 ? (
              pageOfItem
            ) : (
              <tr>
                <td colSpan={7}>등록된 글이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationNum items={boardList} onChangePage={onChangePage} />
      </div>
    </>
  );
}

export default ServiceEventList;
