import React, { useLayoutEffect, useState } from "react";
import Header from "../Header/Header";
import close from "../../images/close.png";
import Modal from "../../components/Modal";
import SideMenu from "./SideMenu";
import UserFreeBasic from "./UserFreeBasic";
import UserFreeAddress from "./UserFreeAddress";
import UserFreeNotice from "./UserFreeNotice";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  alarmMember,
  userFreeDetailAddress,
} from "../../_actions/admin_board_action";

function UserFreeDetail({ match }) {
  const idx = match.params.idx;
  let history = useHistory();
  const [usestopdesc, setUsestopdesc] = useState("");

  let ipnutUsestopdesc = (e) => {
    setUsestopdesc(e.target.value);
  };

  // 회원 이용정지
  let usestopSubmit = () => {
    let url = "/api/admin/user/free/detail/user/usestop";
    let data = {
      idx: idx,
      usestopdesc: usestopdesc,
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert("해당 회원의 정지 처리가 완료되었습니다.");
        history.push("/rf_zib_admin/user/free/1");
      }
    });
  };

  // 회원 탈퇴처리
  let deleteSubmit = () => {
    let url = "/api/admin/user/free/detail/user/deleteUser";
    let data = {
      idx: idx,
      unregdesc: "관리자가 탈퇴 처리한 회원입니다.",
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert("해당 회원의 탈퇴 처리가 완료되었습니다.");
        history.push("/rf_zib_admin/user/free/1");
      } else {
        alert("탈퇴 처리에 실패하였습니다.");
      }
    });
  };

  const obj = {
    0: <UserFreeBasic />,
    1: <UserFreeAddress />,
    2: <UserFreeNotice />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };
  const dispatch = useDispatch();
  const [addrCnt, setAddrCnt] = useState(0);
  const [alarmCnt, setAlarmCnt] = useState(0);
  useLayoutEffect(() => {
    let body = {
      memidx: idx,
    };
    // 관리주소 갯수 가져오기
    dispatch(userFreeDetailAddress(body)).then((res) => {
      if (res.payload.addrList) {
        const result = res.payload.addrList;
        setAddrCnt(result.length);
      }
    });

    // 알림 갯수 가져오기
    dispatch(alarmMember(body)).then((res) => {
      const result = res.payload.alarmList;
      if (res.payload.alarmList) {
        setAlarmCnt(result.length);
      }
    });
  }, [dispatch, idx]);

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">회원 상세 정보</h2>
                <div className="user_control_column">
                  <div
                    className="admin_delete_btn btn left"
                    onClick={() => openModal("modal2")}
                  >
                    이용정지
                  </div>
                  <div
                    className="admin_delete_btn btn"
                    onClick={() => openModal("modal1")}
                  >
                    회원삭제
                  </div>
                </div>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  기본정보
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  관리주소 {addrCnt}
                </li>
                <li
                  onClick={() => clickTab(2)}
                  className={tabActive === 2 ? "active" : null}
                >
                  알림 {alarmCnt}
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>

      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal ">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_desc">
            탈퇴하지 않은 회원입니다.
            <br />
            회원 탈퇴 처리 후 <br />
            회원 삭제 하시겠습니까?
          </div>
          <div className="admin_btn_column">
            <div
              className="admin_delete_btn btn left"
              onClick={() => {
                deleteSubmit();
              }}
            >
              회원삭제
            </div>
            <div
              className="admin_delete_btn btn close_btn"
              onClick={() => closeModal("modal1")}
            >
              취소
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal2"]}>
        <div className="modal_inner_ct admin_basic_modal ">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <div className="modal_info" style={{ marginBottom: 10 }}>
            이용정지 사유를 입력하세요.
          </div>
          <textarea
            placeholder="내용을 입력하세요"
            value={usestopdesc || ""}
            onChange={ipnutUsestopdesc}
          ></textarea>
          <div className="admin_btn_column">
            <div
              className="admin_delete_btn btn left"
              onClick={() => {
                usestopSubmit();
              }}
            >
              정지완료
            </div>
            <div
              className="admin_delete_btn btn close_btn"
              onClick={() => closeModal("modal2")}
            >
              취소
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserFreeDetail;
