import React from "react";
import mobileHome from "../../images/mobile_home.png";
import mobileHomeActive from "../../images/mobile_home_active.png";
import mobileCounseling from "../../images/mobile_counseling.png";
import mobileCounselingActive from "../../images/mobile_counseling_active.png";
import mobileInformation from "../../images/mobile_information.png";
import mobileInformationActive from "../../images/mobile_information_active.png";
import mobileNotice from "../../images/mobile_notice.png";
import mobileNoticeActive from "../../images/mobile_notice_active.png";
import mobileMy from "../../images/mobile_my.png";
import mobileMyActive from "../../images/mobile_my_active.png";
import { BrowserRouter, Link } from "react-router-dom";
import { getCookie } from "../../Common/lib";

function MobileBottomNav() {
  const currentPath = window.location.pathname;
  const x_auth = getCookie("x_auth");
  // 바텀 메뉴 구성
  const bottomMenu = (item) => {
    return (
      <Link to={item.path}>
        <img
          src={
            currentPath === item.path ||
            currentPath.includes(item.basicPath)
              ? item.activeIcon
              : item.icon
          }
          alt={item.alt}
        />
        <div
          className={
            currentPath === item.path ||
            currentPath.includes(item.basicPath)
              ? "isMobile_bottom_menu_name active"
              : "isMobile_bottom_menu_name"
          }
        >
          {item.title}
        </div>
      </Link>
    )
  }


  const data = [
    {
      no: 1,
      title: "홈",
      path: "/",
      icon: mobileHome,
      activeIcon: mobileHomeActive,
      alt: "home",
    },
    {
      no: 2,
      title: "등기변동알림",
      path: "/pts/description",
      icon: mobileNotice,
      activeIcon: mobileNoticeActive,
      alt: "description",
    },
    {
      no: 3,
      title: "무료법률상담",
      path: "/counseling/1",
      icon: mobileCounseling,
      activeIcon: mobileCounselingActive,
      alt: "counseling",
    },
    {
      no: 4,
      title: "부동산정보",
      path: "/information/1",
      icon: mobileInformation,
      activeIcon: mobileInformationActive,
      alt: "information",
    },
    {
      no: 5,
      title: "마이페이지",
      path: x_auth === "" ? "/login" : "/mypage",
      // path: "/mypage/mobile",
      icon: mobileMy,
      activeIcon: mobileMyActive,
      alt: "mypage",
      basicPath:
        currentPath === "/mypage/notice" ? "/mypage/mobile" : "/mypage",
      // "/mypage",
    },
  ];
  return (
    <>
      <div className="isMobile_bottom_nav" style={{ zIndex: 900 }}>
        {data.map((item, index) => {
          return (
            <div className="isMobile_bottom_menu" key={index}>
              {item.alt === "mypage" ? <BrowserRouter forceRefresh={true}>{bottomMenu(item)}</BrowserRouter> : bottomMenu(item)}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MobileBottomNav;
