import React, { useLayoutEffect, useRef, useState } from "react";
import "./FindUser.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import axios from "axios";

function DormantUser({ location }) {
  const params = new URLSearchParams(location.search);
  const memid = params.get("memid");
  const result = params.get("result");

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [userId, setUserId] = useState(false);

  useLayoutEffect(() => {
    // 전화번호가 일치함
    if (result === "yes") {
      setUserId(true);
    } else {
      setUserId(false);
    }
  }, [result]);

  const encodeRef = useRef();

  // 본인인증 팝업 띄우기
  let getEncData = () => {
    let url = `/api/nice/checkplus_main`;
    url += `?sReturnUrl=${axios.defaults.baseURL}api/nice/checkplus_success`;
    url += `&sErrorUrl=${axios.defaults.baseURL}api/nice/checkplus_fail`;
    url += `&type=normal`;
    url += `&memid=${memid}`;
    url += `&token=token`;

    if (isMobile) {
      url += `&mobile=yes`;
    } else {
      url += `&mobile=no`;
    }
    url += `&page=dormant`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.sEncData) {
          encodeRef.current.value = res.data.sEncData;

          if (isMobile === true) {
            document.form_chk.action =
              "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
            document.form_chk.submit();
          } else {
            var uri = "";
            var popupName = "popupChk";
            var options =
              "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=n";

            var openDialog = function (uri, name, options, closeCallback) {
              // eslint-disable-next-line
              var win = window.open(uri, name, options);

              document.form_chk.action =
                "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
              document.form_chk.target = "popupChk";
              document.form_chk.submit();
            };
            openDialog(uri, popupName, options, function (win) {});
          }
        } else {
          alert("본인인증 서버 요청에 실패하였습니다. 다시 시도해주세요");
        }
      })
      .catch((err) => console.log(err));
  }; //end of getEncData()
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header />
        <div className="main center_main find_user" id="dormantUser">
          <h1 className="main_title">휴면 계정 해제</h1>
          {/* 나이스 본인인증 form */}
          <form name="form_chk" method="post" style={{ display: "none" }}>
            <input type="hidden" name="m" value="checkplusService" />
            <input ref={encodeRef} type="hidden" name="EncodeData" />
          </form>
          {/* 나이스 본인인증 form */}

          <div className={userId ? "title_info confirm" : "title_info"}>
            {userId ? (
              <>휴면 해제 되었습니다.</>
            ) : (
              <>
                회원님의 아이디는 휴면 상태로 전환되었습니다.
                <span>
                  <span className="inner_span">
                    회원님은 로그인한 지 1년 이상이 지나&nbsp;
                  </span>
                  <span className="inner_span">
                    아이디가 휴면 상태로 전환되었습니다.
                  </span>
                </span>
                <strong>
                  회원 가입시 등록한 전화번호로 본인확인 후 다시 서비스 이용이
                  가능합니다.
                </strong>
              </>
            )}
          </div>

          <form>
            {userId ? (
              <>
                <Link to="/login">
                  <div className="blue_btn btn login_btn">로그인</div>
                </Link>
              </>
            ) : (
              <>
                <div className="btn_column">
                  <div className="check_btn nice_btn" onClick={getEncData}>
                    본인 인증
                  </div>
                  {result === "no" && (
                    <div className="warning">
                      가입된 전화번호와 일치하지 않습니다.
                    </div>
                  )}
                </div>
                <div className="info btn_bottom">
                  ※ 전화번호가 변경되었을 경우에는 고객센터로 문의바랍니다.
                </div>
              </>
            )}
          </form>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default DormantUser;
