import React, { useRef, useState, useLayoutEffect } from "react";
import close from "../../images/close.png";
import profileImg from "../../images/profile_img.png";
import { changeDateFormat, CheckPw, getTodayDateNTime } from "../../Common/lib";
import Modal from "../../components/Modal";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import axios from "axios";

function UserFreeBasic() {
  let match = useRouteMatch();
  let history = useHistory();
  const idx = match.params.idx;
  const pagen = match.params.pagen;

  // 회원정보
  const [user, setUser] = useState({});
  const [advertising, setAdvertising] = useState([]);
  // 프로필 사진
  const [img, setImg] = useState({});
  // 비밀번호 관리
  const [pwManagage, setPwManagage] = useState({});
  // 관리주소 현황
  const [imdaecha, setImdaecha] = useState(0);
  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
  });
  // 비밀번호 변경하기
  const [changePw, setChangePw] = useState({
    newPw: "",
    confirmPw: "",
  });
  // 비밀번호 변경 시 warning 메시지
  const obj = {
    0: "",
    1: "비밀번호를 다시 확인하세요.",
    2: "비밀번호 형식이 올바르지 않습니다.",
    3: "이전과 다른 비밀번호를 입력하세요.",
    4: "비밀번호가 다릅니다. 다시 확인하세요.",
  };
  const [warning, setWarning] = useState({ id: 0, message: obj[0] });
  // 회원 닉네임
  const [Nickname, setNickname] = useState("");
  // 닉네임 수정 시 중복확인 했는지
  const [checkNickname, setCheckNickname] = useState(true);
  useLayoutEffect(() => {
    let url = "/api/admin/user/free/detail/user";
    let data = { idx: idx };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        if (res.data.user !== "false") {
          setUser(res.data.user);
          setNickname(res.data.user.memname);
          if (res.data.user.advertising !== "false") {
            setAdvertising(res.data.user.advertising);
          }
        }
        if (res.data.profile !== "false") {
          setImg(res.data.profile);
        }
        if (res.data.pwManage !== "false") {
          setPwManagage(res.data.pwManage);
        }
        if (res.data.imdaecha !== "false") {
          setImdaecha(res.data.imdaecha);
        }
      } else {
        alert("회원 정보를 불러오지 못했습니다.");
      }
    });
  }, [idx]);

  // 프로필 삭제
  let deleteProfile = () => {
    if (img.profilePath === "") return;

    let url = "/api/admin/user/free/detail/user/deleteProfile";
    let data = {
      memidx: idx,
      profileName: img.profileName,
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        setImg({});
      } else {
        alert("사진 삭제에 실패하였습니다.");
      }
    });
  };

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const onChangeUserInfo = (e) => {
    // pw값 입력시 값 셋팅
    const value = e.target.value;
    setChangePw({
      ...changePw,
      [e.target.name]: value,
    });
  };

  const inputRefs = useRef([]);

  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  // 비밀번호 변경하기 클릭 시
  const submitPw = () => {

    if (!CheckPw(changePw["newPw"])) {
      setWarning({
        id: 2,
        message: obj[2],
      });
      handleFocus(1);
    } else if (changePw["newPw"] !== changePw["confirmPw"]) {
      setWarning({
        id: 4,
        message: obj[4],
      });
      handleFocus(2);
    } else {
      setWarning({
        id: 0,
        message: obj[0],
      });

      // 비밀번호 변경하기
      let url = "/api/admin/user/free/detail/user/modifyPassword";
      let data = {
        newPassword: changePw.newPw,
        idx: user.idx,
      };
      axios.post(url, data).then((res) => {

        if (res.data.result === "success") {
          alert("비밀번호가 변경되었습니다.");
          setChangePw({
            newPw: "",
            confirmPw: "",
          });
          setPwManagage({
            ...pwManagage,
            passwordchangedate: changeDateFormat(
              getTodayDateNTime("sym"),
              5,
              ".",
              ":"
            ),
          });

          closeModal("modal1");
        } else if (res.data.result === "wrong") {
          setWarning({
            id: 1,
            message: obj[1],
          });
        } else if (res.data.result === "same") {
          setWarning({
            id: 3,
            message: obj[3],
          });
        } else {
          alert("비밀번호 변경에 실패하였습니다.");
        }
      });
    }
  };
  // 비밀번호 관리 중 초기화
  let pwReset = (what) => {
    // what = 변경횟수 || 오류횟수 중 초기화 할 항목
    if (pwManagage[what] === 0) {
      alert("이미 초기화 되어있습니다.");
      return;
    }

    let url = "/api/admin/user/free/detail/user/pwReset";
    let data = {
      what: what,
      idx: idx,
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        setPwManagage({ ...pwManagage, [what]: 0 });
      } else {
        alert("초기화에 실패하였습니다.");
      }
    });
  };

  // 회원상태, 관리주소 주소등록한도 수정 시
  let userlHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // 정보 변경 저장
  let saveUser = () => {
    if (!checkNickname) {
      alert("닉네임 중복 검사를 진행해주세요");
      return;
    }
    let url = "/api/admin/user/free/detail/user/saveUser";
    let data = {
      memname: Nickname,
      memstatus: user.memstatus,
      memaddrmaxjoin: user.memaddrmaxjoin,
      idx: user.idx,
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/user/free/1");
      }
    });
  };

  // 닉네임 중복 검사
  const handleNickname = () => {
    if (Nickname === "") {
      alert("닉네임을 입력해주세요");
      return;
    }
    let url = "/api/join/checkMemname";
    let data = { memname: Nickname };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.result === "false") {
          alert("중복된 닉네임이 존재합니다.");
          setCheckNickname(false);
        } else {
          setCheckNickname(true);
          alert("중복되지 않는 닉네임입니다.");
        }
      })
      .catch((err) => {
        alert("에러가 발생했습니다. 관리자에게 문의해주세요.");
      });
  };

  const onChangeUserNickname = (e) => {
    setCheckNickname(false);
    setNickname(e.target.value);
  };

  const handleImgError = (e) => {
    e.target.src = profileImg;
  };
  return (
    <>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>회원상태</th>
            <td>
              <div className="free_user_radio">
                <input
                  type="radio"
                  id="radio4"
                  name="memstatus"
                  checked={user.memstatus === "정상"}
                  value="정상"
                  onChange={userlHandler}
                />
                <label htmlFor="radio4">정상</label>
                <input
                  type="radio"
                  id="radio5"
                  name="memstatus"
                  checked={user.memstatus === "휴면"}
                  value="휴면"
                  onChange={userlHandler}
                />
                <label htmlFor="radio5">휴면</label>
                <input
                  type="radio"
                  id="radio6"
                  name="memstatus"
                  checked={user.memstatus === "정지"}
                  value="정지"
                  // onChange={userlHandler}
                  readOnly
                />
                <label htmlFor="radio6">정지</label>
                {/* <input
                  type="radio"
                  id="radio7"
                  name="memstatus"
                  checked={user.memstatus === "탈퇴"}
                  value="탈퇴"
                  // onChange={userlHandler}
                  readOnly
                />
                <label htmlFor="radio7">탈퇴</label> */}
              </div>
            </td>
            <th rowSpan="4">프로필 사진</th>
            <td rowSpan="4">
              <div className="img_column">
                <img
                  style={{ maxWidth: 160 }}
                  src={img.profilePath ? img.profilePath : profileImg}
                  alt="profile"
                  onError={handleImgError}
                />
                <div className="img_name">
                  {img.profileName
                    ? img.profileName
                    : "등록된 프로필이 없습니다."}
                </div>
                <div
                  className="admin_black_btn btn"
                  onClick={() => {
                    deleteProfile();
                  }}
                >
                  삭제
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>가입일</th>
            <td>{changeDateFormat(user.memregdtime, 5, "-", ":")}</td>
          </tr>

          <tr>
            <th>이름</th>
            <td>
              <input
                type="text"
                value={user.memrealname ? user.memrealname : ""}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <th>회원 닉네임</th>
            <td className="nickName">
              <div className="nickName_column">
                <input
                  type="text"
                  value={Nickname ? Nickname : ""}
                  className="has_btn"
                  onChange={onChangeUserNickname}
                />
                <div className="admin_white_btn" onClick={handleNickname}>
                  중복확인
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th className="tel_num">핸드폰 번호</th>
            <td>
              {user && user.cellno ? (
                <>
                  <input
                    type="text"
                    value={user.cellno ? user.cellno : ""}
                    readOnly
                  />
                  {/* <div className="admin_input_info">
                    ※ 인증일시 :
                    {user.authdate
                      ? changeDateFormat(user.authdate, 5, ".", ":")
                      : " - "}
                  </div> */}
                </>
              ) : (
                <>
                  <input type="text" value="" readOnly />
                  <div className="admin_input_info" />
                </>
              )}
            </td>
            <th>가입경로</th>
            <td>{user.memjoinpath}</td>
          </tr>

          <tr>
            <th>이메일</th>
            <td>
              <input
                type="text"
                value={user.memid ? user.memid : ""}
                readOnly
              />
            </td>
            <th>가입유형</th>
            <td>{user.memjointype ? user.memjointype : ""}</td>
          </tr>
          <tr>
            <th>최종 로그인 일시</th>
            <td>{changeDateFormat(user.lastlogin, 6, "-", ":")}</td>
            <th>최종 로그인 IP</th>
            <td>{user.ip ? user.ip : "-"}</td>
          </tr>
        </tbody>
      </table>

      {user.memjointype === "이메일" ? (
        <>
          <h3 className="detail_table_title">비밀번호 관리</h3>
          <table className="detail_table">
            <tbody>
              <tr>
                <th>비밀번호</th>
                <td className="change_password">
                  <div
                    className="admin_white_btn btn"
                    onClick={() => openModal("modal1")}
                  >
                    비밀번호 변경 요청
                  </div>
                </td>
                <th>비밀번호 변경일시</th>
                <td>
                  {pwManagage && pwManagage.passwordchangedate
                    ? changeDateFormat(
                        pwManagage.passwordchangedate,
                        5,
                        ".",
                        ":"
                      )
                    : "변경 이력이 없습니다."}
                </td>
              </tr>
              <tr>
                <th>1일 변경 횟수</th>
                <td>
                  <div className="day_change_count">
                    {pwManagage && pwManagage.passwordinitcnt
                      ? pwManagage.passwordinitcnt
                      : "0"}
                    회
                    <div
                      className="admin_white_btn admin_white_btn80"
                      onClick={() => {
                        pwReset("passwordinitcnt");
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </td>
                <th>비밀번호 오류횟수</th>
                <td>
                  <div className="day_change_count">
                    {pwManagage && pwManagage.passworderrorcount
                      ? pwManagage.passworderrorcount
                      : "0"}
                    회 <br />
                    <div
                      className="admin_white_btn admin_white_btn80"
                      onClick={() => {
                        pwReset("passworderrorcount");
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}

      <h3 className="detail_table_title">관리주소 현황</h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th rowSpan="2">주소등록 한도</th>
            <td rowSpan="2">
              <input
                type="text"
                name="memaddrmaxjoin"
                value={user.memaddrmaxjoin ? user.memaddrmaxjoin : 1}
                className="add_address_limit"
                onChange={userlHandler}
              />
              건
            </td>
            <th>주소등록 건</th>
            <td>
              <div className="day_change_count">
                {user.COUNT ? user.COUNT : 0}건
                <BrowserRouter forceRefresh={true}>
                  <Link to={`/rf_zib_admin/address/1?memname=${user.memname}`}>
                    <div className="admin_white_btn admin_white_btn80">
                      전체보기
                    </div>
                  </Link>
                </BrowserRouter>
              </div>
            </td>
          </tr>
          <tr>
            <th>임대차만기일</th>
            <td>{imdaecha}건</td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">마케팅 활용</h3>
      <table className="detail_table marketing_table">
        <tbody>
          <tr>
            <th rowSpan="2">마케팅 정보 수신</th>
            <td>
              <div className="free_user_radio">
                <input
                  type="radio"
                  checked={user.advertising !== "false"}
                  id="radio4"
                  name="agreeMarketing"
                  disabled
                />
                <label htmlFor="radio4">동의</label>
                <input
                  type="radio"
                  id="radio5"
                  name="agreeMarketing"
                  checked={user.advertising === "false"}
                  disabled
                />
                <label htmlFor="radio5">동의안함</label>
              </div>
            </td>
            <th rowSpan="2">
              마케팅 정보수신 <br />
              변경일시
            </th>
            <td rowSpan="2">
              {user.advdate
                ? changeDateFormat(user.advdate, 5, ".", ":")
                : "변경 이력이 없습니다."}
            </td>
          </tr>
          <tr>
            <td>
              <div className="free_user_chk">
                {["앱푸시", "이메일", "문자(SMS)", "알림톡"].map((val, i) => {
                  return (
                    <span key={"adv" + i}>
                      <input
                        type="checkbox"
                        id={"chk" + i}
                        checked={advertising.includes(i.toString())}
                        disabled
                      />
                      <label htmlFor={"chk" + i}>{val}</label>
                    </span>
                  );
                })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="detail_table_title">정지/탈퇴 정보</h3>
      <table className="detail_table">
        <tbody>
          <tr>
            <th>이용정지 일시</th>
            <td>
              {user.usestoptime
                ? changeDateFormat(user.usestoptime, 6, "-", ":")
                : "-"}
            </td>
            <th>이용정지 사유</th>
            <td>{user.usestopdesc ? user.usestopdesc : "-"}</td>
          </tr>
          <tr>
            <th>회원탈퇴 일시</th>
            <td>
              {user.unregtime
                ? changeDateFormat(user.unregtime, 6, "-", ":")
                : "-"}
            </td>
            <th>회원탈퇴 사유</th>
            <td>{user.unregdesc ? user.unregdesc : "-"}</td>
          </tr>
        </tbody>
      </table>
      <div className="detail_user admin_btn_column">
        <div
          className="admin_white_btn btn left"
          onClick={() => {
            history.push(`/rf_zib_admin/user/free/${pagen}`);
          }}
        >
          목록
        </div>
        <div
          className="admin_blue_btn btn "
          onClick={() => {
            saveUser();
          }}
        >
          저장
        </div>
      </div>

      <Modal open={modal["modal1"]} width={600} center={true}>
        <div className="modal_inner_ct wide_modal change_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <h2>비밀번호 변경하기</h2>

          <form>
            <div className="column warning_column">
              <div className="input_name">현재 비밀번호</div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <div className="warning">
                {warning["id"] === 1 ? warning["message"] : null}
              </div>
            </div>
            <div className="column">
              <div className="input_name">새로운 비밀번호</div>
              <div className="input_column">
                <input
                  type="password"
                  placeholder="변경할 비밀번호를 입력하세요."
                  onChange={onChangeUserInfo}
                  name="newPw"
                  ref={(elem) => (inputRefs.current[1] = elem)}
                />
              </div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <div className="warning">
                {warning["id"] === 2 || warning["id"] === 3
                  ? warning["message"]
                  : null}
              </div>
            </div>
            <div className="column warning_column">
              <div className="input_name">새로운 비밀번호 확인</div>
              <div className="input_column">
                <input
                  type="password"
                  placeholder="비밀번호를 다시 한번 입력하세요."
                  onChange={onChangeUserInfo}
                  name="confirmPw"
                  ref={(elem) => (inputRefs.current[2] = elem)}
                />
              </div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <div className="warning" style={{ height: "auto" }}>
                {warning["id"] === 4 ? warning["message"] : null}
              </div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <div className="info">
                ※ 8자리 이상, 영문, 숫자, 특수문자을(를) 사용하세요.
              </div>
            </div>
          </form>
          <div className="save_btn_column">
            <div
              className="btn blue_btn close"
              onClick={() => closeModal("modal1")}
            >
              취소
            </div>
            <div className="blue_btn btn" onClick={submitPw}>
              변경하기
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserFreeBasic;
