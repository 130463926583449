import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./sliderBanner.css";
import SwiperCore, { EffectFade, Pagination, Navigation } from "swiper/core";
import pageNext from "../images/page_next.png";
import pagePrev from "../images/page_prev.png";
import pageStop from "../images/page_stop.png";

import MainBanner1 from "../images/svg/main-banner-1.svg";
import MainBanner2 from "../images/svg/main-banner-2.svg";
import MainBanner3 from "../images/svg/main-banner-3.svg";
import MainBanner4 from "../images/svg/main-banner-4.svg";

import MobileMainBanner1 from "../images/svg/main-banner-m-1.svg";
import MobileMainBanner2 from "../images/svg/main-banner-m-2.svg";
import MobileMainBanner3 from "../images/svg/main-banner-m-3.svg";
import MobileMainBanner4 from "../images/svg/main-banner-m-4.svg";

// install Swiper modules
SwiperCore.use([EffectFade, Pagination, Navigation]);

export default function SliderMainBanner() {
  const swiperRef = useRef(null);
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const lists = [
    { src: MainBanner1, mobileSrc: MobileMainBanner1, alt: 'main-banner-1', className: 'banner', visible: true },
    { src: MainBanner2, mobileSrc: MobileMainBanner2, alt: 'main-banner-2', className: 'banner', visible: true },
    { src: MainBanner3, mobileSrc: MobileMainBanner3, alt: 'main-banner-3', className: 'banner', visible: true },
    { src: MainBanner4, mobileSrc: MobileMainBanner4, alt: 'main-banner-4', className: 'banner', visible: true },
  ]

  const next = () => {
    swiperRef.current.swiper.slideNext();
  };

  const prev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const stop = () => {
    swiperRef.current.swiper.autoplay.stop();
  };

  return (
    <>
      <div
        className="section_2"
      >
        <div className="slide_container">
          <Swiper
            pagination={{
              el: ".swiper-pagination-1",
              type: "fraction",
            }}
            navigation={true}
            ref={swiperRef}
            loop={true}
            effect={'fade'}
            speed={800}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            className="multiple"
            id="sliderMainBanner"
            style={{width:'100%'}}
          >
            {lists.map((list, index) => {
              if (list.src && list.visible) {
                return (
                  <SwiperSlide key={`swiper:slide:item:${index}`}>
                    <img key={'slide:img:'+index}
                      src={ isMobile ? list.mobileSrc : list.src }
                      alt={list.alt}
                      className={list.className}
                      style={{width:'100%'}}
                    />
                  </SwiperSlide>
                )
              } else {
                return null
              }
            })}

            <div className="page_column_move">
              <img
                src={pagePrev}
                alt="prev"
                className="page_prev"
                onClick={() => prev()}
              />
              <img
                src={pageStop}
                alt="stop"
                className="page_stop"
                onClick={() => stop()}
              />
              <img
                src={pageNext}
                alt="next"
                className="page_next"
                onClick={() => next()}
              />
              <div className="swiper-pagination-1"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
