export const data = [
  {
    no: 1,
    title: "무료회원",
    path: "/rf_zib_admin/user/free",
  },
  {
    no: 2,
    title: "변호사 관리",
    path: "/rf_zib_admin/user/lawyer",
  },
  {
    no: 3,
    title: "관리자 관리",
    path: "/rf_zib_admin/user/admin",
  },
  {
    no: 4,
    title: "탈퇴회원",
    path: "/rf_zib_admin/user/out",
  },
];
