import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {adminResendMailDetail, adminSendMail} from "../../_actions/admin_board_action";
import {adminGetToday, changeStringToDateFormat} from "../../Common/lib";

import { Viewer } from '@toast-ui/react-editor';


function SendMailResend({match, history}) {
    const dispatch = useDispatch();
    const idx = match.params.idx;

    //뒤로가기
    const goBack = () => {
        history.goBack();
    };

    // const [meth, setMeth] = useState("M");                        //고지방법(문자:S ,이메일:M ,카카오:K)
    const [submitData, setSubmitData] = useState({});
    const [schdy, setSchdy] = useState("");
    const [content, setContent] = useState("");
    const [showChild, setShowChild] = useState(false);      //toast ui 렌더링

    const resendDate = adminGetToday();
    const retransmission = "Y";                                       //재전송: Y , 재전송아님: N
    const tranCycle = "N";                                            //발송주기 : 한번
    const meth = "M";                                                 //고지방법(문자:S ,이메일:M ,카카오:K)  (현재 메일만 가능)



    //기존 값 불러오기
    useLayoutEffect(() => {
        let body = {
            idx: idx,
        };

        dispatch(adminResendMailDetail(body)).then((res) => {
            if(res.payload.resend) {
                const result = res.payload.resend[0];
                setSubmitData(result);
                setSchdy(changeStringToDateFormat(result.schdy));
                setContent(result.cont);

                //렌더링 조건걸기 (true 되어야기 viewer 렌더링됨)
                setShowChild(true);
            }
        });

    }, [dispatch, idx]);

    //전송하기
    const submitHandler = () => {
        let admin_auth = localStorage.getItem("admin_auth");
        let del = "N";

        let body = {
            nm: submitData.nm,
            grp: submitData.grp,
            retran: retransmission,
            orgidx: submitData.lstidx,                      //고지목록 고유번호 넣어줌
            orgschidx : idx,                                //발송실패한 유저 찾기 위해서 발송실패된 고지일정번호 넘겨줌.
            meth: meth,
            title: submitData.title,
            cont: content,
            trancyc: tranCycle,
            transtdy: resendDate.replace(/-/gim, ""),
            schdate: resendDate.replace(/-/gim, ""),
            del: del,
            admin_auth: admin_auth,
            today: resendDate.replace(/-/gim, ""),
        };

        //메일 발송 action 호출
        dispatch(adminSendMail(body)).then((res) => {
            if (res.payload.result === "success"){
                alert("전송되었습니다.");
                document.location.href = '/rf_zib_admin/set/mail/history';
            }else {
                alert("잠시후 다시 시도해주세요");
            }
        });


    };


    return (
        <>
            <Header />
            <div className="admin_container">
                <SideMenu tabActive={4} />
                <div className="main_container">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <div className="detail_user_container">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                재전송
                            </h2>
                            <table className="detail_table board_table">
                                <tbody>
                                <tr>
                                    <th>수신그룹</th>
                                    <td>
                                        <div className="day_change_count">
                                            <input
                                                type="text"
                                                defaultValue = "재전송"
                                            />
                                        </div>
                                    </td>
                                    <td colSpan={2}>
                                        <div className="admin_white_btn btn">
                                            <Link
                                                to ={`/rf_zib_admin/set/mail/popup/${idx}/${submitData.failcnt}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span>유저리스트 보기</span>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>예약일자</th>
                                    <td>
                                        <input
                                            type="text"
                                            readOnly
                                            name="schdy"
                                            value={schdy}
                                        />
                                    </td>
                                    <th>재발송 일자</th>
                                    <td>
                                        <input
                                            type="text"
                                            readOnly
                                            name="resendDate"
                                            value={resendDate}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th>메일종류</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            name="title"
                                            defaultValue={submitData.nm || ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            name="title"
                                            defaultValue={submitData.title || ''}
                                        />
                                    </td>
                                </tr>
                                <tr className="board_content">
                                    <th>내용</th>
                                    <td colSpan="3">
                                        {showChild && (
                                            <Viewer
                                                height="600px"
                                                initialValue={String(submitData.cont)}
                                            />
                                        )}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div
                                    className="admin_white_btn btn left"
                                    onClick={goBack}
                                >
                                    목록
                                </div>
                                <div className="admin_blue_btn btn" onClick={submitHandler}>
                                    전송하기
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMailResend;
