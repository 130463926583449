import React from "react";
import "./Header.css";
import headerLogo from "../../images/admin/header_logo.png";
import logout from "../../images/admin/logout.png";
import { data } from "./NavbarData";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { auth } from "../../_actions/user_action";
import { useState } from "react";
import { deleteCookie, searchCashClear } from "../../Common/lib";

function Header() {
  let history = useHistory();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;

  // 로그아웃
  let logoutSubmit = () => {
    let url = "/api/admin/logout";
    var admin_auth = localStorage.getItem("admin_auth");
    var admin_authname = localStorage.getItem("admin_authname");
    var x_type = localStorage.getItem("x_type");
    let body = {
      admin_auth: admin_auth,
      admin_authname: admin_authname,
      x_type: x_type,
    };
    axios.post(url, body).then((res) => {
      if (res.data.result === "success") {
        localStorage.clear();
        deleteCookie("admin_auth");
        history.push("/rf_zib_admin");
      } else {
        // alert("로그인 실패이지만 페이지는 이동");
        history.push("/rf_zib_admin");
      }
    });
  };


  const [memname, setMemname] = useState();
  const [memtype, setMemtype] = useState(null);
  useLayoutEffect(() => {
    dispatch(auth("admin")).then((res) => {
      if (res.payload.isLogin) {
        setMemname(res.payload.memname);
        setMemtype(res.payload.memtype);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <header id="admin_header">
        <div className="left_wrapper">
          <BrowserRouter forceRefresh={true}>
            {/* <Link to="/admin/user/free"> */}
            <div
              className="admin_logo_column"
              onClick={() => {
                window.location.reload();
              }}
            >
              <img src={headerLogo} alt="logo" />
              <h1>관리시스템</h1>
            </div>
            {/* </Link> */}
          </BrowserRouter>
          <ul className="nav_menus">
            {data.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    item.path === currentPath ||
                    currentPath.includes(item.basicPath)
                      ? "nav_menu active"
                      : "nav_menu"
                  }
                  style={{
                    display:
                      memtype === item.auth
                        ? "block"
                        : memtype === "super"
                        ? "block"
                        : "none",
                  }}
                  onClick={()=>{searchCashClear();}}
                >
                  <BrowserRouter forceRefresh={true}>
                    <Link to={item.path}>{item.title}</Link>
                  </BrowserRouter>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="right_wrapper">
          <div className="user_name">{memname}</div>
          <div className="admin_logout_column">
            <img src={logout} alt="logout" />
            <span
              // 로그아웃 기능만 구현
              style={{ cursor: "pointer" }}
              onClick={() => {
                logoutSubmit();
              }}
            >
              로그아웃
            </span>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
