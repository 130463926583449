import React, { useState, useRef, useEffect } from "react";
import "./Mypage.css";
import alarm from "../../images/alarm.png";
import deleteIcon from "../../images/delete.png";
import dropdown from "../../images/dropdown.png";
import close from "../../images/close.png";
import Modal from "../../components/Modal";
import { BrowserRouter, Link } from "react-router-dom";
import { formatDay, getCookie, checkSpecial, checkOnlyNumber } from "../../Common/lib";
import { useDispatch } from "react-redux";
import {
  addManageAddress,
  auth,
  manageAddrNickChk,
} from "../../_actions/user_action";
import $ from "jquery";
import DaumPostcode from "react-daum-postcode";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../components/Calendar.css";
import dayjs from "dayjs";
import axios from "axios";

function ManageAddress() {
  // 뒤로가기
  const dispatch = useDispatch();
  const [memname, setMemname] = useState(null);
  useEffect(() => {
    dispatch(auth()).then((res) => {
      setMemname(res.payload.memname);
    });
  }, [dispatch]);
  const [noticeStart, setNoticeStart] = useState("6개월 전부터");
  const [cycle, setCycle] = useState("매월");
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    modal8: false,
  });
  const [preventDoubleClick, setPreventDoubleClick] = useState(true);

  // 만기일 알림
  const noticeStartLists = [
    "1개월 전부터",
    "2개월 전부터",
    "3개월 전부터",
    "6개월 전부터",
    "12개월 전부터",
  ];
  const noticeCycle = ["매주", "매일", "매월", "매년"];
  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };
  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const openModal6 = () => {
    if ($("#switch2").prop("checked")) {
      openModal("modal6");
    }
  };
  const openModal7 = () => {
    if ($("#switch2").prop("checked")) {
      openModal("modal7");
    }
  };
  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };
  // 부동산 닉네임
  const [realEstateName, setRealEstateName] = useState("");
  const [nicknameWarning, setNicknameWarning] = useState("");
  const [addrWarning, setAddrWarning] = useState("");
  const handleRealEstateName = (e) => {
    setRealEstateName(e.target.value);
    setNicknameIsOk(false);
    setNicknameWarning(false);
  };
  // 닉네임 중복확인
  const [nicknameIsOk, setNicknameIsOk] = useState(false);
  const overlapCheck = () => {
    if (realEstateName === "") {
      setNicknameWarning("부동산 닉네임을 입력하세요.");
      handleFocus(2);
      return;
    }
    if(checkSpecial(realEstateName)){
      setNicknameWarning("부동산 닉네임에 특수문자 또는 공백이 포함되어있습니다.");
      handleFocus(2);
      return;
    }

    if(checkOnlyNumber(realEstateName)){
      setNicknameWarning("부동산 닉네임은 문자+숫자로 작성이 가능합니다.");
      handleFocus(2);
      return;
    }  

    let body = {
      addrnickname: realEstateName,
    };
    dispatch(manageAddrNickChk(body)).then((res) => {
      if (res.payload.result) {
        setNicknameIsOk(true);
        setNicknameWarning("중복확인 되었습니다.");
      } else {
        setNicknameWarning(
          "회원님의 관리주소에 동일한 부동산 닉네임이 존재합니다."
        );
      }
    });
  };
  const [expire, setExpire] = useState(false); // 자가/전세 라디오 버튼 셋팅
  // ownOrHireWarning;
  const [ownOrHireWarning, setOwnOrHireWarning] = useState("");
  const ownOrHire = () => {
    setOwnOrHireWarning("");
    // 라디오 버튼 체크
    if ($("#rd2").prop("checked")) {
      setExpire(true);
    } else {
      setExpire(false);
    }
  };
  const [infoAlarm, setInfoAlarm] = useState(true); // 자가/전세 라디오 버튼 셋팅
  const alarmOnOff = () => {
    // 정보변경 알림
    if ($("#switch1").prop("checked")) {
      setInfoAlarm(true);
    } else {
      setInfoAlarm(false);
    }
  };
  const [addrAlarm, setAddrAlarm] = useState("on");
  const switchOnOff = () => {
    // 만기일 알림
    if ($("#switch2").prop("checked")) {
      setAddrAlarm("on");
      $(".notice_cycle").css("color", "#000000");
    } else {
      setAddrAlarm("off");
      $(".notice_cycle").css("color", "#999999");
    }
  };
  // 달력 시작일/만기일 셋팅
  const [dateWarning, setdateWarning] = useState({
    start: "",
    end: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const deleteStartData = () => {
    setStartDate(new Date());
  };

  const deleteEndData = () => {
    setEndDate(new Date());
  };

  const onStartDateChange = (val) => {
    setStartDate(val);
    closeModal("modal5");
  };
  const onEndDateChange = (val) => {
    setEndDate(val);
    closeModal("modal5-1");
  };

  // 등록 완료 클릭 시
  const signUp = () => {
    if (realEstateName === "") {
      setNicknameWarning("부동산 닉네임을 입력하세요.");
      handleFocus(2);
      return;
    } else if (!nicknameIsOk) {
      setNicknameWarning("부동산 닉네임 중복확인을 해주세요.");
      handleFocus(2);
      return;
    } else if (isAddress === "") {
      setAddrWarning("관리자 주소 확인 후 알림 등록이 완료됩니다.");
      return;
    } else if (!$("#rd1").prop("checked") && !$("#rd2").prop("checked")) {
      setOwnOrHireWarning("소유 형태를 선택하세요.");
      return;
    } else if ($("#rd2").prop("checked")) {
      //  임차 선택 시 계약일, 만기일 확인
      const formatStartDate = formatDay(startDate).split("/");
      const formatEndDate = formatDay(endDate).split("/");
      var newStartDate = new Date(
        formatStartDate[0],
        formatStartDate[1] - 1,
        formatStartDate[2]
      );
      var newEndDate = new Date(
        formatEndDate[0],
        formatEndDate[1] - 1,
        formatEndDate[2]
      );
      if (!(newStartDate.getTime() < newEndDate.getTime())) {
        setdateWarning({
          ...dateWarning,
          end: "계약일보다 빠른 날짜입니다. 다시 확인 바랍니다.",
        });
        return;
      }
    }
    setdateWarning("");
    openModal("modal3");
  };

  const handleSubmit = () => {
    // 더블 클릭 방지
    if (preventDoubleClick) {
      const x_auth = getCookie("x_auth");
      // var x_auth = localStorage.getItem("x_auth");
      let body = {
        x_auth: x_auth,
        memid: memname,
        addrnickname: realEstateName,
        addr: isAddress,
        addrsub: detailAddress,
        addrower: expire ? "임차" : "자가",
        addralarm: infoAlarm ? "on" : "off", // 정보변경 알림
        addrmaturity: expire ? addrAlarm : "on", // 만기일 알림
        addrrentdate: expire ? formatDay(startDate) : "",
        addrrentedate: expire ? formatDay(endDate) : "",
        addralarmstart: expire
          ? addrAlarm === "on"
            ? noticeStart.replace("개월 전부터", "")
            : null
          : null,
        addralarmcycle: expire ? (addrAlarm === "on" ? cycle : null) : null,
        bcode: etcAddress.bcode,      // 법정동 [2021-12-28 박윤수]
        bdcode: etcAddress.bdcode,    // 건물관리번호 [2021-12-28 박윤수]
        apt: etcAddress.apt,          // 공동주택 여부 [2021-12-28 박윤수]
        jusodong: jusoDong,           // 상세주소 동 [2022-03-11 박윤수]
        jusofloor: jusoFloor,         // 상세주소 층 [2022-03-11 박윤수]
        jusoho: jusoHo,               // 상세주소 호 [2022-03-11 박윤수]
      };

      dispatch(addManageAddress(body)).then((res) => {
        if (res.payload.result) {
          let tabIndex = 0; //이동해야 할 탭의 index인데, 나중에 쓸 일 있으려나...
          setPreventDoubleClick(false);
          window.location.href = `/mypage/address?tab=${tabIndex}`;
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    }
  };

  // juso.go.kr API
  const [keyword, setKeyword] = useState("");
  const [addrs, setAddrs] = useState([]);
  const [addrView, setAddrView] = useState(false);              // 주소리스트 영역
  const [addrDetailView, setAddrDetailView] = useState(false);  // 상세주소 영역
  const [addDetailDong, setAddDetailDong] = useState();         // 동 리스트
  const [addDetailFloor, setAddDetailFloor] = useState();       // 층 리스트
  const [addDetailHo, setAddDetailHo] = useState();             // 호 리스트

  // 주소 기본정보
  const [isAddress, setIsAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  // 주소 추가정보 [2021-12-28 박윤수]
  const [etcAddress, setEtcAddress] = useState();
  const [jusoDong, setJusoDong] = useState("");             // 선택한 동
  const [jusoFloor, setjusoFloor] = useState("");           // 선택한 층
  const [jusoHo, setjusoHo] = useState("");                 // 선택한 호
  const [floorHo, setFloorHo] = useState();                 // 층, 호수 state
  const [directView, setDirectView] = useState(true);       // 직접입력 체크박스 노출여부
  const [directCheck, setDirectCheck] = useState(false);    // 직접입력 체크박스
  const [directInput, setDirectInput] = useState("");       // 직접입력 input
  const [manageAdressWarning, setManageAdressWarning] = useState("");

  // 주소 검색
  const jusoSearch = () => { 
    const jusoUrl = "/api/join/jusoApi";
    var jusoBody = {
      keyword: keyword,
    };

    //상세박스가 열려있을수도 있으니 hidden / 초기화 [2022-02-15 박윤수]
    resetJuso();

    if(keyword.length > 5 ){
      axios.post(jusoUrl,jusoBody).then((res) => {
          //리스트 State에 등록 [2022-02-15 박윤수]
          setAddrs(res.data.juso); 
          setAddrView(true);
      }); //end of inner axios.post
    }else{
      alert("주소를 정확히 입력해주세요.");
    }
  };

  // 주소 검색 초기화
  const resetJuso = () => {
    setAddrDetailView(false);
    setDirectCheck(false);
    setDirectView(true);
    setAddDetailDong("");
    setAddDetailFloor("");
    setAddDetailHo("");
    setDirectInput("");
    setJusoDong("");
    setjusoFloor("");
    setjusoHo("");
    setFloorHo("");
  }

  // 주소 검색 키워드 Input
  const handleSetKeyword = (e) => {
    setKeyword(e.target.value);
  };

  // 상세주소 동 셀렉트박스
  const handleSetJusoDong = (e) => {
    setJusoDong(e.target.value);

    var val = e.target.value;
    var detailUrl = "/api/join/jusoDongApi";
    var detailBody = {
      admCd: etcAddress.bcode,
      rnMgtSn: etcAddress.rnMgtSn,
      udrtYn: etcAddress.udrtYn,
      buldMnnm: etcAddress.bdBonBun,
      buldSlno: etcAddress.buldSlno,
      searchType: "floor",
      dongNm:e.target.value,
    }
    // detail 데이터 호출
    axios.post(detailUrl,detailBody).then((res) => {
      //리스트 State에 등록 [2022-02-15 박윤수]
      setFloorHo(res.data.juso);
      let detailAddrArry = res.data.juso;
      let floor_arry = [];

      for(var i=0; i<detailAddrArry.length; i++){
        if(detailAddrArry[i].floorNm !== '' && floor_arry.indexOf(detailAddrArry[i].floorNm) === -1){
          if((val === '' || val === 'none') || (val !== '' && detailAddrArry[i].dongNm === val)){
            floor_arry.push(detailAddrArry[i].floorNm);
          }
        }
      }
      
      setAddDetailFloor(floor_arry);
    }); //end of inner axios.post
  };

  // 상세주소 층 셀렉트박스
  const handleSetJusoFloor = (e) => {
    setjusoFloor(e.target.value);
    let ho_arry = [];
    for(var i=0; i<floorHo.length; i++){
      if(floorHo[i].floorNm === e.target.value && floorHo[i].dongNm === jusoDong){
        ho_arry.push(floorHo[i].hoNm);
      }
    }
    setAddDetailHo(ho_arry);
  }

  // 상세주소 호 셀렉트박스
  const handleSetJusoHo = (e) => {
    setjusoHo(e.target.value);
  }

  // 주소리스트 선택시
  const selectAddrList = (e) => {
    let data = addrs[e];
    let apt = data.bdKdcd === 1 ? 'Y' : 'N';
    // 법정동, 건물관리번호, 공동주택 여부 추가 [2021-12-28 박윤수]
    let etcCode = { bcode:data.admCd, bdcode:data.bdMgtSn, apt:apt, rdNm:data.rn, bdBonBun:data.buldMnnm, rnMgtSn:data.rnMgtSn, udrtYn:data.udrtYn, buldSlno:data.buldSlno }  
    setEtcAddress(etcCode);
    setIsAddress(data.roadAddr);
    setDetailAddress("");
    if(data.detBdNmList === ""){
      setManageAdressWarning("");
      setDirectView(false);
      setDirectCheck(true);
      setAddrDetailView(true);
    }else{
      // 상세주소가 있으면
      var detailUrl = "/api/join/jusoDongApi";
      var detailBody = {
        admCd: data.admCd,
        rnMgtSn: data.rnMgtSn,
        udrtYn: data.udrtYn,
        buldMnnm: data.buldMnnm,
        buldSlno: data.buldSlno,
        searchType: "dong",
      }
      // detail 데이터 호출
      axios.post(detailUrl,detailBody).then((res) => {
        //리스트 State에 등록 [2022-02-15 박윤수]
        setAddDetailDong(res.data.juso); 
        setAddrDetailView(true);
      }); //end of inner axios.post
    }
  }

  //주소팝업 완료버튼 클릭시
  const selectAddrDetailList = () => {
    const url = "/api/join/jusoBdcode";
    let detailAddr = "";
    var body = {
      rdNm:etcAddress.rdNm,
      bdBonBun: etcAddress.bdBonBun,
      dtdBdNm:jusoDong
    };
    if(directCheck === false){
      if(jusoDong !== "" && jusoFloor !== "" && jusoHo !== ""){
        detailAddr = jusoDong + " ";
        axios.post(url,body).then((res) => {
          if(res.data[0] !== undefined){
            let apt = res.data[0].mluWh === 1 ? 'Y' : 'N';
            setEtcAddress({...etcAddress, bdcode: res.data[0].bdMngNo, bcode: res.data[0].bjdCd, apt: apt });
          }

          detailAddr += jusoFloor + " " + jusoHo;
          setDetailAddress(detailAddr);
          closeModal("modal8");
        }); //end of inner axios.post
      }else{
        alert("주소를 선택해주세요.");
      }
    }else{
        setDetailAddress(directInput);
        closeModal("modal8");
    }
  }


  // 상세 주소 Input
  const handleDetailAddress = (e) => {
    setDetailAddress(e.target.value);
  };

  // 직접입력 체크
  const handleDirect = () =>{
    setDirectCheck(directCheck => !directCheck); // on,off 개념
  }

  // 직접입력 상세주소
  const handleDirectInput = (e) => {
    setDirectInput(e.target.value);
  }

  // 다음 검색시작 ※현재 사용안함 [2022-02-17 박윤수]
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    // 법정동, 건물관리번호, 공동주택 여부 추가 [2021-12-28 박윤수]
    let etcCode = { bcode:data.bcode, bdcode:data.buildingCode, apt:data.apartment}  
    setEtcAddress(etcCode); 

    setIsAddress(fullAddress);
    setManageAdressWarning("");
    setAddrWarning("");
    closeModal("modal4");
  };
  return (
    <div className="add_content">
      <div className="top">
        <h3>관리주소 정보변경 알림 서비스</h3>
        <div className="column">
          <div className="input_name">
            부동산 닉네임
            <span className="warning">*</span>
          </div>
          <div className="input_column">
            <input
              type="text"
              placeholder="부동산 닉네임을 입력하세요."
              onChange={handleRealEstateName}
              ref={(elem) => (inputRefs.current[2] = elem)}
            />
            <div className="check_btn" onClick={overlapCheck}>
              중복확인
            </div>
          </div>
        </div>
        <div className="column">
          <div className="input_name"></div>
          <span
            className="warning"
            style={{ color: nicknameIsOk ? "#0d6fb8" : "#dd1818" }}
          >
            {nicknameWarning}
          </span>
        </div>
        <div className="column">
          <div className="input_name">
            관리주소 <span className="warning">*</span>
          </div>
          <div className="input_column">
            <input
              type="text"
              placeholder="주소를 입력하세요."
              readOnly
              value={isAddress}
              onClick={() => {
                openModal("modal8");
                resetJuso();
              }}
            />
            <div
              className="check_btn"
              onClick={() => {
                openModal("modal8");
                resetJuso();
              }}
            >
              주소등록
            </div>
          </div>
        </div>
        <div className="column">
          <div className="input_name"></div>
          <span className="warning">{addrWarning}</span>
        </div>
        <div className="column">
          <div className="input_name">
            상세주소 <span className="warning">*</span>
          </div>
          <div className="input_column">
            <input
              type="text"
              placeholder="상세주소를 입력해주세요."
              onChange={handleDetailAddress}
              value={detailAddress}
            />
          </div>
        </div>
        <div className="column">
          <div className="input_name"></div>
          <span className="warning">{manageAdressWarning}</span>
        </div>
        <div
          className="column own_radio_container"
          style={{ marginBottom: 10 }}
        >
          <div className="input_name">
            소유형태 <span className="warning">*</span>
          </div>
          <div className="own_radio_column">
            <div className="radio_wrap left">
              <input id="rd1" type="radio" name="radio1" onChange={ownOrHire} />
              <label htmlFor="rd1">
                <span>자가</span>
              </label>
            </div>
            <div className="radio_wrap">
              <input id="rd2" type="radio" name="radio1" onChange={ownOrHire} />
              <label htmlFor="rd2">
                <span>임차(전/월세)</span>
              </label>
            </div>
          </div>
        </div>
        <div className="column" style={{ marginBottom: 20 }}>
          <div className="input_name"></div>
          <span className="warning">{ownOrHireWarning}</span>
        </div>
        <div className="column isMobile_column">
          <div className="input_name">정보변경 알림 On/Off</div>
          <div className="input_column">
            <div className="column__on-off">
              <input
                type="checkbox"
                id="switch1"
                name="switch1"
                className="input__on-off"
                defaultChecked={true}
                onChange={alarmOnOff}
              />
              <label htmlFor="switch1" className="label__on-off">
                <span className="marble"></span>
                <span className="on">ON</span>
                <span className="off">OFF</span>
              </label>
            </div>
            <BrowserRouter forceRefresh={true}>
              <Link to="/mypage/notice/setting">
                <div className="alarm_btn">
                  <img src={alarm} alt="alarm" />
                  알림설정
                </div>
              </Link>
            </BrowserRouter>
          </div>
        </div>
      </div>
      {expire ? (
        <>
          <div className="bottom">
            <h3>임대차 만기일 알림 서비스</h3>
            <div className="column isMobile_column">
              <div className="input_name">만기일 알림 On/Off</div>
              <div className="input_column">
                <div className="column__on-off">
                  <input
                    type="checkbox"
                    id="switch2"
                    name="switch2"
                    defaultChecked={true}
                    className="input__on-off"
                    onChange={switchOnOff}
                  />
                  <label htmlFor="switch2" className="label__on-off">
                    <span className="marble"></span>
                    <span className="on">ON</span>
                    <span className="off">OFF</span>
                  </label>
                </div>
                <BrowserRouter forceRefresh={true}>
                  <Link to="/mypage/notice/setting">
                    <div className="alarm_btn">
                      <img src={alarm} alt="alarm" />
                      알림설정
                    </div>
                  </Link>
                </BrowserRouter>
              </div>
            </div>
            <div className="column">
              <div className="input_name">
                임대차 계약일 <span className="warning">*</span>
              </div>
              <div className="input_column">
                <input
                  type="text"
                  value={formatDay(startDate)}
                  className="calendar"
                  readOnly
                  onClick={() => openModal("modal5")}
                />
                <div className="check_btn" onClick={deleteStartData}>
                  <img src={deleteIcon} alt="delete" className="delete_icon" />
                  삭제
                </div>
              </div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <span className="warning">{dateWarning["start"]}</span>
            </div>
            <div className="column">
              <div className="input_name">
                임대차 만기일 <span className="warning">*</span>
              </div>
              <div className="input_column">
                <input
                  type="text"
                  value={formatDay(endDate)}
                  className="calendar"
                  readOnly
                  onClick={() => openModal("modal5-1")}
                />
                <div className="check_btn" onClick={deleteEndData}>
                  <img src={deleteIcon} alt="delete" className="delete_icon" />
                  삭제
                </div>
              </div>
            </div>
            <div className="column">
              <div className="input_name"></div>
              <span className="warning">{dateWarning["end"]}</span>
            </div>
            <div className="column">
              <div className="input_name">알림 시작일</div>
              <div className="notice_cycle btn" onClick={openModal6}>
                {noticeStart}
                <img src={dropdown} alt="dropdown" />
              </div>
            </div>
            <div className="column">
              <div className="input_name">알림 주기</div>
              <div className="notice_cycle btn" onClick={openModal7}>
                {cycle}
                <img src={dropdown} alt="dropdown" />
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="column save">
        <div className="save_btn_column">
          <div
            className="btn blue_btn close"
            onClick={() => {
              window.location.reload();
            }}
          >
            취소
          </div>
          <div className="blue_btn btn" onClick={signUp}>
            저장
          </div>
        </div>
        <span className="warning">
          {/* * 필수 항목을 모두 입력해주세요. */}
        </span>
      </div>
      <Modal open={modal["modal1"]} width={400}>
        <div className="modal_inner_ct modal_400 num_modal">
          <h2>관리고유 고유번호</h2>
          <div className="manage_num_list">
            1234-0000-123456 <br />
            1234-0000-123456
            <br />
            1234-0000-123456
            <br />
            1234-0000-123456
            <br />
            1234-0000-123456
            <br />
          </div>
          <div className="save_btn_column">
            <div className="blue_btn btn" onClick={() => closeModal("modal1")}>
              닫기
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal2"]} width={400}>
        <div className="modal_inner_ct modal_400 exit_modal">
          <h2>관리 주소를 추가할 수 없습니다.</h2>
          <div className="error_info">관리자에게 문의바랍니다.</div>
          <div className="save_btn_column">
            <div className="blue_btn btn" onClick={() => closeModal("modal2")}>
              확인
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal3"]} width={400}>
        <div className="modal_inner_ct modal_400 exit_modal">
          <h2 style={{ borderBottom: 0 }}>관리 주소를 등록하겠습니까?</h2>
          <div className="info">
            관리자가 주소확인 후 알림 설정이 완료됩니다.
          </div>
          <div className="warning">
            (※ 등록 요청일로부터 3영업일내 처리 예정)
          </div>
          <div className="save_btn_column">
            <div
              className="btn blue_btn close"
              onClick={() => closeModal("modal3")}
            >
              취소
            </div>
            <div className="blue_btn btn" onClick={handleSubmit}>
              등록하기
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={modal["modal4"]}
        width={500}
        center={true}
        close={() => closeModal("modal4")}
      >
        <DaumPostcode
          onComplete={handleComplete}
          style={{ height: 480, padding: 7 }}
        />
      </Modal>
      <Modal open={modal["modal5"]} width={600}>
        <div className="modal_inner_ct wide_modal calendar_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal5")}
          />{" "}
          <Calendar
            onChange={onStartDateChange}
            value={startDate}
            calendarType="US"
            formatDay={(locale, date) => dayjs(date).format("D")}
          />
        </div>
      </Modal>
      <Modal open={modal["modal5-1"]} width={600}>
        <div className="modal_inner_ct wide_modal calendar_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal5-1")}
          />
          <Calendar
            onChange={onEndDateChange}
            value={endDate}
            calendarType="US"
            formatDay={(locale, date) => dayjs(date).format("D")}
          />
        </div>
      </Modal>
      <Modal
        open={modal["modal6"]}
        width={400}
        close={() => closeModal("modal6")}
      >
        <div className="modal_inner_ct modal_400 notice_alram">
          {noticeStartLists.map((list, index) => (
            <div
              key={index}
              className={
                noticeStart === list ? "notice_list active" : "notice_list"
              }
              onClick={() => {
                setNoticeStart(list);
                closeModal("modal6");
              }}
            >
              {list}
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        open={modal["modal7"]}
        width={400}
        close={() => closeModal("modal7")}
      >
        <div className="modal_inner_ct modal_400 notice_alram">
          {noticeCycle.map((list, index) => (
            <div
              key={index}
              className={cycle === list ? "notice_list active" : "notice_list"}
              onClick={() => {
                setCycle(list);
                closeModal("modal7");
              }}
            >
              {list}
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        open={modal["modal8"]}
        width={500}
        close={() => closeModal("modal8")}
            >
            <div className="modal_inner_ct modal_500 pop-address-search">
              <div className="pop-address-search-inner">
                <div className="search-wrap" style={{ marginTop: 6 }}>
                <span class="wrap">
                  <input type="text" 
                    className="popSearchInput" 
                    placeholder="주소를 입력해주세요." 
                    onChange={handleSetKeyword} 
                    onKeyPress={(e)=>{
                      if(e.key === 'Enter'){jusoSearch()}
                    }}
                  />  
							    <input type="button" title="검색" onClick={jusoSearch} style={{cursor:'pointer'}}/>
						    </span>
                </div>
                <div className="result">
                  {
                    addrView ? <table className="data-col"> 
                    <thead> 
                      <tr> 
                        <th>번호</th> 
                        <th style={{width:"100vw"}}>도로명 주소</th> 
                        <th>우편번호</th> 
                      </tr>
                    </thead>
                    <tbody> 
                      {addrs.map((row,index) => ( 
                      <tr key={row.roadAddr}> 
                        <td style={{verticalAlign:"middle"}}>{index+1}</td> 
                        <td><div onClick={()=>{selectAddrList(index)}} style={{cursor:"pointer"}}><span className="list-roadAddr">{row.roadAddr}</span><br/><span className="list-jibunAddr">[지번] {row.jibunAddr}</span></div></td> 
                        <td style={{verticalAlign:"middle"}}>{row.zipNo}</td> 
                      </tr> 
                      ))} 
                      </tbody> 
                    </table>
                    : null
                  }
                </div>
                {addrDetailView ? 
                  <div className="detail"> 
                    <table>
                      <tbody>
                          <tr>
                            <td className="titleTd">선택한 주소</td>
                            <td style={{verticalAlign:"middle"}}><span style={{fontSize:13,paddingLeft:10}}>{isAddress}</span></td>
                          </tr>
                          <tr>
                            <td className="titleTd">상세주소{!directCheck ? "(동선택)":null}</td>
                            <td style={{textAlign:"left"}}>
                              {!directCheck ?
                              <div>
                                <select onChange={handleSetJusoDong} value={jusoDong}>
                                  <option value="">동선택</option>
                                {addDetailDong.map((row,index) => (
                                  <option key={index} value={row.dongNm}>{row.dongNm}</option>
                                ))}
                                </select>
                                {Array.isArray(addDetailFloor)?
                                <select onChange={handleSetJusoFloor} value={jusoFloor}>
                                  <option value="">층선택</option>
                                {addDetailFloor.map((row,index) => (
                                  <option key={index} value={row}>{row}</option>
                                ))}
                                </select>
                                :null}
                                {Array.isArray(addDetailHo)?
                                <select onChange={handleSetJusoHo} value={jusoHo}>
                                  <option value="">호선택</option>
                                {addDetailHo.map((row,index) => (
                                  <option key={index} value={row}>{row}</option>
                                ))}
                                </select>
                                :null}
                              </div>:
                              <div>
                                <input type="text"
                                  onChange={handleDirectInput}
                                />
                              </div>
                              }
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style={{textAlign:"left"}}>
                              {directView ? 
                              <div className="checkbox_wrap main" style={{marginTop:5}}>
                                <input
                                  id="direct"
                                  type="checkbox"
                                  name="direct"
                                  checked={directCheck}
                                  onChange={handleDirect}
                                />
                                <label htmlFor="direct">직접입력</label>
                              </div>
                              :null}
                            </td>
                          </tr>
                      </tbody>    
                    </table>
                  <div className="btn-area">
                    <div className="blue_btn btn" style={{width:"100%",cursor:"pointer"}} onClick={()=>{selectAddrDetailList()}}>완료</div>
                  </div>
                </div>:null}
              </div>
            </div>
          </Modal>
    </div>
  );
}
export default ManageAddress;