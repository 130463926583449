import React, { useLayoutEffect, useState, useRef } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import close from "../../images/close.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { changeDateFormat } from "../../Common/lib";
import { getInquiryDetail, modifyInquiry, boardDivisionList } from "../../_actions/main_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function InquiryBoardDetail({ match }) {
  const idx = match.params.idx;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [categories, setCategories] = useState([]); // 카테고리 리스트

  // 상세페이지 문의 글
  const [detail, setDetail] = useState({});
  // 상세페이지 답변 글
  const [detailReply, setDetailReply] = useState({});
  const [isDetailReply, setIsDetailReply] = useState(false);
  // 초기 제목
  const [originTitle, setOriginTitle] = useState("");
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      kind: "문의하기",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });

    let data = {
      idx: idx,
    };
    // 글내용 가져오기
    dispatch(getInquiryDetail(data)).then((res) => {
      if (res.payload.result === "success") {
        setDetail(res.payload.inquiry);
        setOriginTitle(res.payload.inquiry.title);

        if (res.payload.inquiryReply !== "false") {
          setDetailReply(res.payload.inquiryReply);
          setIsDetailReply(true);
        }
      }
    });
  }, [dispatch, idx]);

  const inputHandler = (e) => {
    let el = e.target.name;
    setDetail({ ...detail, [el]: e.target.value });
  };
  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };
  const handelSubmit = () => {
    let body = {
      idx: idx,
      title: detail.title,
      text: detail.text,
      phinfo: detail.phinfo,
    };
    if (detail.title === "") {
      alert("제목을 입력해주세요");
      handleFocus(0);
      return;
    } else if (detail.text === "") {
      alert("내용을 입력해주세요");
      handleFocus(1);
      return;
    }

    dispatch(modifyInquiry(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div
          className="main center_main mypage_main inquiry_write"
          id="inquiryBoard"
        >
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content board_main">
              <h2 className="isMobile_inquiryBoard_h2">
                {originTitle}
                {isMobile ? (
                  <img
                    src={close}
                    alt="close"
                    className="close btn"
                    onClick={goBack}
                  />
                ) : null}
              </h2>

              <div className="write_column">
                <form>
                  <div className="form_column ">
                    <div className="form_column isMobile_form_column">
                      <div className="column_name">작성일</div>
                      <div className="writer">
                        {changeDateFormat(detail.registerdate, 5, "-", ":")}
                      </div>
                    </div>
                  </div>
                  <div className="form_column">
                    <div className="column_name">
                      구분 <span className="warning">*</span>
                    </div>
                    <select
                      name="gubun"
                      value={detail.gubun}
                      disabled
                      onChange={inputHandler}
                    >
                      {categories.map((item, index) => {
                        return <option key={index}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form_column">
                    <div className="column_name">
                      제목 <span className="warning">*</span>
                    </div>

                    <input
                      readOnly={isDetailReply}
                      type="text"
                      name="title"
                      value={detail.title}
                      onChange={inputHandler}
                      ref={(elem) => (inputRefs.current[0] = elem)}
                      className="write_title"
                    />
                  </div>
                  <div className="form_column">
                    <div className="column_name write_content">
                      내용 <span className="warning">*</span>
                    </div>
                    <textarea
                      readOnly={isDetailReply}
                      name="text"
                      value={detail.text}
                      onChange={inputHandler}
                      ref={(elem) => (inputRefs.current[1] = elem)}
                    ></textarea>
                  </div>
                  <div className="form_column">
                    <div className="column_name write_content">기기정보</div>
                    <textarea
                      readOnly={isDetailReply}
                      name="phinfo"
                      value={detail.phinfo}
                      onChange={inputHandler}
                    ></textarea>
                  </div>
                </form>

                {isDetailReply && (
                  <form className="reply_form">
                    <div className="form_column">
                      <div className="form_column isMobile_form_column">
                        <div className="column_name">작성일</div>
                        <div className="writer">
                          {changeDateFormat(
                            detailReply.registerdate,
                            5,
                            "-",
                            ":"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form_column">
                      <div className="form_column isMobile_form_column">
                        <div className="column_name">작성자</div>
                        <div className="writer">{detailReply.memname}</div>
                      </div>
                    </div>
                    <div className="form_column">
                      <div className="column_name">제목</div>
                      <input
                        type="text"
                        className="write_title"
                        defaultValue={detailReply.replytitle}
                        readOnly
                      />
                    </div>
                    <div className="form_column">
                      <div className="column_name write_content">
                        내용 <span className="warning">*</span>
                      </div>
                      <textarea
                        readOnly
                        defaultValue={detailReply.replytext}
                      ></textarea>
                    </div>
                  </form>
                )}

                <div className="btn_column modify">
                  {isDetailReply ? (
                    <div className="blue_btn btn" onClick={goBack}>
                      목록으로
                    </div>
                  ) : (
                    <>
                      <div className="blue_btn btn close" onClick={goBack}>
                        취소
                      </div>
                      <div className="blue_btn btn" onClick={handelSubmit}>
                        수정하기
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default InquiryBoardDetail;
