import React, { useLayoutEffect, useState, useRef } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import close from "../../images/close.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { writeInquiry, boardDivisionList } from "../../_actions/main_action";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { getCookie } from "../../Common/lib";

function InquiryWrite() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [categories, setCategories] = useState([]); // 카테고리 리스트
  const [Categorie, setCategorie] = useState(""); // 선택한 카테고리
  const [Title, setTitle] = useState(""); // 제목
  const [Content, setContent] = useState(""); // 내용
  const [DeviceInfo, setDeviceInfo] = useState(""); // 기기정보
  const [Warning, setWarning] = useState(false); // 경고 문구

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      kind: "문의하기",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
        setCategorie(result[0].name);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch]);

  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "title":
        setTitle(e.target.value);
        return;
      case "content":
        setContent(e.target.value);
        return;
      case "device":
        setDeviceInfo(e.target.value);
        return;
      case "categorie":
        setCategorie(e.target.value);
        return;
      // categorie;
      default:
        return;
    }
  };

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };
  const handelSubmit = () => {
    const x_auth = getCookie("x_auth");
    // let x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
      gubun: Categorie,
      title: Title,
      text: Content,
      phinfo: DeviceInfo,
    };
    if (Title === "") {
      setWarning(true);
      handleFocus(0);
      return;
    } else if (Content === "") {
      setWarning(true);
      handleFocus(1);
      return;
    } else {
      setWarning(false);
    }

    dispatch(writeInquiry(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="InquiryWrite">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body inquiry_write">
            {isMobile ? null : <Sidebar />}
            <div className="content">
              <h2>
                문의 작성하기
                {isMobile ? (
                  <img
                    src={close}
                    alt="close"
                    className="close btn"
                    onClick={goBack}
                  />
                ) : null}
              </h2>

              <div className="write_column">
                <form>
                  <div className="form_column">
                    <div className="column_name">
                      구분 <span className="warning">*</span>
                    </div>
                    <select name="categorie" onChange={inputHandler}>
                      {categories.map((item, index) => {
                        return (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form_column">
                    <div className="column_name">
                      제목 <span className="warning">*</span>
                    </div>
                    <input
                      type="text"
                      name="title"
                      onChange={inputHandler}
                      placeholder="제목을 입력하세요. (최대 20자)"
                      className="write_title"
                      ref={(elem) => (inputRefs.current[0] = elem)}
                    />
                  </div>
                  <div className="form_column">
                    <div className="column_name write_content">
                      내용 <span className="warning">*</span>
                    </div>
                    <textarea
                      name="content"
                      onChange={inputHandler}
                      ref={(elem) => (inputRefs.current[1] = elem)}
                      placeholder="내용을 입력하세요. (최대 1,000자 이내)&#13;&#10;※ 개인정보가 포함되지 않도록 유의해 주시기 바랍니다."
                    ></textarea>
                  </div>

                  <div className="form_column">
                    <div className="column_name write_content">
                      기기정보(선택)
                    </div>
                    <textarea
                      name="device"
                      onChange={inputHandler}
                      placeholder="- 기기 종류 (예. PC, 휴대폰 등)&#13;&#10;- OS 정보 (예.윈도우, Mac, Android, iOS 등)&#13;&#10;- 모델명 (예. 갤럭시S9, 아이폰11 등)"
                    ></textarea>
                  </div>

                  <div className="btn_column">
                    <div
                      className="btn blue_btn close"
                      onClick={() => history.goBack()}
                    >
                      취소
                    </div>
                    <div className="blue_btn btn" onClick={handelSubmit}>
                      저장
                    </div>
                  </div>
                  <div className="warning last">
                    {/* * 필수 항목을 모두 입력해주세요. */}
                    {Warning ? "* 필수 항목을 모두 입력해주세요." : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default InquiryWrite;
