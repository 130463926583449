import React, { useLayoutEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function JoinEmailAuth() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <div className="ie_container">
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
          <div className="main center_main" id="joinAuth">
            <h1 className="main_title">본인 확인 서비스</h1>

            <div className="title_info">
              안전한 서비스 제공을 위해 <br />
              본인 인증이 과정이 필요합니다.
            </div>

            <button className="btn blue_btn">본인 인증</button>
          </div>
          {isMobile ? <MobileBottomNav /> : <Footer />}
        </div>
      </div>
    </>
  );
}

export default JoinEmailAuth;
