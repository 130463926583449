import React, { useLayoutEffect, useState } from "react";
import next from "../images/next.png";
import close from "../images/close.png";
import $ from "jquery";
import Modal from "../components/Modal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { setCookie, saveLimitTime, strEncoding } from "../Common/lib";

function Provision({ handle, nextStep, allInputOk, joinInfo, type }) {
  const [buttonHandle, setButtonHandle] = useState(0);
  let history = useHistory();

  const checkVal = (e) => {
    e.preventDefault();
    // 이메일 중복 검사
    let url = "/api/join/checkMemid";
    let data = { memid: joinInfo.joinEmail };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.result === "success") handle(true);
        else handle(false);
      })
      .catch((err) => {
        console.log(err);
        alert("에러가 발생했습니다. 관리자에게 문의해주세요.");
      }); //end of axios
    inputCheck(allInputOk); // 한번 더 있어줘야함...
  };

  // 이용약관
  const [provisionContent1, setProvisionContent1] = useState("");
  const [provisionContent2, setProvisionContent2] = useState("");
  const [provisionContent3, setProvisionContent3] = useState("");
  const [provisionContent4, setProvisionContent4] = useState("");
  // 마케팅 활용 동의 여부
  const [advertising, setAdvertising] = useState("false");
  // 앱푸시용 devicetoken 저장
  const [userToken, setUserToken] = useState("");

  useLayoutEffect(() => {
    window.addEventListener("getToken", async (e) => {
      setUserToken(e.detail);
    });
  }, []);

  const handleAdvertising = () => {
    if ($("#agree4").prop("checked")) {
      setAdvertising("true");
    } else {
      setAdvertising("false");
    }
  };
  useLayoutEffect(() => {
    inputCheck(allInputOk);

    let url = "/api/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent1(res.data.terms[0].termsdescription);
      setProvisionContent2(res.data.terms[1].termsdescription);
      setProvisionContent3(res.data.terms[2].termsdescription);
      setProvisionContent4(res.data.terms[3].termsdescription);
    });
    // eslint-disable-next-line
  }, [allInputOk]);

  let inputCheck = (allInputOk) => {
    if (allInputOk) {
      // 모달창 내용 변경
      if (!$("#agreeAge").prop("checked")) {
        setMessage("14세 미만은 가입할 수 없습니다.");
        openModal("modal1");
        $(".login_form .warning.age").text("만 14세 이상부터 이용 가능합니다.");
        return;
      } else if ($("#agreeAge").prop("checked")) {
        $(".login_form .warning.age").text("");
      }
      if (!$("#agree1").prop("checked")) {
        setMessage("서비스 이용약관에 동의해주세요.");
        openModal("modal1");
      } else if (!$("#agree2").prop("checked")) {
        setMessage("개인정보수집 및 이용에 동의해주세요.");
        openModal("modal1");
      } else {
        // 빠르게 두번 클릭하니까 중복가입돼서
        if (buttonHandle === 0) {
          setButtonHandle((num) => ++num);
          let url = "/api/join";
          let data = {
            memid: strEncoding(joinInfo.joinEmail),
            password: strEncoding(joinInfo.joinPw),
            memname: strEncoding(joinInfo.joinNickname),
            memjointype: "",
            cellno: strEncoding(joinInfo.cellno),
            memrealname: strEncoding(joinInfo.memrealname),
            successtag: joinInfo.successtag,
            advertising: advertising,
            deviceToken: userToken,
            memjoinpath: joinInfo.memJoinPath,
          };

          switch (type) {
            case "email":
              url = "/api/call/mail";
              let mailData = { memid: data.memid };
              axios.post(url, mailData).then((res) => {
                if (res.data.result === "success") {
                  save_idx_local(res.data.idx);
                  data.memjointype = "이메일";
                  history.push({
                    pathname: "/join/email/auth",
                    user: data,
                  });
                } else {
                  alert("이메일 발송에 실패하였습니다.");
                }
              });

              break;

            case "kakao":
              data.memjointype = "카카오톡";
              data.token = joinInfo.token;
              axios.post(url, data).then((res) => {
                if (res.data.result === "success") {
                  save_idx_local(res.data.idx);
                  history.push("/join/add");
                } else {
                  alert("가입에 실패하였습니다.");
                }
              });
              break;

            case "naver":
              data.memjointype = "네이버";
              data.token = joinInfo.token;
              axios.post(url, data).then((res) => {
                if (res.data.result === "success") {
                  save_idx_local(res.data.idx);
                  history.push("/join/add");
                } else {
                  alert("가입에 실패하였습니다.");
                }
              });
              break;
            case "apple":
              data.memjointype = "애플";
              data.token = joinInfo.token;

              axios.post(url, data).then((res) => {
                if (res.data.result === "success") {
                  save_idx_local(res.data.idx);
                  history.push("/join/add");
                } else {
                  alert("가입에 실패하였습니다.");
                }
              });
              break;

            default:
              break;
          }
        }
      }
    }
  };
  // 회원가입 후 관리주소 등록 위해 idx저장
  let save_idx_local = (idx) => {
    setCookie("x_auth", idx, saveLimitTime());

    // localStorage.setItem("x_auth", idx);
  };

  // 모달 title명 변경
  const [message, setMessage] = useState("");

  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 전체 동의 클릭 시 서비스 이용약관 체크박스 전체 선택
  const [agree, setAgree] = useState(false);
  const [checkboxControl, setcheckboxControl] = useState(0);
  const changeAgree = () => {
    setAgree(!agree);
    if ($("#agreeAll").prop("checked")) {
      $("input[name=chk]").prop("checked", true);
      setcheckboxControl(3);
    } else {
      $("input[name=chk]").prop("checked", false);
      setcheckboxControl(0);
    }
  };

  // 동의하기 체크 시 변경
  const allAgreeHandler = (e) => {
    if (e.target.checked) {
      setcheckboxControl((num) => ++num);
    } else {
      setcheckboxControl((num) => --num);
    }
  };

  // 개별 체크박스 활성화 시 전체동의 활성화/비활성화
  // 현재는 체크박스가 3개인데, 4개로 늘어나면 밑의 숫자를 4로 바꿔야함
  useLayoutEffect(() => {
    if (checkboxControl >= 3 ? setAgree(true) : setAgree(false));
  }, [checkboxControl]);

  return (
    <>
      <div className="checkbox_wrap main">
        <input id="agreeAge" type="checkbox" />
        <label htmlFor="agreeAge">만 14세 이상입니다.</label>
      </div>
      <div className="warning age"></div>
      <div className="checkbox_wrap main">
        <input
          id="agreeAll"
          type="checkbox"
          onClick={() => {
            changeAgree();
            handleAdvertising();
          }}
          checked={agree}
        />
        <label htmlFor="agreeAll">전체동의</label>
      </div>
      <div className="provision">
        <div className="column">
          <div className="checkbox_wrap sub" onChange={allAgreeHandler}>
            <input id="agree1" type="checkbox" name="chk" />
            <label htmlFor="agree1">서비스 이용약관(필수)</label>
          </div>
          <img src={next} alt="next" onClick={() => openModal("modal2")} />
        </div>
        <div className="column">
          <div className="checkbox_wrap sub" onChange={allAgreeHandler}>
            <input id="agree2" type="checkbox" name="chk" />
            <label htmlFor="agree2">개인정보수집 및 이용 동의(필수)</label>
          </div>
          <img src={next} alt="next" onClick={() => openModal("modal3")} />
        </div>
        <div className="column" style={{ display: "none" }}>
          <div className="checkbox_wrap sub" onChange={allAgreeHandler}>
            <input id="agree3" type="checkbox" name="chk" />
            <label htmlFor="agree3">개인정보 제3자 제공 동의(선택)</label>
          </div>
          <img src={next} alt="next" onClick={() => openModal("modal4")} />
        </div>
        <div className="column">
          <div className="checkbox_wrap sub" onChange={allAgreeHandler}>
            <input
              id="agree4"
              type="checkbox"
              name="chk"
              onClick={handleAdvertising}
            />
            <label htmlFor="agree4">마케팅 활용 동의(선택)</label>
          </div>
          <img src={next} alt="next" onClick={() => openModal("modal5")} />
        </div>
      </div>
      <div className="btn_column">
        <button
          className={nextStep ? "next_btn active btn" : "next_btn btn"}
          onClick={checkVal}
        >
          다음
        </button>
      </div>
      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct age_limit">
          <h2>{message}</h2>
          <div className="modal_info">회원정보를 다시 확인 바랍니다.</div>
          <div
            className="blue_btn btn close"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal2"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <h2>서비스 이용약관</h2>
          <div className="article_column">
            <textarea value={provisionContent1 || ""} readOnly></textarea>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal3"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal3")}
          />
          <h2>개인정보수집 및 이용 동의</h2>
          <div className="article_column">
            <textarea value={provisionContent2 || ""} readOnly></textarea>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal4"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal4")}
          />
          <h2>개인정보 제3자 제공 동의</h2>
          <div className="article_column">
            <textarea value={provisionContent3 || ""} readOnly></textarea>
          </div>
        </div>
      </Modal>
      <Modal open={modal["modal5"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal5")}
          />
          <h2>마케팅 활용 동의</h2>
          <div className="article_column">
            <textarea value={provisionContent4 || ""} readOnly></textarea>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Provision;
