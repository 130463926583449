import React, {useLayoutEffect, useState} from "react";
import {BrowserRouter, Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {mailScheduleList} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";
import SideMenu from "./SideMenu";
import {changeStringToDateFormat} from "../../Common/lib";
import Header from "../Header/Header";

function SendMailScheduleList(){
    const dispatch = useDispatch();

    const [scheduleList, setScheduleList] = useState([]);
    // const [scheduleListCnt, setScheduleListCnt] = useState(0);
    const [pageOfItems, setPageOfItems] = useState([]); //페이징
    const [status, setStatus] = useState("A");


    useLayoutEffect(() => {
        let body = {
          status: status,
        };
       //전체 예약목록 가져오기
       dispatch(mailScheduleList(body)).then((res) => {
           if(res.payload.scheduleList){
               const result =  res.payload.scheduleList;
               setScheduleList(result);
               // setScheduleListCnt(result.length);
           }
       });
    }, [dispatch, status]);


    //상태값 분류
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    //페이징 처리
    const onChangePage = (pageOfItems) => {
        setPageOfItems(pageOfItems);
    };


    const pageOfItem = pageOfItems.map((item) => {

        return (
            <tr key={item.idx}>
                <td style={{ width: "10%" }}>{item.rownum}</td>
                <td style={{ width: "10%" }}>{changeStringToDateFormat(item.schdy)}</td>
                <td style={{ width: "10%" }}>{item.grp}</td>
                <td>
                    {item.stt === "R" ? (
                        <BrowserRouter forceRefresh={true}>
                            <Link to={`/rf_zib_admin/set/mail/schedule/detail/${item.schidx}`}>
                                <span className="btn">{item.title}</span>
                            </Link>
                        </BrowserRouter>
                    ) : (<span>{item.title}</span>)}
                </td>
                <td style={{ width: "10%" }}>{changeTrancycToKorean(item.trancyc)}</td>
                <td style={{ width: "10%" }}>{item.memname}</td>
                <td style={{ width: "10%" }}>{item.stt === "R" ? "준비" : "완료"}</td>
            </tr>
        );
    });



    return (
        <>
            <Header />
            <div className="admin_container" id="addressDetail">
                <SideMenu tabActive={4} />
                <div className="main_container" id="setVersion">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <ul className="detail_user_tab">
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail">
                                    <li className="btn">발송</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/schedule">
                                    <li className="btn active">예약목록</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/history">
                                    <li className="btn">발송내역</li>
                                </Link>
                            </BrowserRouter>
                        </ul>

                        <div className="address_detail">
                            <div className="user_list_column">
                                <div className="select_column">
                                    <div className="select_name">상태</div>
                                    <select onChange={handleStatus}>
                                        <option value="A">전체</option>
                                        <option value="R">준비</option>
                                        <option value="D">완료</option>
                                    </select>
                                </div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th style={{ width: "10%" }}>No.</th>
                                        <th style={{ width: "10%" }}>예약일자</th>
                                        <th style={{ width: "10%" }}>수신그룹</th>
                                        <th>제목</th>
                                        <th style={{ width: "10%" }}>발송주기</th>
                                        <th style={{ width: "10%" }}>관리자</th>
                                        <th style={{ width: "10%" }}>상태</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageOfItem.length > 0 ? (pageOfItem) : (
                                        <tr>
                                            <td colSpan={7}>등록된 글이 없습니다.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <PaginationNum
                                    items={scheduleList}
                                    onChangePage={onChangePage}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default SendMailScheduleList;

//발송주기 한글로 변경
function changeTrancycToKorean(str) {
    let lastStr = str.substr(-1, 1);
    let strLength = str.length;
    let trancycNumber = str.substr(0, strLength - 1);
    let trancycKorean;

    if (lastStr === "D") {
        trancycKorean = trancycNumber + "일";
    } else if (lastStr === "M") {
        trancycKorean = trancycNumber + "월";
    } else if (lastStr === "Y") {
        trancycKorean = trancycNumber + "년";
    } else {
        trancycKorean = "주기없음";
    }
    return trancycKorean;
}

