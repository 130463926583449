import React, { useState, useLayoutEffect } from "react";
import inquiry from "../../images/inquiry.png";
import logout from "../../images/logout.png";
import kakao from "../../images/kakao.png";
import kakaoStory from "../../images/kakao_story.png";
import line from "../../images/line.png";
import url from "../../images/url.png";
import sms from "../../images/sms.png";
import band from "../../images/band.png";
import facebook from "../../images/facebook.png";
import close from "../../images/close.png";
import next from "../../images/next.png";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import { data1, data3 } from "./SidebarData";
import Modal from "../../components/Modal";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { getAlarmCount } from "../../_actions/user_action";
import { isIOS } from "react-device-detect";
import { deleteCookie, getCookie } from "../../Common/lib";

function Sidebar() {
  const x_auth = getCookie("x_auth");
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const allCount = useSelector((store) => store.adminInfo);
  let dispatch = useDispatch();
  let history = useHistory();

  const currentPath = window.location.pathname;
  const shareUrl = "https://zibfine.com";
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  useLayoutEffect(() => {
    getVersion();
    getCounselingNonRead();
    getInquiryNonRead();
    getAlarmNonRead();
    // eslint-disable-next-line
  }, []);

  // 앱 버전 가져오기
  let getVersion = () => {
    let url = "/api/admin/set/version";
    axios.get(url).then((res) => {
      setAndroidVersion(res.data.version[0].version);
      setIosVersion(res.data.version[1].version);
    });
  };

  // 법률상담 읽지 않은 알림 개수 가져오기
  let getCounselingNonRead = () => {
    let url = "/api/mypage/counseling/count";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("counseling", res.data.counselingCount));
    });
  };

  // 문의글 읽지 않은 알림 개수 가져오기
  let getInquiryNonRead = () => {
    let url = "/api/mypage/inquiry/count";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("inquiry", res.data.inquiryCount));
    });
  };
  // 읽지 않은 알림 개수 가져오기
  let getAlarmNonRead = () => {
    let url = "/api/alarm/count";
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("alarm", res.data.alarmCount));
    });
  };

  // 앱버전 가져오기
  const [androidVersion, setAndroidVersion] = useState("0.0");
  const [iosVersion, setIosVersion] = useState("0.0");

  // 로그아웃
  const handleLogout = () => {
    let url = "/api/logout";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      // alert(res.data.result);
      if (res.data.result === "success") {
        localStorage.clear();
        deleteCookie("x_auth");
        history.push("/login");
        window.location.reload();
      } else if (res.data.result === "noneLogin") {
        alert("로그인 정보가 없습니다.");
        localStorage.clear();
        deleteCookie("x_auth");
        history.push("/");
      } else {
        alert("로그아웃에 실패하였습니다");
      }
    });
  };

  // url 복사하기
  const copy = () => {
    const t = document.createElement("textarea");
    document.body.appendChild(t);
    t.value = shareUrl;
    t.select();
    document.execCommand("copy");
    document.body.removeChild(t);
    alert("URL이 클립보드에 복사되었습니다");
  };

  // 페이스북 공유하기
  const facebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "페이스북 공유하기",
      "width=600,height=800,location=no,status=no,scrollbars=yes"
    );
  };

  // 카카오스토리 공유하기
  const kakaoStoryShare = () => {
    window.Kakao.Story.share({
      url: shareUrl,
      text: "나도 모르게 변경되는 등기부등본 이제 변경되면 바로 알림 받으세요.",
    });
  };

  // 네이버 밴드 공유하기
  const bandShare = () => {
    window.open(
      `http://www.band.us/plugin/share?body=${shareUrl}&route=${shareUrl}`,
      "share_band",
      "width=410, height=540, resizable=no"
    );
  };

  // 라인 공유하기
  const lineShare = () => {
    window.open(
      `https://social-plugins.line.me/lineit/share?url=${shareUrl}`,
      "share_line",
      "width=500, height=540, resizable=no"
    );
  };
  useLayoutEffect(() => {
    // 카카오톡 공유하기
    window.Kakao.Link.createDefaultButton({
      container: "#kakao-share",
      objectType: "feed",
      content: {
        title: "집파인",
        description:
          "나도 모르게 변경되는 등기부등본 이제 변경되면 바로 알림 받으세요.",
        imageUrl: "https://zibfine.com/static/media/logo.f944934c.svg",
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 286,
        commentCount: 45,
        sharedCount: 845,
      },
      buttons: [
        {
          title: "웹으로 보기",
          link: {
            webUrl: shareUrl,
          },
        },
      ],
    });
  }, []);

  const checkMobile = () => {
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1) {
      //안드로이드
      return "android";
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1
    ) {
      //IOS
      return "ios";
    } else {
      //아이폰, 안드로이드 외
      return "other";
    }
  };

  // sms 공유하기
  const smsShare = () => {
    let message = shareUrl;
    if (checkMobile() === "other") {
      alert("모바일에서 사용 가능합니다.");
      return;
    }
    window.location.href =
      "sms:" + (checkMobile() === "ios" ? "&" : "?") + "body=" + message;
  };

  const data1List = data1.map((item, index) => {
    let count = 0;
    switch (item.no) {
      case 4: //"내글보기",
        count = allCount.counselingCount;
        break;
      case 5: //"알림보기",
        count = allCount.alarmCount;
        break;

      default:
        count = 0;
        break;
    }
    return (
      <BrowserRouter forceRefresh={true} key={index}>
        <Link to={item.path}>
          <li
            className={
              item.path === currentPath || currentPath.includes(item.basicPath)
                ? "active"
                : null
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {isMobile ? null : <div className="dot"></div>}
              {item.title}
              {item.count ? <div className="inquiry_count">{count}</div> : null}
            </div>
            {isMobile ? <img src={next} alt="next" /> : null}
          </li>
        </Link>
      </BrowserRouter>
    );
  });
  return (
    <>
      <div className="side_menu">
        <ul>
          {data1List}
          <li onClick={() => openModal("modal1")}>
            {isMobile ? null : <div className="dot"></div>}
            추천하기
            {isMobile ? <img src={next} alt="next" /> : null}
          </li>
        </ul>

        {/* <ul>
          {data2.map((item, index) => {
            return (
              <Link to={item.path} key={index}>
                <li
                  className={
                    item.path === currentPath ||
                    currentPath.includes(item.basicPath)
                      ? "active"
                      : null
                  }
                >
                  {isMobile ? null : <div className="dot"></div>}
                  {item.title}
                  {isMobile ? <img src={next} alt="next" /> : null}
                </li>
              </Link>
            );
          })}
        </ul> */}

        <ul>
          {data3.map((item, index) => {
            return (
              <BrowserRouter forceRefresh={true} key={index}>
                <Link to={item.path}>
                  <li className={item.path === currentPath ? "active" : null}>
                    {isMobile ? null : <div className="dot"></div>}
                    {item.title}
                    {isMobile ? <img src={next} alt="next" /> : null}
                  </li>
                </Link>
              </BrowserRouter>
            );
          })}
        </ul>
        {/* <ul>
          {data4.map((item, index) => {
            return (
              <Link to={item.path} key={index}>
                <li className={item.path === currentPath ? "active" : null}>
                  {isMobile ? null : <div className="dot"></div>}
                  {item.title}
                  {isMobile ? <img src={next} alt="next" /> : null}
                </li>
              </Link>
            );
          })}
        </ul> */}

        <div className="side_bottom_menu">
          {isMobile ? (
            <>
              <BrowserRouter forceRefresh={true}>
                <Link to="/mypage/inquiry">
                  <ul style={{ borderBottom: "4px solid #f1f1f1" }}>
                    <li>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        문의하기
                        <div className="inquiry_count">
                          {allCount.inquiryCount}
                        </div>
                      </div>
                      <img src={next} alt="next" />
                    </li>
                  </ul>
                </Link>
              </BrowserRouter>
            </>
          ) : (
            <>
              <BrowserRouter forceRefresh={true}>
                <Link to="/mypage/inquiry">
                  <div
                    className={
                      currentPath === "/mypage/inquiry" ||
                      currentPath.includes("/mypage/inquiry")
                        ? "inquiry_wrapper active"
                        : "inquiry_wrapper"
                    }
                  >
                    <div className="dot"></div>
                    <div className="inquiry_column">
                      <div className="inquiry_icon">
                        <img src={inquiry} alt="inquiry" />
                        <div className="inquiry_count">
                          {allCount.inquiryCount}
                        </div>
                      </div>
                      문의하기
                    </div>
                  </div>
                </Link>
              </BrowserRouter>
            </>
          )}

          {isMobile ? (
            <>
              <ul>
                <li onClick={() => openModal("modal2")}>
                  로그아웃
                  <img src={next} alt="next" />
                </li>
              </ul>
              <ul>
                {isIOS ? (
                  <li>
                    iOS
                    <span>{iosVersion}</span>
                  </li>
                ) : (
                  <>
                    <li>
                      Android
                      <span>{androidVersion}</span>
                    </li>
                    <li>
                      iOS
                      <span>{iosVersion}</span>
                    </li>
                  </>
                )}
              </ul>
            </>
          ) : (
            <>
              <div
                className="logout_btn btn"
                onClick={() => openModal("modal2")}
              >
                <img src={logout} alt="logout" />
                로그아웃
              </div>
            </>
          )}
        </div>
      </div>

      <Modal open={modal["modal1"]} width={600}>
        <div className="modal_inner_ct recommend_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />

          <h2>추천하기</h2>

          <div className="grid_column">
            <div className="grid_icon" id="kakao-share">
              <img src={kakao} alt="kakao" />
              <div className="grid_icon_name">카카오톡</div>
            </div>
            <div className="grid_icon" onClick={bandShare}>
              <img src={band} alt="band" />
              <div className="grid_icon_name">밴드</div>
            </div>
            <div className="grid_icon" onClick={kakaoStoryShare}>
              <img src={kakaoStory} alt="kakaoStory" />
              <div className="grid_icon_name">카카오스토리</div>
            </div>
            <div className="grid_icon">
              <img src={line} alt="line" onClick={lineShare} />
              <div className="grid_icon_name">라인</div>
            </div>
            <div className="grid_icon" onClick={facebookShare}>
              <img src={facebook} alt="facebook" />
              <div className="grid_icon_name">페이스북</div>
            </div>
            {/* <div className="grid_icon">
              <img src={instagram} alt="instagram" />
              <div className="grid_icon_name">인스타그램</div>
            </div> */}
            <div className="grid_icon" onClick={smsShare}>
              <img src={sms} alt="sms" />
              <div className="grid_icon_name">SMS</div>
            </div>
            <div className="grid_icon" onClick={copy}>
              <img src={url} alt="url" />
              <div className="grid_icon_name">URL</div>
              <input type="text" id="ShareUrl" style={{ display: "none" }} />
            </div>
            {/* <div className="grid_icon">
              <img src={seeMore} alt="seeMore" />
              <div className="grid_icon_name">더보기</div>
            </div> */}
          </div>
          <div className="save_btn_column">
            <div className="blue_btn btn" onClick={() => closeModal("modal1")}>
              닫기
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={modal["modal2"]} width={400}>
        <div className="modal_inner_ct modal_400 is_login_modal">
          <h2 className="title">로그아웃 하시겠습니까?</h2>
          <div className="btn_column">
            <div
              className="btn blue_btn close "
              onClick={() => closeModal("modal2")}
            >
              취소
            </div>

            <div className="blue_btn btn" onClick={handleLogout}>
              로그아웃
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Sidebar;
