import React, { useLayoutEffect, useRef, useState } from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import axios from "axios";

function SetEvent() {
  let bannerInit = [
    {
      banneridx: 0,
      visible: 0,
      url: "",
    },
    {
      banneridx: 1,
      visible: 0,
      url: "",
    },
    {
      banneridx: 2,
      visible: 0,
      url: "",
    },
  ];

  const [banners, setBanners] = useState(bannerInit);
  const [filename, setFilename] = useState({
    banner_img0: "",
    banner_img1: "",
    banner_img2: "",
  });
  // 배너 이미지
  const [img, setImg] = useState([{}, {}, {}]);
  // 이미지 업로드 전 미리보기
  const [fileUrl, setFileUrl] = useState(["", "", ""]);

  useLayoutEffect(() => {
    let url = "/api/admin/set/banner";
    let data = { gubun: "event" };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        if (res.data.banners !== "false") setBanners(res.data.banners);
      } else alert("정보를 가져오지 못했습니다.");
    });
  }, []);

  // 사진 등록 클릭시 파일 선택창 open
  const fileInputs = useRef([]);

  const setFile = (e) => {
    let idx = e.target.id.substr(-1);
    const value = e.target.files[0];

    setFilename({
      ...filename,
      [e.target.id]: value.name,
    });

    let copyImg = [...img];
    value.banneridx = idx;
    copyImg.splice(idx, 1, value);
    setImg(copyImg);

    let copyImageUrl = [...fileUrl];
    copyImageUrl[idx] = URL.createObjectURL(value);
    setFileUrl(copyImageUrl);
  };

  // 배너 정보 설정
  const setBannerInfo = (e) => {
    let idx = e.target.id.substr(-1);
    let copyBanners = [...banners];
    let value, name;

    if (e.target.checked) {
      name = e.target.name.slice(0, -1);

      if (e.target.id === "show" + idx) value = 1;
      else value = 0;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    copyBanners[idx][name] = value;
    setBanners(copyBanners);
  };

  // 파일 열기
  const openFile = (index) => {
    fileInputs.current[index].click();
  };

  // 파일 삭제
  const deleteFile = (index) => {
    let url = "/api/admin/set/banner/deleteBannerImg";
    let data = {
      imagefile: banners[index].imagefile,
      banneridx: banners[index].banneridx,
      gubun: "event",
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        let copyBanners = [...banners];
        let copyImg = [...img];
        let copyFileUrl = [...fileUrl];

        fileInputs.current[index].value = "";
        copyBanners[index].imagefile = "";
        copyBanners[index].imagefilename = "";
        setBanners(copyBanners);

        copyImg[index] = {};
        setImg(copyImg);

        copyFileUrl[index] = "";
        setFileUrl(copyFileUrl);

        alert("삭제되었습니다.");
      } else {
        alert("삭제에 실패하였습니다.");
      }
    });
  };

  // 저장하기 전 삭제 버튼 클릭 시 이미지 삭제
  const deleteFileUrl = (index) => {
    let getFileUrl = fileUrl[index];
    setFileUrl(fileUrl.filter((e) => e !== getFileUrl));
    setFilename({ ...filename, ["banner_img_" + index]: "" });
    let copyImg = [...img];
    let copyFileUrl = [...fileUrl];

    copyImg[index] = {};
    setImg(copyImg);

    copyFileUrl[index] = "";
    setFileUrl(copyFileUrl);
  };

  // 저장하기
  const submitEventBanner = () => {
    let imgForm = new FormData();
    img.map((val) => {
      // form에 데이터 추가
      return imgForm.append("banner_img_" + val.banneridx, val);
    });

    let imgUrl = "/api/admin/set/banner/updateEventImg";
    let infoUrl = "/api/admin/set/banner/updateEvent";

    let data = { banners: banners };

    if ((fileUrl[0] || fileUrl[1] || fileUrl[2]) !== "") {
      axios.all([axios.post(imgUrl, imgForm), axios.post(infoUrl, data)]).then(
        axios.spread((res1, res2) => {
          let imgRs = res1.data.result;
          let infoRs = res2.data.result;
          if (imgRs === "success" && imgRs === infoRs) {
            let copyBanners = [...banners];
            res1.data.imgInfo.map((val, i) => {
              copyBanners[val.banneridx].imagefile = val.imagefile;
              copyBanners[val.banneridx].imagefilename = val.imagefilename;
              return "";
            });
            setBanners(copyBanners);
            setImg([{}, {}, {}]);
            setFileUrl(["", "", ""]);

            alert("저장되었습니다.");
          } else {
            alert("다시 시도해주세요.");
          }
        })
      );
    } else {
      axios.post(infoUrl, data).then((res) => {
        if (res.data.result === "success") alert("저장되었습니다.");
        else alert("다시 시도해주세요.");
      });
    }
  };

  // event배너 반복문
  const eventBanners =
    banners.length > 0 &&
    banners.map((val, i) => {
      return (
        <div key={"eventBanner" + i} className="detail_user_header_column">
          <div className="detail_user_header">
            <h2 className="main_container_name">이벤트 배너 {i + 1}</h2>
          </div>

          <table className="detail_table board_table single_column">
            <tbody>
              <tr>
                <th>클릭 URL</th>
                <td>
                  <input
                    type="text"
                    id={"url_" + i}
                    placeholder="URL을 입력하세요."
                    className="title"
                    name="url"
                    value={banners[i].url ? banners[i].url : ""}
                    onChange={setBannerInfo}
                  />
                </td>
              </tr>

              <tr>
                <th>이미지</th>
                <td>
                  <div className="img_column">
                    <div className="banner_img_column">
                      <img
                        src={fileUrl[i] ? fileUrl[i] : banners[i].imagefile}
                        alt="배너이미지"
                        style={{
                          display:
                            banners[i].imagefile || fileUrl[i] ? null : "none",
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      id={"banner_img_" + i}
                      name={"banner_img_" + i}
                      className="banner_img_file"
                      accept="image/*"
                      ref={(elem) => (fileInputs.current[i] = elem)}
                      onChange={setFile}
                    />
                    <div>
                      <div className="admin_black_btn_column">
                        {banners[i].imagefile ? (
                          <div
                            className="admin_black_btn btn left"
                            onClick={() => deleteFile(i)}
                          >
                            삭제
                          </div>
                        ) : fileUrl[i] ? (
                          <div
                            className="admin_black_btn btn left"
                            onClick={() => deleteFileUrl(i)}
                          >
                            삭제
                          </div>
                        ) : (
                          <div
                            className="admin_black_btn left btn"
                            onClick={() => openFile(i)}
                          >
                            업로드
                          </div>
                        )}
                      </div>
                      <div className="img_name">
                        {filename["banner_img_" + i]
                          ? filename["banner_img_" + i]
                          : banners[i]["imagefilename"]}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th>노출</th>
                <td>
                  <div className="free_user_radio">
                    <input
                      type="radio"
                      id={"show" + i}
                      name={"visible" + i}
                      value={banners[i]["visible"]}
                      checked={banners[i]["visible"] === 1}
                      onChange={setBannerInfo}
                    />
                    <label htmlFor={"show" + i}>노출중</label>

                    <input
                      type="radio"
                      id={"hidden" + i}
                      name={"visible" + i}
                      value={banners[i]["visible"]}
                      checked={banners[i]["visible"] === 0}
                      onChange={setBannerInfo}
                    />
                    <label htmlFor={"hidden" + i}>비노출</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={1} />
        <div className="main_container" id="setMain">
          {eventBanners}
          <div className="detail_user admin_btn_column" style={{ margin: 0 }}>
            <div
              className="admin_blue_btn btn"
              onClick={() => {
                submitEventBanner();
              }}
            >
              저장
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetEvent;
