import React, { useState } from "react";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import ServicePushSend from "./ServicePushSend";
import ServicePushList from "./ServicePushList";

function ServicePush() {
  const obj = {
    0: <ServicePushSend />,
    1: <ServicePushList />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={4} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">알림발송</h2>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  발송
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  발송 내역
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicePush;
