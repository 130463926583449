import React, {useState, useLayoutEffect, useEffect} from "react";
import {useDispatch} from "react-redux";
import {adminSendSms, userLawyerList} from "../../_actions/admin_board_action";
import {BrowserRouter, Link} from "react-router-dom";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import $ from "jquery";
//toast plugins
import 'tui-color-picker/dist/tui-color-picker.css'


function SendSmsWrite(){

    const dispatch = useDispatch();

    const [lawList, setLawList] = useState();                               //변호사 리스트
    const [selectLawNum, setSelectLawNum] = useState({num:"", name:""});    //선택한 변호사 전번, 이름
    const [bytes, setBytes] =  useState(0);

    let smsText = "님, 안녕하세요.\n집파인에 경매관련 게시글이 신규 등록되었습니다.\n000번 게시글에 대해 답변 부탁 드립니다.\n감사합니다.";

    useEffect(() => {
        chgText();
    },[selectLawNum]);

    useLayoutEffect(() => {
        dispatch(userLawyerList()).then((res) => {
          if (res.payload.user) {
            const result = res.payload.user;
            setLawList(result);
          }
        });

        getBytes(smsText);
      }, [dispatch]);


    //문자메시지 내용 변경
    const chgText = () => {
        $(".smsMsgBox").val(selectLawNum.name + smsText);
        getBytes(selectLawNum.name + smsText);
    }

    //선택한 변호사 핸드폰 번호
    const handleSelect = (e) => {
        setSelectLawNum({
                num : e.target.value, 
                name: e.target[e.target.selectedIndex].innerHTML,
                idx: lawList[e.target.selectedIndex-1].idx,
            }
        );
    }

    //글자(byte)수 계산
    const getBytes = (e) => {
        let item;
        if(e.target) item = e.target.value;
        else item = e;    

        let str_character;
        let char_count = 0;
        let int_contents_length = item.length;

        for (let i = 0; i < int_contents_length; i++) {

            //charAt로 문자를 쪼갠다
            str_character = item.charAt(i);
            if (escape(str_character).length > 4)
                //한글포함 특수문자는 2바이트로
                char_count += 2;
            else
                char_count++;
        }
        setBytes(char_count);
    }

    //전송하기
    const submitHandler = () => {
        
        //content 내용 html로 변경
        const content = $(".smsMsgBox").val();

        if(content === "" || content === undefined) {
            alert("내용을 입력하세요.");
            return;
        }

        if(selectLawNum.num === ""){
            alert("변호사님을 선택해주세요.");
            return;    
        }

        if(bytes > 2000){
            alert("글자 수를 초과했습니다. 2000byte 이하로 작성해주세요.");
            return; 
        }

        let admin_auth = localStorage.getItem("admin_auth");

        let body = {
            lawidx: selectLawNum.idx,
            cellno: selectLawNum.num,
            title:"문자전송 테스트",
            content: content,
            admin_auth: admin_auth,
        };
        
        if (window.confirm("메시지를 전송하시겠습니까?")) {
            //문자 발송 action 호출
            dispatch(adminSendSms(body)).then((res) => {
                if (res.payload.result === "success"){
                    alert("전송되었습니다.");
                    window.location.reload();
                } else if(res.payload.result === "reservation"){
                    alert("예약되었습니다.");
                    window.location.reload();
                } else {
                    alert("잠시후 다시 시도해주세요");
                }
            });
        }
    }

    return (
        <>
            <Header />
            <div className="admin_container" id="addressDetail">
                <SideMenu tabActive={4} />
                <div className="main_container" id="setVersion">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                문자발송
                            </h2>
                        </div>
                        <ul className="detail_user_tab">
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/sms">
                                    <li className="btn active">발송</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/sms/history">
                                    <li className="btn">발송내역</li>
                                </Link>
                            </BrowserRouter>
                        </ul>

                        <div className="detail_user_container">
                            <table className="detail_table board_table">
                                <tbody>
                                <tr>
                                    <th>수신자</th>
                                    <td colSpan={3}>
                                        <div className="free_user_radio">
                                            <select onChange={handleSelect}>
                                                <option value="">- 변호사리스트 -</option>
                                                {lawList && lawList.map((item, index) => {
                                                    return(<option key={index} value={item.cellno}>{item.memname}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="board_content">
                                    <th>메시지내용</th>
                                    <td colSpan="3">
                                        <textarea style={{width:"350px", height:"450px"}} onKeyUp={getBytes} className="smsMsgBox">{smsText}</textarea>
                                        <div>
                                           {bytes} / 2000byte
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div className="admin_blue_btn btn" onClick={submitHandler}>
                                    전송하기
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default SendSmsWrite