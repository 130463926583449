import React, { useLayoutEffect, useState } from "react";
import Header from "../../Header/Header";
import close from "../../../images/close.png";
import Modal from "../../../components/Modal";
import SideMenu from "../SideMenu";
import UserOutBasic from "./UserOutBasic";
import UserOutAddress from "./UserOutAddress";
import UserOutNotice from "./UserOutNotice";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  alarmOutMember,
  userOutDetailAddress,
} from "../../../_actions/admin_board_action";

function UserOutDetail({ match }) {
  const idx = match.params.idx;

  let history = useHistory();

  // 회원 탈퇴처리
  let deleteSubmit = () => {
    let url = "/api/admin/user/out/removeUser";
    let data = {
      delIdx: idx,
    };

    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert("해당 회원의 삭제 처리가 완료되었습니다.");
        history.push("/rf_zib_admin/user/out/1");
      }
    });
  };

  const obj = {
    0: <UserOutBasic />,
    1: <UserOutAddress />,
    2: <UserOutNotice />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };
  const dispatch = useDispatch();
  const [addrCnt, setAddrCnt] = useState(0);
  const [alarmCnt, setAlarmCnt] = useState(0);
  useLayoutEffect(() => {
    let body = {
      memidx: idx,
    };
    // 관리주소 갯수 가져오기
    dispatch(userOutDetailAddress(body)).then((res) => {
      if (res.payload.addrList) {
        const result = res.payload.addrList;
        setAddrCnt(result.length);
      }
    });

    // 알림 갯수 가져오기
    dispatch(alarmOutMember(body)).then((res) => {
      const result = res.payload.alarmList;
      if (res.payload.alarmList) {
        setAlarmCnt(result.length);
      }
    });
  }, [dispatch, idx]);

  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">회원 상세 정보</h2>
                <div className="user_control_column">
                  <div
                    className="admin_delete_btn btn"
                    onClick={() => openModal("modal1")}
                  >
                    회원 완전 삭제
                  </div>
                </div>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  기본정보
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  관리주소 {addrCnt}
                </li>
                <li
                  onClick={() => clickTab(2)}
                  className={tabActive === 2 ? "active" : null}
                >
                  알림 {alarmCnt}
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>

      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal ">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_desc">
            완전 삭제 시 더 이상 <br />
            정보를 볼 수 없습니다. <br />
            <br />
            정말 삭제하시겠습니까?
          </div>
          <div className="admin_btn_column">
            <div
              className="admin_delete_btn btn left"
              onClick={() => {
                deleteSubmit();
              }}
            >
              완전 삭제
            </div>
            <div
              className="admin_delete_btn btn close_btn"
              onClick={() => closeModal("modal1")}
            >
              취소
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserOutDetail;
