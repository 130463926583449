import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./slider.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import place from "../images/place.png";
import mainSetting from "../images/main_setting.png";
import { BrowserRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getManageAddress } from "../_actions/main_action";
import SliderMainBanner from "../components/SliderMainBanner";
import { useLayoutEffect } from "react";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const { kakao } = window;

export default function SliderAddress({ memname }) {
  const dispatch = useDispatch();
  const [lists, setLists] = useState();

  useLayoutEffect(() => {
    dispatch(getManageAddress()).then((res) => {
      if (res.payload.result !== "false") {
        let result = res.payload.address;
        setLists(result);

        result &&
          result.map((list, index) => {
            // local에서는 안뜸
            if (kakao && kakao.maps) {
              const mapContainer = document.getElementById("map" + index), // 지도를 표시할 div
                mapOption = {
                  center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
                  level: 3, // 지도의 확대 레벨
                };
              const map = new kakao.maps.Map(mapContainer, mapOption);
              const geocoder = new kakao.maps.services.Geocoder();
              // 주소로 좌표를 검색합니다
              geocoder.addressSearch(list.addr, function (result, status) {
                // 정상적으로 검색이 완료됐으면
                if (status === kakao.maps.services.Status.OK) {
                  var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

                  new kakao.maps.Marker({
                    map: map,
                    position: coords,
                  });
                  map.setCenter(coords);
                }
              });
              return "";
            }
            return "";
          });
      }
    });
  }, [dispatch]);
  return (
    <>
      {lists ? (
        <div className="login_section_2 ">
          <div className="slide_section">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              className={lists.length > 0 ? "mySwiper" : "mySwiper single"}
            >
              {lists &&
                lists.map((list, index) => {
                  // let addrucodeList = list.addrucode;

                  return (
                    <SwiperSlide key={index}>
                      <div className="slide_content ">
                        <div className="slide_top_content">
                          <div className="left">
                            <div className="title">
                              <img src={place} alt="place" />
                              <strong className="isMobile_strong">
                                {memname}
                              </strong>
                              님 관리주소
                            </div>
                            <div className="address_name">
                              <span>{list.addrnickname}</span>
                              <div
                                className="admin_state"
                                style={{
                                  background:
                                    list.addrtype === "승인완료" ? "#1c8ee7"
                                      : list.addrtype === "미승인" && list.addrtype2 !== null ? "#4e4e4e" 
                                      : null ,
                                }}
                              >
                                {
                                  list.addrtype === "승인완료" ? "승인완료" 
                                  : list.addrtype === "미승인" && list.addrtype2 !== null ? "승인거절" 
                                  : "관리자 확인 중"
                                }
                              </div>
                              <div style={{color:"red", marginLeft:10, fontSize:12}}>{list.addrtype === "미승인" && list.addrtype2 !== null && "* " + list.etcomment}</div>
                            </div>

                            <div className="address">
                              <div>{list.addr}</div>
                              <div>{list.addrsub}</div>
                            </div>

                            {list.addrtype === "승인완료" && list.addrucode && (
                              <div className="serial_number">
                                고유번호 &nbsp;
                                {list.addrucode[0]}
                                {list.addrucode.length > 1 &&
                                  " 외 " + (list.addrucode.length - 1)}
                              </div>
                            )}

                            <div className="btn_column">
                              <BrowserRouter forceRefresh={true}>
                                <Link to={`/mypage/address?tab=${index}`}>
                                  <div className="set_btn">
                                    <img src={mainSetting} alt="setting" />
                                    설정
                                  </div>
                                </Link>
                              </BrowserRouter>
                              {/* <BrowserRouter forceRefresh={true}>
                                <Link to="/mypage/address?tab=all">
                                  <div className="add_btn">
                                    <img src={mainAdd} alt="add" />
                                    주소추가
                                  </div>
                                </Link>
                              </BrowserRouter> */}
                            </div>
                          </div>

                          <div className="right">
                            <div id={"map" + index} className="slider_map">
                              {/* <img src={mainMap} alt="map" className="map" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      ) : (
        <SliderMainBanner />
      )}
    </>
  );
}
