import React, { useLayoutEffect, useState } from "react";
import "./Main.css";

import axios from "axios";

function Home() {
  // 이용약관
  const [provisionContent1, setProvisionContent1] = useState("");
  // eslint-disable-next-line
  const [provisionContent2, setProvisionContent2] = useState("");

  useLayoutEffect(() => {
    let url = "/api/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent1(res.data.terms[0].termsdescription);
      setProvisionContent2(res.data.terms[1].termsdescription);
    });
  }, []);
  return (
    <>
      <div className="root_container" style={{ padding: "20px" }}>
        {provisionContent1 &&
          provisionContent1.split("\n").map((line) => {
            return (
              <p
                style={{
                  width: "100%",
                  "word-break": "break-all",
                  "line-height": "22px",
                }}
              >
                {line}
                <br />
              </p>
            );
          })}
      </div>
    </>
  );
}

export default Home;
