import React, { useLayoutEffect, useRef, useState } from "react";
import {
  boardDivisionList,
  serviceEventAdd,
} from "../../_actions/admin_board_action";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import { adminGetToday } from "../../Common/lib";
function ServiceEventAdd({ history }) {
  const dispatch = useDispatch();

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const goBack = () => {
    history.goBack();
  };

  // 카테고리 리스트
  const [categories, setCategories] = useState([]);
  const [Categorie, setCategorie] = useState(""); // 선택한 카테고리
  useLayoutEffect(() => {
    let body = {
      kind: "이벤트",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
        setCategorie(result[0].name);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch]);

  const [Title, setTitle] = useState(""); // 제목
  const [Content, setContent] = useState(""); // 내용
  const [Visible, setVisible] = useState("노출");
  const [Ongoing, setOngoing] = useState("진행중");
  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "title":
        setTitle(e.target.value);
        return;
      case "content":
        setContent(e.target.value);
        return;
      case "categorie":
        setCategorie(e.target.value);
        return;
      case "chkVisible":
        setVisible(e.target.value);
        return;
      case "chkOngoin":
        setOngoing(e.target.value);
        return;

      default:
        return;
    }
  };

  // 달력 시작일 셋팅
  const [eventDate, setEventDate] = useState({
    start: adminGetToday(),
    end: adminGetToday(),
  });

  const handleEventDate = (e) => {
    setEventDate({ ...eventDate, [e.target.name]: e.target.value });
  };

  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  const [FileInfo, setFileInfo] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
  };

  const deleteFileUrl = () => {
    setFileInfo(null);
    setFileUrl("");
  };
  // 저장하기
  const handelSubmit = () => {
    if (Title === "") {
      alert("제목을 입력하세요");
      handleFocus(0);
      return;
    } else if (Content === "") {
      alert("내용을 입력하세요");
      handleFocus(1);
      return;
    }

    const startDate = eventDate["start"].split("-");
    const endDate = eventDate["end"].split("-");

    var newStartDate = new Date(startDate[0], startDate[1] - 1, startDate[2]);
    var newEndDate = new Date(endDate[0], endDate[1] - 1, endDate[2]);

    if (!(newStartDate.getTime() < newEndDate.getTime())) {
      alert("이벤트 종료일보다 빠른 날짜를 선택하세요.");
      return;
    }
    let admin_auth = localStorage.getItem("admin_auth");

    //폼에 다 넣기 위해서 반복문 돌릴 배열 선언
    let bodyNameList = [
      "gubun",
      "title",
      "content",
      "datestart",
      "dateend",
      "visible",
      "status",
      "eventImg",
      "admin_auth",
    ];
    let bodyValueList = [
      Categorie,
      Title,
      Content,
      eventDate["start"],
      eventDate["end"],
      Visible === "노출" ? 1 : 0,
      Ongoing === "진행중" ? 1 : 0,
      FileInfo,
      admin_auth,
    ];
    let formData = new FormData();

    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      return formData.append(val, bodyValueList[i]);
    });
    // // 폼에 들어있는 내용 확인하기(확인하시면 지워도 됩니당);

    dispatch(serviceEventAdd(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={1} />
        <div className="main_container add_event">
          <div className="detail_user_header">
            <h2 className="main_container_name">이벤트 등록</h2>
          </div>

          <table className="detail_table board_table single_column">
            <tbody>
              <tr>
                <th>구분</th>
                <td>
                  <select name="categorie" onChange={inputHandler}>
                    {categories.map((item, index) => {
                      return (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    type="text"
                    name="title"
                    onChange={inputHandler}
                    placeholder="제목을 입력하세요."
                    className="title"
                    ref={(elem) => (inputRefs.current[0] = elem)}
                  />
                </td>
              </tr>

              <tr>
                <th>이미지</th>
                <td>
                  <div
                    className="img_column"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div className="sample_img">
                      {fileUrl && fileUrl !== "/api" ? (
                        <img
                          alt="sample"
                          src={fileUrl}
                          style={{ margin: "auto" }}
                        />
                      ) : null}

                      {/* <img alt="uploadImg" src={fileUrl} /> */}
                      <input
                        name="eventImg"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        ref={(elem) => (fileInputs.current = elem)}
                        onChange={setFile}
                      />
                    </div>
                    <div
                      className="admin_black_btn_column"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="admin_black_btn left btn"
                        onClick={() => openFile()}
                      >
                        업로드
                      </div>
                      <div
                        className="admin_black_btn btn left"
                        onClick={() => deleteFileUrl()}
                      >
                        삭제
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>기간</th>
                <td>
                  <div className="event_period_column">
                    <input
                      type="date"
                      name="start"
                      value={eventDate["start"]}
                      onChange={handleEventDate}
                      className="calendar_data"
                    />
                    <span>~</span>
                    <input
                      type="date"
                      name="end"
                      value={eventDate["end"]}
                      onChange={handleEventDate}
                      className="calendar_data"
                    />
                  </div>
                </td>
              </tr>
              <tr className="board_content">
                <th>내용</th>
                <td colSpan="3">
                  <textarea
                    name="content"
                    onChange={inputHandler}
                    ref={(elem) => (inputRefs.current[1] = elem)}
                    placeholder="내용을 입력하세요."
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>노출</th>
                <td>
                  <div className="free_user_radio">
                    <input
                      type="radio"
                      defaultChecked
                      id="radio1"
                      name="chkVisible"
                      value="노출"
                      onClick={inputHandler}
                    />
                    <label htmlFor="radio1">노출</label>
                    <input
                      type="radio"
                      id="radio2"
                      name="chkVisible"
                      value="비노출"
                      onClick={inputHandler}
                    />
                    <label htmlFor="radio2">비노출</label>
                  </div>
                </td>
              </tr>
              {/* <tr>
                <th>상태</th>
                <td>
                  <div className="free_user_radio">
                    <input
                      type="radio"
                      defaultChecked
                      id="radio3"
                      name="chkOngoin"
                      value="진행중"
                      onClick={inputHandler}
                    />
                    <label htmlFor="radio3">진행중</label>
                    <input
                      type="radio"
                      id="radio4"
                      name="chkOngoin"
                      value="종료"
                      onClick={inputHandler}
                    />
                    <label htmlFor="radio4">종료</label>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>

          <div className="detail_user admin_btn_column" style={{ margin: 0 }}>
            <div className="admin_white_btn btn left" onClick={goBack}>
              목록
            </div>
            <div className="admin_blue_btn btn" onClick={handelSubmit}>
              저장
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceEventAdd;
