import React, {useLayoutEffect, useState} from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {useDispatch} from "react-redux";
import {adminGetToday, changeStringToDateFormat} from "../../Common/lib";
import {
    mailScheduleDelete,
    mailScheduleDetail,
    mailScheduleModify
} from "../../_actions/admin_board_action";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";


function SendMailScheduleDetail({match, history}) {
    const dispatch = useDispatch();
    const idx = match.params.idx;


    //toast editor 변수
    const editorRef = React.createRef();

    //뒤로가기
    const goBack = () => {
        history.goBack();
    };

    // const [retransmission, setRetransmission] = useState("N");
    // const [meth, setMeth] = useState("M");
    const [group, setGroup] = useState("전체");
    const [noticeName, setNoticeName] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [tranCycle, setTranCycle] = useState("N");
    const [tranStartDate, setTranStartDate] = useState(adminGetToday());
    const [schDate, setSchDate] = useState(adminGetToday());

    const [submitData, setSubmitData] = useState({});  //불러온 데이터 담아줌
    const [showChild, setShowChild] = useState(false); //toast ui 렌더링


    //기존 값 불러오기
    useLayoutEffect(() => {
       let body = {
           idx: idx,
       };

       dispatch(mailScheduleDetail(body)).then((res) => {
         if(res.payload.schedule) {
            const result = res.payload.schedule[0];
            setSubmitData(result);
            setGroup(result.grp);
            setTitle(result.title);
            setContent(result.cont);
            setTranStartDate(changeStringToDateFormat(result.transtdy));
            setSchDate(changeStringToDateFormat(result.transtdy));

            //렌더링 조건걸기 (true 되어야지 editor 렌더링됨)
            setShowChild(true);

            //발송주기 value 설정
            switch(result.trancyc){
                case "N": setTranCycle("N"); break;
                case "D": setTranCycle("D"); break;
                case "M": setTranCycle("6M"); break;
                case "Y": setTranCycle("1Y"); break;
                default: break;
            }

            //메일 종류 value 설정
             switch(result.nm) {
                 case "개인정보 이용내역" : setNoticeName("1"); break;
                 case "이용약관 변경": setNoticeName("2"); break;
                 case "개인정보 처리방침 변경": setNoticeName("3"); break;
                 case "기타" : setNoticeName("4"); break;
                 default: break;
             }

         }
       });

    }, [dispatch, idx]);




    //input 값 제어
    const inputHandler = (e) => {
        let el = e.target.name;
        switch (el){
            case "tranCycle": setTranCycle(e.target.value); return;
            case "tranStartDate": setTranStartDate(e.target.value); setSchDate(e.target.value); return;
            case "noticeName": setNoticeName(e.target.value); return;
            case "title": setTitle(e.target.value); return;
            case "content": setContent(e.target.value); return;
            case "group": setGroup(e.target.value); return;
            default: return;
        }

    };


    //저장하기
    const saveHandler = () => {
        //content 내용 html로 변경
        const content_html = editorRef.current.getInstance().getHTML();

        if(noticeName === "") {
            alert("메일종류를 입력하세요.");
            return;
        } else if (title === "") {
            alert("제목을 입력하세요.");
            return;
        } else if (content.includes("<span class=\"placeholder ProseMirror-widget\" contenteditable=\"false\">내용을 입력하세요.</span>")) {
            alert("내용을 입력하세요.");
            return;
        }

        let admin_auth = localStorage.getItem("admin_auth");
        let del = "N";

        let body = {
            nm: noticeName,
            grp: group,
            title: title,
            cont: content_html,
            trancyc: tranCycle,
            transtdy: tranStartDate.replace(/-/gim, ""),   //하이픈 제거
            del: del,
            idx: submitData.lstidx,
            schidx: idx,
            admin_auth: admin_auth,
            schdate: schDate,
        };

        //action 호출
        dispatch(mailScheduleModify(body)).then((res) => {
            if (res.payload.result === "success") {
                alert("수정되었습니다.");
                document.location.href = '/rf_zib_admin/set/mail/schedule';
            } else {
                alert("수정실패");
            }
        });


    };

    //예약삭제
    const deleteHandler = () => {
        let result = window.confirm("예약내역을 삭제하시겠습니까?");
        if(result){
           let body = {
                idx : idx,
            };
            dispatch(mailScheduleDelete(body)).then((res) => {
                if(res.payload.result === "success") {
                    alert("삭제하였습니다.");
                    document.location.href = '/rf_zib_admin/set/mail/schedule';
                } else {
                    alert("삭제실패");
                }
            });
        } else {
            alert("취소하였습니다.");
        }

    };


    return (
        <>
            <Header />
            <div className="admin_container">
                <SideMenu tabActive={4} />
                <div className="main_container">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <div className="detail_user_container">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                    예약내역 상세보기
                                </h2>
                                <div className="user_control_column">
                                    <div
                                        className="admin_delete_btn btn"
                                        onClick={deleteHandler}
                                    >
                                        예약삭제
                                    </div>
                                </div>
                            </div>
                            <table className="detail_table board_table">
                                <tbody>
                                <tr>
                                    <th>수신그룹</th>
                                    <td colSpan={3}>
                                        <div className="free_user_radio">
                                            <input
                                                type="radio"
                                                id="groupAll"
                                                name="group"
                                                value="전체"
                                                onChange={inputHandler}
                                                checked={group === "전체" && true}
                                            />
                                            <label htmlFor="groupAll">전체(정상+휴면)</label>
                                            <input
                                                type="radio"
                                                id="normal"
                                                name="group"
                                                value="정상"
                                                onChange={inputHandler}
                                                checked={group === "정상" && true}
                                            />
                                            <label htmlFor="normal">정상</label>
                                            <input
                                                type="radio"
                                                id="resting"
                                                name="group"
                                                value="휴면"
                                                onChange={inputHandler}
                                                checked={group === "휴면" && true}
                                            />
                                            <label htmlFor="resting">휴면</label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>발송 주기</th>
                                    <td>
                                        <select
                                            value={tranCycle}
                                            onChange={inputHandler}
                                            name="tranCycle"
                                        >
                                            <option value="N">한 번</option>
                                            <option value="6M">6개월마다</option>
                                            <option value="1Y">1년마다</option>
                                        </select>
                                    </td>
                                    <th>시작일</th>
                                    <td>
                                        <input
                                            type="date"
                                            id="tranStartDate"
                                            name="tranStartDate"
                                            onChange={inputHandler}
                                            value = {tranStartDate}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th>메일종류</th>
                                    <td colSpan={3}>
                                        <select
                                            value={noticeName}
                                            onChange={inputHandler}
                                            name="noticeName"
                                            style={{width: "399px"}}
                                        >
                                            <option value="1">개인정보 이용내역</option>
                                            <option value="2">이용약관 변경</option>
                                            <option value="3">개인정보 처리방침 변경</option>
                                            <option value="4">기타</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            placeholder="제목을 입력하세요."
                                            id="title"
                                            name="title"
                                            onChange={inputHandler}
                                            value={title}
                                        />
                                    </td>
                                </tr>
                                <tr className="board_content">
                                    <th>내용</th>
                                    <td colSpan="3">
                                        {/*showChild가 true이면 보여줌*/}
                                        {showChild && (
                                            <Editor
                                                placeholder="내용을 입력하세요."
                                                ref={editorRef}
                                                initialValue={String(submitData.cont)}
                                                previewStyle="vertical"
                                                height="600px"
                                                initialEditType="wysiwyg"
                                                useCommandShortcut={true}
                                                usageStatistics={false}
                                                plugins={[colorSyntax,tableMergedCell]}
                                            />
                                        )}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div
                                    className="admin_white_btn btn left"
                                    onClick={goBack}
                                >
                                목록
                                </div>
                                <div className="admin_blue_btn btn" onClick={saveHandler} >
                                    저장
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMailScheduleDetail;

