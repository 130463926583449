import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../_actions/user_action";
import axios from "axios";
import { deleteCookie, getCookie } from "../Common/lib";
import { ADMIN_SUPER, ADMIN_GENERAL, ADMIN_LAWYER, ADMIN_PRIVACY } from "../Common/adminType"
import {isAdminAllow} from "../_actions/admin_board_action";

// SpecificComponent => Component(View Page)
// option =>
// null : 아무나 출입이 가능한 페이지
// true : 로그인한 유저만 출입이 가능한 페이지
// false : 로그인한 유저는 출입 불가능한 페이지
// adminRoute => 관리자 권한 부여
// null : 사용안함

// eslint-disable-next-line
export default function (SpecificComponent, option, adminRoute = null) {
  function AuthenticationCheck(props) {
    const handleLogout = (page) => {
      let url = "/api/logout";
      const x_auth = getCookie("x_auth");
      // var x_auth = localStorage.getItem("x_auth");
      let body = {
        x_auth: x_auth,
      };
      axios.post(url, body).then((res) => {
        if (res.data.result === "success") {
          deleteCookie("x_auth");
          props.history.push(page);
        } else if (res.data.result === "noneLogin") {
          alert("로그인 정보가 없습니다.");
          deleteCookie("x_auth");
          props.history.push(page);
        } else {
          alert("로그아웃에 실패하였습니다");
        }
      });
    };

    const maintenance = () => {
      let url = window.location.href;

      axios.post("/api/admin/set/construction/set").then((res)=> {
        if(url.indexOf("admin") < 0){
          if (res.data.result === "success" && res.data.working === "Y") {
            props.history.push("/maintenance");
          }
        }
      });
    }

    const adminInfo = useSelector((state) => state.adminInfo);
    const dispatch = useDispatch();
    const memid = getCookie("memid");

    let options = "";
    if (adminRoute) {
      options = "admin";
    }

    let data = {
      memid: memid,
    }

    useLayoutEffect(() => {
      //사이트 점검중인지 체크
      maintenance();
      
      
      dispatch(auth(options)).then((res) => {

        // 로그인하지않은 상태
        if (!res.payload.isLogin) {
          deleteCookie("x_auth");
          if (option && adminRoute) {
            // 로그인해야만 접속 가능한데 관리자페이지면
            props.history.push("/rf_zib_admin");
          } else if (option) {
            // 로그인해야만 접속 가능한데 사용자페이지면
            props.history.push("/login");
          }
        } else {

          function adminAllowChk(res) {
            dispatch(isAdminAllow(data)).then((res) => {
              if (!res.payload.isAdminAllow) props.history.push("/unauthorized");
            }, [dispatch]);
          }

          // 로그인 한 상태
          if (option === false && adminRoute) {
            // 관리자페이지에서 로그인한 유저는 접근 불가(로그인화면)
            adminAllowChk(res);
            props.history.push("/rf_zib_admin/user/free/1");
          } else if (option === false) {
            // 사용자페이지에서 로그인한 유저는 접근 불가(로그인화면, 회원가입 등)
            props.history.push("/");
          } else if (adminRoute === ADMIN_SUPER.Memtype) {

            adminAllowChk(res);
            if (res.payload.memtype !== ADMIN_SUPER.Memtype) {
              if (res.payload.memtype === ADMIN_GENERAL.Memtype) {
                props.history.push(ADMIN_GENERAL.RedirectUrl);
              } else if (res.payload.memtype === ADMIN_LAWYER.Memtype) {
                props.history.push(ADMIN_LAWYER.RedirectUrl);
              } else if (res.payload.memtype === ADMIN_PRIVACY.Memtype) {
                props.history.push(ADMIN_PRIVACY.RedirectUrl);
              }
            }

          } else if (adminRoute === ADMIN_GENERAL.Memtype) {
            adminAllowChk(res);
            if (res.payload.memtype === ADMIN_LAWYER.Memtype) {
              props.history.push(ADMIN_LAWYER.RedirectUrl);
            } else if (res.payload.memtype === ADMIN_PRIVACY.Memtype) {
              props.history.push(ADMIN_PRIVACY.RedirectUrl);
            }

          } else if (adminRoute === ADMIN_LAWYER.Memtype) {

            if (res.payload.memtype === ADMIN_GENERAL.Memtype) {
              props.history.push(ADMIN_GENERAL.RedirectUrl);
            } else if (res.payload.memtype === ADMIN_PRIVACY.Memtype) {
              props.history.push(ADMIN_PRIVACY.RedirectUrl);
            }

          } else if (adminRoute === ADMIN_PRIVACY.Memtype) {
            adminAllowChk(res);
            if (res.payload.memtype === ADMIN_GENERAL.Memtype) {
              props.history.push(ADMIN_GENERAL.RedirectUrl);
            } else if (res.payload.memtype === ADMIN_LAWYER.Memtype) {
              props.history.push(ADMIN_LAWYER.RedirectUrl);
            }
          }

          if (res.payload.isAdmin && !adminRoute) {
            handleLogout("/login");
          }

        }

      });
      // eslint-disable-next-line
    }, [dispatch]);

    return <SpecificComponent {...props} adminInfo={adminInfo} />;
  }

  return AuthenticationCheck;
}