import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {adminSendMail} from "../../_actions/admin_board_action";
import {BrowserRouter, Link, useRouteMatch} from "react-router-dom";
import {adminGetToday} from "../../Common/lib";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
//toast plugins
import 'tui-color-picker/dist/tui-color-picker.css'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";





function SendMailWrite(){
    let match = useRouteMatch();

    const dispatch = useDispatch();
    const idx = match.params.idx;

    //toast editor 변수
    const editorRef = React.createRef();

    const [group, setGroup] = useState("전체");
    const [noticeName, setNoticeName] = useState("1");
    const [title, setTitle] = useState("");
    const [tranCycle, setTranCycle] = useState("N");
    const [tranStartDate, setTranStartDate] = useState(adminGetToday());
    const [schDate, setSchDate] = useState(adminGetToday());                //발송예정일자 (발송주기가 한번이면 시작일과 동일함 )
    const today = adminGetToday();

    const retransmission = "N";                                             //재전송여부 ( 재전송: Y , 재전송아님: N )
    const meth = "M";                                                       //고지방법(문자:S ,이메일:M ,카카오:K)  (현재 이메일만 가능)


    //input 값 제어
    const inputHandler = (e) => {
        let el = e.target.name;
        switch (el){
           case "tranCycle": setTranCycle(e.target.value); return;
           case "tranStartDate": setTranStartDate(e.target.value); setSchDate(e.target.value); return;
           case "noticeName": setNoticeName(e.target.value); return;
           case "title": setTitle(e.target.value); return;
           case "group": setGroup(e.target.value); return;
           default: return;
        }
    };

    //전송하기
    const submitHandler = () => {
        //content 내용 html로 변경
        const content = editorRef.current.getInstance().getHTML();

        if (noticeName === "") {
            alert("메일종류를 입력하세요.");
            return;
        }else if (title === "") {
            alert("제목을 입력하세요.");
            return;
        }else if( content.includes("<span class=\"placeholder ProseMirror-widget\" contenteditable=\"false\">내용을 입력하세요.</span>")) {
            alert("내용을 입력하세요.");
            return;
        }


        let admin_auth = localStorage.getItem("admin_auth");
        let del = "N";

        let body = {
          nm: noticeName,
          grp: group,
          retran: retransmission,
          orgidx: null,
          meth: meth,
          title: title,
          cont: content,
          // cont: content.replace(/(?:\r\n|\r|\n)/g,'<br/>'),  //DB에 <br/>로 저장
          trancyc: tranCycle,
          transtdy: tranStartDate.replace(/-/gim, ""),       //하이픈 제거
          del: del,
          idx: idx,  //?
          admin_auth: admin_auth,
          schdate: schDate,
          today: today.replace(/-/gim, ""),
        };

        //메일 발송 action 호출
        dispatch(adminSendMail(body)).then((res) => {
            if (res.payload.result === "success"){
                alert("전송되었습니다.");
                window.location.reload();
            } else if(res.payload.result === "reservation"){
                alert("예약되었습니다.");
                window.location.reload();
            } else {
                alert("잠시후 다시 시도해주세요");
            }
        });

    };



    return (
        <>
            <Header />
            <div className="admin_container" id="addressDetail">
                <SideMenu tabActive={4} />
                <div className="main_container" id="setVersion">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <ul className="detail_user_tab">
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail">
                                    <li className="btn active">발송</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/schedule">
                                    <li className="btn">예약목록</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/history">
                                    <li className="btn">발송내역</li>
                                </Link>
                            </BrowserRouter>
                        </ul>

                        <div className="detail_user_container">
                            <table className="detail_table board_table">
                                <tbody>
                                <tr>
                                    <th>수신그룹</th>
                                    <td colSpan={3}>
                                        <div className="free_user_radio">
                                            <input
                                                type="radio"
                                                defaultChecked
                                                id="groupAll"
                                                name="group"
                                                value="전체"
                                                onClick={inputHandler}
                                            />
                                            <label htmlFor="groupAll">전체(정상+휴면)</label>
                                            <input
                                                type="radio"
                                                id="normal"
                                                name="group"
                                                value="정상"
                                                onClick={inputHandler}
                                            />
                                            <label htmlFor="normal">정상</label>
                                            <input
                                                type="radio"
                                                id="resting"
                                                name="group"
                                                value="휴면"
                                                onClick={inputHandler}
                                            />
                                            <label htmlFor="resting">휴면</label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>발송 주기</th>
                                    <td>
                                        <select onChange={inputHandler} name="tranCycle">
                                            <option value="N">한 번</option>
                                            <option value="6M">6개월마다</option>
                                            <option value="1Y">1년마다</option>
                                        </select>
                                    </td>
                                    <th>시작일</th>
                                    <td>
                                        <input
                                            type="date"
                                            id="tranStartDate"
                                            name="tranStartDate"
                                            onChange= {inputHandler}
                                            value = {tranStartDate}
                                        />
                                        <div>*** 현재 당일 발송만 가능합니다. ***</div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>메일종류</th>
                                    <td colSpan={3}>
                                        <select onChange={inputHandler} name="noticeName" style={{width: "399px" }}>
                                            <option value="1">개인정보 이용내역</option>
                                            <option value="2">이용약관 변경</option>
                                            <option value="3">개인정보 처리방침 변경</option>
                                            <option value="4">기타</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td colSpan={3}>
                                        <input
                                            type="text"
                                            placeholder="제목을 입력하세요."
                                            name="title"
                                            onChange={inputHandler}
                                        />
                                    </td>
                                </tr>
                                <tr className="board_content">
                                    <th>내용</th>
                                    <td colSpan="3">
                                        <Editor
                                            placeholder="내용을 입력하세요."
                                            previewStyle="vertical"
                                            height="600px"
                                            initialEditType="wysiwyg"
                                            useCommandShortcut={true}
                                            usageStatistics={false}
                                            plugins={[colorSyntax, tableMergedCell]}
                                            ref={editorRef}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="admin_table_bar"></div>
                            <div className="detail_user admin_btn_column">
                                <div className="admin_blue_btn btn" onClick={submitHandler}>
                                    전송하기
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMailWrite