import React, { useState, useLayoutEffect } from "react";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  boardDivisionList,
  boardDivisionAdd,
} from "../../_actions/admin_board_action";
import { BrowserRouter, Link } from "react-router-dom";

function BoardDivision() {
  const dispatch = useDispatch();

  // 카테고리 리스트
  const [categories, setCategories] = useState([]);
  const [isCategories, setIsCategories] = useState(false);

  useLayoutEffect(() => {
    let body = {
      kind: "무료법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setIsCategories(true);
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch]);

  // 카테고리 추가
  const addCategory = () => {
    setIsCategories(true);
    let timestamp = new Date().getTime();
    setCategories([
      ...categories,
      {
        kind: "무료법률상담",
        categoryidx: "consulting_" + timestamp,
        name: "",
      },
    ]);
  };

  // 카테고리 저장
  const submitCategory = () => {
    let body = {
      categories: categories,
    };

    let isOk = true;
    categories.map((item) => {
      if (item.name === "") {
        alert("카테고리를 입력해주세요");
        isOk = false;
        return isOk;
      } else {
        isOk = true;
        return isOk;
      }
    });

    if (isOk) {
      dispatch(boardDivisionAdd(body)).then((res) => {
        if (res.payload.result) {
          alert("저장되었습니다.");
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    }
  };

  const handleCategory = (e) => {
    let copyCategories = [...categories];
    copyCategories[e.target.name] = {
      kind: "무료법률상담",
      categoryidx: e.target.id,
      name: e.target.value,
    };
    setCategories(copyCategories);
  };

  // 카테고리 삭제
  const deleteCategory = (index) => {
    let filtered = categories.filter((item, idx) => idx !== index);
    setCategories(filtered);
  };

  return (
    <>
      <Header />
      <div className="admin_container" id="addressDetail">
        <SideMenu tabActive={1} />
        <div className="main_container">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name">게시판 구분</h2>
            </div>
            <div className="address_detail category_column">
              <div className="detail_table_header">
                <div></div>
                <div className="admin_black_btn btn" onClick={addCategory}>
                  구분 추가
                </div>
              </div>

              <table className="detail_table">
                <tbody>
                  {isCategories ? (
                    categories.map((item, index) => {
                      return (
                        <tr key={index}>
                          <th>구분 {index + 1}</th>
                          <td>
                            <div className="day_change_count">
                              <input
                                type="text"
                                id={item.categoryidx}
                                name={index}
                                value={item.name}
                                onChange={handleCategory}
                              />
                              <div
                                className="admin_white_btn admin_white_btn100 btn"
                                onClick={() => deleteCategory(index)}
                              >
                                삭제
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>등록된 카테고리가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="admin_table_bar"></div>
              <div className="detail_user admin_btn_column">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/board/consult/1">
                    <div className="admin_white_btn btn left">목록</div>
                  </Link>
                </BrowserRouter>

                <div className="admin_blue_btn btn" onClick={submitCategory}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoardDivision;
