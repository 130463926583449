import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminLawAdd,
  boardDivisionList,
} from "../../_actions/admin_board_action";

function ServiceLawListAdd({ history }) {
  const dispatch = useDispatch();

  // 카테고리 리스트
  const [categories, setCategories] = useState([]);

  // input값 제어
  const [SubmitData, setSubmitData] = useState({
    gubun: "",
    title: "",
    content: "",
    visible: "노출",
  });

  // 뒤로가기
  const goBack = () => {
    history.goBack();
  };

  const inputHandler = (e) => {
    let el = e.target;

    setSubmitData({ ...SubmitData, [el.name]: el.value });
  };

  // 전송시
  const submitHandler = () => {
    if (SubmitData.title === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (SubmitData.content === "") {
      alert("내용을 입력하세요.");
      return;
    }

    let admin_auth = localStorage.getItem("admin_auth");

    let body = {
      gubun: SubmitData.gubun,
      title: SubmitData.title,
      content: SubmitData.content,
      visible: SubmitData.visible === "노출" ? 1 : 0,
      admin_auth: admin_auth,
    };

    // gubun, title, content, visible;
    dispatch(adminLawAdd(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/service/law");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  useLayoutEffect(() => {
    let body = {
      kind: "법률상담",
    };
    // 카테고리 목록 가져오기
    dispatch(boardDivisionList(body)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
        setSubmitData({ ...SubmitData, gubun: result[0].name });
      } else {
        setCategories([]);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">법률상담 FAQ 등록</h2>
              </div>

              <>
                <table className="detail_table board_table single_column">
                  <tbody>
                    <tr>
                      <th>구분</th>
                      <td>
                        <select onChange={inputHandler} name="gubun">
                          {categories.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>제목</th>
                      <td>
                        <input
                          type="text"
                          placeholder="제목을 입력하세요."
                          onChange={inputHandler}
                          name="title"
                          className="title"
                        />
                      </td>
                    </tr>

                    <tr className="board_content">
                      <th>내용</th>
                      <td colSpan="3">
                        <textarea
                          placeholder="내용을 입력하세요."
                          onChange={inputHandler}
                          name="content"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th>노출</th>
                      <td>
                        <div className="free_user_radio">
                          <input
                            type="radio"
                            defaultChecked
                            id="radio1"
                            onClick={inputHandler}
                            value="노출"
                            name="visible"
                          />
                          <label htmlFor="radio1">노출</label>
                          <input
                            type="radio"
                            id="radio2"
                            onClick={inputHandler}
                            value="비노출"
                            name="visible"
                          />
                          <label htmlFor="radio2">비노출</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  className="detail_user admin_btn_column"
                  style={{ margin: 0 }}
                >
                  <div className="admin_white_btn btn left" onClick={goBack}>
                    목록
                  </div>
                  <div className="admin_blue_btn btn" onClick={submitHandler}>
                    저장
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ServiceLawListAdd;
