import React, { useLayoutEffect, useRef, useState } from "react";
import "./FindUser.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import $ from "jquery";
import { useMediaQuery } from "react-responsive";
import { CheckEmail, CheckPw } from "../../Common/lib";
import axios from "axios";
import MobileBottomNav from "../Footer/MobileBottomNav";

function FindPw({ history }) {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  // 비밀번호 Input
  const [changePwVal, setChangePwVal] = useState({
    newPw: "",
    confirmPw: "",
  });

  const [btnMessage, setBtnMessage] = useState("인증번호 발송");
  const [inputData, setInputData] = useState(false);
  const [userId, setUserId] = useState(false);
  const [infoMessage, setInfoMessage] = useState("(-) 없이 숫자만 입력하세요.");
  const [getAuthNumVal, setGetAuthNumVal] = useState("");
  const [authOk, setAuthOk] = useState(false);
  const [authnum, setAuthnum] = useState("");
  // 핸드폰 번호 입력창
  const [telNum, setTelNum] = useState("");
  const [memid, setMemid] = useState("");
  const [idx, setIdx] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [display, setDisplay] = useState("");

  // 유효성 검사 false일 때 경고 문구
  const obj = {
    0: "",
    1: "이메일 형식이 올바르지 않습니다.",
    2: "가입된 이메일주소가 아닙니다.",
    3: "가입된 전화번호가 아닙니다.",
    4: "인증번호가 일치하지 않습니다.",
    5: "문자 전송에 실패하였습니다.",
  };
  const [warningMessge, setWarningMessge] = useState({
    id: 0,
    message: obj[0],
  });

  // 휴대폰 번호 입력 후 enter 눌렀을 때
  const onKeyPressTel = (e) => {
    if (e.key === "Enter") {
      sendAuthNum();
    }
  };

  // 인증번호 타이머
  const _interval = useRef(null);
  const interval = () => _interval.current;

  const clearCurrentInterval = () => {
    clearInterval(interval());
  };

  const replaceCurrentInterval = (newInterval) => {
    clearCurrentInterval();
    _interval.current = newInterval;
  };

  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 번호 입력 시 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);

  // 인증번호 입력
  const handleAuthNum = (e) => {
    let value = e.target.value;
    if (value.length > 5) {
      value = e.target.value.slice(0, 5);
    }
    setAuthnum(value);
    if (getAuthNumVal === value) {
      setInfoMessage("인증이 완료 되었습니다.");
      setAuthOk(true);
      clearCurrentInterval();
    }
  };
  // 아이디 입력 핸들러
  const handleMemid = (e) => {
    setMemid(e.target.value);
    if (!CheckEmail(e.target.value)) {
      // 메일형식 체크
      setWarningMessge({ id: 1, message: obj[1] });
    } else {
      setWarningMessge({ id: 0, message: obj[0] });
    }
  };

  const checkStatus = (count) => {
    let minutes, seconds;
    const newInterval = setInterval(() => {
      minutes = parseInt(count / 60, 10);
      seconds = parseInt(count % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setDisplay(minutes + ":" + seconds);

      if (--count < 0) {
        clearInterval(newInterval);
      }
    }, 1000);

    replaceCurrentInterval(newInterval);
    setIsRunning(true);
  };

  const onChangePw = (e) => {
    // input값 입력시 값 셋팅
    const value = e.target.value;
    setChangePwVal({
      ...changePwVal,
      [e.target.name]: value,
    });
  };

  // 로그인 버튼 클릭 시
  const handleChangePw = () => {
    if (!CheckPw(changePwVal["newPw"])) {
      // 비밀번호 유효성 검사
      $(".new_pw_warning").text("비밀번호 형식이 올바르지 않습니다.");
      $(".confirm_pw_warning").text("");
      handleFocus(0);
    } else if (changePwVal["newPw"] !== changePwVal["confirmPw"]) {
      $(".confirm_pw_warning").text("비밀번호가 다릅니다. 다시 확인하세요.");
      $(".new_pw_warning").text("");
      handleFocus(1);
    } else {
      let url = "/api/mypage/find/updatePassword";
      let data = {
        password: changePwVal["newPw"],
        idx: idx,
      };

      axios.post(url, data).then((res) => {
        if (res.data.result === "success") {
          alert("비밀번호가 변경 되었습니다.");
          history.push("/login");
        } else {
          alert("비밀번호 변경에 실패하였습니다. 다시 시도해주세요.");
        }
      });
    }
  };

  const sendAuthNum = () => {
    let body = { memid: memid, cellno: telNum };
    let url = "/api/mypage/find/member";

    axios.post(url, body).then((res) => {
      if (res.data.result === "success") {
        if (res.data.memid !== "success") {
          if (res.data.memid.indexOf("snsmember") >= 0) {
            let snsName = res.data.memid.substring(Number(res.data.memid.indexOf("-")) + 1, res.data.memid.length);
            alert("회원님은 간편로그인("+ snsName +")으로 가입하셨습니다.");
            history.push("/frequently/detail/40/1");
          } else {
            setWarningMessge({ id: 2, message: obj[2] });
            return;
          }
        } else if (res.data.cellno !== "success") {
          setWarningMessge({ id: 3, message: obj[3] });
          return;
        }
        setIdx(res.data.idx);
        if (telNum === "") return;
        // 인증번호 DB 저장
        axios.post("/api/call/aligo/sms", body).then((res) => {
          if (res.data.result === "success") {
            setGetAuthNumVal(res.data.authnum);
            setBtnMessage("재발송");
            setInputData(true);
            handleFocus(1);
            setWarningMessge({ id: 0, message: obj[0] });
            if (isRunning) {
              replaceCurrentInterval();
              checkStatus(180);
            } else {
              checkStatus(180);
            }
          } else if (res.data.result === "undefined") {
            setWarningMessge({ id: 3, message: obj[3] });
            handleFocus(0);
            setGetAuthNumVal("");
          } else {
            setWarningMessge({ id: 5, message: obj[5] });
            handleFocus(0);
            setGetAuthNumVal("");
          }
        });
      } else if (res.data.result === "undefined") {
        setWarningMessge({ id: 2, message: obj[2] });
      }
    });
  };

  // 인증 확인
  const onSubmit = (e) => {
    e.preventDefault();
    if (authOk) {
      let url = "/api/call/aligo/key";
      let body = {
        cellno: telNum,
        authnum: authnum,
      };
      axios.post(url, body).then((res) => {
        if (res.data.result === "success") {
          setWarningMessge({ id: 0, message: obj[0] });
          setUserId(res.data.memid);
        } else if (res.data.result === "wrong") {
          setWarningMessge({ id: 4, message: obj[4] });
        } else {
          e.preventDefault();
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    }
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header />
        <div className="main center_main find_user" id="findPw">
          <h1 className="main_title">비밀번호 찾기</h1>

          <div className={userId ? "title_info confirm" : "title_info"}>
            {userId ? (
              <>새로운 비밀번호를 입력하세요.</>
            ) : (
              <>
                비밀번호 변경을 위한 <br />
                이메일주소와 전화번호 인증이 필요합니다.
              </>
            )}
          </div>

          <form onSubmit={onSubmit}>
            {userId ? (
              <>
                <div className="new_pw">
                  <div className="input_column">
                    <div className="input_name">
                      새로운 비밀번호 <span className="warning">*</span>
                    </div>
                    <input
                      type="password"
                      placeholder="8자 이상, 영문, 숫자, 특수문자 포함"
                      onChange={onChangePw}
                      name="newPw"
                      ref={(elem) => (inputRefs.current[0] = elem)}
                    />
                  </div>
                  <div className="new_pw_warning warning"></div>
                  <div className="input_column">
                    <div className="input_name">
                      비밀번호 확인 <span className="warning">*</span>
                    </div>
                    <input
                      type="password"
                      placeholder="비밀번호를 한번 더 입력하세요."
                      onChange={onChangePw}
                      name="confirmPw"
                      ref={(elem) => (inputRefs.current[1] = elem)}
                    />
                  </div>
                  <div className="info">
                    ※ 8자리 이상, 영문, 숫자, 특수문자을(를) 사용하세요.
                  </div>
                  <div className="confirm_pw_warning warning"></div>
                  <div
                    className="blue_btn btn login_btn"
                    onClick={handleChangePw}
                  >
                    로그인
                  </div>
                </div>
              </>
            ) : (
              <>
                {inputData ? null : (
                  <>
                    <div className="input_column">
                      <input
                        type="text"
                        placeholder="아이디(이메일 주소)"
                        className="wide"
                        name="memid"
                        onChange={handleMemid}
                      />
                    </div>
                    <div className="warning">
                      {1 <= warningMessge.id && warningMessge.id <= 2
                        ? warningMessge.message
                        : ""}
                    </div>
                  </>
                )}

                <div className="input_column">
                  <input
                    type="text"
                    placeholder="휴대폰을 입력하세요."
                    onChange={handelTelNum}
                    onKeyPress={onKeyPressTel}
                    value={telNum}
                    ref={(elem) => (inputRefs.current[0] = elem)}
                  />
                  <div className="check_btn" onClick={sendAuthNum}>
                    {btnMessage}
                  </div>
                </div>
                {inputData ? (
                  <div className="input_column auth_num_column">
                    <input
                      type="text"
                      placeholder="인증번호를 입력하세요."
                      className="auth_num"
                      onChange={handleAuthNum}
                      ref={(elem) => (inputRefs.current[1] = elem)}
                    />
                    <span className="counter">{display}</span>
                  </div>
                ) : null}

                <div className="info">
                  ※ {infoMessage}
                  {/* ※ (-) 없이 숫자만 입력하세요./인증이 완료 되었습니다. */}
                </div>
                <div className="warning">
                  {3 <= warningMessge.id && warningMessge.id <= 5
                    ? warningMessge.message
                    : ""}
                </div>

                <div className="btn_column">
                  {authOk ? (
                    <div className="next_btn active btn" onClick={onSubmit}>
                      인증 확인
                    </div>
                  ) : (
                    <div className="next_btn btn">인증 확인</div>
                  )}
                </div>
                <div className="info btn_bottom">
                  ※ 전화번호가 변경되었을 경우에는 고객센터로 문의바랍니다.
                </div>
              </>
            )}
          </form>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default FindPw;
