import React, {useLayoutEffect, useState} from "react";
import {BrowserRouter, Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {smsHistoryList} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";


function SendSmsHistoryList(){
    const dispatch = useDispatch();

    const [historyList, setHistoryList] = useState([]);
    // const [historyListCnt, setHistoryListCnt] = useState(0);
    const [pageOfItems, setPageOfItems] = useState([]); //페이징

    useLayoutEffect(() => {
       //전체 발송내역 가져오기
       dispatch(smsHistoryList()).then((res) => {
            if(res.payload.historyList){
                const result = res.payload.historyList;
                setHistoryList(result);
                // setHistoryListCnt(result.length);
            }
       });
    }, [dispatch]);

    //페이징 처리
    const onChangePage = (pageOfItems) => {
        setPageOfItems(pageOfItems);
    };


    const pageOfItem = pageOfItems.map((item) => {

        return (
            <tr key={item.idx}>
                <td style={{ width: "5%" }}>{item.rownum}</td>
                <td style={{ width: "10%" }}>{item.regdate}</td>
                <td><span>{item.smstext}</span></td>
                <td style={{ width: "10%" }}>{item.memname}</td>
                <td style={{ width: "5%" }}>{item.sendflag === 0 ? "미전송" : "전송완료"}</td>
                <td style={{ width: "5%" }}>{item.succflag === 0 ? "실패" : "성공"}</td>
            </tr>
        )
    });

    return (
        <>
            <Header />
            <div className="admin_container">
                <SideMenu tabActive={4} />
                <div className="main_container">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                문자발송
                            </h2>
                        </div>
                        <ul className="detail_user_tab">
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/sms">
                                    <li className="btn">발송</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/sms/history">
                                    <li className="btn active">발송내역</li>
                                </Link>
                            </BrowserRouter>
                        </ul>

                        <div className="sms_history">
                            <div className="user_list_column">
                                <table>
                                    <thead>
                                    <tr>
                                        <th style={{ width: "5%" }}>No.</th>
                                        <th style={{ width: "10%" }}>발송일자</th>
                                        <th>메시지내용</th>
                                        <th style={{ width: "10%" }}>수신자</th>
                                        <th style={{ width: "5%" }}>전송여부</th>
                                        <th style={{ width: "5%" }}>상태</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageOfItem.length > 0 ? (pageOfItem) : (
                                    <tr>
                                        <td colSpan={6}>등록된 글이 없습니다.</td>
                                    </tr>
                                    )}
                                    </tbody>
                                </table>
                                <PaginationNum items={historyList} onChangePage={onChangePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendSmsHistoryList