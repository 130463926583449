import React, { useState } from "react";
import axios from "axios";
function ServicePushSend() {
  const [alarmContent, setAlarmContent] = useState("");
  const [target, setTarget] = useState("전체회원");

  let contentController = (e) => {
    setAlarmContent(e.target.value);
  };

  let targetController = (e) => {
    if (e.target.id === "chk1") {
      setTarget("전체회원");
    } else if (e.target.id === "chk2") {
      setTarget("무료회원");
    } else if (e.target.id === "chk3") {
      setTarget("변호사");
    } else {
      setTarget("관리자");
    }
  };

  // 알림발송
  let sendAlarm = () => {
    let url = "/api/admin/send/alarm/add";
    let data = {
      content: alarmContent,
      target: target,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert(`${target}에게 알림 발송을 완료하였습니다.`);
      }
    }); //end of inner axios.post
  };

  return (
    <>
      <table className="detail_table board_table single_column">
        <tbody>
          <tr>
            <th>발송대상</th>
            <td>
              <div className="approval_column">
                <div className="free_user_chk">
                  <input
                    type="radio"
                    id="chk1"
                    name="gubun"
                    onChange={(e) => {
                      targetController(e);
                    }}
                    checked={target === "전체회원"}
                  />
                  <label htmlFor="chk1">전체회원</label>
                  <input
                    type="radio"
                    id="chk2"
                    name="gubun"
                    onChange={(e) => {
                      targetController(e);
                    }}
                    checked={target === "무료회원"}
                  />
                  <label htmlFor="chk2">무료회원</label>
                  <input
                    type="radio"
                    id="chk3"
                    name="gubun"
                    onChange={(e) => {
                      targetController(e);
                    }}
                    checked={target === "변호사"}
                  />
                  <label htmlFor="chk3">변호사</label>
                  <input
                    type="radio"
                    id="chk4"
                    name="gubun"
                    onChange={(e) => {
                      targetController(e);
                    }}
                    checked={target === "관리자"}
                  />
                  <label htmlFor="chk4">관리자</label>
                </div>
              </div>
            </td>
          </tr>

          <tr className="board_content">
            <th>발송내용</th>
            <td colSpan="3">
              <textarea
                onChange={contentController}
                value={alarmContent || ""}
                // placeholder="내용을 입력하세요."
                placeholder="알림 받을 번호와 메일 주소를 개발자가 임의로 넣어줘야 수신 가능한 상태입니다."
                style={{ height: 150 }}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="detail_user admin_btn_column"
        style={{ margin: 30 }}
        onClick={() => {
          sendAlarm();
        }}
      >
        <div className="admin_blue_btn btn" style={{ width: 200 }}>
          보내기
        </div>
      </div>
    </>
  );
}

export default ServicePushSend;
