import React, { useLayoutEffect, useState } from "react";
import "./Board.css";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { serviceQuestionDetail } from "../../_actions/main_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function FrequentlyBoardDetail({ match }) {
  const pagen = match.params.pagen;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.push("/frequently/" + pagen);
    // history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 게시글 번호
  const idx = match.params.idx;
  // const [changeIdx, setChangeIdx] = useState(Number(idx));
  const [Content, setContent] = useState([]); // 공지글 내용

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let body = {
      idx: idx, // 글번호
    };
    dispatch(serviceQuestionDetail(body)).then((res) => {
      if (res.payload.question) {
        let cnt = res.payload.question;
        cnt.content = cnt.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
        console.log(cnt.content);
        setContent(cnt);
      } else {
        alert("잠시 후 다시 시도해주세요.");
        goBack();
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}

        <div id="noticeBoard" style={{"height":"54px !important"}}>
            {isMobile && (
              <div className="content board_main">
                <h2>
                  <div style={{ paddingRight: 14 }} onClick={goBack}>
                    <img src={back} alt="back" className="isMobile_back" />
                  </div>
                  자주하는 질문
                </h2>
              </div>
            )}
		</div>

        <div className="main center_main" id="informationDetail">

          <div className="header_column isMobile_detail">
            <h2 className="detail_title">
              <span style={{"margin-top":"0px"}}>{Content.title}</span>
            </h2>
          </div>

          <div className="body_column">
            <div className="detail_content_column" dangerouslySetInnerHTML={{ __html: Content.content || ""}} ></div>
          </div>
          <div className="btn_column">
            <div className="go_list btn" onClick={goBack}>
              목록으로
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default FrequentlyBoardDetail;
