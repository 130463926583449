import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import axios from "axios";

function SetProvision() {
  const obj = {
    0: <ProvisionTab1 />,
    1: <ProvisionTab2 />,
    2: <ProvisionTab3 />,
    3: <ProvisionTab4 />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  return (
    <>
      <Header />
      <div className="admin_container" id="setProvision">
        <SideMenu tabActive={3} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">약관</h2>
              </div>
              <ul className="detail_user_tab">
                <li
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active" : null}
                >
                  서비스 이용약관
                </li>
                <li
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active" : null}
                >
                  개인정보수집 및 이용 동의
                </li>
                <li
                  onClick={() => clickTab(2)}
                  className={tabActive === 2 ? "active" : null}
                >
                  개인정보 제3자 제공 동의
                </li>
                <li
                  onClick={() => clickTab(3)}
                  className={tabActive === 3 ? "active" : null}
                >
                  마케팅 활용 동의
                </li>
              </ul>

              {tabPage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetProvision;

function ProvisionTab1() {
  //  서비스이용약관

  // 약관 가져오기
  function getProvision() {
    let url = "/api/admin/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent(res.data.terms[0].termsdescription);
    });
  }

  const [provisionContent, setProvisionContent] = useState("");

  useLayoutEffect(() => {
    getProvision();
  }, []);

  const handleContent = (e) => {
    setProvisionContent(e.target.value);
  };

  // 약관 저장
  const submitProvision = () => {
    let url = "/api/admin/set/provision/modify";
    let body = {
      idx: "0",
      termsdescription: provisionContent,
    };
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <textarea
        className="provision_content"
        value={provisionContent || ""}
        onChange={handleContent}
      ></textarea>
      <div
        className="admin_blue_btn btn provision_save"
        onClick={submitProvision}
      >
        저장
      </div>
    </>
  );
}

function ProvisionTab2() {
  //  개인정보수집 및 이용 동의

  // 약관 가져오기
  function getProvision() {
    let url = "/api/admin/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent(res.data.terms[1].termsdescription);
    });
  }

  const [provisionContent, setProvisionContent] = useState("");

  useLayoutEffect(() => {
    getProvision();
  }, []);

  const handleContent = (e) => {
    setProvisionContent(e.target.value);
  };

  // 약관 저장
  const submitProvision = () => {
    let url = "/api/admin/set/provision/modify";
    let body = {
      idx: "1",
      termsdescription: provisionContent,
    };
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <textarea
        className="provision_content"
        value={provisionContent || ""}
        onChange={handleContent}
      ></textarea>
      <div
        className="admin_blue_btn btn provision_save"
        onClick={submitProvision}
      >
        저장
      </div>
    </>
  );
}

function ProvisionTab3() {
  //  개인정보 제3자 제공 동의

  // 약관 가져오기
  function getProvision() {
    let url = "/api/admin/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent(res.data.terms[2].termsdescription);
    });
  }

  const [provisionContent, setProvisionContent] = useState("");

  useLayoutEffect(() => {
    getProvision();
  }, []);

  const handleContent = (e) => {
    setProvisionContent(e.target.value);
  };

  // 약관 저장
  const submitProvision = () => {
    let url = "/api/admin/set/provision/modify";
    let body = {
      idx: "2",
      termsdescription: provisionContent,
    };
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <textarea
        className="provision_content"
        value={provisionContent || ""}
        onChange={handleContent}
      ></textarea>
      <div
        className="admin_blue_btn btn provision_save"
        onClick={submitProvision}
      >
        저장
      </div>
    </>
  );
}

function ProvisionTab4() {
  //  마케팅 활용 동의

  // 약관 가져오기
  function getProvision() {
    let url = "/api/admin/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent(res.data.terms[3].termsdescription);
    });
  }

  const [provisionContent, setProvisionContent] = useState("");

  useLayoutEffect(() => {
    getProvision();
  }, []);

  const handleContent = (e) => {
    setProvisionContent(e.target.value);
  };

  // 약관 저장
  const submitProvision = () => {
    let url = "/api/admin/set/provision/modify";
    let body = {
      idx: "3",
      termsdescription: provisionContent,
    };
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <>
      <textarea
        className="provision_content"
        value={provisionContent || ""}
        onChange={handleContent}
      ></textarea>
      <div
        className="admin_blue_btn btn provision_save"
        onClick={submitProvision}
      >
        저장
      </div>
    </>
  );
}
