import React, { useState } from "react";
import UserFreeNoticeSet from "./UserFreeNoticeSet";
import UserFreeNoticeView from "./UserFreeNoticeView";

function UserFreeNotice() {
  const obj = {
    0: <UserFreeNoticeView />,
    1: <UserFreeNoticeSet />,
  };

  const [tabPage, setTabPage] = useState(obj[0]);
  const [tabActive, setTabActive] = useState(0);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  return (
    <>
      <ul className="user_free_notice_tab">
        <li
          onClick={() => clickTab(0)}
          className={tabActive === 0 ? "active" : null}
        >
          알림보기
        </li>
        <li
          onClick={() => clickTab(1)}
          className={tabActive === 1 ? "active" : null}
        >
          알림설정
        </li>
      </ul>

      {tabPage}
    </>
  );
}

export default UserFreeNotice;
