export const data1 = [
  {
    no: 1,
    title: "내 정보",
    path: "/mypage",
    count: false,
  },
  {
    no: 2,
    title: "내 정보 수정",
    path: "/mypage/modify",
  },
  {
    no: 3,
    title: "관리주소 설정",
    path: "/mypage/address",
    count: false,
  },
  {
    no: 4,
    title: "내글보기",
    path: "/mypage/myarticle",
    basicPath: "/mypage/myarticle",
    count: true,
  },
  {
    no: 5,
    title: "알림보기",
    path: "/mypage/notice",
    count: true,
  },
  {
    no: 6,
    title: "알림설정",
    path: "/mypage/notice/setting",
    count: false,
  },
];

// export const data2 = [
//   {
//     no: 7,
//     title: "공지사항",
//     path: "/mypage/notice/board/1",
//     basicPath: "/mypage/notice/board",
//     modal: false,
//   },
//   {
//     no: 8,
//     title: "이벤트",
//     path: "/mypage/event",
//     basicPath: "/mypage/event",
//     modal: false,
//   },
// ];

export const data3 = [
  {
    no: 9,
    title: "서비스 이용약관",
    path: "/mypage/agreement",
  },
  {
    no: 10,
    title: "개인정보처리방침",
    path: "/mypage/perinformation",
  },
  {
    no: 11,
    title: "마케팅활용동의",
    path: "/mypage/agreementMarketing",
  },
];

// export const data4 = [
//   {
//     no: 12,
//     title: "자주하는 질문",
//     path: "/mypage/frequently",
//   },
// ];
