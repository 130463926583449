import React, { useLayoutEffect, useState } from "react";
import "../../admin.css";
import Header from "../../Header/Header";
import { changeDateFormat, searchDataSet, searchCashClear } from "../../../Common/lib";
import close from "../../../images/close.png";
import SideMenu from "../SideMenu";
import {
  BrowserRouter,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import PaginationNum from "../../../components/PaginationNum";
import {
  searchUserout,
  useroutList,
} from "../../../_actions/admin_board_action";
import $ from "jquery";
import Modal from "../../../components/Modal";

function UserOut() {
  const dispatch = useDispatch();
  let history = useHistory();
  let match = useRouteMatch();
  let pagen = match.params.pagen;

  // 달력 시작일 셋팅
  const [searchDate, setSearchDate] = useState({
    registerStart: "",
    registerEnd: "",
  });

  const handleSearchDate = (e) => {
    setSearchDate({ ...searchDate, [e.target.name]: e.target.value });
  };

  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 글 목록
  const [boardListCnt, setBoardListCnt] = useState();
  const [boardList, setBoardList] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState();
  const [pageOfItems, setPageOfItems] = useState([]);
  // 완전 삭제할 회원 목록
  const [delIdxList, setDelIdxList] = useState([]);

  useLayoutEffect(() => {

    let body = {
      registerStart: searchDate.registerStart,
      registerEnd: searchDate.registerEnd,
    };

    // 검색input 호출
    let finalData = searchDataSet(body);

    dispatch(searchUserout(finalData)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.users;
        setBoardList(result);
        setBoardListCnt(result.length);
        setBoardSearchListCnt(result.length);
        setSearchDate(finalData);
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  }, [dispatch]);

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
    // 페이지 이동 시 delIdx 목록 비우기 및 check박스 해제
    setDelIdxList([]);
    $("#checkall").prop("checked", 0);
  };
  const getPageNum = (pageNum) => {
    history.replace(`/rf_zib_admin/user/out/${pageNum}`);
  };

  // 회원 삭제 처리
  let deleteSubmit = () => {
    let url = "/api/admin/user/out/removeUser";
    let data = {
      delIdx: delIdxList,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        alert("해당 회원의 삭제 처리가 완료되었습니다.");
        window.location.reload();
      }
    });
  };

  //화면에 있는 리스트의 idx 값만 담아주는 함수
  function getCheckboxValue() {
    // 선택된 목록 가져오기
    const query = 'input[name="chk"]:checked';
    const selectedEls = document.querySelectorAll(query);

    // 선택된 목록에서 value 찾기
    let result = [];
    selectedEls.forEach((el) => {
      result.push(el.value);
    });

    setDelIdxList(result);
  }

  // 체크박스 전체 선택
  const selectAll = () => {
    if ($("#checkall").prop("checked")) {
      //input태그의 name이 chk인 태그들을 찾아서 checked옵션을 true로 정의
      $("input[name=chk]").prop("checked", true);
      getCheckboxValue(); //화면에 있는 리스트의 idx 값만 담아주는 함수
    } else {
      //클릭이 안되있으면
      //input태그의 name이 chk인 태그들을 찾아서 checked옵션을 false로 정의
      $("input[name=chk]").prop("checked", false);
      setDelIdxList([]);
    }
  };

  // 삭제할 회원 checkbox 핸들러
  const handleDelCheckList = (e, idx) => {
    let idx_string = idx.toString();
    // checkbox on
    if (e.target.checked) {
      // state안에 값이 중복되지 않을 때만 넣기
      if (!delIdxList.includes(idx_string))
        setDelIdxList([...delIdxList, idx_string]);
    } else {
      // checkbox off
      let newList = delIdxList.filter((delIdx) => delIdx !== idx_string);
      setDelIdxList(newList);
    }
  };

  // 검색하기
  const handleSearch = () => {
    
    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(searchDate));

    dispatch(searchUserout(searchDate)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.users;
        setBoardList(result);
        if (
          result === "false"
            ? setBoardSearchListCnt(0)
            : setBoardSearchListCnt(result.length)
        );
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };

  // 가입일 검색하는 html부분
  const user_search_column = (
    <div className="user_search_column">
      <div className="first_column">
        <div className="search_regdate_column">
          <span className="input_title">탈퇴일</span>
          <input
            type="date"
            name="registerStart"
            value={searchDate.registerStart}
            onChange={handleSearchDate}
          />
          <span className="range_text">~</span>
          <input
            type="date"
            name="registerEnd"
            value={searchDate.registerEnd}
            onChange={handleSearchDate}
          />
        </div>
        <div
          className="admin_white_btn btn left"
          onClick={() => {
            searchCashClear();
            window.location.href = `/rf_zib_admin/user/out/1`;
          }}
        >
          기본설정
        </div>
        <div
          style={{marginLeft:10}}
          className="admin_blue_btn btn"
          onClick={() => {
            handleSearch();
          }}
        >
          검색
        </div>
      </div>
    </div>
  );
  // 회원 목록
  const pageOfItem = pageOfItems.map((user) => {
    return (
      <tr key={user.idx}>
        <td>
          <input
            type="checkbox"
            id={`userNum_${user.idx}`}
            name="chk"
            onChange={(e) => {
              handleDelCheckList(e, user.idx);
            }}
            value={user.idx}
          />
          /{user.rownum}
        </td>
        <td>{changeDateFormat(user.deletedate, 5, ".", ":")}</td>
        <td>{user.memrealname ? user.memrealname : "-"}</td>
        <td>
          <Link to={`/rf_zib_admin/user/out/detail/${user.idx}/${pagen}`}>
            <span className="btn">{user.memname}</span>
          </Link>
        </td>
        <td>{user.cellno ? user.cellno : "-"}</td>
        <td>{user.memid}</td>
        <td>{user.memjointype}</td>
        <td>{user.memaddrmaxjoin}</td>
        <td>{user.COUNT > 0 ? user.COUNT : "-"}</td>
        <td className={user.memstatus === "정지" ? "stop" : null}>
          {user.memstatus}
        </td>
      </tr>
    );
  });

  return (
    <>
      <div>
        <Header />
        <div className="admin_container">
          <SideMenu tabActive={3} />
          <div className="main_container">
            <div>
              <div className="detail_user_header">
                <h2 className="main_container_name">
                  <BrowserRouter forceRefresh={true}>
                    <Link to="/rf_zib_admin/user/out/1">탈퇴회원</Link>
                  </BrowserRouter>
                  <span className="total_column">
                    전체 <span>{boardListCnt}</span>
                  </span>
                </h2>
                <div
                  className="admin_delete_btn btn left"
                  onClick={() => {
                    delIdxList.length > 0
                      ? openModal("modal1")
                      : alert("선택된 회원이 없습니다.");
                  }}
                >
                  회원 완전 삭제
                </div>
              </div>

              {user_search_column}

              <div className="user_list_column">
                <div className="title_column">
                  <h3>목록</h3>
                  <div className="search_result">
                    검색결과
                    <span> {boardSearchListCnt ? boardSearchListCnt : 0}</span>
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onClick={selectAll}
                          id="checkall"
                        />
                      </th>
                      <th>탈퇴일시</th>
                      <th>이름</th>
                      <th>회원닉네임</th>
                      <th>휴대폰</th>
                      <th>아이디(이메일)</th>
                      <th>가입유형</th>
                      <th>최대 주소등록(개)</th>
                      <th>현재 주소등록(개)</th>
                      <th>회원상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItem.length > 0 ? (
                      pageOfItem
                    ) : (
                      <tr>
                        <td colSpan={10}>등록된 회원이 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <PaginationNum
                  items={boardList}
                  onChangePage={onChangePage}
                  getPageNum={getPageNum}
                  initialPage={pagen++}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal ">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="modal_desc">
            완전 삭제 시 더 이상 <br />
            정보를 볼 수 없습니다. <br />
            <br />
            정말 삭제하시겠습니까?
          </div>
          <div className="admin_btn_column">
            <div
              className="admin_delete_btn btn left"
              onClick={() => {
                deleteSubmit();
              }}
            >
              완전 삭제
            </div>
            <div
              className="admin_delete_btn btn close_btn"
              onClick={() => closeModal("modal1")}
            >
              취소
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserOut;
