import React, { useLayoutEffect, useRef, useState } from "react";
import { CheckEmail, CheckPw } from "../../Common/lib";
import { userLawyerAdd } from "../../_actions/admin_board_action";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
function UserLawyerAdd({ history }) {
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState(""); // 화면에 이미지 표시
  const [FileInfo, setFileInfo] = useState(null);

  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  // 파일 정보 저장
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
  };

  // 파일 삭제
  const deleteFileUrl = () => {
    setFileInfo(null);
    setFileUrl("");
  };

  // input값 제어
  const [SubmitData, setSubmitData] = useState({
    memstatus: "정상",
    memname: "",
    company: "",
    memid: "",
    access: "",
    profileImg: "",
  });
  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value.replace(" ", "") });
  };

  const [SubmitDataPw, setSubmitDataPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const pwHandler = (e) => {
    let el = e.target;
    setSubmitDataPw({ ...SubmitDataPw, [el.name]: el.value.replace(" ", "") });
  };

  const [warningPw, setWarningPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const [accessStatus, setAccessStatus] = useState(true);
  const accessToggle = () => {
    setAccessStatus(!accessStatus);
  };

  // 전송시
  const submitHandler = () => {
    if (SubmitData.memname === "") {
      alert("이름을 입력해주세요.");
      return;
    } else if (SubmitData.company === "") {
      alert("소속을 입력해주세요.");
      return;
    } else if (telNum === "") {
      alert("핸드폰번호를 입력해주세요.");
      return;
    } else if (!CheckEmail(SubmitData.memid)) {
      // 아이디(이메일) 유효성 검사
      alert("이메일 형식을 확인해주세요.");
      return;
    } else if (!CheckPw(SubmitDataPw.originPw)) {
      // 비밀번호 유효성 검사
      setWarningPw({
        originPw: "비밀번호 생성 규칙에 어긋납니다. 다시 설정 바랍니다.",
        confirmPw: "",
      });
      return;
    } else if (SubmitDataPw.originPw !== SubmitDataPw.confirmPw) {
      setWarningPw({
        originPw: "",
        confirmPw: "동일한 비밀번호를 입력하세요.",
      });
      return;
    } else {
      setWarningPw({
        originPw: "",
        confirmPw: "",
      });
    }

    let bodyNameList = [
      "memstatus",
      "memname",
      "company",
      "cellno",
      "memid",
      "password",
      "access",
      "profileImg",
    ];
    let bodyValueList = [
      SubmitData.memstatus,
      SubmitData.memname,
      SubmitData.company,
      telNum,
      SubmitData.memid,
      SubmitDataPw.originPw,
      accessStatus ? "Y" : "N",
      FileInfo,
    ];
    let formData = new FormData();

    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      return formData.append(val, bodyValueList[i]);
    });

    dispatch(userLawyerAdd(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        history.push("/rf_zib_admin/user/lawyer/1");
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  const [telNum, setTelNum] = useState("");
  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={1} />
        <div className="main_container">
          <div id="userLawyerAdd">
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">변호사 신규등록</h2>
              </div>

              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>회원상태</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          defaultChecked
                          id="radio1"
                          name="memstatus"
                          value="정상"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio1">정상</label>
                        <input
                          type="radio"
                          id="radio2"
                          name="memstatus"
                          value="정지"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio2">정지</label>
                      </div>
                    </td>
                    <th rowSpan="6">프로필 사진</th>
                    <td rowSpan="6">
                      <div
                        className="img_column"
                        style={{
                          width: 160,
                          height: 200,
                          border: "solid 1px #dddddd",
                        }}
                      >
                        {fileUrl && fileUrl !== "/api" ? (
                          <img
                            alt="sample"
                            src={fileUrl}
                            style={{ margin: "auto" }}
                          />
                        ) : null}

                        <input
                          name="eventImg"
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          ref={(elem) => (fileInputs.current = elem)}
                          onChange={setFile}
                        />
                      </div>
                      <div
                        className="admin_black_btn_column"
                        style={{
                          marginTop: 10,
                          justifyContent: "center",
                          width: 160,
                        }}
                      >
                        <div
                          className="admin_black_btn left btn"
                          onClick={() => {
                            openFile();
                          }}
                        >
                          업로드
                        </div>
                        <div
                          className="admin_black_btn btn"
                          onClick={() => deleteFileUrl()}
                        >
                          삭제
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>이름</th>
                    <td>
                      <input
                        type="text"
                        placeholder="변호사 이름을 입력하세요."
                        name="memname"
                        value={SubmitData.memname}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>소속</th>
                    <td className="nickName">
                      <div className="nickName_column">
                        <input
                          type="text"
                          placeholder="소속을 입력하세요."
                          name="company"
                          value={SubmitData.company}
                          onChange={inputHandler}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>핸드폰 번호</th>
                    <td>
                      <input
                        type="text"
                        placeholder="휴대 전화번호를 입력하세요."
                        name="cellno"
                        value={telNum}
                        onChange={handelTelNum}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>이메일(ID)</th>
                    <td>
                      <input
                        type="text"
                        placeholder="이메일(ID)를 입력하세요."
                        name="memid"
                        value={SubmitData.memid}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">비밀번호 관리</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <input
                        type="password"
                        name="originPw"
                        onChange={pwHandler}
                        value={SubmitDataPw.originPw}
                        autoComplete="new-password"
                      />
                      <div className="warning">{warningPw.originPw}</div>
                    </td>
                    <th>비밀번호 확인</th>
                    <td>
                      <input
                        type="password"
                        name="confirmPw"
                        value={SubmitDataPw.confirmPw}
                        onChange={pwHandler}
                      />
                      <div className="warning">{warningPw.confirmPw}</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">
                변호사 회원 권한 관리
                <span className="info">
                  ※ 변호사 회원은 관리자 메뉴의 [게시판관리]에 대한 접근 및
                  권한만 부여됩니다.
                </span>
              </h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>게시판 관리 접근</th>
                    <td className="board_access">
                      <input
                        type="checkbox"
                        id="chk1"
                        checked={accessStatus}
                        onClick={accessToggle}
                      />
                      <label htmlFor="chk1">권한 허용</label>
                    </td>
                    <th style={{ background: "none" }}></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="detail_user admin_btn_column">
                <div
                  className="admin_white_btn left btn"
                  onClick={() => history.goBack()}
                >
                  취소
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  등록
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal open={modal["modal1"]}>
        <div className="modal_inner_ct admin_basic_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <div className="lawyer_img"></div>
          <div className="lawyer_img_info">
            <div className="sign">※</div>
            <div>
              100kb 용량 이하 <br />
              jpg, png 이미지 파일만 <br />
              업로드 가능합니다.
            </div>
          </div>

          <div className="admin_btn_column">
            <div
              className="confirm_btn btn close_btn left"
              onClick={() => closeModal("modal1")}
            >
              취소
            </div>

            <div className="confirm_btn btn">저장</div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default UserLawyerAdd;
