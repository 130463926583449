import React, { useLayoutEffect, useState } from "react";
import profileImg from "../../images/profile_img.png";
import profileIconKakao from "../../images/profile_icon_kakao.png";
import profileIconNaver from "../../images/profile_icon_naver.png";
import profileIconApple from "../../images/profile_icon_apple.png";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, getAlarmCount } from "../../_actions/user_action";
import { getCookie } from "../../Common/lib";
import axios from "axios";

function MypageMobile() {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const allAlarmCount = useSelector((store) => store.adminInfo);
  const dispatch = useDispatch();
  const [memname, setMemname] = useState(null);
  const [userId, setUserId] = useState("");
  const [profilePath, setProfilePath] = useState("");
  const [profileIcon, setProfileIcon] = useState("");
  const x_auth = getCookie("x_auth");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
      setUserId(res.payload.memid);
      if (res.payload.memjointype === "카카오톡") {
        setProfileIcon(profileIconKakao);
      } else if (res.payload.memjointype === "네이버") {
        setProfileIcon(profileIconNaver);
      } else if (res.payload.memjointype === "애플") {
        setProfileIcon(profileIconApple);
      }
    });
  }, [dispatch]);

  useLayoutEffect(() => {
    if (allAlarmCount.adminInfo && allAlarmCount.adminInfo.isLogin) {
      getCounselingNonRead();
      getInquiryNonRead();
      getAlarmNonRead();
    }
    // eslint-disable-next-line
  }, [allAlarmCount.adminInfo]);

  // 법률상담 읽지 않은 알림 개수 가져오기
  let getCounselingNonRead = () => {
    let url = "/api/mypage/counseling/count";
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("counseling", res.data.counselingCount));
    });
  };

  // 문의글 읽지 않은 알림 개수 가져오기
  let getInquiryNonRead = () => {
    let url = "/api/mypage/inquiry/count";
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("inquiry", res.data.inquiryCount));
    });
  };

  // 읽지 않은 알림 개수 가져오기
  let getAlarmNonRead = () => {
    let url = "/api/alarm/count";
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("alarm", res.data.alarmCount));
    });
  };

  return (
    <>
      {memname && (
        <div className="root_container" id={isMobile ? "isMobile" : null}>
          <div className="main" id="MypageMobile">
            <div className="write_column">
              <h2 className="title">
                <span>마이페이지</span>
              </h2>
              <div className="hr"></div>
              <div className="profile_column">
                <img
                  src={profilePath ? profilePath : profileImg}
                  alt="profile"
                  className="profile"
                />
                <div className="profile_info">
                  <div className="profile_name">
                    <strong>{memname}</strong>님
                  </div>
                  <div className="profile_email">
                    {profileIcon === "" ? (
                      ""
                    ) : (
                      <img src={profileIcon} alt="icon" />
                    )}
                    {userId}
                  </div>
                  <div className="my_notice_info">
                    <Link to="/mypage/myarticle">
                      <div>내글 {allAlarmCount.counselingCount}건</div>
                    </Link>

                    <div className="bar"></div>
                    <Link to="/mypage/notice">
                      <div>알림 {allAlarmCount.alarmCount}건</div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hr strong_hr"></div>
              <div className="mypage_body">
                <Sidebar />
              </div>
            </div>
          </div>
          <MobileBottomNav />
        </div>
      )}
    </>
  );
}

export default MypageMobile;
