import React, { useLayoutEffect, useState } from "react";
import "../Mypage/Mypage.css";
import depthHome from "../../images/depth_home.png";
import eventImg from "../../images/event_img.png";
import back from "../../images/back.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { getEventList } from "../../_actions/main_action";
import Pagination from "../../components/Pagination";
import { changeDateFormat } from "../../Common/lib";
import MobileBottomNav from "../Footer/MobileBottomNav";
import useHistoryState from "use-history-state";

function EventBoard() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [OngoingCnt, setOngoingCnt] = useState("");
  const parentFunction = (data) => {
    setOngoingCnt(data);
  };

  const obj = {
    0: <Ongoing parentFunction={parentFunction} />,
    1: <Closed />,
  };

  const [tabActive, setTabActive] = useHistoryState(0, "tabActive");
  const [tabPage, setTabPage] = useState(obj[tabActive]);

  const clickTab = (num) => {
    setTabPage(obj[num]);
    setTabActive(num);
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    let body = {
      visible: "노출중",
    };

    dispatch(getEventList(body)).then((res) => {
      if (res.payload.eventList) {
        let result = res.payload.eventList;
        let arr = result.filter((event) => event.status === 1);

        // // 오늘 날짜 이후 이벤트만 Push
        // result.map((item) => {
        //   //상태 진행 중인 것만
        //   if (item.status) arr.push(item);
        //   return arr;
        // });
        parentFunction(arr.length);
      }
    });
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}

        <div className="main center_main mypage_main" id="EventBoard">
          {isMobile ? null : (
            <div className="mypage_header" style={{ border: "none" }}>
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>이벤트
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            <div className="content">
              {isMobile && (
                <h2>
                  <div style={{ paddingRight: 14 }} onClick={goBack}>
                    <img src={back} alt="back" className="isMobile_back" />
                  </div>
                  이벤트
                </h2>
              )}

              <div
                className="mypage_tab_menu"
                style={{ marginTop: isMobile ? 0 : 19 }}
              >
                <div
                  onClick={() => clickTab(0)}
                  className={tabActive === 0 ? "active tab" : "tab"}
                >
                  진행중 ({OngoingCnt})
                </div>
                <div
                  onClick={() => clickTab(1)}
                  className={tabActive === 1 ? "active tab" : "tab"}
                >
                  종료
                </div>
              </div>
              {tabPage}
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default EventBoard;

function Ongoing({ parentFunction }) {
  let history = useHistory();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const dispatch = useDispatch();
  const [EventList, setEventList] = useState([]);
  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const childFunction = (cnt) => {
    parentFunction(cnt);
  };

  useLayoutEffect(() => {
    let body = {
      visible: "노출중",
    };

    dispatch(getEventList(body)).then((res) => {
      if (res.payload.eventList) {
        let result = res.payload.eventList;
        //상태 진행 중인 것만
        let arr = result.filter((event) => event.status === 1);
        // let arr = [];
        // result.map((item) => {
        //   if (item.status) arr.push(item);
        //   return arr;
        // });
        setEventList(arr);
        childFunction(arr.length);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className="board_main event_table" id="ongoing">
        <div className="table_column">
          <table>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>이벤트 사진</th>
                <th style={{ width: "15%" }}>구분</th>
                <th>내용</th>
                <th style={{ width: "25%" }}>기간</th>
              </tr>
            </thead>
            <tbody>
              {isMobile ? (
                <>
                  {pageOfItems.length > 0 ? (
                    pageOfItems.map((item, index) => {
                      return (
                        <tr className="isMobile_tr" key={index}>
                          <td>
                            <img
                              src={
                                item.file === "" || item.file === null
                                  ? eventImg
                                  : item.file
                              }
                              alt="img"
                              className="event_img"
                            />
                          </td>

                          <td
                            onClick={() => {
                              history.push(`/event/detail/${item.idx}`);
                              localStorage.removeItem("pathname");
                            }}
                          >
                            <div>{item.gubun}</div>
                            <div className="title">{item.title}</div>
                            <div className="period">
                              {changeDateFormat(item.datestart, 3, "-", "")}~
                              {changeDateFormat(item.dateend, 3, "-", "")}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="isMobile_tr">
                      <td style={{ height: 50 }}>등록된 이벤트가 없습니다.</td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {pageOfItems.length > 0 ? (
                    pageOfItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <img
                              style={{ width: 120, height: 120 }}
                              src={
                                item.file === "" || item.file === null
                                  ? eventImg
                                  : item.file
                              }
                              alt="img"
                            />
                          </td>
                          <td>{item.gubun}</td>
                          <td
                            onClick={() => {
                              history.push(`/event/detail/${item.idx}`);
                              localStorage.removeItem("pathname");
                            }}
                          >
                            {item.title}
                          </td>
                          <td>
                            <div className="period">
                              {changeDateFormat(item.datestart, 3, "-", "")}~
                              {changeDateFormat(item.dateend, 3, "-", "")}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">등록된 이벤트가 없습니다.</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <Pagination items={EventList} onChangePage={onChangePage} />
      </div>
    </>
  );
}

function Closed() {
  let history = useHistory();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const dispatch = useDispatch();
  const [EventList, setEventList] = useState([]);
  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  useLayoutEffect(() => {
    let body = {
      visible: "노출중",
    };

    dispatch(getEventList(body)).then((res) => {
      if (res.payload.eventList) {
        let result = res.payload.eventList;
        //상태가 종료인 것만
        let arr = result.filter((event) => event.status === 0);

        // let arr = [];
        // result.map((item) => {
        //   if (!item.status) arr.push(item);
        //   return arr;
        // });
        setEventList(arr);
      }
    });
  }, [dispatch]);

  return (
    <>
      <div className="board_main event_table" id="ongoing">
        <div className="table_column">
          <table>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>이벤트 사진</th>
                <th style={{ width: "15%" }}>구분</th>
                <th>내용</th>
                <th style={{ width: "25%" }}>기간</th>
              </tr>
            </thead>
            <tbody>
              {isMobile ? (
                <>
                  {pageOfItems.length > 0 ? (
                    pageOfItems.map((item, index) => {
                      return (
                        <tr className="isMobile_tr" key={index}>
                          <td>
                            <img
                              src={item.file === "" ? eventImg : item.file}
                              alt="img"
                              className="event_img"
                            />
                          </td>

                          <td
                            onClick={() => {
                              history.push(`/event/detail/${item.idx}`);
                              localStorage.removeItem("pathname");
                            }}
                          >
                            <div>{item.gubun}</div>

                            <div className="title">{item.title}</div>
                            <div className="period">
                              {changeDateFormat(item.datestart, 3, "-", "")}~
                              {changeDateFormat(item.dateend, 3, "-", "")}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="isMobile_tr">
                      <td style={{ height: 50 }}>등록된 이벤트가 없습니다.</td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {pageOfItems.length > 0 ? (
                    pageOfItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <img
                              style={{ width: 120, height: 120 }}
                              src={item.file === "" ? eventImg : item.file}
                              alt="img"
                              className="event_img"
                            />
                          </td>

                          <td>{item.gubun}</td>
                          <td
                            onClick={() => {
                              history.push(`/event/detail/${item.idx}`);
                              localStorage.removeItem("pathname");
                            }}
                          >
                            {item.title}
                          </td>
                          <td>
                            <div className="period">
                              {changeDateFormat(item.datestart, 3, "-", "")}~
                              {changeDateFormat(item.dateend, 3, "-", "")}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">등록된 이벤트가 없습니다.</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <Pagination items={EventList} onChangePage={onChangePage} />
      </div>
    </>
  );
}
