import React, { useLayoutEffect, useState } from "react";
import "./Board.css";
import informationImg from "../../images/information_img.png";
import depthHome from "../../images/depth_home.png";
import boardCardImg from "../../images/board_card_img.png";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { addressBlog } from "../../_actions/main_action";
import { changeDateFormat } from "../../Common/lib";
import { useRouteMatch } from "react-router-dom";

function Information() {
  let match = useRouteMatch();
  const idx = match.params.idx;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const pathName = "/information/";

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  const [boardList, setBoardList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const pageSize = 6;

  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useLayoutEffect(() => {
    // 유저정보
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let data = { status: 1 }; //공개글만 가져오기
    dispatch(addressBlog(data)).then((res) => {
      if (res.payload.result && res.payload.blogList) {
        const result = res.payload.blogList;
        setBoardList(result);
      }
    });
  }, [dispatch]);

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main board_main" id="information">
          <div className="section_1">
            <div className="container">
              <h1 className="title">부동산 정보</h1>
              <div className="desc">
                어렵고 복잡한 부동산 관련 정보를
                <br />
                누구나 쉽고 알기 쉽게 알려드립니다.
              </div>

              <img
                src={informationImg}
                alt="information"
                className="board_main_img"
              />
            </div>
          </div>
          <div className="section_2">
            <div className="depth_column">
              <Link to="/">
                <img src={depthHome} alt="home" />
              </Link>
              <div className="board_name">
                <span>〉</span>부동산정보
              </div>
            </div>
            <div className="board_card_column table_column">
              {pageOfItems.length > 0
                ? pageOfItems.map((item, index) => {
                    var imgData = "";
                    if (item.file === "") {
                      imgData = boardCardImg;
                    } else {
                      imgData = item.file;
                    }

                    return (
                      <Link to={`/information/detail/${item.idx}`} key={index}>
                        <div className="board_card">
                          <div
                            className="board_card_img"
                            style={{
                              backgroundImage: `url('${imgData}')`,
                              // "background-image": "url('" + imgData + "')",
                            }}
                          ></div>
                          <div className="board_card_info">
                            <div className="board_card_title">{item.title}</div>
                            <div className="board_card_desc">{item.text}</div>
                            <div className="board_card_wrapper">
                              <div className="board_card_date">
                                {changeDateFormat(
                                  item.registerdate,
                                  3,
                                  "-",
                                  ""
                                )}
                              </div>
                              <div className="bar"></div>
                              <div className="board_card_writer">
                                {item.gubun}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                : "등록된 글이 없습니다."}
            </div>

            <Pagination
              items={boardList}
              onChangePage={onChangePage}
              pageSize={pageSize}
              initialPage={idx}
              pathName={pathName}
            />
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default Information;
