import React, { useLayoutEffect, useState } from "react";
import { CheckEmail, CheckPw } from "../../Common/lib";
import { userAdminAdd } from "../../_actions/admin_board_action";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import { ADMIN_SUPER, ADMIN_GENERAL, ADMIN_PRIVACY } from "../../Common/adminType";

function UserAdminAdd({ history }) {
  const dispatch = useDispatch();

  // input값 제어
  const [SubmitData, setSubmitData] = useState({
    memstatus: "정상",
    memtype: "일반",
    memname: "",
    cellno: "",
    memid: "",
  });
  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value.replace(" ", "") });
  };

  const [SubmitDataPw, setSubmitDataPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const pwHandler = (e) => {
    let el = e.target;
    setSubmitDataPw({ ...SubmitDataPw, [el.name]: el.value.replace(" ", "") });
  };

  const [warningPw, setWarningPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const [accessStatus, setAccessStatus] = useState(true);
  const accessToggle = () => {
    setAccessStatus(!accessStatus);
  };
  // 전송시
  const submitHandler = () => {
    if (SubmitData.memname === "") {
      alert("관리자명을 입력해주세요.");
      return;
    } else if (telNum === "") {
      alert("핸드폰번호를 입력해주세요.");
      return;
    } else if (!CheckEmail(SubmitData.memid)) {
      // 아이디(이메일) 유효성 검사
      alert("이메일 형식을 확인해주세요.");
      return;
    } else if (!CheckPw(SubmitDataPw.originPw)) {
      // 비밀번호 유효성 검사
      setWarningPw({
        originPw: "비밀번호 생성 규칙에 어긋납니다. 다시 설정 바랍니다.",
        confirmPw: "",
      });
      return;
    } else if (SubmitDataPw.originPw !== SubmitDataPw.confirmPw) {
      setWarningPw({
        originPw: "",
        confirmPw: "동일한 비밀번호를 입력하세요.",
      });
      return;
    } else {
      setWarningPw({
        originPw: "",
        confirmPw: "",
      });
    }

    let body = {
      memstatus: SubmitData.memstatus,
      memtype: SubmitData.memtype,
      memname: SubmitData.memname,
      cellno: telNum,
      memid: SubmitData.memid,
      password: SubmitDataPw.originPw,
      access: accessStatus ? "Y" : "N",
    };
    dispatch(userAdminAdd(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("등록되었습니다.");
        history.push("/rf_zib_admin/user/admin/1");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const [telNum, setTelNum] = useState("");
  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container" id="userAdminAdd">
          <div id="userLawyerAdd">
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">관리자 신규등록</h2>
              </div>

              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>회원상태</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          defaultChecked
                          id="radio1"
                          name="memstatus"
                          value="정상"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio1">정상</label>
                        <input
                          type="radio"
                          id="radio2"
                          name="memstatus"
                          value="정지"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio2">정지</label>
                      </div>
                    </td>
                    <th rowSpan="6"></th>
                    <td rowSpan="6" style={{ background: "#f5f5f5" }}></td>
                  </tr>
                  <tr>
                    <th>관리자 등급</th>
                    <td>
                      <div className="free_user_chk">
                        <input
                          type="radio"
                          id="chk1"
                          defaultChecked
                          name="memtype"
                          value={ADMIN_GENERAL.Alias}
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk1">{ADMIN_GENERAL.Alias}관리자</label>
                        <input
                          type="radio"
                          id="chk2"
                          name="memtype"
                          value={ADMIN_SUPER.Alias}
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk2">{ADMIN_SUPER.Alias}관리자</label>
                        <input
                          type="radio"
                          id="chk3"
                          name="memtype"
                          value={ADMIN_PRIVACY.Alias}
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk3">{ADMIN_PRIVACY.Alias}관리자</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>관리자명</th>
                    <td>
                      <input
                        type="text"
                        name="memname"
                        value={SubmitData.memname}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>핸드폰 번호</th>
                    <td>
                      <input
                        type="text"
                        placeholder="휴대 전화번호를 입력하세요."
                        name="cellno"
                        value={telNum}
                        onChange={handelTelNum}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>이메일(ID)</th>
                    <td>
                      <input
                        type="text"
                        placeholder="이메일(ID)를 입력하세요."
                        name="memid"
                        value={SubmitData.memid}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">비밀번호 관리</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <input
                        type="password"
                        name="originPw"
                        onChange={pwHandler}
                        value={SubmitDataPw.originPw}
                        autoComplete="new-password"
                      />
                      <div className="warning">{warningPw.originPw}</div>
                    </td>
                    <th>비밀번호 확인</th>
                    <td>
                      <input
                        type="password"
                        name="confirmPw"
                        value={SubmitDataPw.confirmPw}
                        onChange={pwHandler}
                      />
                      <div className="warning">{warningPw.confirmPw}</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title access">
                일반관리자 권한 관리
                <span className="info">
                  ※ 일반관리자는 관리자 메뉴의 [주소관리]에 대한 접근 및 권한만
                  부여됩니다.
                  <br />※ 최고관리자 등급은 관리자 메뉴의 모든 권한이
                  부여됩니다.
                </span>
              </h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>주소관리</th>
                    <td className="board_access">
                      <input
                        type="checkbox"
                        id="chk5"
                        checked={accessStatus}
                        onClick={accessToggle}
                      />
                      <label htmlFor="chk5">권한 허용</label>
                    </td>
                    <th style={{ background: "none" }}></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="detail_user admin_btn_column">
                <div
                  className="admin_white_btn left btn"
                  onClick={() => history.goBack()}
                >
                  취소
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  등록
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAdminAdd;
