import React, { useState, useRef, useLayoutEffect } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  adminBlogDetail,
  boardDivisionList,
  adminBlogModify,
  adminBlogImgDelete,
  adminBlogDelete,
} from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";
import defaultProfile from "../../images/event_img.png";

function BlogBoardDetail({ match, history }) {
  const dispatch = useDispatch();
  const idx = match.params.idx;

  // 뒤로가기
  const goBack = () => {
    history.goBack();
  };

  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [getText, setGetText] = useState("");
  const [categories, setCategories] = useState([]); // 카테고리 리스트
  const [categorie, setCategorie] = useState(); // 선택한 카테고리
  const [fileUrl, setFileUrl] = useState(""); // 화면에 이미지 표시
  const [FileInfo, setFileInfo] = useState(null);
  const [memname, setMemname] = useState();
  const [registerdate, setRegisterdate] = useState();
  const [updatedate, setUpdatedate] = useState();
  const [isFile, setIsFile] = useState();

  useLayoutEffect(() => {
    // dispatch 가져오기
    let body = {
      idx: idx,
    };
    dispatch(adminBlogDetail(body)).then((res) => {
      if (res.payload.result === "success") {
        const result = res.payload.blog;

        setStatus(result.status);
        setTitle(result.title);
        setGetText(result.text);
        setCategorie(result.gubun);
        setMemname(result.memname);
        setRegisterdate(result.registerdate);
        setUpdatedate(result.updatedate);
        setFileUrl(result.imagefile1);
        setIsFile(result.imagefilename1);
      }
    });

    // 카테고리 목록 가져오기
    let data = {
      kind: "블로그",
    };
    dispatch(boardDivisionList(data)).then((res) => {
      if (res.payload.categories !== "false") {
        const result = res.payload.categories;
        setCategories(result);
      } else {
        setCategories([]);
      }
    });
  }, [dispatch, idx]);

  // input값 제어
  const inputHandler = (e) => {
    let el = e.target.name;
    switch (el) {
      case "title":
        setTitle(e.target.value);
        return;
      case "text":
        setGetText(e.target.value);
        return;
      case "gubun":
        setCategorie(e.target.value);
        return;
      case "status":
        //공개
        if (e.target.id === "radio1") setStatus(1);
        else if (e.target.id === "radio2") setStatus(0); //비공개
        return;
      default:
        return;
    }
  };
  // 파일 열기
  const fileInputs = useRef([]);
  const openFile = () => {
    fileInputs.current.click();
  };

  // 파일 정보 저장
  const setFile = (e) => {
    const value = e.target.files[0];
    setFileInfo(value);
    setFileUrl(URL.createObjectURL(value));
  };

  // 파일 삭제
  const deleteFileUrl = () => {
    if (isFile) {
      if (!window.confirm("정말 삭제하시겠습니까?")) return;
      let body = {
        idx: idx,
        file: isFile,
      };

      dispatch(adminBlogImgDelete(body)).then((res) => {
        if (res.payload.result === "success") {
        }
      });
    }

    setFileInfo(null);
    setFileUrl("");
  };
  const handleImgError = (e) => {
    e.target.src = defaultProfile;
  };
  // 저장
  const submitHandler = () => {
    if (title === "") {
      alert("제목을 입력하세요.");
      return;
    } else if (getText === "") {
      alert("내용을 입력하세요.");
      return;
    }
    let admin_auth = localStorage.getItem("admin_auth");

    let bodyNameList = [
      "idx",
      "title",
      "text",
      "gubun",
      "blogImg",
      "status",
      "admin_auth",
    ];
    let bodyValueList = [
      idx,
      title,
      getText,
      categorie,
      FileInfo,
      status,
      admin_auth,
    ];
    let formData = new FormData();

    // 선언한 배열 이용해서 form에 데이터 다~ 추가
    bodyNameList.map((val, i) => {
      return formData.append(val, bodyValueList[i]);
    });
    dispatch(adminBlogModify(formData)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        goBack();
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    });
  };

  // 게시글 삭제
  const deleteBoard = () => {
    let body = {
      idx: idx,
      file: isFile,
    };

    dispatch(adminBlogDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        goBack();
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">부동산정보 상세</h2>
                <div className="user_control_column">
                  <div className="admin_delete_btn btn" onClick={deleteBoard}>
                    삭제
                  </div>
                </div>
              </div>

              <table className="detail_table board_table">
                <tbody>
                  <tr>
                    <th>공개여부</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          checked={status === 1 ? true : false}
                          id="radio1"
                          onChange={inputHandler}
                          name="status"
                          value={1}
                        />
                        <label htmlFor="radio1">공개</label>
                        <input
                          type="radio"
                          id="radio2"
                          onChange={inputHandler}
                          name="status"
                          value={0}
                          checked={status === 0 ? true : false}
                        />
                        <label htmlFor="radio2">비공개</label>
                      </div>
                    </td>
                    <th rowSpan="4">대표 이미지</th>
                    <td rowSpan="4">
                      <div className="img_column">
                        <div className="sample_img">
                          {fileUrl && fileUrl !== "/api" ? (
                            <img
                              alt="sample"
                              src={fileUrl}
                              style={{ margin: "auto" }}
                              onError={handleImgError}
                            />
                          ) : null}

                          <input
                            name="eventImg"
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            ref={(elem) => (fileInputs.current = elem)}
                            onChange={setFile}
                          />
                        </div>

                        <div
                          className="admin_black_btn_column"
                          style={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            className="admin_black_btn left btn"
                            onClick={() => {
                              openFile();
                            }}
                          >
                            업로드
                          </div>
                          <div
                            className="admin_black_btn btn left"
                            onClick={() => deleteFileUrl()}
                          >
                            삭제
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>글번호</th>
                    <td>{idx}</td>
                  </tr>
                  <tr>
                    <th>구분</th>
                    <td>
                      <select
                        onChange={inputHandler}
                        name="gubun"
                        value={categorie}
                      >
                        {categories.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>작성자명</th>
                    <td>{memname}</td>
                  </tr>
                  <tr>
                    <th>최초 등록일</th>
                    <td>{changeDateFormat(registerdate, 5, "-", ":")}</td>
                    <th>최종 수정일</th>
                    <td>
                      {updatedate
                        ? changeDateFormat(updatedate, 5, "-", ":")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>제목</th>
                    <td>
                      <input
                        type="text"
                        placeholder="제목을 입력하세요."
                        onChange={inputHandler}
                        value={title}
                        name="title"
                      />
                    </td>
                  </tr>
                  <tr className="board_content">
                    <th>내용</th>
                    <td colSpan="3">
                      <textarea
                        placeholder="내용을 입력하세요."
                        onChange={inputHandler}
                        value={getText || ""}
                        name="text"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="detail_user admin_btn_column">
                <div className="admin_white_btn btn left" onClick={goBack}>
                  목록
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogBoardDetail;
