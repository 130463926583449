import React, { useLayoutEffect, useState } from "react";
import pageFirst from "../../images/page_first.png";
import pagePrev from "../../images/page_prev_gray.png";
import pageNext from "../../images/page_next_gray.png";
import pageLast from "../../images/page_last.png";
import axios from "axios";
import { changeDateFormat } from "../../Common/lib";
function ServicePushList() {
  const [pushList, setPushList] = useState([]);
  useLayoutEffect(() => {
    let url = "/api/admin/send/alarm/list";
    axios.get(url).then((res) => {
      if (res.data.pushList) setPushList(res.data.pushList);
    });
  }, []);

  const list =
    pushList.length > 0 &&
    pushList.map((value, i) => {
      return (
        <tr key={value.idx}>
          {/* <td>{value.idx}</td> */}
          <td>{value.rownum}</td>
          <td>{changeDateFormat(value.senddate, 5, ".", ":")} </td>
          <td>{value.target}</td>
          <td>{value.alarmcontent}</td>
        </tr>
      );
    });
  return (
    <>
      <div className="main_container user_list_column">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>발송일시</th>
              <th>발송대상</th>
              <th>발송 내용</th>
            </tr>
          </thead>
          <tbody>
            {list ? (
              list
            ) : (
              <tr>
                <td colSpan="4">발송된 알림이 존재하지 않습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="admin_pagenation">
          <img src={pageFirst} alt="<<" className="page_first btn" />
          <img src={pagePrev} alt="<" className="page_prev btn" />
          <div className="current_page">
            Page <input type="text" defaultValue="1" /> of 1
          </div>
          <img src={pageNext} alt=">" className="page_next btn" />
          <img src={pageLast} alt=">>" className="page_last btn" />
        </div>
      </div>
    </>
  );
}

export default ServicePushList;
