import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  boardConsultDelete,
  boardConsultDetail,
  boardConsultReply,
  boardConsultReplyDelete,
  userLawyerList
} from "../../_actions/admin_board_action";
import { changeDateFormat, getToday } from "../../Common/lib";
import { auth } from "../../_actions/user_action";
import $ from "jquery";

function BoardConsultDetail({ match, history }) {
  var x_type = localStorage.getItem("x_type");
  const dispatch = useDispatch();
  const pagen = match.params.pagen;

  const [boardDetail, setBoardDetail] = useState([]); // 글 정보 가져오기
  const [isReply, setIsReply] = useState(false); // 댓글 있는지 여부
  const [lawList, setLawList] = useState([]); // 변호사리스트
  const [selectLaw, setSelectLaw] = useState(""); // 선택한 변호사
  const [msgSend, setMsgSend] = useState(false); // 메시지 전송
  
  const idx = match.params.idx;
  const [replytitle, setReplytitle] = useState("");
  const [replycontent, setReplycontent] = useState("");
  const [memname, setMemname] = useState("");

  useLayoutEffect(() => {
    var memname = localStorage.getItem("admin_authname");
    setMemname(memname);
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleOpen = (e) => {
    setBoardDetail({ ...boardDetail, open: Number(e.target.value) });
  };

  useLayoutEffect(() => {
    let body = { idx: idx };
    // 전체 글 가져오기
    dispatch(boardConsultDetail(body)).then((res) => {
      if (res.payload.board) {
        setBoardDetail(res.payload.board);
        if (res.payload.reply) {
          const result = res.payload.reply;
          setIsReply(result);
          setReplytitle(result.replytitle);
          setReplycontent(result.replycontent);
        }
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });

    dispatch(auth("admin")).then((res) => {
      if (res.payload.isLogin) {
      }
    });

    //변호사 목록 호출
    dispatch(userLawyerList()).then((res) => {
        setLawList(res.payload.user);
    });

  }, [dispatch, idx]);

  const inputHandler = (e) => {
    let el = e.target;
    if (el.name === "replytitle") {
      setReplytitle(el.value);
    } else if (el.name === "replycontent") {
      setReplycontent(el.value);
    }
  };

  // 변호사 선택
  const changeLaw = (e) => {
    let msg = "";

    // value값을 이용해 변호사들 문자전송내역 확인
    if(Number(e.target.value) > 0){
      if(lawList[e.target.selectedIndex].senddate === null){
        msg = "문자전송 내역이 없습니다.";
        setMsgSend(true);
      }else{
        let today = changeDateFormat(getToday(), 3, ".");
        let senddate = changeDateFormat(lawList[e.target.selectedIndex].senddate, 3, ".")

        // 오늘날짜로 전송을 안했으면 메시지전송
        if(today !== senddate){
          setMsgSend(true);
        }else{
          setMsgSend(false);
        };
        msg = `최근 전송내역 : ${senddate}`;
      }
      setBoardDetail({ ...boardDetail, status: 2 });
      setSelectLaw(e.target.selectedIndex);
      $(".senddate").html(msg);
    }else{
      // -1값을 넣어 해당없음을 따로 구분
      setSelectLaw(-1);
      setBoardDetail({ ...boardDetail, status: 0 });
      $(".senddate").html("");
    }
  }

  // 저장하기
  const submitHandler = () => {
    let admin_auth = localStorage.getItem("admin_auth");
    let lawidx, cellno;

    if(selectLaw === ""){
      // 선택된 변호사가 없으면 배정된 변호사가 있는지 확인!
      // 없으면 게시글을 답변하는 사람이 변호사인경우 작성자의 idx값으로 변경
      lawidx = boardDetail.arrange ? boardDetail.arrange : replytitle && replycontent && x_type === "lawyer" ? admin_auth : 0;
      cellno = "";
    }else if(selectLaw === -1){ /// -1값은 해당없음을 선택한것
      lawidx = 0;
      cellno = "";
    }else{
      lawidx = lawList[selectLaw].idx;
      cellno = lawList[selectLaw].cellno;
    }

    let body = {
      seq: idx,
      replytitle: replytitle,
      replycontent: replycontent,
      open: boardDetail.open,
      memid: boardDetail.memid,
      isReply: isReply,
      admin_auth: admin_auth,
      status: boardDetail.status,
      sendmsg: msgSend,
      lawidx: lawidx,
      cellno: cellno
    };

    dispatch(boardConsultReply(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("저장되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });

  };

  // 답변 삭제
  const deleteReply = () => {
    if (!isReply || isReply === 2) {
      alert("등록된 답변이 없습니다.");
      return;
    }
    let body = {
      seq: idx,
    };
    dispatch(boardConsultReplyDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  // 게시글 삭제
  const deleteBoard = () => {
    let body = {
      idx: idx,
      isReply: isReply,
    };
    dispatch(boardConsultDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제되었습니다.");
        goBack();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container" id="boardConsultDetail">
        <SideMenu tabActive={0} />
        <div className="main_container">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">무료법률상담 내용</h2>
                <div className="user_control_column">
                  <div
                    className="admin_delete_btn btn left"
                    onClick={deleteReply}
                  >
                    답변 삭제
                  </div>
                  <div className="admin_delete_btn btn" onClick={deleteBoard}>
                    게시글 삭제
                  </div>
                </div>
              </div>

              <table className="detail_table board_table">
                <tbody>
                  <tr>
                    <th>공개여부</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          id="radio1"
                          name="openNclosed"
                          value="1"
                          checked={boardDetail.open === 1 && true}
                          onChange={handleOpen}
                        />
                        <label htmlFor="radio1">공개</label>
                        <input
                          type="radio"
                          id="radio2"
                          name="openNclosed"
                          value="0"
                          checked={boardDetail.open === 0 && true}
                          onChange={handleOpen}
                        />
                        <label htmlFor="radio2">비공개</label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>답변상태</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          id="radio3"
                          value={0}
                          name="replyState"
                          checked={boardDetail.status === 0 ? true : false}
                          disabled
                        />
                        <label htmlFor="radio3">대기</label>
                        <input
                          type="radio"
                          id="radio3"
                          value={2}
                          name="replyState"
                          checked={boardDetail.status === 2 ? true : false}
                          disabled
                        />
                        <label htmlFor="radio3">변호사 답변 대기</label>
                        <input
                          type="radio"
                          id="radio4"
                          name="replyState"
                          checked={boardDetail.status === 1 ? true : false}
                          disabled
                        />
                        <label htmlFor="radio4">완료</label>
                        <span className="arrange" style={{"marginLeft":"10px"}}></span>
                      </div>
                    </td>
                  </tr>
                  {
                    x_type === "admin" ? 
                    <tr>
                      <th>변호사 배정</th>
                      <td>
                          <select onChange={changeLaw} >
                            {
                              lawList.map((e,i)=> { 
                                return(<option value={e.idx} key={i} selected={boardDetail.arrange === e.idx ?true:false}>{e.memname}</option>)
                              })
                            }
                            <option value={0}>해당없음</option>

                          </select>
                          <span className="senddate" style={{"marginLeft":"10px"}}></span>
                      </td>
                      <th>배정된 변호사</th>
                      <td>{boardDetail.lawmemname} </td>
                    </tr> 
                    : 
                    <tr>
                      <th>배정된 변호사</th>
                      <td>{boardDetail.lawmemname} </td>
                    </tr> 
                  }
                  <tr>
                    <th>글번호</th>
                    <td>{boardDetail.idx}</td>
                  </tr>
                  <tr>
                    <th>구분</th>
                    <td>
                      <select disabled>
                        <option value={boardDetail.gubun}>
                          {boardDetail.gubun}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>최초 등록일</th>
                    <td>
                      {changeDateFormat(boardDetail.registerdate, 5, ".", ":")}
                    </td>
                    <th>최종 수정일</th>
                    <td>
                      {boardDetail.updatedate
                        ? changeDateFormat(boardDetail.updatedate, 5, ".", ":")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>작성자명</th>
                    <td>{boardDetail.memname}</td>
                  </tr>
                  <tr>
                    <th>제목</th>
                    <td colSpan="3">
                      <div className="board_title">{boardDetail.title}</div>
                    </td>
                  </tr>
                  <tr className="board_content">
                    <th>내용</th>

                    <td colSpan="3">
                      <textarea
                        value={boardDetail.text || ""}
                        readOnly
                        style={{ marginBottom: 10, marginTop: 10 }}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="detail_table board_table reply_table">
                <tbody>
                  <tr>
                    <th>답변일자</th>
                    <td>
                      {isReply
                        ? changeDateFormat(isReply.registerdate, 5, ".", ":")
                        : changeDateFormat(new Date().toString(), 5, ".", ":")}
                    </td>
                    <th>답변 수정일</th>
                    <td>
                      {isReply.updatedate
                        ? changeDateFormat(isReply.updatedate, 5, ".", ":")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>답변자명</th>
                    <td>{isReply.memid ? isReply.memid : memname}</td>
                  </tr>

                  <tr>
                    <th>제목</th>
                    <td>
                      <input
                        type="text"
                        value={replytitle}
                        placeholder="제목을 입력하세요."
                        name="replytitle"
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr className="board_content reply_content">
                    <th>답변 내용</th>
                    <td colSpan="3">
                      <textarea
                        placeholder="내용을 입력하세요."
                        value={replycontent || ""}
                        name="replycontent"
                        onChange={inputHandler}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="detail_user admin_btn_column">
                <div
                  className="admin_white_btn btn left"
                  onClick={() => {
                    history.push(`/rf_zib_admin/board/consult/${pagen}`);
                  }}
                >
                  목록
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoardConsultDetail;
