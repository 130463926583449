/* eslint-disable no-useless-escape */
import { isMobile } from 'react-device-detect';

// 로컬스토리지에 있는 input값 확인후 리턴 
export function searchDataSet(item){
  let localItem = localStorage.getItem("searchInput")
   if(localItem !== null) {
    return JSON.parse(localItem);
  }else{
    return item;
  }
}

export function searchCashClear(){
  window.localStorage.removeItem('searchInput');
}

// base64 인코딩
export function strEncoding(str){
  let chgStr = Buffer.from(str).toString('base64');
return chgStr;
}

// 디코딩
export function strDecoding(str){
  let chgStr;
  if(str === false || str === undefined){
    chgStr = str;
  } else {
    chgStr = Buffer.from(str, 'base64').toString('utf8');
  }
return chgStr;
}


// 이메일 유효성 검사
export function CheckEmail(email) {
  var reg_email =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  if (!reg_email.test(email)) {
    return false;
  } else {
    return true;
  }
}

// 비밀번호 유효성 검사
export function CheckPw(pw) {
  var num = pw.search(/[0-9]/g);
  var eng = pw.search(/[a-z]/gi);
  var spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
  if (pw.length < 8) {
    return false;
  }
  if (pw.search(/₩s/) !== -1) {
    return false;
  }
  if (num < 0 || eng < 0 || spe < 0) {
    return false;
  }
  return true;
}

export function getToday() {
  // 달력 오늘 날짜 기본 셋팅
  let today = new Date();

  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  if (month < 10) {
    month = "0" + month;
  }
  let date = today.getDate(); // 날짜
  if (date < 10) {
    date = "0" + date;
  }
  return year + "/" + month + "/" + date;
}

export function formatDay(day) {
  let year = day.getFullYear(); // 년도
  let month = day.getMonth() + 1; // 월
  if (month < 10) {
    month = "0" + month;
  }
  let date = day.getDate(); // 날짜
  if (date < 10) {
    date = "0" + date;
  }
  return year + "/" + month + "/" + date;
}

export function adminGetToday() {
  // 관리자페이지 달력 오늘 날짜 기본 셋팅
  let today = new Date();

  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  if (month < 10) {
    month = "0" + month;
  }
  let date = today.getDate(); // 날짜
  if (date < 10) {
    date = "0" + date;
  }
  return year + "-" + month + "-" + date;
}

export function getTodayDateNTime(lang) {
  // 오늘 날짜, 시간 돌려주기
  let today = new Date();

  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  if (month < 10) {
    month = "0" + month;
  }
  let date = today.getDate(); // 날짜
  if (date < 10) {
    date = "0" + date;
  }
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();

  if (lang === "ko") {
    return `${year}년 ${month}월 ${date}일 ${hours}시 ${minutes}분`;
  } else if (lang === "sym") {
    return `${year}.${month}.${date} ${hours}:${minutes}`;
  } else {
    return `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;
  }
}

export function changeLocalDate(getDate) {
  // DB에서 날짜 조회 시 시간이 변경되어서 local시간으로 변경하는 함수
  return new Date(getDate).toLocaleString();
}

export function changeDateFormat(getDate, where, symbol1, symbol2) {
  // 이미 정해진 날짜의 포맷 변경
  // getDate = 변경할 날짜 , where = 어떤날짜까지 반환할것인지(년월일시분초...) , symbol1(년월일에 사용할 기호), symbol2(시분초에 사용할 기호)
  if (where > 6) {
    return;
  }
  if (!getDate || getDate.slice(0, 4) === "0000") {
    return "";
  }

  let date = new Date(getDate);
  let year = date.getFullYear(); // 년도
  let month = date.getMonth() + 1; // 월
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate(); // 날짜
  if (day < 10) {
    day = "0" + day;
  }
  let hours = date.getHours();
  if (hours < 10) {
    hours = "0" + hours;
  }
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  let rt = [];
  switch (where) {
    case 1:
      rt.push(year);
      break;

    case 2:
      rt.push(year);
      rt.push(month);
      break;

    case 3:
      rt.push(year);
      rt.push(month);
      rt.push(day);
      break;

    case 4:
      rt.push(year);
      rt.push(month);
      rt.push(day);
      rt.push(hours);
      break;

    case 5:
      rt.push(year);
      rt.push(month);
      rt.push(day);
      rt.push(hours);
      rt.push(minutes);
      break;

    case 6:
      rt.push(year);
      rt.push(month);
      rt.push(day);
      rt.push(hours);
      rt.push(minutes);
      rt.push(seconds);
      break;

    default:
      break;
  }

  let ko = ["년 ", "월 ", "일 ", "시 ", "분 ", "초 "];

  let str = rt.reduce((str, val, i) => {
    if (i < 3) {
      if (symbol1 === "ko") str += val + ko[i];
      else if (i === 2) str += val + " ";
      else str += val + symbol1;
    } else {
      if (symbol2 === "ko") str += val + ko[i];
      else if (i === rt.length - 1) str += val + " ";
      else str += val + symbol2;
    }
    return str;
  }, "");

  return str;
}

// 5자리 난수 생성
export function generateRandomCode(n) {
  let str = "";
  for (let i = 0; i < n; i++) {
    str += Math.floor(Math.random() * 10);
  }
  return str;
}

// 타이머
export function startTimer(count, display, timer, isRunning) {
  var minutes, seconds;
  timer = setInterval(function () {
    minutes = parseInt(count / 60, 10);
    seconds = parseInt(count % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.html(minutes + ":" + seconds);

    // 타이머 끝
    if (--count < 0) {
      clearInterval(timer);
      alert("시간초과");
      isRunning = false;
      return isRunning;
    }
  }, 1000);
  isRunning = true;
  return isRunning;
}

export function isDevice() {
  var mobile = /iphone|ipad|ipod|android/i.test(
    navigator.userAgent.toLowerCase()
  );

  if (mobile) {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search("android") > -1) {
      return false;
    } else if (
      userAgent.search("iphone") > -1 ||
      userAgent.search("ipod") > -1 ||
      userAgent.search("ipad") > -1
    )
      // IOS 일때만 true
      return true;
    else {
      return false;
    }
  } else {
    return false;
  }
}

// 쿠키 가져오기 함수
export function getCookie(cName) {
  cName = cName + "=";
  let cookieData = document.cookie;
  let start = cookieData.indexOf(cName);
  let cValue = "";
  if (start !== -1) {
    start += cName.length;
    let end = cookieData.indexOf(";", start);
    if (end === -1) end = cookieData.length;
    cValue = cookieData.substring(start, end);
  }
  return unescape(cValue);
}

// 쿠키 삭제하기 함수 window.location.host
export function deleteCookie(name, path = "/", domain = "") {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  let cookieValue =
    name +
    "=" +
    (path && ";path=" + path) +
    (domain && ";domain=" + domain) +
    ";expires=" +
    date.toUTCString();

  if (getCookie(name)) {
    document.cookie = cookieValue;
  }
  return;
}

// 쿠키 저장하기 함수
export function setCookie(name, value = "", days = "") {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

//2022-01-14 장지혜
//8자리 string date -> YYYY-MM-DD 형태로 변경
export function changeStringToDateFormat(str){
  let year = str.substr(0,4);
  let month = str.substr(4, 2);
  let day = str.substr(6,2);

  let dateFormat = year + "-" + month + "-" + day;

  return dateFormat;
}

// 특수 문자, 공백 체크 
export function checkSpecial(str) { 
    const regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
    const spaceExp = /\s/g;

    if(regExp.test(str) || str.match(spaceExp)) {
        return true;
    }else{
        return false;
    } 
} 

// 숫자만 입력 체크
export function checkOnlyNumber(str) { 
  const regExp = /^[0-9]+$/;
  if(regExp.test(str)) {
      return true;
  }else{
      return false;
  } 
}

// 핸드폰 번호 가리기
export function hidePhoneNumber(num) {
  let first = num.substring(0,4);
  let last = num.substring(num.length - 5, num.length);

  return first + '****' + last;
}

// 이름 가리기
export function hideName(str) {
  let first = str.substring(0,1);
  let last, finish;
  last = str.substring(str.length - 1, str.length);
  finish = first + '*' + last;
  return finish;
}

export function saveLimitTime(){
  //모바일이면 30일 웹이면 1시간
  if(isMobile){
    return 30
  }else{
    return 0.04
  }
}