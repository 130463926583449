import React from "react";
import pageFirst from "../images/page_first.png";
import pagePrev from "../images/page_prev_gray.png";
import pageNext from "../images/page_next_gray.png";
import pageLast from "../images/page_last.png";
import { Link } from "react-router-dom";

const defaultProps = {
  initialPage: 1,
  pageSize: 10,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  UNSAFE_componentWillMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    var { items, pageSize } = this.props;
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    pager = this.getPager(items.length, page, pageSize);

    var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    this.setState({ pager: pager });

    this.props.onChangePage(pageOfItems);
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;

    pageSize = pageSize || 10;

    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    var pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }

    return (
      <>
        <div className="pagenation">
          <div className="prev_btn_column">
            <img
              src={pageFirst}
              alt="<<"
              className="page_first btn"
              onClick={() => this.setPage(1)}
            />
            <img
              src={pagePrev}
              alt="<"
              className="page_prev btn"
              onClick={() => this.setPage(pager.currentPage - 1)}
            />
          </div>
          <ul className="page">
            {pager.pages.map((page, index) => (
              <Link
                key={index}
                to={this.props.pathName + (index + 1)}
                className="page_link"
              >
                <li
                  key={index}
                  className={Number(pager.currentPage) === page ? "active" : ""}
                  onClick={() => this.setPage(page)}
                >
                  {page}
                </li>
              </Link>
            ))}
          </ul>

          <div className="next_btn_column">
            <img
              src={pageNext}
              alt=">"
              className="page_next btn"
              onClick={() => this.setPage(pager.currentPage + 1)}
            />

            <img
              src={pageLast}
              alt=">>"
              className="page_last btn"
              onClick={() => this.setPage(pager.totalPages)}
            />
          </div>
        </div>
      </>
    );
  }
}

Pagination.defaultProps = defaultProps;
export default Pagination;
