import React, { useLayoutEffect, useState } from "react";
import "../admin.css";
import SideMenu from "./SideMenu";
import Header from "../Header/Header";
import axios from "axios";

function SetVersion() {
  // 이용약관
  const [androidVersion, setAndroidVersion] = useState("0.0");
  const [iosVersion, setIosVersion] = useState("0.0");

  useLayoutEffect(() => {
    let url = "/api/admin/set/version";
    axios.get(url).then((res) => {
      setAndroidVersion(res.data.version[0].version);
      setIosVersion(res.data.version[1].version);
    });
  }, []);

  const handleVersion = (e) => {
    if (e.target.name === "androidVersion") {
      setAndroidVersion(e.target.value);
    } else {
      setIosVersion(e.target.value);
    }
  };

  const submitVersion = () => {
    let url = "/api/admin/set/version/modify";
    let body = {
      versions: [
        { os: "Android", version: androidVersion },
        { os: "iOS", version: iosVersion },
      ],
    };
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };
  return (
    <>
      <Header />
      <div className="admin_container" id="addressDetail">
        <SideMenu tabActive={4} />
        <div className="main_container" id="setVersion">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                앱버전
              </h2>
            </div>
            <div className="address_detail">
              <table className="detail_table version_table">
                <tbody>
                  <tr>
                    <th>Android</th>
                    <td>
                      <input
                        type="text"
                        value={androidVersion}
                        name="androidVersion"
                        onChange={handleVersion}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>iOS</th>
                    <td>
                      <input
                        type="text"
                        value={iosVersion}
                        name="iosVersion"
                        onChange={handleVersion}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="admin_table_bar"></div>
              <div className="detail_user admin_btn_column">
                <div className="admin_blue_btn btn" onClick={submitVersion}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetVersion;
