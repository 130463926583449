import React, { useLayoutEffect, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import reply from "../../images/reply.png";
import back from "../../images/back.png";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { getInquiry } from "../../_actions/main_action";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../_actions/user_action";
import { changeDateFormat, getCookie } from "../../Common/lib";
import Pagination from "../../components/Pagination";
import MobileBottomNav from "../Footer/MobileBottomNav";
import axios from "axios";
import { getAlarmCount } from "../../_actions/user_action";

function InquiryBoard() {
  const inquiryCount = useSelector((store) => store.adminInfo.inquiryCount);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  const [InquiryList, setInquiryList] = useState([]);
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
    isMobile && getInquiryNonRead();

    dispatch(getInquiry()).then((res) => {
      if (res.payload.result === "success") {
        setInquiryList(res.payload.inquiryList);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  // 문의글 읽지 않은 알림 개수 가져오기
  let getInquiryNonRead = () => {
    let url = "/api/mypage/inquiry/count";
    const x_auth = getCookie("x_auth");
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("inquiry", res.data.inquiryCount));
    });
  };

  // 페이징 처리
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div
          className="main center_main mypage_main inquiry_write"
          id="inquiryBoard"
        >
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content board_main">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                문의하기 <span className="count">{inquiryCount}</span>
              </h2>
              <div className="board_btn_column">
                <div className="info_btn_column">
                  <strong>자주하는 질문</strong> 에서 문의하실 내용이 있는지
                  먼저 확인해 주십시오.
                </div>
                <Link to="/mypage/inquiry/write">
                  <div className="inquiry_btn btn">문의하기</div>
                </Link>
              </div>
              <div className="table_column">
                <table>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>제목</th>
                      <th>답변</th>
                      <th className="day">작성일</th>
                      <th className="day">답변일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems.length > 0 ? (
                      pageOfItems.map((item, index) => {
                        return (
                          <>
                            <tr
                              className={item.status && "have_reply"}
                              key={index}
                            >
                              <BrowserRouter forceRefresh={true}>
                                <Link
                                  to={`/mypage/inquiry/detail/${item.idx}`}
                                  style={{ display: "contents" }}
                                >
                                  <td>{item.gubun}</td>
                                  <td>
                                    <div>
                                      {item.checkread === "미확인" &&
                                      !item.status ? (
                                        <span
                                          className="new_icon"
                                          style={{ marginRight: "10px" }}
                                        >
                                          NEW
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {item.title}
                                    </div>

                                    {isMobile ? null : item.status ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <img
                                          src={reply}
                                          alt="reply"
                                          style={{
                                            marginTop: 5,
                                            marginRight: 10,
                                          }}
                                        />
                                        <div style={{ lineHeight: 2 }}>
                                          {item.checkread === "미확인" &&
                                          item.status ? (
                                            <span
                                              className="new_icon"
                                              style={{ marginRight: "10px" }}
                                            >
                                              NEW
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {item.replytitle}{" "}
                                        </div>
                                      </div>
                                    ) : null}

                                    {isMobile ? (
                                      item.status ? (
                                        <div className="check_reply finish">
                                          완료
                                        </div>
                                      ) : (
                                        <div className="check_reply">대기</div>
                                      )
                                    ) : null}
                                  </td>
                                  {isMobile ? null : (
                                    <td>
                                      {item.status ? (
                                        <div className="check_reply finish">
                                          완료
                                        </div>
                                      ) : (
                                        <div className="check_reply">대기</div>
                                      )}
                                    </td>
                                  )}

                                  <td>
                                    <div className="regdate">
                                      {isMobile ? <>작성일 &nbsp; </> : null}
                                      {changeDateFormat(
                                        item.registerdate,
                                        3,
                                        "-",
                                        ""
                                      )}
                                    </div>

                                    {isMobile && item.status ? (
                                      <div
                                        className="reply_column"
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <img
                                          src={reply}
                                          alt="reply"
                                          style={{ marginRight: 10 }}
                                        />
                                        <div style={{ fontWeight: "bold" }}>
                                          {item.checkread === "미확인" &&
                                          item.status ? (
                                            <span
                                              className="new_icon"
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            >
                                              NEW
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <BrowserRouter forceRefresh={true}>
                                            <Link
                                              to={`/mypage/inquiry/detail/${item.idx}`}
                                            >
                                              {item.replytitle}
                                              {/* {item.checkread === "미확인" && (
                                                <span className="new_icon">
                                                  NEW
                                                </span>
                                              )} */}
                                            </Link>
                                          </BrowserRouter>
                                          <div className="reply_regdate regdate">
                                            {changeDateFormat(
                                              item.replydate,
                                              3,
                                              "-",
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </td>

                                  {isMobile ? null : item.status ? (
                                    <td className="reply_regdate regdate">
                                      {changeDateFormat(
                                        item.replydate,
                                        3,
                                        "-",
                                        ""
                                      )}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </Link>
                              </BrowserRouter>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5">문의글이 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination items={InquiryList} onChangePage={onChangePage} />
            </div>
          </div>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default InquiryBoard;
