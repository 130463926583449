import React from "react";
import "./JoinUser.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BrowserRouter, Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import { useState } from "react";

function JoinFinish() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");
  const [isLoginAuth, setIsLoginAuth] = useState(false);

  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });
  }, [dispatch]);
  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <div className="ie_container">
          {isMobile ? null : (
            <Header
              isLoginAuth={isLoginAuth}
              memname={memname}
              profilePath={profilePath}
            />
          )}{" "}
          <div className="main center_main" id="joinFinish">
            <h1 className="main_title">회원가입이 완료 되었습니다.</h1>
            <div className="title_info">
              관리주소 등 추가 정보입력은 <br />
              마이페이지에서 등록 가능합니다.
            </div>
            <BrowserRouter forceRefresh={true}>
              <Link to="/">
                <div className="btn blue_btn">메인으로</div>
              </Link>
            </BrowserRouter>
          </div>
          {isMobile ? <MobileBottomNav /> : <Footer />}
        </div>
      </div>
    </>
  );
}

export default JoinFinish;
