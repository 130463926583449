import React, { useLayoutEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { auth } from "../../_actions/user_action";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { setCookie, saveLimitTime, CheckPw, strEncoding} from "../../Common/lib";

function AutoAuth({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [userId, setUserId] = useState(null);
  const [password, setSassword] = useState("");
  const [profilePath, setProfilePath] = useState("");

  
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setUserId(res.payload.memid);
      setProfilePath(res.payload.profilesImg);
    });
  }, [dispatch]);

  // 비번 체크
  const checkPass = () => {

    if(!CheckPw(password)) {
      alert('비밀번호가 올바르지않습니다.');
      return;
    } else {
      let url = "/api/login/passCheck";
      let data = {
        password: strEncoding(password),
        memid: strEncoding(userId),
      };
      axios.post(url, data).then((res) => {
        if (res.data.result === "success") {
          setCookie('autoCheck','1',0.2);
          document.location.href = '/mypage/modify';
        } else if (res.data.result === "wrong") {
          alert("비밀번호가 일치하지 않습니다.");
        } else {
          alert("에러가 발생하였습니다.");
        }
      }, []);
    }
  };

  let passwordHandler = (e) => {
      setSassword(e.target.value);
  };

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <div className="ie_container">
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
          <div className="main center_main" id="joinAuth">
            <h1 className="main_title">본인 체크</h1>

            <div className="title_info">
              본인 확인을 위해 비밀번호를 입력해주세요.
            </div>

            <input
              type="password"
              className="password_input"
              value={password}
              onChange={passwordHandler}
            />
            <button
              className="btn blue_btn"
              onClick={() => {
                checkPass();
              }}
            >
              인증하기
            </button>
          </div>
          {isMobile ? <MobileBottomNav /> : <Footer />}
        </div>
      </div>
    </>
  );
}

export default AutoAuth;
