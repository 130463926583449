import React, { useRef, useState } from "react";
import "./JoinUser.css";
import kakao from "../../images/kakao.png";
import naver from "../../images/naver.png";
import Ios from "../../images/ios.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Provision from "../../components/Provision";
import { CheckEmail } from "../../Common/lib";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import MobileBottomNav from "../Footer/MobileBottomNav";
import { useHistory } from "react-router-dom";
import $ from "jquery";

// 네이버, 카카오톡 로그인
function JoinSNS({ location }) {
  let searchParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };
  const memid = searchParam("email");
  const nickname = searchParam("nickname");
  const token = searchParam("token");
  const type = searchParam("type");

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  var nicename = params.get("name");
  var nicephone = params.get("mobileno");
  var parmmemid = params.get("memid");
  const history = useHistory();

  // 유효성 검사 false일 때 경고 문구
  const obj = {
    0: "",
    1: "이메일 형식이 올바르지 않습니다.",
    2: "비밀번호 형식이 올바르지 않습니다.",
    3: "비밀번호가 다릅니다. 다시 확인하세요.",
    4: "닉네임을 확인해주세요.",
    5: "중복된 닉네임입니다. 다른 닉네임을 입력하세요.",
    6: "사용 가능한 닉네임입니다. 아래 [다음]을 누르세요.",
    7: "입력이 필요합니다.",
    8: "이미 가입된 이메일입니다.",
    9: "핸드폰 번호를 입력해 주세요.",
    10: "휴대폰 본인인증을 완료해 주세요.",
  };
  const [warning, setWarning] = useState({ id: 0, message: obj[0] });

  // 닉네임 중복 확인
  const [isNickname, setisNickname] = useState(false);

  const [allInputOk, setAllInputOk] = useState(false);

  // 사용자 회원가입 정보
  const [joinInfo, setJoinInfo] = useState({
    joinEmail: parmmemid ? parmmemid : memid,
    joinPw: "",
    joinNickname: "",
    token: token,
    cellno: nicephone ? nicephone : "",
    memrealname: nicename ? nicename : "", //실명
    successtag: nicephone ? "Y" : "N",
    memJoinPath: "",
  });

  // 가입경로
  const [pathRds, setPathRds] = useState([false, false, false, false, false]);
  const [etcControl, setEtcControl] = useState(false);

  const onChangeUserInfo = (e) => {
    setAllInputOk(false);
    let value = e.target.value;
    if (e.target.name === "joinNickname") {
      if (e.target.value.length > 10) {
        value = value.slice(0, -1);
      }
      setisNickname(false);
      setWarning({
        id: 4,
        message: obj[4],
      });
    }
    const regex = /^[0-9\b -]{0,13}$/;
    if (e.target.name === "cellno") {
      if (regex.test(value)) {
        if (value.length === 10) {
          value = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
        if (value.length === 13) {
          value = value
            .replace(/-/g, "")
            .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        }
      } else {
        return;
      } // end of if (regex.test(value))
    }

    setJoinInfo({
      ...joinInfo,
      [e.target.name]: value.replace(" ", ""),
    });
  };

  // 닉네임 중복 검사
  const handleNickname = () => {
    if (joinInfo.joinNickname === "" || joinInfo["joinNickname"].length < 3) {
      setWarning({
        id: 7,
        message: obj[7],
      });
      return;
    }
    let url = "/api/join/checkMemname";
    let data = { memname: joinInfo.joinNickname };

    axios
      .post(url, data)
      .then((res) => {
        if (res.data.result === "success") {
          setisNickname(true);
          setWarning({
            id: 6,
            message: obj[6],
          });
        } else {
          setisNickname(false);
          setWarning({
            id: 5,
            message: obj[5],
          });
        }
      })
      .catch((err) => {
        alert("에러가 발생했습니다. 관리자에게 문의해주세요.");
      });
  };

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const handleJoin = (canUseEmail) => {
    if (canUseEmail) {
      // 이메일 중복 확인
      // 로그인 버튼 클릭 시 유효성 검사
      if (!CheckEmail(joinInfo["joinEmail"])) {
        // 아이디(이메일) 유효성 검사
        setWarning({
          id: 1,
          message: obj[1],
        });
        handleFocus(0);
      } else if (!isNickname) {
        setWarning({
          id: 4,
          message: obj[4],
        });
        handleFocus(3);
      } else if (joinInfo["successtag"] === "N") {
        setWarning({
          id: 10,
          message: obj[10],
        });
      } else if (
        joinInfo["joinNickname"] === "" ||
        joinInfo["joinNickname"].length < 3
      ) {
        setWarning({
          id: 7,
          message: obj[7],
        });
      } else {
        setAllInputOk(true);
        setWarning({
          id: 0,
          message: obj[0],
        });
      } // end of inner if
    } else {
      setWarning({
        id: 8,
        message: obj[8],
      });
      handleFocus(0);
    }
  };

  const encodeRef = useRef();
  // 핸드폰 번호 중복 검사
  let checkTel = () => {
    // if (joinInfo["cellno"] === "" || joinInfo["cellno"].length < 13) {
    //   alert("휴대폰 번호를 입력해주세요.");
    //   return;
    // }
    let url = "/api/join/checkTel";
    let data = {
      cellno: joinInfo.cellno,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result !== "success") {
        alert("이미 가입된 정보입니다.");
        history.push("/");
      }
    });
  };

  if (nicephone) {
    const niceregex = /^[0-9\b -]{0,13}$/;
    if (niceregex.test(nicephone)) {
      if (nicephone.length === 10) {
        nicephone = nicephone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
      if (nicephone.length === 11) {
        nicephone = nicephone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      }
      joinInfo.cellno = nicephone;
      checkTel();
    }
  }

  // 본인인증 팝업 띄우기
  let getEncData = () => {
    let url = `/api/nice/checkplus_main`;
    url += `?sReturnUrl=${axios.defaults.baseURL}api/nice/checkplus_success`;
    url += `&sErrorUrl=${axios.defaults.baseURL}api/nice/checkplus_fail`;
    url += `&type=${type}`;
    url += `&memid=${memid}`;
    url += `&token=${token}`;

    if (isMobile === true) {
      url += `&mobile=yes`;
    } else {
      url += `&mobile=no`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.sEncData) {
          encodeRef.current.value = res.data.sEncData;

          if (isMobile === true) {
            document.form_chk.action =
              "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
            document.form_chk.submit();
          } else {
            var uri = "";
            var popupName = "popupChk";
            var options =
              "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=n";

            var openDialog = function (uri, name, options, closeCallback) {
              // eslint-disable-next-line
              var win = window.open(uri, name, options);

              document.form_chk.action =
                "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
              document.form_chk.target = "popupChk";
              document.form_chk.submit();
            };
            openDialog(uri, popupName, options, function (win) {});
          }
        } else {
          alert("본인인증 서버 요청에 실패하였습니다. 다시 시도해주세요");
        }
      })
      .catch((err) => console.log(err));
  }; //end of getEncData()

  //가입경로
  const handlePath = () => {

    if($("#rd1").prop("checked")){
      setPathRds(true, false, false, false, false);
      setJoinInfo({...joinInfo, memJoinPath : "인터넷 검색"});
      setEtcControl(false);
    }else if($("#rd2").prop("checked")){
      setPathRds(false, true, false, false, false);
      setJoinInfo({...joinInfo, memJoinPath : "블로그 및 SNS 추천"});
      setEtcControl(false);
    }else if($("#rd3").prop("checked")){
      setPathRds(false, false, true, false, false);
      setJoinInfo({...joinInfo, memJoinPath : "지인소개"});
      setEtcControl(false);
    }else if($("#rd4").prop("checked")){
      setPathRds(false, false, false, true, false);
      setJoinInfo({...joinInfo, memJoinPath : "언론 및 유튜브 광고"});
      setEtcControl(false);
    }else{
      setPathRds(false, false, false, false, true);
      setJoinInfo({...joinInfo, memJoinPath : "기타"});
      setEtcControl(true);
    }

  }

  //가입경로 기타
  const onChangeJoinEtc = (e) => {
    setJoinInfo({...joinInfo, memJoinPath : e.target.value});
  }



  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        <Header />
        {/* 나이스 본인인증 form */}
        <form name="form_chk" method="post" style={{ display: "none" }}>
          <input type="hidden" name="m" value="checkplusService" />
          <input ref={encodeRef} type="hidden" name="EncodeData" />
        </form>
        {/* 나이스 본인인증 form */}
        <div className="main center_main join_user">
          <h1 className="join_title">
            {type === "kakao"
              ? "카카오톡"
              : type === "naver"
              ? "네이버"
              : "애플"}
            계정으로 회원가입하기
          </h1>
          <h2 className="isMobile">서비스 이용을 위해 로그인 해주세요.</h2>
          <div className="sns_login">
            <div className="column">
              <img
                src={type === "kakao" ? kakao : type === "naver" ? naver : Ios}
                alt="kakao"
              />
              {nickname ? <h2>{nickname}님</h2> : ""}
            </div>
          </div>

          <form className="login_form ">
            <input
              type="hidden"
              name="successtag"
              id="successtag"
              value={joinInfo["successtag"]}
            />

            <div className="column sns_join_name_cloumn">
              <div className="input_name">
                이름 <span className="warning">*</span>
              </div>
              {nicename ? (
                <input
                  type="text"
                  placeholder="본인인증을 진행해주세요."
                  className="wide"
                  name="memrealname"
                  value={joinInfo["memrealname"]}
                  readOnly
                />
              ) : (
                <input
                  type="text"
                  placeholder="본인인증을 진행해주세요."
                  className="wide"
                  name="memrealname"
                  value={joinInfo["memrealname"]}
                  readOnly
                  onClick={() => {
                    getEncData();
                  }}
                />
              )}
            </div>

            <div className="column">
              <div className="input_name">
                핸드폰 번호 <span className="warning">*</span>
              </div>
              <div className="input_column">
                {nicephone ? (
                  <input
                    type="text"
                    placeholder="핸드폰 번호를 입력해주세요"
                    className="wide"
                    name="cellno"
                    value={joinInfo["cellno"]}
                    id="cellno"
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="핸드폰 번호를 입력해주세요"
                    className="wide"
                    name="cellno"
                    value={joinInfo["cellno"]}
                    onChange={onChangeUserInfo}
                    id="cellno"
                    readOnly
                    onClick={() => {
                      getEncData();
                    }}
                  />
                )}

                <div
                  className="check_btn"
                  style={{ display: joinInfo.successtag === "Y" ? "none" : "" }}
                  onClick={() => {
                    getEncData();
                  }}
                >
                  본인인증
                </div>
              </div>
            </div>

            <div className="cellno warning">
              {warning["id"] === 9 || warning["id"] === 10
                ? warning["message"]
                : null}
            </div>

            <div className="column">
              <div className="input_name">
                아이디(이메일) <span className="warning">*</span>
              </div>

              <input
                type="text"
                placeholder="이메일 주소를 입력해 주세요."
                className="wide"
                name="joinEmail"
                value={joinInfo.joinEmail ? joinInfo.joinEmail : ""}
                onChange={onChangeUserInfo}
                ref={(elem) => (inputRefs.current[0] = elem)}
                readOnly
              />
            </div>
            <div className="email warning">
              {warning["id"] === 1 || warning["id"] === 8
                ? warning["message"]
                : null}
            </div>

            <div className="column">
              <div className="input_name">
                닉네임<span className="warning">*</span>
              </div>
              <div className="input_column">
                <input
                  type="text"
                  name="joinNickname"
                  value={joinInfo.joinNickname ? joinInfo.joinNickname : ""}
                  onChange={onChangeUserInfo}
                  placeholder="사용할 닉네임을 입력하세요."
                  ref={(elem) => (inputRefs.current[3] = elem)}
                />
                <div
                  className="check_btn"
                  onClick={() => {
                    handleNickname();
                  }}
                >
                  중복확인
                </div>
              </div>
            </div>
            <div className="info">※ 3~10자 사이로 입력하세요</div>
            <div className={isNickname ? "warning pass" : "warning"}>
              {4 <= warning["id"] && warning["id"] < 8
                ? warning["message"]
                : null}
            </div>
            <div className="joinPath">
              <div className="input_name">가입경로</div>
              <div className="own_radio_column">
                <ul>
                  <li>
                    <div className="radio_wrap left">
                      <input
                        id="rd1"
                        type="radio"
                        name="radio1"
                        onChange={handlePath}
                      />
                      <label htmlFor="rd1">
                        <span>인터넷 검색</span>
                      </label>
                    </div>
                    <div className="radio_wrap">
                      <input
                        id="rd2"
                        type="radio"
                        name="radio1"
                        onChange={handlePath}
                      />
                      <label htmlFor="rd2">
                        <span>블로그 및 SNS 추천</span>
                      </label>
                    </div> 
                  </li>
                  <li>
                    <div className="radio_wrap left">
                      <input
                        id="rd3"
                        type="radio"
                        name="radio1"
                        onChange={handlePath}
                      />
                      <label htmlFor="rd3">
                        <span>지인소개</span>
                      </label>
                    </div>
                    <div className="radio_wrap">
                      <input
                        id="rd4"
                        type="radio"
                        name="radio1"
                        onChange={handlePath}
                      />
                      <label htmlFor="rd4">
                        <span>언론/유튜브 광고</span>
                      </label>
                    </div> 
                  </li>
                  <li>
                    <div className="radio_wrap">
                      <input
                        id="rd5"
                        type="radio"
                        name="radio1"
                        onChange={handlePath}
                      />
                      <label htmlFor="rd5">
                        <span>기타</span>
                      </label>
                    </div>
                    {etcControl ?
                    <div className="text_wrap">
                      <input
                        type="text"
                        name="memJoinPath"
                        className="wide"
                        onChange={onChangeJoinEtc}
                      />
                    </div>
                    : null}
                  </li>
                </ul>  
              </div>
            </div>
            <Provision
              info={joinInfo}
              handle={handleJoin}
              nextStep={isNickname}
              allInputOk={allInputOk}
              joinInfo={joinInfo}
              type={type}
            />
          </form>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default JoinSNS;
