import React, { useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./slider.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { getManageAddress } from "../_actions/main_action";
import { useDispatch } from "react-redux";
import { changeDateFormat } from "../Common/lib";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default function SliderContractDate() {
  const dispatch = useDispatch();

  const [lists, setLists] = useState();
  useLayoutEffect(() => {
    dispatch(getManageAddress()).then((res) => {
      if (res.payload.address) {
        let result = res.payload.address;
        let arr = [];
        result &&
          result.map((item) => {
            if (item.addrower !== "자가") {
              arr.push(item);
            }
            return arr;
          });
        setLists(arr);
      }
    });
  }, [dispatch]);

  const handleDday = (day) => {
    const getDate = day.split("-");
    const Dday = new Date(getDate[0], getDate[1] - 1, getDate[2]);
    const now = new Date(); // 현재(오늘) 날짜를 받아온다.
    const gap = now.getTime() - Dday.getTime(); // 현재 날짜에서 D-day의 차이를 구한다.
    const result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1; // gap을 일(밀리초 * 초 * 분 * 시간)로 나눈다. 이 때 -1 을 곱해야 날짜차이가 맞게 나온다.
    getDate.push(result);

    return getDate;
  };

  const handleDdayPer = (start, end) => {
    const getStartDay = start.split("-");
    const getExpireDay = end.split("-");

    const setStartDay = new Date(
      getStartDay[0],
      getStartDay[1] - 1,
      getStartDay[2]
    );
    const setExpireDay = new Date(
      getExpireDay[0],
      getExpireDay[1] - 1,
      getExpireDay[2]
    );

    const gap = setStartDay.getTime() - setExpireDay.getTime(); // 현재 날짜에서 D-day의 차이를 구한다.
    const result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1; // gap을 일(밀리초 * 초 * 분 * 시간)로 나눈다. 이 때 -1 을 곱해야 날짜차이가 맞게 나온다.
    return result;
  };

  return (
    <>
      {lists && lists.length > 0 ? (
        <div className="day_column">
          <div className="slide_section">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              className={lists.length === 1 ? "mySwiper single" : "mySwiper"}
            >
              {lists &&
                lists.map((list, index) => {
                  const year = handleDday(
                    changeDateFormat(list.addrrentedate, 3, "-", ":")
                  )[0];
                  const month = handleDday(
                    changeDateFormat(list.addrrentedate, 3, "-", ":")
                  )[1];
                  const day = handleDday(
                    changeDateFormat(list.addrrentedate, 3, "-", ":")
                  )[2];
                  let Dday = handleDday(
                    changeDateFormat(list.addrrentedate, 3, "-", ":")
                  )[3];
                  const totalDay = handleDdayPer(
                    changeDateFormat(list.addrrentdate, 3, "-", ":"),
                    changeDateFormat(list.addrrentedate, 3, "-", ":")
                  );
                  let remainDayPer = Math.floor(
                    ((totalDay - Dday) / totalDay) * 100
                  );

                  if (Dday < 0) {
                    Dday = false;
                  }
                  if (remainDayPer > 100) {
                    remainDayPer = 100;
                  }

                  if (list.addrower === "임차") {
                    return (
                      <SwiperSlide key={index}>
                        <div className="slide_content">
                          <div className="slide_top_content">
                            <div className="left">
                              <div className="day_text">
                                {list.addrnickname}의 임대차 만기일이
                                <br />
                                {Dday ? (
                                  <>
                                    <strong>{year}</strong>
                                    년&nbsp;
                                    <strong>{month}</strong>
                                    월&nbsp;
                                    <strong>{day}</strong>
                                    일까지&nbsp;
                                    <strong>D-{Dday}</strong>
                                    &nbsp; 남았습니다.
                                  </>
                                ) : (
                                  <>종료되었습니다.</>
                                )}
                              </div>
                            </div>

                            <div className="right">
                              <div className="total_per">
                                <div
                                  className="pass_per"
                                  style={{
                                    width: remainDayPer + "%",
                                  }}
                                >
                                  {remainDayPer}
                                  <span>%</span>
                                </div>
                                <div
                                  className="remain_per"
                                  style={{
                                    width: 100 - remainDayPer + "%",
                                    display:
                                      100 - remainDayPer < 6 ? "none" : "flex",
                                  }}
                                >
                                  {100 - remainDayPer}
                                  <span>%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  } else {
                    return null;
                  }
                })}
            </Swiper>
          </div>
        </div>
      ) : null}
    </>
  );
}
