import React from "react";
import "./Modal.css";

function Modal({ open, children, width, center, close }) {
  let winHeight = window.innerHeight;
  let setHeight = '';
  let setScroll = 'hidden';
  if(winHeight <= 600){
    setHeight = '300px';
    setScroll = 'scroll'
  }
  return (
    <>
      <div
        className={open ? "openModal modal_bg" : "modal_bg"}
        onClick={close}
      ></div>
      <div
        className={
          open
            ? center
              ? "openModal modal_ct center_modal"
              : "openModal modal_ct"
            : "modal_ct"
        }
        style={{ width: width, maxWidth: width, height: setHeight, overflowY: setScroll}}
      >
        {children}
      </div>
    </>
  );
}

export default Modal;
