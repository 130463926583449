import React, { useLayoutEffect, useState } from "react";
import {
  userAdminDelete,
  userAdminDetail,
  userAdminModify,
  userAdminModifyPassword,
  userAdminModifyPasswordReset,
  userAdminModifyStatus,
} from "../../_actions/admin_board_action";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import { changeDateFormat, CheckPw } from "../../Common/lib";
import {ADMIN_GENERAL, ADMIN_PRIVACY, ADMIN_SUPER} from "../../Common/adminType";

function UserAdminDetail({ match, history }) {
  const idx = match.params.idx;
  const pagen = match.params.pagen;
  const dispatch = useDispatch();

  // input값 제어
  const [SubmitData, setSubmitData] = useState({});
  const inputHandler = (e) => {
    let el = e.target;
    setSubmitData({ ...SubmitData, [el.name]: el.value.replace(" ", "") });
  };

  useLayoutEffect(() => {
    let body = {
      idx: idx,
    };
    dispatch(userAdminDetail(body)).then((res) => {
      if (res.payload.user) {
        setSubmitData(res.payload.user);
        setTelNum(res.payload.user.cellno);
      }
    });
  }, [dispatch, idx]);

  // 전송시
  const submitHandler = () => {
    if (SubmitData.memname === "") {
      alert("관리자명을 입력해주세요.");
      return;
    } else if (telNum === "") {
      alert("핸드폰번호를 입력해주세요.");
      return;
    }
    // 이메일 유효성 검사 넣어달라면 다시 주석풀 것...
    // else if (!CheckEmail(SubmitData.memid)) {
    //   // 아이디(이메일) 유효성 검사
    //   alert("이메일 형식을 확인해주세요.");
    //   return;
    // }
    let body = {
      idx: idx,
      memstatus: SubmitData.memstatus,
      memtype: SubmitData.memtype,
      memname: SubmitData.memname,
      cellno: telNum,
      memid: SubmitData.memid,
      access: accessStatus ? "Y" : "N",
    };

    dispatch(userAdminModify(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("수정되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const [telNum, setTelNum] = useState("");
  const handelTelNum = (e) => {
    // 숫자와 하이픈만, 길이는 13자 까지 허용
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTelNum(e.target.value);
    }
  };

  // 자동으로 하이픈 넣기
  useLayoutEffect(() => {
    if (telNum && telNum.length === 10) {
      setTelNum(telNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (telNum && telNum.length === 13) {
      setTelNum(
        telNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [telNum]);

  const [SubmitDataPw, setSubmitDataPw] = useState({
    originPw: "",
    confirmPw: "",
  });

  const pwHandler = (e) => {
    let el = e.target;
    setSubmitDataPw({ ...SubmitDataPw, [el.name]: el.value.replace(" ", "") });
  };

  const changePw = () => {
    if (SubmitDataPw.originPw === "") {
      alert("비밀번호를 입력해주세요");
      return;
    } else if (!CheckPw(SubmitDataPw.originPw)) {
      // 비밀번호 유효성 검사
      alert("비밀번호 생성 규칙에 어긋납니다. 다시 설정 바랍니다.");
      return;
    } else if (SubmitDataPw.originPw !== SubmitDataPw.confirmPw) {
      alert("동일한 비밀번호를 입력하세요.");
      return;
    }
    let body = {
      idx: idx,
      password: SubmitDataPw.originPw,
    };
    dispatch(userAdminModifyPassword(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("비밀번호가 변경되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const resetPw = () => {
    if (!window.confirm("정말 초기화하시겠습니까?")) return;
    let body = {
      idx: idx,
    };
    dispatch(userAdminModifyPasswordReset(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("초기화 되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const stopAdmin = () => {
    let body = {
      idx: idx,
    };
    dispatch(userAdminModifyStatus(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("이용정지 되었습니다.");
        window.location.reload();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };

  const deleteAdmin = () => {
    let body = {
      idx: idx,
    };
    dispatch(userAdminDelete(body)).then((res) => {
      if (res.payload.result === "success") {
        alert("삭제 되었습니다.");
        history.goBack();
      } else {
        alert("잠시 후 다시 시도해주세요.");
      }
    });
  };
  const [accessStatus, setAccessStatus] = useState(true);
  const accessToggle = () => {
    setAccessStatus(!accessStatus);
  };
  return (
    <>
      <Header />
      <div className="admin_container">
        <SideMenu tabActive={2} />
        <div className="main_container" id="userAdminDetail">
          <div>
            <div className="detail_user_container">
              <div className="detail_user_header">
                <h2 className="main_container_name">관리자 상세 정보</h2>
                <div className="user_control_column">
                  <div
                    className="admin_delete_btn btn left"
                    onClick={stopAdmin}
                  >
                    이용정지
                  </div>

                  <div className="admin_delete_btn btn" onClick={deleteAdmin}>
                    관리자 삭제
                  </div>
                </div>
              </div>

              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>회원상태</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          checked={SubmitData.memstatus === "정상" && true}
                          id="radio1"
                          name="memstatus"
                          value="정상"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio1">정상</label>
                        <input
                          type="radio"
                          checked={SubmitData.memstatus === "정지" && true}
                          id="radio2"
                          name="memstatus"
                          value="정지"
                          onChange={inputHandler}
                        />
                        <label htmlFor="radio2">정지</label>
                      </div>
                    </td>
                    <th rowSpan="6"></th>
                    <td rowSpan="6" style={{ background: "#f5f5f5" }}></td>
                  </tr>
                  <tr>
                    <th>관리자 등급</th>
                    <td>
                      <div className="free_user_chk">
                        <input
                          type="radio"
                          id="chk1"
                          checked={SubmitData.memtype === ADMIN_GENERAL.Alias && true}
                          name="memtype"
                          value="일반"
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk1">{ADMIN_GENERAL.Alias}관리자</label>
                        <input
                          type="radio"
                          id="chk2"
                          checked={SubmitData.memtype === ADMIN_SUPER.Alias && true}
                          name="memtype"
                          value="최고"
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk2">{ADMIN_SUPER.Alias}관리자</label>
                        <input
                          type="radio"
                          id="chk3"
                          checked={SubmitData.memtype === ADMIN_PRIVACY.Alias && true}
                          name="memtype"
                          value="개인정보보호"
                          onChange={inputHandler}
                        />
                        <label htmlFor="chk3">{ADMIN_PRIVACY.Alias}관리자</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>등록일</th>
                    <td>
                      {changeDateFormat(SubmitData.registerdate, 5, ".", ":")}
                    </td>
                  </tr>
                  <tr>
                    <th>관리자명</th>
                    <td>
                      <input
                        type="text"
                        name="memname"
                        value={SubmitData.memname}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>핸드폰 번호</th>
                    <td>
                      <input
                        type="text"
                        placeholder="휴대 전화번호를 입력하세요."
                        name="cellno"
                        value={telNum}
                        onChange={handelTelNum}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>이메일(ID)</th>
                    <td>
                      <input
                        type="text"
                        placeholder="이메일(ID)를 입력하세요."
                        name="memid"
                        value={SubmitData.memid}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>최종 로그인 일시</th>
                    <td>
                      {SubmitData.lastlogin ? (
                        <>
                          {changeDateFormat(SubmitData.lastlogin, 5, ".", ":")}
                          <span className="ip_column">
                            {SubmitData.ip && "(IP:" + SubmitData.ip + ")"}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">비밀번호 관리</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <input
                        type="password"
                        name="originPw"
                        onChange={pwHandler}
                        value={SubmitDataPw.originPw}
                        autoComplete="new-password"
                      />
                    </td>
                    <th>비밀번호 확인</th>
                    <td>
                      <div className="password_column">
                        <input
                          type="password"
                          name="confirmPw"
                          value={SubmitDataPw.confirmPw}
                          onChange={pwHandler}
                        />
                        <div className="admin_white_btn" onClick={changePw}>
                          비밀번호 변경
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>비밀번호 오류 횟수</th>
                    <td>
                      <div className="day_change_count">
                        {SubmitData.passworderrorcount}회
                        <div
                          className="admin_white_btn admin_white_btn80"
                          onClick={resetPw}
                        >
                          초기화
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title access">
                일반관리자 권한 관리
                <span className="info">
                  ※ 일반관리자는 관리자 메뉴의 [주소관리]에 대한 접근 및 권한만
                  부여됩니다.
                  <br />※ 최고관리자 등급은 관리자 메뉴의 모든 권한이
                  부여됩니다.
                </span>
              </h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>주소관리</th>
                    <td className="board_access">
                      <input
                        type="checkbox"
                        id="chk5"
                        checked={accessStatus}
                        onClick={accessToggle}
                      />
                      <label htmlFor="chk5">권한 허용</label>
                    </td>
                    <th style={{ background: "none" }}></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="detail_user admin_btn_column">
                <div
                  className="admin_white_btn left btn"
                  onClick={() => history.push(`/rf_zib_admin/user/admin/${pagen}`)}
                >
                  목록
                </div>
                <div className="admin_blue_btn btn" onClick={submitHandler}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAdminDetail;
