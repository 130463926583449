import React, {useLayoutEffect, useState} from "react";
import {BrowserRouter, Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import {mailHistoryList} from "../../_actions/admin_board_action";
import PaginationNum from "../../components/PaginationNum";


function SendMailHistoryList(){
    const dispatch = useDispatch();

    const [historyList, setHistoryList] = useState([]);
    // const [historyListCnt, setHistoryListCnt] = useState(0);
    const [pageOfItems, setPageOfItems] = useState([]); //페이징
    const [status, setStatus] = useState("A");


    useLayoutEffect(() => {
        let body = {
            status: status,
        };

       //전체 발송내역 가져오기
       dispatch(mailHistoryList(body)).then((res) => {
            if(res.payload.historyList){
                const result = res.payload.historyList;
                setHistoryList(result);
                // setHistoryListCnt(result.length);
            }
       });
    }, [dispatch, status]);


    //상태값 분류
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    //페이징 처리
    const onChangePage = (pageOfItems) => {
        setPageOfItems(pageOfItems);
    };


    const pageOfItem = pageOfItems.map((item) => {

        return (
            <tr key={item.idx}>
                <td style={{ width: "10%" }}>{item.rownum}</td>
                <td style={{ width: "10%" }}>{item.trandt}</td>
                <td>
                    <BrowserRouter forceRefresh={true}>
                        <Link to={`/rf_zib_admin/set/mail/history/detail/${item.idx}`}>
                            <span className="btn">{item.title}</span>
                        </Link>
                    </BrowserRouter>
                </td>
                <td style={{ width: "10%" }}>{item.memname}</td>
                <td style={{ width: "10%" }}>{item.status === "F" ? "실패" : "성공"}</td>
                <td style={{ width: "10%" }}>{item.failcnt}건/{item.totalcnt}건</td>
                {item.status === "F" && item.resendyn === "N" ? (
                    <td style={{ width: "10%" }}>
                        <BrowserRouter forceRefresh={true}>
                            <Link to={`/rf_zib_admin/set/mail/resend/${item.idx}`}>
                                <div className="admin_blue_btn btn">재전송</div>
                            </Link>
                        </BrowserRouter>
                    </td>
                ) : (
                    <td style={{width: "10%"}}></td>
                )}
            </tr>
        )
    });



    return (
        <>
            <Header />
            <div className="admin_container" id="addressDetail">
                <SideMenu tabActive={4} />
                <div className="main_container" id="setVersion">
                    <div>
                        <div className="detail_user_header">
                            <h2 className="main_container_name" style={{ marginBottom: 16 }}>
                                메일발송
                            </h2>
                        </div>
                        <ul className="detail_user_tab">
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail">
                                    <li className="btn">발송</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/schedule">
                                    <li className="btn">예약목록</li>
                                </Link>
                            </BrowserRouter>
                            <BrowserRouter forceRefresh={true}>
                                <Link to="/rf_zib_admin/set/mail/history">
                                    <li className="btn active">발송내역</li>
                                </Link>
                            </BrowserRouter>
                        </ul>

                        <div className="address_detail">
                            <div className="user_list_column">
                                <div className="select_column">
                                    <div className="select_name">상태</div>
                                    <select onChange={handleStatus}>
                                        <option value="A">전체</option>
                                        <option value="S">성공</option>
                                        <option value="F">실패</option>
                                    </select>
                                </div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th style={{ width: "10%" }}>No.</th>
                                        <th style={{ width: "10%" }}>발송일자</th>
                                        <th>제목</th>
                                        <th style={{ width: "10%" }}>관리자</th>
                                        <th style={{ width: "10%" }}>상태</th>
                                        <th style={{ width: "10%" }}>실패/총</th>
                                        <th style={{ width: "10%" }}>재전송</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageOfItem.length > 0 ? (pageOfItem) : (
                                    <tr>
                                        <td colSpan={6}>등록된 글이 없습니다.</td>
                                    </tr>
                                    )}
                                    </tbody>
                                </table>
                                <PaginationNum items={historyList} onChangePage={onChangePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMailHistoryList