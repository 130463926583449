export const data = [
  {
    no: 1,
    title: "로그인 Log",
    path: "/rf_zib_admin/log/login",
  },
  {
    no: 2,
    title: "마케팅활용동의",
    path: "/rf_zib_admin/log/marketing",
  },
  {
    no: 3,
    title: "로그인 Log (탈퇴)",
    path: "/rf_zib_admin/out/log/login",
  },
  {
    no: 4,
    title: "마케팅활용동의 (탈퇴)",
    path: "/rf_zib_admin/out/log/marketing",
  },
  {
    no: 5,
    title: "PTS 호출 Log",
    path: "/rf_zib_admin/log/pts",
  }
];
