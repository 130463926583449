import React, { useLayoutEffect, useState, useEffect } from "react";
import "../admin.css";
import tab from "../../images/admin/tab.png";
import Header from "../Header/Header";
import { Link, useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import axios from 'axios';
import { useDispatch } from "react-redux";
import {
  addressCallAPI,
  addressCallEventAPI,
  addressCallOwnerAPI,
  addressModify,
  pdfCallAPI,
  addressVerstdate,
  userAddressDelete,
  userAddressDetail,
} from "../../_actions/admin_board_action";
import { changeDateFormat } from "../../Common/lib";
import Modal from "../../components/Modal";
import $ from "jquery";


function AddressDetail() {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const idx = match.params.idx;
  const pagen = match.params.pagen;

  const [backUpData, setBackUpData] = useState(false);  // 백업데이터 저장 유무
  const [addrTypeChg, setAddrTypeChg] = useState(false); 
  const [ptsbtn, setPtsbtn] = useState(false); // Pts 버튼 노출 유무
  const [ptsOverlap, setPtsOverlap]  = useState(false); // 중복버튼
  const [originalAddrType, setOriginalAddrType] = useState({});   // 기존 AddrType 확인용 [2022-08-04 박윤수]
  const [addressDetail, setAddressDetail] = useState({});
  const [adminAddress, setAdminAddress] = useState({
    addr:"",
    addrsub:""
  });
  const [alarmContent, setAlarmContent] = useState({
    content: "",
    ph: "",
  });
  const [apiMessage, setApiMessage] = useState({
    addr: false,
    addrMsg: "",
    chg: false, //관계인 정보 일치여부
    chgMsg: "",
  });
  const [addrtype_push, setAddrtype_push] = useState(false);
  const [modal, setModal] = useState({
    modal1: false,
  });
  const padding = 64; // 모달창 padding값

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  const inputHandler = (e) => {
    let el = e.target;
    
    if (!apiMessage.chg && el.name === "addrtype" && el.value === "승인완료") {
      if (
        window.confirm(
          "관계인 정보가 확인되지 않았습니다.\n그래도 승인하시겠습니까?"
        )
      ) {
        setAddrtype_push(true);
      } else {
        return;
      }
    } else if (
      apiMessage.chg &&
      el.name === "addrtype" &&
      el.value === "승인완료"
    ) {
      // 이번에 승인완료 처리한거라 알림 발송해야함
      setAddrtype_push(true);
    } else if (el.value === "미승인" || el.value === "보류") {
      setAddrtype_push(false);
    }
    if(el.name === "addrtype"){
      setAddrTypeChg(true);
    }

    setAddressDetail({ ...addressDetail, [el.name]: el.value });
  };

  const etcInputHandler = (e) => {
    let el = e.target;
    let elvalue = el.value;
    let elnum = el.id.substr(4);
    setAddressDetail({ ...addressDetail, [el.name]: elnum, etcomment: elvalue });
  }

  const etcCommentHandler = (e) => {
    let el = e.target;
    setAddressDetail({ ...addressDetail, etcomment: el.value });
  }

  // addrType 라디오 박스 선택시 초기값 세팅
  const addrTypeRadio = (e) => {
      $('input[name="addrtype2"]').each(function(index){
        if(index === 0){
          let obj = $(this)[0]
          let num = obj.id.substr(4);
          let name = obj.name;
          let elvalue = obj.value;

          $(this).prop('checked', true);
          setAddressDetail({ ...addressDetail, [name]: num, etcomment: elvalue });
        }
      });
  }

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setAdminAddress({...adminAddress,addr:fullAddress, addrsub:""});
    closeModal("modal2");
  };

  const submitVerstdate = () => {
    if (!addressDetail.verstdate) {
      alert("경매배당기일을 입력해주세요.");
      return;
    }

    let data = {
      verstdate: addressDetail.verstdate,
      idx: idx,
      memidx: addressDetail.memidx,
      addrnickname: addressDetail.addrnickname,
    };

    dispatch(addressVerstdate(data)).then((res) => {
      if (res.payload.result === "success") alert("저장되었습니다.");
      else alert("저장에 실패하였습니다.");
    });
  };

  // 수정내역 저장하기
  const submitHandler = () => {

    let finalAddrtype = "";
    let finalAddrtype2 = addressDetail.addrtype2;
    let finalEtcomment = addressDetail.etcomment;
    let finalPush = addrtype_push;

    // AddrType 값이 변경이 있을경우 finalAddrtype에 저장 
    if(addressDetail.addrtype !== originalAddrType.addrtype || finalAddrtype2 !== originalAddrType.addrtype2){
      finalAddrtype = addressDetail.addrtype;
    }

    // 보류선택시 Addrtype2, Etcomment 초기화
    if(addressDetail.addrtype === "보류" || addressDetail.addrtype2 === "0"){
      finalAddrtype2 = "";
      finalEtcomment = "";
    }

    // 관리주소 승인메일 중복발송 방지를 위해 체크
    if(originalAddrType.addrtype === "승인완료" && addressDetail.addrtype === "승인완료"){
      finalPush = false;
    }

    let data = {
      addrtype_push: finalPush,
      addrtype: finalAddrtype,
      addrtype2: finalAddrtype2,
      etcomment: finalEtcomment,
      addrrenew: addressDetail.addrrenew,
      owner: addressDetail.owner,
      // addrucode: addressDetail.addrucode,
      idx: idx,
      memidx: addressDetail.memidx,
      addrnickname: addressDetail.addrnickname,
      addrucode : addressDetail.addrucode,
      memo : addressDetail.memo,
      rgstrTpCd: addressDetail.rgstrTpCd
    };

    // 고유번호가 정상적인인지 체크 2022-02-07 박윤수
    if(addressDetail.addrucode[0].length > 0){
      for(var i = 0; addressDetail.addrucode.length > i; i++){
        var item = addressDetail.addrucode[i].match(/-/g);
        if(item === null){ alert("고유번호를 정확히 입력해주세요."); return; }
        if(addressDetail.addrucode[i].length !== 16 || item.length !== 2){
          alert("고유번호를 정확히 입력해주세요.");
          return;
        }
      }
    }

    dispatch(addressModify(data)).then((res) => {
      if (res.payload.result === "success") alert("저장되었습니다.");
      else alert("저장에 실패하였습니다.");
    });
  };

  const deleteRentdate = (name) => {
    setAddressDetail({ ...addressDetail, [name]: "" });
  };


  const ptsCall = () => {
    // 중복클릭 방지
    if (ptsOverlap === false){
      setPtsOverlap(true);
      setTimeout(() => {
        callApi("N");
        callOwnerApi("N");
        alert("API 호출 성공");
      }, 2000);
    }
  }

  // 정제주소 및 고유번호 호출 버튼
  const callApi = (msg) => {
    let ptsapi = "";

    if(msg === "N"){ptsapi = "Y";}
    
    let body = {
      idx: idx,
      addr: adminAddress.addr,
      addrsub: adminAddress.addrsub,
      ptsapi: ptsapi,
    };
    dispatch(addressCallAPI(body)).then((res) => {
      if (res.payload.result === "success") {
        setTimeout(() => {
          window.location.reload();
          if(msg !== "N"){
            alert("API 호출 성공");
          }
        }, 5000);
      } else alert("API 호출 실패");
    });
  };

  // 정제주소 및 고유번호 호출 버튼
  const callOwnerApi = (msg) => {
    let body = {
      idx: idx,
      addr: adminAddress.addr,
      addrsub: adminAddress.addrsub,
    };
    dispatch(addressCallOwnerAPI(body)).then((res) => {
      if (res.payload.result === "success") {
        setTimeout(() => {
          window.location.reload();
          if(msg !== "N"){
            alert("API 호출 성공");
          }

          // 관리주소 가져오기
          dispatch(userAddressDetail(body)).then((res) => {
            if (res.payload.address) {
              setAddressDetail({...addressDetail, owner: res.payload.address.owner});
            }
          });
          
        }, 1000);
      } else alert("API 호출 실패");
    });
  };

  // 관계인 정보 확인 버튼
  const callEventApi = () => {
    if (!addressDetail.addrucode || !addressDetail.owner) {
      alert("정제주소 또는 관계인 정보가 누락되었습니다.");
      return;
    }
    let body = {
      idx: idx,
      memidx: addressDetail.memidx,
      addrucode: addressDetail.addrucode,
      owner: addressDetail.owner,
    };

    dispatch(addressCallEventAPI(body)).then((res) => {
      if (res.payload.result === "success") {
        let url = "/api/admin/call/checkOwner";
        let data = { idx: idx };
        axios.post(url, data).then((res) => {
          if (res.data.result === "success" && res.data.check) {
            let msg = "";
            if (res.data.check === "Y") {
              msg = "* 등기사건 조회 준비가 완료되었습니다.";
            } else if (res.data.check === "N") {
              msg = "* 관계인 정보가 일치하지 않습니다.";
            } else{
              msg = "* 확인 중 입니다.";
            }
            setApiMessage({
              ...apiMessage,
              chg: res.data.check === "Y" ? true : false,
              chgMsg: msg,
            });
          }
        });
      } else alert("API 호출 실패");
    });
  };

  const callViewPdf = (item) => {
    let pdf = addressDetail.fileKey.split("|");
    let fileKey = "";

    pdf.map((key) => {
      if(item.substr(item.length-6, item.length) === key.substr(key.length-10, 6)){
        fileKey = key;
      }
    })

    let body = {
      _id: addressDetail.api_id,
      fileKey: fileKey,
    };
    if(fileKey === ""){
      alert("해당 등기부 파일이 존재하지 않습니다.")
    }else{
      dispatch(pdfCallAPI(body)).then((res) => {
        if (res.payload.result !== false) {
            // 경로가 담겨나오면 새창으로 열기
            window.open(res.payload.result, '_blank');
        } 
      });
    }
  }

  
  // 관계인 추가 버튼
  const addOwner = () => {
    let copyOwner;
    if (Array.isArray(addressDetail.owner)) {
      copyOwner = [...addressDetail.owner];
    } else {
      copyOwner = [];
    }
    copyOwner.push("");
    setAddressDetail({ ...addressDetail, owner: copyOwner });
  };

  // 관계인 추가 input 관리
  const handleOwner = (e, index) => {
    let copyOwner = [...addressDetail.owner];
    copyOwner[index] = e.target.value;
    setAddressDetail({ ...addressDetail, owner: copyOwner });
  };

  // 관계인 삭제
  const delOwnerHandler = (e, index) => {
    let copyOwner = [...addressDetail.owner];
    if(copyOwner.length === 1){
      copyOwner = [""];
    }else{
      copyOwner.splice(index, 1);
    }
    setAddressDetail({ ...addressDetail, owner: copyOwner });
  };

  // 고유번호 추가 버튼
  const addAddrucode = () => {
    let copyAddrucode;
    let copyRgstrTpCd = "";
    if (Array.isArray(addressDetail.addrucode)) {
      copyAddrucode = [...addressDetail.addrucode];
    } else {
      copyAddrucode = [];
    }

    copyAddrucode.push("");

    // 건물타입이 없는경우 건물(2)로 통일시킴
    if(addressDetail.rgstrTpCd !== null && addressDetail.rgstrTpCd !== undefined){
      copyRgstrTpCd = addressDetail.rgstrTpCd + ",2";
    }else{
      copyAddrucode.forEach((item, num) => {
        if(num !== 0) {copyRgstrTpCd += ","}
        copyRgstrTpCd += "2";
      });
    }

    setAddressDetail({ ...addressDetail, addrucode: copyAddrucode, rgstrTpCd:copyRgstrTpCd });
  };


  // 토지구분 명칭변경
  const tpcdType = (index, item) => {
    let result;
    if(item !== "" && item !== null){
      let cdItem = item.split(",");
      // eslint-disable-next-line default-case
      switch(cdItem[index]){
        case "1":
          result = "집합건물";
          break;
        case "2":
          result = "건물";
          break;
        case "3": 
          result = "토지";
          break;
      }
    }else{
      result =  "건물";
    }
      return result;
  };

  // 고유번호 추가 input 관리
  const handleAddrucode = (e, index) => {
    let copyAddrucode = [...addressDetail.addrucode];
    copyAddrucode[index] = e.target.value;
    setAddressDetail({ ...addressDetail, addrucode: copyAddrucode });
  };

  // 고유번호 삭제
  const delAddrucodeHandler = (e, index) => {
    let copyAddrucode = [...addressDetail.addrucode];
    let copyRgstrTpCd = addressDetail.rgstrTpCd.split(",");
    let joinRgstrTpCd;
    if(copyAddrucode.length === 1){
      copyAddrucode = [""];
      joinRgstrTpCd = "";
    }
    else{
      copyAddrucode.splice(index, 1);
      copyRgstrTpCd.splice(index, 1);
      joinRgstrTpCd = copyRgstrTpCd.join(",");
    }

    setAddressDetail({ ...addressDetail, addrucode: copyAddrucode, rgstrTpCd:joinRgstrTpCd });
  };

  // 상세 주소 Input
  const handleDetailAddress = (e) => {
    setAdminAddress({...adminAddress, [e.target.name]:e.target.value});
  };

  // PTS 호출 상태
  const ptsResultState = (result,date,msg) => {
    let item = "";
  
    if(date === undefined || date === null || date === ""){
      date = "";
    }

    switch (result){
      case 'N': item = "미진행";
      break;
      case 'W': item = <span style={{color:'#1876bb'}}>진행중</span>;
      break;
      case 'Y': item = <span>처리완료 ( {date})</span>;
      break;
      case 'F': item = <span style={{color:'#ff0000'}}>실패 ( {date}) : {msg}</span>;
      break;
    }
    return item;
  }


  useEffect(() => {
    if(addrTypeChg){
        addrTypeRadio();
    }
  }, [addressDetail.addrtype]);

  useLayoutEffect(() => {
    // api호출 밑에있는 메시지 숨기기
    // eslint-disable-next-line
    setAlarmContent({ ...alarmContent, addr: false, chg: false });
    // eslint-disable-next-line0
  }, []);

  useLayoutEffect(() => {
    let body = {
      idx: idx,
    };

    // 관리주소 가져오기
    dispatch(userAddressDetail(body)).then((res) => {
      let payloadData = res.payload.address;
      
      if (payloadData) {
        // 고유번호가 없을시 고유번호정보 입력칸을 위해 빈값으로 생성
        if(!payloadData.addrucode){
          payloadData.addrucode = [""];
        }  
        // 소유주가 없을시 소유주 입력칸을 위해 빈값으로 생성
        if(!payloadData.owner){
          payloadData.owner = [""];
        }
        setAddressDetail(payloadData);
        setAdminAddress({...adminAddress, addr:payloadData.confirmaddr, addrsub:payloadData.confirmaddrsub});
        if (payloadData.checkowner === "Y"){
          setApiMessage({ ...apiMessage, chg: true });
        }

        // 초기값 저장
        if(backUpData === false){
          setOriginalAddrType(payloadData);
          setBackUpData(true);
        }

        // PTS호출이 미진행이면 버튼 오픈
        if(payloadData.ownerapi === "N" && payloadData.addrtype === "미승인"){
          setPtsbtn(true);
        }

      }
    });
    // eslint-disable-next-line

  }, [dispatch, idx]);

  // 관리주소 삭제하기
  let deleteAddress = () => {
    let body = {
      idx: idx,
      memidx: addressDetail.memidx,
    };
    dispatch(userAddressDelete(body)).then((res) => {
      if (res.payload.result) {
        alert("삭제되었습니다.");
        history.goBack();
      } else {
        alert("삭제에 실패하였습니다. 다시 시도해주세요.");
      }
    });
  };

  // API 호출 결과 불러오기
  const [apiResult, setApiResult] = useState({});
  let getResult = (api_name) => {
    setApiResult({});
    let url = "/api/admin/user/address/apiResult";
    let data = {
      addridx: idx,
      api_name: api_name,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        setApiResult(res.data.apiResult);
      } else {
        alert("결과를 불러오지 못하였습니다.");
      }
    });
    openModal("modal1");
  };

  // 경매배당기일 삭제
  const delVerstdate = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      let url = "/api/admin/user/address/delVerstdate";
      let data = { idx: idx };

      axios.post(url, data).then((res) => {
        if (res.data.result === "success") {
          alert("삭제되었습니다.");
          deleteRentdate("verstdate");
        } else {
          alert("결과를 불러오지 못하였습니다.");
        }
      });
    }
  };

  return (
    <>
      <Header />
      <div className="admin_container" id="addressDetail">
        <ul className="side_container">
          <Link to="/rf_zib_admin/address/1">
            <li className="side_tab active">
              등록주소
              <img src={tab} alt="tab" />
            </li>
          </Link>
        </ul>
        <div className="main_container">
          <div>
            <div className="detail_user_header">
              <h2 className="main_container_name">등록주소 상세 정보</h2>

              <div
                className="admin_delete_btn btn"
                onClick={() => {
                  deleteAddress();
                }}
              >
                주소 삭제
              </div>
            </div>
            <div className="address_detail">
              <table className="detail_table">
                <tbody>
                <tr>
                    <th colSpan="2">PTS 호출</th>
                    <td>
                      <div style={{display:"flex"}}>
                        <div style={{alignSelf:"center", marginRight:"10px", width:"350px"}}> 
                          {ptsResultState(addressDetail.ownerapi, changeDateFormat(addressDetail.resultdate, 5, ".", ":"), addressDetail.content)}
                        </div>
                        {ptsbtn?<div 
                          className="admin_blue_btn btn"
                          onClick={() => {
                            ptsCall();
                          }}
                        >
                          PTS API 호출
                        </div>:""}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="2">등록일</th>
                    <td>
                      {changeDateFormat(
                        addressDetail.registerdate,
                        5,
                        ".",
                        ":"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="2">회원 이름</th>
                    <td>
                      <input
                        readOnly
                        type="text"
                        name="addrnickname"
                        value={addressDetail.memrealname}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="2">부동산 닉네임</th>
                    <td>
                      <input
                        readOnly
                        type="text"
                        name="addrnickname"
                        value={addressDetail.addrnickname}
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="column_name" rowSpan="3">
                      회원 입력
                      <br />
                      정보
                    </th>
                    <th className="sub_th">기본주소</th>
                    <td>
                      <input
                        readOnly
                        type="text"
                        name="addr"
                        onChange={inputHandler}
                        value={addressDetail.addr}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="sub_th">상세주소</th>
                    <td>
                      <input
                        readOnly
                        type="text"
                        value={addressDetail.addrsub}
                        name="addrsub"
                        onChange={inputHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="sub_th">소유형태</th>
                    <td>
                      <div className="free_user_radio">
                        <input
                          type="radio"
                          id="radio1"
                          name="addrower"
                          checked={addressDetail.addrower === "자가" && true}
                          value="자가"
                          onChange={inputHandler}
                          disabled
                        />
                        <label htmlFor="radio1">자가</label>
                        <input
                          type="radio"
                          id="radio2"
                          name="addrower"
                          value="임차"
                          onChange={inputHandler}
                          checked={addressDetail.addrower === "임차" && true}
                          disabled
                        />
                        <label htmlFor="radio2">임차(전/월세)</label>
                      </div>
                    </td>
                  </tr>
                  {/*
                  <tr>
                    <th className="sub_th">임대차 계약일</th>
                    <td>
                      <div className="day_change_count">
                        {addressDetail.addrower === "임차" ? (
                          <>
                            <input
                              type="date"
                              value={changeDateFormat(
                                addressDetail.addrrentdate,
                                3,
                                "-",
                                ""
                              ).trim()}
                              name="addrrentdate"
                              // onChange={inputHandler}
                              className="calendar_data"
                              readOnly
                            />
                             <div
                              className="admin_white_btn admin_white_btn100 btn"
                              onClick={() => deleteRentdate("addrrentdate")}
                            >
                              삭제
                            </div> 
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                  </tr>
                  */}
                  {/* 
                  <tr>
                    <th className="sub_th">임대차 만기일</th>
                    <td>
                      <div className="day_change_count">
                        {addressDetail.addrower === "임차" ? (
                          <>
                            <input
                              type="date"
                              value={changeDateFormat(
                                addressDetail.addrrentedate,
                                3,
                                "-",
                                ""
                              ).trim()}
                              name="addrrentedate"
                              // onChange={inputHandler}
                              className="calendar_data"
                              readOnly
                            />
                             <div
                              className="admin_white_btn admin_white_btn100 btn"
                              onClick={() => deleteRentdate("addrrentedate")}
                            >
                              삭제
                            </div> 
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                  </tr>
                  */}
                  <tr>
                    <th className="column_name" rowSpan="2">
                      관리자
                      <br />
                      인증주소
                    </th>
                    <th className="sub_th">기본주소</th>
                    <td style={{display:"flex"}}>
                      <input
                        type="text"
                        name="addr"
                        value={adminAddress.addr}
                        onChange={handleDetailAddress}
                      />
                      <div className="admin_black_btn btn" 
                        style={{
                          marginLeft:"10px", 
                          width: 100, 
                          height:38
                        }} onClick={() => openModal("modal2")}>검색</div>
                    </td>
                  </tr>
                  <tr>
                    <th className="sub_th">상세주소</th>
                    <td>
                      <input
                        type="text"
                        value={adminAddress.addrsub}
                        name="addrsub"
                        onChange={handleDetailAddress}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="detail_table_title" style={{ display: "flex", alignItems:"center" }}>
              <h3 >지번주소 및 고유번호정보</h3>
              <div
                className="admin_black_btn btn"
                style={{ width: 100, height: 28, marginLeft:"10px",  }}
                onClick={() => {
                  getResult("고유번호");
                }}
              >
                API 통신 결과
              </div>
              </div>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th className="refine_address">지번주소</th>
                    <td>
                      <div style={{ display: "flex" }}>
                        <textarea
                          style={{
                            width: 400,
                            height: 60,
                            padding: "6px 22px",
                            fontSize: 16,
                          }}
                          value={ addressDetail.addrrenew || "" }
                          name="addrrenew"
                          readOnly
                        ></textarea>
                        <div
                          className="admin_black_btn btn"
                          onClick={() => {
                            callApi("Y");
                          }}
                          style={{ marginLeft: 10, height: "auto", width: 100, textAlign:"center", lineHeight:1.2, letterSpacing:1 }}
                        >
                          고유번호 호출<br/>(API)
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="detail_table">
                <tbody>
                  {addressDetail.addrucode
                    && addressDetail.addrucode.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th>고유번호 {index + 1}</th>
                            <td>
                              <div className="day_change_count">
                                <input
                                  type="text"
                                  value={tpcdType(index,addressDetail.rgstrTpCd)}
                                  className="serial_type"
                                  style={{"width":"100px","marginRight":"10px"}}
                                  readOnly
                                />
                                <input
                                  type="text"
                                  value={item}
                                  className="serial_number"
                                  onChange={(e) => {
                                    handleAddrucode(e, index);
                                  }}
                                />
                                <div
                                  className="admin_white_btn admin_white_btn100 btn"
                                  onClick={(e) => {
                                    delAddrucodeHandler(e, index);
                                  }}
                                >
                                  삭제
                                </div>
                                {
                                  index === 0?
                                  <div className="admin_black_btn btn admin_white_btn100" 
                                       style={{ height: "40px" }} 
                                       onClick={() => {addAddrucode();}}>
                                       추가
                                  </div>
                                  :
                                  null
                                }
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    }
                </tbody>
              </table>


              <div className="detail_table_title" style={{ display: "flex", alignItems:"center" }}>
                {/*
                  <div 
                    style={{marginRight:"10px"}}
                    className="admin_black_btn btn"
                    onClick={() => {
                      callOwnerApi();
                    }}
                  >
                    관계인 호출 API
                  </div>
                */}          
                  <h3>관계인(소유주) 정보</h3>
                  <div
                    className="admin_black_btn btn"
                    style={{ width: 100, height: 28, marginLeft:"10px",  }}
                    onClick={() => {
                      getResult("관계인");
                    }}
                  >
                    API 통신 결과
                  </div>

              </div>

              <table className="detail_table">
                <tbody>
                  {/* addressDetail.ownerapi === "W"?<tr><th>관계인(소유주)</th><td style={{color:"blue"}}> [ 자료 호출중... ] </td></tr>:null */}
                  {addressDetail.owner === null && addressDetail.ownerapi === "F" ?<tr><th>관계인(소유주)</th><td style={{color:"red"}}> [ 관계인(소유자)가 없거나 주소가 잘못되었습니다. ] </td></tr>:null}
                  {addressDetail.owner &&
                    addressDetail.owner.map((item, index) => {
                      return (
                        <tr key={index}>
                          <th>관계인(소유주) {index + 1}</th>
                          <td>
                            <div className="day_change_count">
                              <input
                                type="text"
                                className="serial_number"
                                value={item}
                                onChange={(e) => {
                                  handleOwner(e, index);
                                }}
                              />
                              <div
                                className="admin_white_btn admin_white_btn100 btn"
                                onClick={(e) => {
                                  delOwnerHandler(e, index);
                                }}
                              >
                                삭제
                              </div>
                              {
                              index === 0?
                              <div
                              className="admin_black_btn admin_white_btn100 btn"
                              style={{ height: "40px" }} 
                              onClick={() => {
                                addOwner();
                              }}
                              >
                              추가
                              </div>
                              :null
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    }
                </tbody>
              </table>

              <div
                style={{
                  width: 696,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span style={{ display:"flex", color: "red", alignItems:"center", marginRight: 10 }}>
                  {apiMessage ? apiMessage.chgMsg : ""}
                </span>
                <div
                  className="admin_black_btn btn"
                  onClick={() => {
                    callEventApi();
                  }}
                  style={{ width: 210, height:40, textAlign:"center", lineHeight:1.2, letterSpacing:1 }}
                >
                  관계인 호출<br/>(API)
                </div>
              </div>           

              <h3 className="detail_table_title">등기부등본 정보</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>고유번호</th>
                    <td style={{"display":"flex"}}>
                    {!addressDetail.fileKey ? <div style={{"margin":"20px"}}>등기부등본 자료가 없습니다.</div>
                     : addressDetail.addrucode && addressDetail.addrucode.map((item, index) => {
                        return (
                          <div className="admin_white_btn admin_white_btn200 btn" 
                               style={{"fontSize":"14px","margin":"5px"}}
                               onClick={() => {
                                callViewPdf(item);
                               }
                              }
                          >{item}</div>
                        )})}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">경매배당 정보</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>경매배당기일</th>
                    <td>
                      <div className="day_change_count">
                        <input
                          type="date"
                          value={
                            addressDetail.verstdate &&
                            changeDateFormat(
                              addressDetail.verstdate,
                              3,
                              "-",
                              ""
                            ).trim()
                          }
                          name="verstdate"
                          onChange={inputHandler}
                          className="calendar_data"
                        />

                        <div
                          className="admin_white_btn admin_white_btn100 btn"
                          onClick={() => {
                            delVerstdate();
                          }}
                        >
                          삭제
                        </div>
                        <div
                          className="admin_blue_btn btn verstdate_btn"
                          onClick={() => {
                            submitVerstdate();
                          }}
                        >
                          저장
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">등록정보 승인 여부</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>승인 결과</th>
                    <td style={{ padding: 0 }}>
                      <div className="approval_column">
                        <div className="free_user_chk">
                          <input
                            type="radio"
                            id="chk1"
                            name="addrtype"
                            checked={
                              addressDetail.addrtype === "승인완료" && true
                            }
                            value="승인완료"
                            onChange={inputHandler}
                          />
                          <label htmlFor="chk1">승인완료</label>
                          <input
                            type="radio"
                            id="chk2"
                            name="addrtype"
                            checked={
                              addressDetail.addrtype === "미승인" && true
                            }
                            value="미승인"
                            onChange={inputHandler}
                          />
                          <label htmlFor="chk2">미승인</label>
                          <input
                            type="radio"
                            id="chk3"
                            name="addrtype"
                            checked={addressDetail.addrtype === "보류" && true}
                            value="보류"
                            onChange={inputHandler}
                          />
                          <label htmlFor="chk3">보류</label>
                        </div>

                        <div className="warning">
                          {addressDetail.addrtypedate &&
                            "※ 최종처리일 : " +
                              changeDateFormat(
                                addressDetail.addrtypedate,
                                5,
                                ".",
                                ":"
                              )
                          }
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>결과 사유</th>
                    <td style={{ padding: 0 }}>
                      <div className="approval_column">
                        {addressDetail.addrtype === "승인완료"?
                        <div className="free_user_chk">
                          <ul>
                            <li>
                              <input
                                type="radio"
                                id="chk11"
                                name="addrtype2"
                                checked={( addressDetail.addrtype2 === "1" || addressDetail.addrtype2 === null ) && true}
                                value="승인"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk11">승인</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk12"
                                name="addrtype2"
                                checked={addressDetail.addrtype2 === "2" && true}
                                value="관계인 불일치"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk12">관계인 불일치</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk13"
                                name="addrtype2"
                                checked={addressDetail.addrtype2 === "3" && true}
                                value="관계인(소유주) 불일치 및 소유권 이전"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk13">관계인(소유주) 불일치 및 소유권 이전</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk14"
                                name="addrtype2"
                                checked={addressDetail.addrtype2 === "4" && true}
                                value="기타"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk14">기타</label>
                              <input type="text" style={{width:250}} onChange={etcCommentHandler} value={addressDetail.addrtype2 === "4" ? addressDetail.etcomment:""}/>
                            </li>
                          </ul>
                        </div>:null}

                        {addressDetail.addrtype === "미승인"?
                        <div className="free_user_chk">
                          <ul>
                            <li>
                              <input
                                type="radio"
                                id="chk20"
                                name="addrtype2"
                                checked={
                                  (addressDetail.addrtype2 === "0" || addressDetail.addrtype2 === null) && true
                                }
                                value=""
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk20">미처리</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk21"
                                name="addrtype2"
                                checked={
                                  addressDetail.addrtype2 === "1" && true
                                }
                                value="상세입력주소 불확실"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk21">상세입력주소 불확실</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk22"
                                name="addrtype2"
                                checked={
                                  addressDetail.addrtype2 === "2" && true
                                }
                                value="관계인 확인불가"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk22">관계인 확인불가</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk23"
                                name="addrtype2"
                                checked={addressDetail.addrtype2 === "3" && true}
                                value="건물등기 없음"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk23">건물등기 없음</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="chk24"
                                name="addrtype2"
                                checked={addressDetail.addrtype2 === "4" && true}
                                value="기타"
                                onChange={etcInputHandler}
                              />
                              <label htmlFor="chk24">기타</label>
                              <input type="text" style={{width:250}} onChange={etcCommentHandler} value={addressDetail.addrtype2 === "4" ? addressDetail.etcomment:""}/>
                            </li>
                          </ul>
                        </div>:null}

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="detail_table_title">기타정보</h3>
              <table className="detail_table">
                <tbody>
                  <tr>
                    <th>메모</th>
                    <td>
                        <textarea
                          style={{
                            width: 500,
                            height: 100,
                            padding: "6px 22px",
                            fontSize: 16,
                          }}
                          name="memo"
                          onChange={inputHandler}
                          value={ addressDetail.memo || "" }
                        ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>                    
              <div className="detail_user admin_btn_column">
                <div
                  className="admin_white_btn btn left "
                  onClick={() => {
                    history.push(`/rf_zib_admin/address/${pagen}`);
                  }}
                >
                  목록
                </div>
                <div className="admin_blue_btn btn " onClick={submitHandler}>
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={modal["modal1"]} width="80%" height="80%">
        <div
          className="modal_inner_ct modal_400"
          style={{ paddingLeft: padding, paddingRight: padding }}
        >
          {apiResult ? (
            <>
              <div className="modal_title">
                [요청 일자]{" "}
                {apiResult.request_date &&
                  changeDateFormat(apiResult.request_date, 6, "ko", "ko")}
              </div>
              <div className="modal_title">
                [부동산 닉네임]{" "}
                {addressDetail.addrnickname && addressDetail.addrnickname}
              </div>
              <div className="modal_title">
                [호출 API 명] {apiResult.api_name && apiResult.api_name}
              </div>

              <div className="modal_content">
                {apiResult.content && apiResult.content}
              </div>
              <div className="modal_warning"></div>
            </>
          ) : (
            <>
              <div className="modal_title">
                {addressDetail.addrnickname}에 대한 요청이 존재하지 않습니다.
              </div>
            </>
          )}

          <div
            className="blue_btn btn close address_modal_btn"
            onClick={() => closeModal("modal1")}
          >
            닫기
          </div>
        </div>
      </Modal>
      <Modal
            open={modal["modal2"]}
            width={500}
            center={true}
            close={() => closeModal("modal2")}
          >
            <DaumPostcode
              onComplete={handleComplete}
              style={{ height: 480, padding: 7}}
            />
      </Modal>
    </>
  );
}

export default AddressDetail;
